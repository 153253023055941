//import { useState } from "react";
import { SitePayload, Contact } from "../redux/types";

type occupationProps = {
  siteId: string;
  range: string;
  nombre: number;
  taux: number;
}
type statsOccup = {
  [key:string]: occupationProps[];
}

export const piscineFields = { present: ["Oui", "Non"], type: ["Hors sol","Enterrée"], chauffage: ["Oui","Non"]}
export type piscineInfos = { present: string; type: string; chauffage: string; longueurOrDiam: number; largeur: number; prof: number; consoPompe: string; note: string }
export const getPiscineInfos = (strPiscine: string[] | undefined) => {
  let tableau: piscineInfos = {present: "Non", type: '', chauffage: '', longueurOrDiam: 0, largeur: 0, prof: 0, consoPompe: '', note: ''}
  if(strPiscine === undefined) { return tableau }
  if(strPiscine[0] === "Oui") {
    tableau.present = strPiscine[0]
    if(strPiscine[1] !== "$") { tableau.type = strPiscine[1] }
    if(strPiscine[2] !== "$") { tableau.chauffage = strPiscine[2] }
    if(strPiscine[3] !== "$") { tableau.longueurOrDiam = Number(strPiscine[3]) }
    if(strPiscine[4] !== "$") { tableau.largeur = Number(strPiscine[4]) }
    if(strPiscine[5] !== "$") { tableau.prof = Number(strPiscine[5]) }
    tableau.consoPompe = strPiscine[6]
  }
  if(strPiscine[7]?.length >= 3) { tableau.note = strPiscine[7] }
//  console.log("Infos Piscine (returned values functionExtract): "+tableau.present+" "+tableau.type+" "+tableau.chauffage)
  return tableau
}
export const chauffFields = [{ 
  nrj: "Bois",
  detail: ["Chaudière","Chaudière bois déchiquetté","Chaudière granulés","Cheminée","Poêle bois déchiquetté Poêle à granulés","Poêle à buches","Autre"],
  rq: ""
  },
  {nrj: "Collectif",
  detail: ["Collectif granulés","Collectif bois déchiquetté","Collectif fioul Collectif gaz","Collectif PAC","Autre"],
  rq: ""
  },
  {nrj: "Electricité",
  detail: ["Panneaux rayonnant - Convecteur","Plancher chauffant","Appoint conséquent","Appoint léger","Appoint PC","Sèche serviette","Soufflant","Radiateur à inertie","Bain d'huile","Infra-rouge","Chaudière","Autre"],
  rq: ""
  },
  {nrj: "Fioul",
  detail: ["Chaudière","Poêle","Autre"],
  rq: ""
  },
  {nrj: "Gaz",
  detail: ["Chaudière","Poêle","Autre"],
  rq: ""
  },
  {nrj: "Multi-split Air/Air",
  detail: ["2 (bi split)","3 (tri split)","4 (quadri split)","Technologie « inverter »","COP > 5,1 (A+++)","COP entre 4,6 et 5,1 (A++)","COP entre 4 et 4,6 (A+)","COP entre 3,4 et 4 (A)","Autre"],
  rq: ""
  },
  {nrj: "Multi-split Air/Eau",
  detail: ["2 (bi split)","3 (tri split)","4 (quadri split)","Technologie « inverter »","COP > 5,1 (A+++)","COP entre 4,6 et 5,1 (A++)","COP entre 4 et 4,6 (A+)","COP entre 3,4 et 4 (A)","Autre"],
  rq: ""
  },
  {nrj: "PAC Air/Air",
  detail: ["COP > 5,1 (A+++)","COP entre 4,6 et 5,1 (A++)","COP entre 4 et 4,6 (A+)","COP entre 3,4 et 4 (A)","Autre"],
  rq: ""
  },
  {nrj: "PAC Air/Eau",
  detail: ["COP > 5,1 (A+++)","COP entre 4,6 et 5,1 (A++)","COP entre 4 et 4,6 (A+)","COP entre 3,4 et 4 (A)","Autre"],
  rq: ""
  },
  {nrj: "Autre",
  detail: [""],
  rq: ""
  },
  {nrj: "Aucun",
  detail: ["Logement passif"],
  rq: ""
  }]
  
  export const chauffFields2 = [{ 
    name: "Bois",
    items: [{ name: "Chaudière" },{ name: "Chaudière bois déchiquetté" },{ name: "Chaudière granulés" },{ name: "Cheminée" },{ name: "Poêle bois déchiquetté Poêle à granulés" },{ name: "Poêle à buches" },{ name: "Autre" }],
    rq: ""
    },
    {name: "Collectif",
    items: [{ name: "Collectif granulés" },{ name: "Collectif bois déchiquetté" },{ name: "Collectif fioul Collectif gaz" },{ name: "Collectif PAC" },{ name: "Autre"}],
    rq: ""
    },
    {name: "Electricité",
    items: [{ name: "Panneaux rayonnant - Convecteur" },{ name: "Plancher chauffant" },{ name: "Appoint conséquent" },{ name: "Appoint léger" },{ name: "Appoint PC" },{ name: "Sèche serviette" },{ name: "Soufflant" },{ name: "Radiateur à inertie" },{ name: "Bain d'huile" },{ name: "Infra-rouge" },{ name: "Chaudière" },{ name: "Autre"}],
    rq: ""
    },
    {name: "Fioul",
    items: [{ name: "Chaudière" },{ name: "Poêle" },{ name: "Autre"}],
    rq: ""
    },
    {name: "Gaz",
    items: [{ name: "Chaudière" },{ name: "Poêle" },{ name: "Autre"}],
    rq: ""
    },
    {name: "Multi-split Air/Air",
    items: [{ name: "2 (bi split)" },{ name: "3 (tri split)" },{ name: "4 (quadri split)" },{ name: "Technologie « inverter »" },{ name: "COP > 5,1 (A+++)" },{ name: "COP entre 4,6 et 5,1 (A++)" },{ name: "COP entre 4 et 4,6 (A+)" },{ name: "COP entre 3,4 et 4 (A)" },{ name: "Autre"}],
    rq: ""
    },
    {name: "Multi-split Air/Eau",
    items: [{ name: "2 (bi split)" },{ name: "3 (tri split)" },{ name: "4 (quadri split)" },{ name: "Technologie « inverter »" },{ name: "COP > 5,1 (A+++)" },{ name: "COP entre 4,6 et 5,1 (A++)" },{ name: "COP entre 4 et 4,6 (A+)" },{ name: "COP entre 3,4 et 4 (A)" },{ name: "Autre"}],
    rq: ""
    },
    {name: "PAC Air/Air",
    items: [{ name: "COP > 5,1 (A+++)" },{ name: "COP entre 4,6 et 5,1 (A++)" },{ name: "COP entre 4 et 4,6 (A+)" },{ name: "COP entre 3,4 et 4 (A)" },{ name: "Autre"}],
    rq: ""
    },
    {name: "PAC Air/Eau",
    items: [{ name: "COP > 5,1 (A+++)" },{ name: "COP entre 4,6 et 5,1 (A++)" },{ name: "COP entre 4 et 4,6 (A+)" },{ name: "COP entre 3,4 et 4 (A)" },{ name: "Autre"}],
    rq: ""
    },
    {name: "Autre",
    items: [{ name: ""}],
    rq: ""
    },
    {name: "Aucun",
    items: [{ name: "Logement passif"}],
    rq: ""
  }]
/*export type chauffInfos = [{ nrj: string | undefined; detail: string[] | undefined; rq: string | undefined },
  { nrj: string | undefined; detail: string[] | undefined; rq: string | undefined },
  { nrj: string | undefined; detail: string[] | undefined; rq: string | undefined }]*/
export type chauffInfos = { nrj: string | undefined; detail: string[] | undefined; rq: string | undefined }
/****************************************************************************************************/
/*************** Doit retourner les infos courantes du logement à propos du chauffage ***************/
/****************************************************************************************************/
export const getChauffInfos = (strChauffNrj: string[] | undefined, strChauffDetail: string[][] | undefined, strChauffRq: string[] | undefined) => {
  let tableau: chauffInfos[] = [{ nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }]
//  let tableau: string[{string, string[], string}] = [{ nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }]
  strChauffNrj?.map((s,index) => {
    //console.log("strChauffNrj mapped: "+s)
    if(s === undefined && strChauffDetail?.[index] === undefined /*&& strChauffRq?[index] === undefined*/) { return tableau }
    if(s !== undefined) { tableau[index].nrj = s }
    //tableau[index].detail = [""]
    strChauffDetail?.[index].map((c) => { 
      if(c !== undefined && c.length > 2) { 
        //c.split(",").map((d) => tableau[index].detail?.concat(d+",")) 
        tableau[index].detail?.push(c)
      //  console.log(c)
      }
    })
    if(strChauffRq?.[index] !== undefined) { tableau[index].rq = strChauffRq?.[index] }
//    console.log(tableau[index].detail?.map((d) => d+" / "))
    console.log("Infos CHAUFF (returned values functionExtract): "+tableau[index].nrj+" "+tableau[index].detail?.map((d) => "["+d+"]")+" "+tableau[index].rq)
  })
  return tableau
}
export const plaquesFields = { 
  type: ["Induction","Vitrocéramique","Fonte","Gaz"],
  nbFeux: ["1","2","3","4","5","6","7","8","9","10"],
  rq: ""
}
export type plaquesInfos = { type: string | undefined; nbFeux: string | undefined; rq: string | undefined }
export const getPlaquesInfos = (typeFeux: string[] | undefined, nbFeux: string[] | undefined, rqFeux: string[] | undefined) => {
  let tableau: plaquesInfos[] = [{ type: "", nbFeux: "", rq: "" }, {type: "", nbFeux: "", rq: "" }]
//  let tableau: string[{string, string[], string}] = [{ nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }]
  typeFeux?.map((s,index) => {
    //console.log("strChauffNrj mapped: "+s)
    if(s === undefined && nbFeux?.[index] === undefined /*&& strChauffRq?[index] === undefined*/) { return tableau }
    if(s !== undefined) { tableau[index].type = s }
    //tableau[index].detail = [""]
    tableau[index].nbFeux = nbFeux?.[index]
    if(rqFeux?.[index] !== undefined) { tableau[index].rq = rqFeux?.[index] }
//    console.log(tableau[index].detail?.map((d) => d+" / "))
    console.log("Infos PLAQUES (returned values functionExtract): "+tableau[index].type+" "+tableau[index].nbFeux+" "+tableau[index].rq)
  })
  return tableau
}
export const fourFields = { 
  type: ["Convection","Chaleur tournante"],
  details: "",
  rq: ""
}
export type fourInfos = { type: string | undefined; detail: string | undefined; rq: string | undefined }
export const getFourInfos = (type: string[] | undefined, detail: string[] | undefined, rq: string[] | undefined) => {
  let tableau: fourInfos[] = [{ type: "", detail: "", rq: "" }, {type: "", detail: "", rq: "" }]
//  let tableau: string[{string, string[], string}] = [{ nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }]
  type?.map((s,index) => {
    //console.log("strChauffNrj mapped: "+s)
    if(s === undefined && detail?.[index] === undefined /*&& strChauffRq?[index] === undefined*/) { return tableau }
    if(s !== undefined) { tableau[index].type = s }
    //tableau[index].detail = [""]
    tableau[index].detail = detail?.[index]
    if(rq?.[index] !== undefined) { tableau[index].rq = rq?.[index] }
//    console.log(tableau[index].detail?.map((d) => d+" / "))
    console.log("Infos PLAQUES (returned values functionExtract): "+tableau[index].type+" "+tableau[index].detail+" "+tableau[index].rq)
  })
  return tableau
}
export const climFields = { 
  type: ["PAC air eau", "PAC eau eau", "Split (air-air)", "MultiSplit", "Climatiseur sur prise/mobile", "Aucune"], 
  detail: ["","peu utilisé", "pas utilisé", "réversible mais pas utilisé", "autre"],
  rq: ""
}
export type climInfos = [{ type: string | undefined; detail: string | undefined; rq: string | undefined },
  { type: string | undefined; detail: string | undefined; rq: string | undefined },
  { type: string | undefined; detail: string | undefined; rq: string | undefined }]
export const getClimInfos = (type: string[] | undefined, detail: string[] | undefined, rq: string[] | undefined) => {
  let tableau: climInfos = [{ type: "", detail: "", rq: "" }, {type: "", detail: "", rq: "" }, {type: "", detail: "", rq: "" }]
  type?.map((s,index) => {
    if(s === undefined && detail?.[index] === undefined ) { return tableau }
    if(s !== "Aucune") {
      if(s !== undefined) { tableau[index].type = s }
      if(detail?.[index] !== undefined) { tableau[index].detail = detail?.[index] }
      if(rq?.[index] !== undefined) { tableau[index].rq = rq?.[index] }
    } else {
      tableau[index].type = s
    }
    console.log("Infos CLIM (returned values functionExtract): "+tableau[index].type+" "+tableau[index].detail+" "+tableau[index].rq)
  })
  return tableau
}
export const ventilFields = { 
  type: ["Collective partie commune","Double flux","Simple flux autoréglable","Simple flux hygroréglable","Ne sais pas", 
    "Entrées d'air fenêtres", "Aucune"],
  detail: ["","Home made","Puit canadien","Peu utilisé", "Pas utilisé", "Ne sais pas ", "SDB only", "WC only","Autre"],
  rq: ""
}
export type ventilInfos = [{ type: string | undefined; detail: string | undefined; rq: string | undefined },
  { type: string | undefined; detail: string | undefined; rq: string | undefined },
  { type: string | undefined; detail: string | undefined; rq: string | undefined }]
export const getVentilInfos = (type: string[] | undefined, detail: string[] | undefined, rq: string[] | undefined) => {
  let tableau: ventilInfos = [{ type: "", detail: "", rq: "" }, {type: "", detail: "", rq: "" }, {type: "", detail: "", rq: "" }]
  type?.map((s,index) => {
    if(s === undefined && detail?.[index] === undefined ) { return tableau }
    if(s !== "Aucune") {
      if(s !== undefined) { tableau[index].type = s }
      if(detail?.[index] !== undefined) { tableau[index].detail = detail?.[index] }
      if(rq?.[index] !== undefined) { tableau[index].rq = rq?.[index] }
    } else {
      tableau[index].type = s
    }
    console.log("Infos VENTIL (returned values functionExtract): "+tableau[index].type+" "+tableau[index].detail+" "+tableau[index].rq)
  })
  return tableau
}
export const getChauffInfosBack = (strChauffNrj: string[] | undefined, strChauffDetail: string[][] | undefined, strChauffRq: string[] | undefined) => {
  let tableau: chauffInfos[] = [{ nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }]
//  let tableau: string[{string, string[], string}] = [{ nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }, {nrj: "", detail: [], rq: "" }]
  strChauffNrj?.map((s,index) => {
    console.log("strChauffNrj mapped: "+s)
    if(s === undefined && strChauffDetail?.[index] === undefined /*&& strChauffRq?[index] === undefined*/) { return tableau }
    if(s !== undefined) { tableau[index].nrj = s }
    //tableau[index].detail = [""]
    strChauffDetail?.[index].map((c) => { 
      if(c !== undefined && c.length > 2) { 
        //c.split(",").map((d) => tableau[index].detail?.concat(d+",")) 
        tableau[index].detail?.push(c)
        console.log(c)
      }
    })
    if(strChauffRq?.[index] !== undefined) { tableau[index].rq = strChauffRq?.[index] }
//    console.log(tableau[index].detail?.map((d) => d+" / "))
    console.log("Infos CHAUFF (returned values functionExtract): "+tableau[index].nrj+" "+tableau[index].detail?.map((d) => "["+d+"]")+" "+tableau[index].rq)
  })
  return tableau
}
export const ecsFields = { 
  nrj: ["Collectif","Cumulus électrique","Chauffe-eau thermodynamique","Depuis chaudière gaz","Depuis chaudière fioul","Depuis PAC",
    "Solaire","Autre", "Aucune"],
  detail: ["Pas de détail","Appoint cumulus électrique","Appoint chauffe-eau thermodynamique","Appoint depuis chaudière","Appoint depuis PAC",
    "Appoint solaire","Appoint autre","Pas d'appoint"],
  rq: ""
}
export type ecsInfos = [{ nrj: string | undefined; detail: string | undefined; rq: string | undefined },
  { nrj: string | undefined; detail: string | undefined; rq: string | undefined }]
export const getEcsInfos = (strEcsNrj: string[] | undefined, strEcsDetail: string[] | undefined, strEcsRq: string[] | undefined) => {
  let tableau: ecsInfos = [{ nrj: "", detail: "", rq: "" }, {nrj: "", detail: "", rq: "" }]
  strEcsNrj?.map((s,index) => {
    if(s === undefined && strEcsDetail?.[index] === undefined /*&& strEcsRq?[index] === undefined*/) { return tableau }
    if(s !== "Aucune") {
      if(s !== undefined) { tableau[index].nrj = s }
      if(strEcsDetail?.[index] !== undefined) { tableau[index].detail = strEcsDetail?.[index] }
      if(strEcsRq?.[index] !== undefined) { tableau[index].rq = strEcsRq?.[index] }
    } else {
      tableau[index].nrj = s
    }
    console.log("Infos ECS (returned values functionExtract): "+tableau[index].nrj+" "+tableau[index].detail+" "+tableau[index].rq)
  })
  return tableau
}

export const getSDetailRegister = (currentData: SitePayload | undefined, contact: Contact | undefined) => {
  let statOccupy: statsOccup = {}
  let set: Array<occupationProps> = []

  if(currentData !== undefined && contact !== undefined) {
    currentData?.tx_occupation?.map((line) => {
      let tab = line.split("|")
  //      console.log("line occupy: "+tab[0]+","+tab[1]+","+tab[2])
      set.push({siteId: currentData.id, range: tab[0], nombre: Number(tab[1].replace("nb ","")), taux: Number(tab[2].replace("poids ","")) })
    })
  //    set.map((x) => console.log("SSSSSSSSSSSEEETTTT: "+x.range+" "+x.nombre+" "+x.taux))
    statOccupy[currentData.id] = set
    let currOccupation: occupationProps[] = Array.from(statOccupy[currentData.id]);
    currOccupation.map((co) => console.log("currOkk: "+co.range+" "+co.nombre+" "+co.taux))

    console.log("DEBUG REGISTER: "+currentData?.cpt_type)
    return ({
      sn_site: currentData?.sn_site || '',
      site_id: currentData?.id || '',
      ctct_prenom: contact?.prenom || '',
      ctct_nom: contact?.nom || '',
      ctct_tel: contact?.tel || '',
      ctct_mail: contact?.mail || '',
      ctct_adresse: contact?.adresse || '',
      ctct_code_postal: contact?.code_postal || '',
      ctct_actif: contact?.actif || '',
      ctct_revenus: contact?.revenus || '',
      ctct_remarques: contact?.remarques || '',
      gmt_date: currentData?.gmt_date || '',
      //occup_0_10_nb: currentData?.occup_0_10_nb || '',
      /*occup_0_10_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("0-10"))?.nombre || '',//find((oc) => oc.range["0-10"]).nombre,
      occup_11_17_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("11-17"))?.nombre || '',
      occup_18_24_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("18-24"))?.nombre || '',
      occup_25_59_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("25-59"))?.nombre || '',
      occup_60_74_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("60-74"))?.nombre || '',
      occup_74_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare(">74"))?.nombre || '',
      occup_0_10_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("0-10"))?.taux || '',
      occup_11_17_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("11-17"))?.taux || '',
      occup_18_24_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("18-24"))?.taux || '',
      occup_25_59_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("25-59"))?.taux || '',
      occup_60_74_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("60-74"))?.taux || '',
      occup_74_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare(">74"))?.taux || '',*/
      //const currentData?.Occupation: occupationProps[] = Array.from(statOccupy[currSite_id]);
      occupation: Array.from(statOccupy[currentData.id]) as occupationProps[],
      cpt_type: currentData?.cpt_type || '',
      type_inst_elec: currentData?.type_inst_elec || '',
      index_cpt_elec: currentData?.index_cpt_elec || '',
      index_cpt_hc: currentData?.index_cpt_hc || '',
      tarif_elec: currentData?.tarif_elec || '',
      puiss_sscrite: currentData?.puiss_sscrite || '',
      prm_linky: currentData?.prm_linky || '',
      adresse: currentData?.adresse || '',
      code_postal: currentData?.code_postal || '',
      instrum_by: currentData?.instrum_by || '',
      nature: currentData?.nature || '',
      surface: currentData?.surface || '',
      surf_unused: currentData?.surf_unused || '',
      surf_nonchauff: currentData?.surf_nonchauff || '',
      niveaux: currentData?.niveaux || '',
      date_update: currentData?.date_update || '',
      rq_instru: currentData?.rq_instru || '',
      chauff_nrj: currentData?.chauff_nrj || '',
      chauff_detail: currentData?.chauff_detail || '',
      chauff_rq: currentData?.chauff_rq || '',
      ecs_nrj: currentData?.ecs_nrj || '',
      ecs_detail: currentData?.ecs_detail || '',
      ecs_rq: currentData?.ecs_rq || '',
      cuissonplq_nrj: currentData?.cuissonplq_nrj || '',
      cuissonplq_detail: currentData?.cuissonplq_detail || '',
      cuissonplq_rq: currentData?.cuissonplq_rq || '',
      four_type: currentData?.four_type || '',
      four_detail: currentData?.four_detail || '',
      four_rq: currentData?.four_rq || '',
      ventil_type: currentData?.ventil_type || '',
      ventil_detail: currentData?.ventil_detail || '',
      ventil_rq: currentData?.ventil_rq || '',
      clim_type: currentData?.clim_type || '',
      clim_detail: currentData?.clim_detail || '',
      clim_rq: currentData?.clim_rq || '' })
    }
    else {
      console.log("REGISTER: currentData and/or contact are undefined")
      set.push({ siteId: "0", range: "0-10", nombre: 0, taux: 0 })
      set.push({ siteId: "0", range: "11-17", nombre: 0, taux: 0 })
      set.push({ siteId: "0", range: "18-24", nombre: 0, taux: 0 })
      set.push({ siteId: "0", range: "25-59", nombre: 0, taux: 0 })
      set.push({ siteId: "0", range: "60-74", nombre: 0, taux: 0 })
      set.push({ siteId: "0", range: ">74", nombre: 0, taux: 0 })
      statOccupy[0] = set
      return ({
        sn_site: '',
        site_id: '',
        ctct_prenom: '',
        ctct_nom: '',
        ctct_tel: '',
        ctct_mail: '',
        ctct_adresse: '',
        ctct_code_postal: '',
        ctct_actif: '',
        ctct_revenus: '',
        ctct_remarques: '',
        gmt_date: '',
        //occup_0_10_nb: '',
        /*occup_0_10_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("0-10"))?.nombre || '',//find((oc) => oc.range["0-10"]).nombre,
        occup_11_17_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("11-17"))?.nombre || '',
        occup_18_24_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("18-24"))?.nombre || '',
        occup_25_59_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("25-59"))?.nombre || '',
        occup_60_74_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("60-74"))?.nombre || '',
        occup_74_nb: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare(">74"))?.nombre || '',
        occup_0_10_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("0-10"))?.taux || '',
        occup_11_17_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("11-17"))?.taux || '',
        occup_18_24_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("18-24"))?.taux || '',
        occup_25_59_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("25-59"))?.taux || '',
        occup_60_74_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare("60-74"))?.taux || '',
        occup_74_txpres: occupationStats[Number(currSite_id)].find((oc) => oc.range.localeCompare(">74"))?.taux || '',*/
        //const currentData?.Occupation: occupationProps[] = Array.from(statOccupy[currSite_id]);
        occupation: Array.from(statOccupy[0]) as occupationProps[],
        cpt_type: '',
        type_inst_elec: '',
        index_cpt_elec: '',
        index_cpt_hc: '',
        tarif_elec: '',
        puiss_sscrite: '',
        prm_linky: '',
        adresse: '',
        code_postal: '',
        instrum_by: '',
        nature: '',
        surface: '',
        surf_unused: '',
        surf_nonchauff: '',
        niveaux: '',
        date_update: '',
        rq_instru: '',
        chauff_nrj: '',
        chauff_detail: '',
        chauff_rq: '',
        ecs_nrj: '',
        ecs_detail: '',
        ecs_rq: '',
        cuissonplq_nrj: '',
        cuissonplq_detail: '',
        cuissonplq_rq: '',
        four_type: '',
        four_detail: '',
        four_rq: '',
        ventil_type: '',
        ventil_detail: '',
        ventil_rq: '',
        clim_type: '',
        clim_detail: '',
        clim_rq: '' })
    }
};
