import axios from 'axios';
import backendUrl from '../backendUrl';
import { setConfig } from './auth';
import { SitePayload } from '../redux/types';

const baseUrl = `${backendUrl}/sites`;

const getSites = async () => {
  const response = await axios.get(baseUrl, setConfig());
  return response.data;
};

const getSitesSn = async (siteSn: string) => {
  const response = await axios.get(`${backendUrl}/site/${siteSn}`, setConfig());
  return response.data;
};

const getSiteBySiteId = async (siteId: string) => {
  const response = await axios.get(`${backendUrl}/site/${siteId}`, setConfig());
  console.log(response.data);
  return response.data;
}; 

const createSite = async (siteData: SitePayload) => {
  const response = await axios.post(baseUrl, siteData, setConfig());
  return response.data;
};

const deleteSite = async (siteId: string) => {
  const response = await axios.delete(`${baseUrl}/${siteId}`, setConfig());
  return response.data;
};

const editSiteSn = async (siteId: string, newSN: string) => {
  const response = await axios.put(
    `${baseUrl}/${siteId}`,
    { name: newSN },
    setConfig()
  );
  return response.data;
};

const updateLegendes = async (siteSn: string) => {
  const response = await axios.post(`${baseUrl}/legendes/update/${siteSn}`, {}, setConfig());
  return response.data;
};

const siteService = {
  getSites,
  getSitesSn,
  getSiteBySiteId,
  createSite,
  deleteSite,
  editSiteSn,
  updateLegendes,
};

export default siteService;
