import axios from 'axios';
import backendUrl from '../backendUrl';
import { setConfig } from './auth';
import { QuestionsInstrumPayload }from '../redux/types';

//const baseUrl = `${backendUrl}/sites`;
const baseUrl = `${backendUrl}/questionsinstrums`;

//const getQuestionsIntrums = async (siteId: string) => {
const getQuestionsIntrums = async () => {
//onst response = await axios.get(baseUrl, setConfig());
//  const response = await axios.get(`${backendUrl}/sites/questionsIntrums`, setConfig());
  const response = await axios.get(`${backendUrl}/questionsinstrums`, setConfig());
  return response.data;
};

const getSiteQuestionsIntrums = async (site_sn: string) => {
  //onst response = await axios.get(baseUrl, setConfig());
  const response = await axios.get(`${backendUrl}/sites/${site_sn}/questionsinstrums`, setConfig());
  return response.data;
};

const addQuestionsIntrums = async (site_id: string, questionsIntrums: string[]) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/questionsIntrums`,
    { questionsIntrums },
    setConfig()
  );
  return response.data;
};

const removeQuestionsIntrum = async (site_id: string, questionsIntrumId: string) => {
//const removeQuestionsIntrum = async (site_id: string, questionsIntrumId: number) => {
  const response = await axios.delete(
    `${baseUrl}/${site_id}/questionsIntrums/${questionsIntrumId}`,
    setConfig()
  );
  return response.data;
};

const leaveSite = async (site_id: string) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/questionsIntrums/leave`,
    null,
    setConfig()
  );
  return response.data;
};

const questionsIntrumService = { 
  addQuestionsIntrums,
  removeQuestionsIntrum,
  leaveSite,
  getQuestionsIntrums,
  getSiteQuestionsIntrums,
};

export default questionsIntrumService;
