import { EquipementState, EquipementSortValues, Equipement } from '../redux/types';

const sortEquipements = (equipements: EquipementState[], sortBy: EquipementSortValues) => {
  switch (sortBy) {
    case 'default':
      return equipements
    case 'newest':
      return equipements
        .slice()
        //@ts-ignore
        .sort((a, b) => +new Date(b.app_date_start) - +new Date(a.app_date_start));
    case 'oldest':
      return equipements
        .slice()
        //@ts-ignore
        .sort((a, b) => +new Date(a.app_date_start) - +new Date(b.app_date_start));
    case 'usage-a-z':
      return equipements
        .slice()
        .sort((a, b) =>
          a.usage.name.localeCompare(b.usage.name/*str_equip*/, "fr", { ignorePunctuation: true })
        );
    case 'usage-z-a':
      return equipements
        .slice()
        .sort((a, b) =>
          b.usage.name.localeCompare(a.usage.name, "fr", { ignorePunctuation: true })
        );
    case 'poste-a-z':
      return equipements
        .slice()
        .sort((a, b) =>
          a.poste.name.localeCompare(b.poste.name/*str_equip*/, 'en', { caseFirst: 'upper', ignorePunctuation: true, sensitivity: 'base' })
//          a.str_equip.localeCompare(b.poste.name/*str_equip*/, 'en', { caseFirst: 'upper', ignorePunctuation: true, sensitivity: 'base' })
        );
    case 'poste-z-a':
      return equipements
        .slice()
        .sort((a, b) =>
          b.poste.name.localeCompare(a.poste.name, 'fr', { sensitivity: 'base' })
        );
    case 'room-a-z':
      return equipements
        .slice()
        .sort((a, b) =>
          a.room?.name.localeCompare(b.room?.name/*str_equip*/, 'fr', { usage: 'sort', ignorePunctuation: true, sensitivity: 'base' })
        );
    case 'room-z-a':
      return equipements
        .slice()
        .sort((a, b) =>
          b.room?.name.localeCompare(a.room?.name, 'fr', { sensitivity: 'base' })
        );
    case 'nonsuivi':
      //return equipements.slice();
      //@ts-ignore
      return equipements.slice().sort((a,b) => {
        if (b.voies2mesures?.length > 0 && a.voies2mesures?.length > 0) {
          return true;
        }
        if (a.voies2mesures?.length > 0) {
          return true;
        }
//        if (!b.app_date_end && b.voies2mesures.length > 0) {
        if (a.voies2mesures.length === 0) {
          return false;
        }
        return true;
      }); 
/*    case 'app-start-asc':
      return equipements.slice().sort((a, b) => {
        if(a.app_date_start !== null && b.app_date_start !== null) {
          if (+new Date(a.app_date_start) - +new Date(b.app_date_start)) {
            return 1;
          }
          if (+new Date(b.app_date_start) - +new Date(a.app_date_start)) {
            return -1;
          }
        }
        return +new Date(b.app_date_start!) - +new Date(a.app_date_start!);
      });*/
    case 'app-end-asc':
      return equipements.slice().sort((a, b) => {
        if(a.app_date_end !== null && b.app_date_end !== null) {
          if (+new Date(a.app_date_end) - +new Date(b.app_date_end)) {
            return 1;
          }
          if (+new Date(b.app_date_end) - +new Date(a.app_date_end)) {
            return -1;
          }
          return +new Date(b.app_date_end) - +new Date(a.app_date_end);
        } else { return 0 }
      });
    case 'app-end-desc':
      return equipements.slice().sort((a, b) => {
        if(a.app_date_end !== null && b.app_date_end !== null) {
          if (+new Date(a.app_date_end) - +new Date(b.app_date_end)) {
            return -1;
          }
          if (+new Date(b.app_date_end) - +new Date(a.app_date_end)) {
            return 1;
          }
          return +new Date(b.app_date_end) - +new Date(a.app_date_end);
        } else { return 0 }
      });
    case 'suivi-start-asc':
      return equipements
    case 'suivi-start-desc':
      return equipements
    case 'suivi-end-asc':
      return equipements
    case 'suivi-end-desc':
      return equipements
    case 'most-notes':
      return equipements.slice().sort((a, b) => b.comments.length - a.comments.length);
    case 'least-notes':
      return equipements.slice().sort((a, b) => a.comments.length - b.comments.length);
  }
};

export default sortEquipements;
  
