import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  createNewConcentrateur,
  editConcentrateur,
  clearSubmitConcentrateurError,
  selectConcentrateursState,
} from '../../redux/slices/concentrateursSlice';
import { ConcentrateurPayload } from '../../redux/types';
import ErrorBox from '../../components/ErrorBox';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  InputAdornment,
  FormLabel,
  FormControl,
} from '@material-ui/core';
import { useFormStyles } from '../../styles/muiStyles';
import TitleIcon from '@material-ui/icons/Title';
import SubjectIcon from '@material-ui/icons/Subject';

const validationSchema = yup.object({
  hardSn: yup
    .string()
    .required('Required')
    .min(3, 'Must be at least 3 characters')
    .max(60, 'Must be at most 60 characters'),

  description: yup.string().required('Required'),
});

interface ConcentrateurFormProps {
  closeDialog?: () => void;
  siteId: string;
/*  siteSn: string;
  hardSn: string;
  tel: string;
  date_last_data: Date;
  ts_last_data: Date;
  modules: number[];*/
  concId: string;
  isEditMode: boolean;
  currentData?: ConcentrateurPayload;
//  concentrateurId?: string;
}

const ConcentrateurForm: React.FC<ConcentrateurFormProps> = ({
  closeDialog,
  isEditMode,
  siteId,
  concId,
//  siteSn,
  currentData,
//  concentrateurId,
}) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { submitError, submitLoading } = useSelector(selectConcentrateursState);
  const { register, control, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      /*title: currentData?.title || '',*/
      /*hardSn: currentData?.hardSn || '',
      tel: currentData?.tel || '',
      concId: currentData?.concId || '',*/
      siteId: currentData?.siteId || '',
    },
  });

  const handleCreateConcentrateur = (data: ConcentrateurPayload) => {
    dispatch(createNewConcentrateur(siteId, data, closeDialog));
  };

  const handleUpdateConcentrateur = (data: ConcentrateurPayload) => {
    dispatch(editConcentrateur(siteId, /*siteSn,*/ concId as string, data, closeDialog));
  };

  return (
    <form
      onSubmit={handleSubmit(isEditMode ? handleUpdateConcentrateur : handleCreateConcentrateur)}
    >
      <TextField
        inputRef={register}
        name="hardSn"
        required
        fullWidth
        type="text"
        label="Concentrateur SN"
        variant="outlined"
        error={'hardSn' in errors}
        helperText="SN Concentrateur"/*{'hardSn' in errors ? errors.hardSn?.message : ''}*/
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <TitleIcon color="primary" />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        className={classes.fieldMargin}
        multiline
        rows={1}
        rowsMax={4}
        inputRef={register}
        name="tel"
        required
        fullWidth
        type="text"
        label="Téléphone"
        variant="outlined"
        error={'tel' in errors}
        helperText="N° téléphone concentrateur"/*{'tel' in errors ? errors.tel?.message : ''}*/
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SubjectIcon color="primary" />
            </InputAdornment>
          ),
        }}
      />
      <Button
        size="large"
        color="primary"
        variant="contained"
        fullWidth
        className={classes.submitBtn}
        type="submit"
        disabled={submitLoading}
      >
        {isEditMode ? 'Update Concentrateur' : 'Create New Concentrateur'}
      </Button>
      {submitError && (
        <ErrorBox
          errorMsg={submitError}
          clearErrorMsg={() => dispatch(clearSubmitConcentrateurError())}
        />
      )}
    </form>
  );
};

export default ConcentrateurForm;
