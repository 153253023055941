import { createMuiTheme } from '@material-ui/core/styles';

const customTheme = (darkMode: boolean) =>
  createMuiTheme({
    palette: {
      type: darkMode ? 'dark' : 'light',
      primary: {
        main: '#009688',
        light: '#00968840',
      },
      secondary: {
        main: darkMode ? '#e4fffd' : '#005b53',
        //@ts-ignore
        appchgetableHeaderEdit: '#c51325',
        appchgetableHeaderEvol: '#008000',
        appchgetableHeaderAdd: '#005fd2',
        appchgetable: darkMode ? '#f97725' : '#007acc',
        headerappmod: darkMode ? 'black' : 'white',
        headerappmodbckgrd: darkMode ? '#007acc' : '#f97725',
	      apptabledblesuivi: darkMode ? '#333' : '#101010',
      },
    },
    overrides: {
      MuiTypography: {
        root: {
          wordBreak: 'break-word',
        },
      },
    },
  });

export default customTheme;
