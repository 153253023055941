import axios from 'axios';
import backendUrl from '../backendUrl';
import { setConfig } from './auth';
import { ConcentrateurPayload } from '../redux/types';

const baseUrl = `${backendUrl}/sites`;

const addConcentrateurs = async (siteId: string, concentrateurs: string[]) => {
  const response = await axios.post(
    `${baseUrl}/${siteId}/concentrateurs`,
    { concentrateurs },
    setConfig()
  );
  return response.data;
};

const getAllConcentrateurs = async () => {
// const response = await axios.get(`${baseUrl}/${siteId}/concentrateurs`, setConfig());
  const response = await axios.get(`${backendUrl}/allconcentrateurs`, setConfig());
  return response.data;
};

const getConcentrateursBySiteSn = async (siteSn: string) => {
//  const response = await axios.get(`${baseUrl}/${siteSn}/concentrateurs`, setConfig());
  const response = await axios.get(`${backendUrl}/concentrateurs/${siteSn}/concentrateurs`, setConfig());
  return response.data;
};

const createConcentrateur = async (siteId: string, concentrateurData: ConcentrateurPayload) => {
  const response = await axios.post(
    `${baseUrl}/${siteId}/concentrateurs`,
    concentrateurData,
    setConfig()
  );
  return response.data;
};

const updateConcentrateur = async (
  siteId: string,
  concentrateurId: string,
  concentrateurData: ConcentrateurPayload
) => {
  const response = await axios.put(
    `${baseUrl}/${siteId}/concentrateurs/${concentrateurId}`,
    concentrateurData,
    setConfig()
  );
  return response.data;
};

const deleteConcentrateur = async (siteId: string, concentrateurId: string) => {
  const response = await axios.delete(
    `${baseUrl}/${siteId}/concentrateurs/${concentrateurId}`,
    setConfig()
  );
  return response.data;
};

const closeConcentrateur = async (siteId: string, concentrateurId: string) => {
  const response = await axios.post(
    `${baseUrl}/${siteId}/concentrateurs/${concentrateurId}/close`,
    null,
    setConfig()
  );
  return response.data;
};

const reopenConcentrateur = async (siteId: string, concentrateurId: string) => {
  const response = await axios.post(
    `${baseUrl}/${siteId}/concentrateurs/${concentrateurId}/reopen`,
    null,
    setConfig()
  );
  return response.data;
};

const concentrateurService = {
  addConcentrateurs,
  getAllConcentrateurs,
  getConcentrateursBySiteSn,
  createConcentrateur,
  updateConcentrateur,
  deleteConcentrateur,
  closeConcentrateur,
  reopenConcentrateur,
};

export default concentrateurService;
