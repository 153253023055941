import axios from 'axios';
import backendUrl from '../backendUrl';
import { setConfig } from './auth';
import { RoomPayload }from '../redux/types';

//const baseUrl = `${backendUrl}/sites`;
const baseUrl = `${backendUrl}/rooms`;

//const getRooms = async (siteId: string) => {
const getRooms = async () => {
//onst response = await axios.get(baseUrl, setConfig());
//  const response = await axios.get(`${backendUrl}/sites/rooms`, setConfig());
  const response = await axios.get(`${backendUrl}/rooms`, setConfig());
  return response.data;
};

const getSiteRooms = async (site_sn: string) => {
  //onst response = await axios.get(baseUrl, setConfig());
  const response = await axios.get(`${backendUrl}/sites/${site_sn}/rooms`, setConfig());
  return response.data;
};

const addRooms = async (site_id: string, rooms: string[]) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/rooms`,
    { rooms },
    setConfig()
  );
  return response.data;
};

const removeRoom = async (site_id: string, roomId: string) => {
//const removeRoom = async (site_id: string, roomId: number) => {
  const response = await axios.delete(
    `${baseUrl}/${site_id}/rooms/${roomId}`,
    setConfig()
  );
  return response.data;
};

const leaveSite = async (site_id: string) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/rooms/leave`,
    null,
    setConfig()
  );
  return response.data;
};

const roomService = { 
  addRooms,
  removeRoom,
  leaveSite,
  getRooms,
  getSiteRooms,
};

export default roomService;
