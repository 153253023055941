import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import siteService from '../../services/sites';
import contactService from '../../services/contacts';
import concentrateurService from '../../services/concentrateurs';
import {
  SiteState,
  SiteSortValues,
  SitePayload,
  SiteContact,
  SiteConcentrateur,
  Concentrateur,
  Contact,
} from '../types';
import { fetchContacts } from './contactsSlice';
import { fetchConcentrateursBySitesSn } from './concentrateursSlice';
import { notify } from './notificationSlice';
import { History } from 'history';
import { getErrorMsg } from '../../utils/helperFuncs';
import { fetchEquipementsForSiteSn } from './equipementsSlice';
//import { selectEquipementsBySiteSn } from './equipementsSlice';

interface InitialSitesState {
  sites: SiteState[];
//  contacts: {};
  fetchStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  fetchError: string | null;
  submitLoading: boolean;
  submitError: string | null;
  sortBy: SiteSortValues;
}

const initialState: InitialSitesState = {
  sites: [],
  fetchStatus: 'idle',
  fetchError: null,
  submitLoading: false,
  submitError: null,
  sortBy: 'sn-sites-asc',
};

const sitesSlice = createSlice({
  name: 'sites',
  initialState,
  reducers: {
    setSites: (state, action: PayloadAction<SiteState[]>) => {
      state.sites = action.payload;
      state.fetchStatus = 'succeeded';
      state.fetchError = null;
    },
    addSite: (state, action: PayloadAction<SiteState>) => {
      state.sites.push(action.payload);
      state.submitLoading = false;
      state.submitError = null;
    },
    removeSite: (state, action: PayloadAction<string>) => {
      state.sites = state.sites.filter((p) => p.id !== action.payload);
    },
    updateSiteName: (
      state,
      action: PayloadAction<{
        data: { siteSn: string; date_update: Date };
        siteId: string;
      }>
    ) => {
      state.sites = state.sites.map((p) =>
        p.id === action.payload.siteId ? { ...p, ...action.payload.data } : p
      );
      state.submitLoading = false;
      state.submitError = null;
    },
    addContacts: (
      state,      
      action: PayloadAction<{ contacts: string; siteId: string }>
      //action: PayloadAction<{ contacts: SiteContact; siteId: string }>
    ) => {
/*      state.sites = state.sites.map((p) =>
        p.id === action.payload.siteId
          ? { ...p, contacts: action.payload.contacts }
          : p
      );
      state.submitLoading = false;
      state.submitError = null;*/
    },
    removeContact: (
      state,
      action: PayloadAction<{ contactId: string; siteId: string }>
    ) => {
      const site = state.sites.find(
        (p) => p.id === action.payload.siteId
      );
    /*
      if (site) {
        const updatedContacts = site.contacts.filter(
          (m) => m.contact.id !== action.payload.contactId
        );
        state.sites = state.sites.map((p) =>
          p.id === action.payload.siteId
            ? { ...p, contacts: updatedContacts }
            : p
        );
      }*/
    },
    addConcentrateurs: (
      state,
      action: PayloadAction<{ concentrateurs: /*Site*/Concentrateur[]; siteId: string }>
    ) => {
      state.sites = state.sites.map((p) =>
        p.id === action.payload.siteId
          ? { ...p, concentrateurs: action.payload.concentrateurs }
          : p
      );
      state.submitLoading = false;
      state.submitError = null;
    },
    removeConcentrateur: (
      state,
      action: PayloadAction<{ concentrateurId: string; siteId: string }>
    ) => {
      const site = state.sites.find(
        (p) => p.id === action.payload.siteId
      );
    },
    setFetchSitesLoading: (state) => {
      state.fetchStatus = 'loading';
      state.fetchError = null;
    },
    setFetchSitesError: (state, action: PayloadAction<string>) => {
      state.fetchStatus = 'failed';
      state.fetchError = action.payload;
    },
    setSubmitSiteLoading: (state) => {
      state.submitLoading = true;
      state.submitError = null;
    },
    setSubmitSiteError: (state, action: PayloadAction<string>) => {
      state.submitLoading = false;
      state.submitError = action.payload;
    },
    clearSubmitSiteError: (state) => {
      state.submitError = null;
    },
    sortSitesBy: (state, action: PayloadAction<SiteSortValues>) => {
      state.sortBy = action.payload;
    },
  },
});

export const {
  setSites,
  addSite,
  removeSite,
  updateSiteName,
  addContacts,
  addConcentrateurs,
  removeContact,
  removeConcentrateur,
  setFetchSitesLoading,
  setFetchSitesError,
  setSubmitSiteLoading,
  setSubmitSiteError,
  clearSubmitSiteError,
  sortSitesBy,
} = sitesSlice.actions;

export const fetchSites = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setFetchSitesLoading());
      const allSites = await siteService.getSites();
      dispatch(setSites(allSites));
    } catch (e) {
      //@ts-ignore
      dispatch(setFetchSitesError(getErrorMsg(e)));
    }
  };
};

export const createNewSite = (
  siteData: SitePayload,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setSubmitSiteLoading());
      const newSite = await siteService.createSite(siteData);
      dispatch(addSite(newSite));
      dispatch(notify('New site added!', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitSiteError(getErrorMsg(e)));
    }
  };
};

export const deleteSite = (
  siteId: string,
  history: History
): AppThunk => {
  return async (dispatch) => {
    try {
      await siteService.deleteSite(siteId);
      history.push('/');
      dispatch(removeSite(siteId));
      dispatch(notify('Deleted the site.', 'success'));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const editSiteSn = (
  siteId: string,
  siteSn: string,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setSubmitSiteLoading());
      const updatedSite = await siteService.editSiteSn(
        siteId,
        siteSn
      );
      dispatch(
        updateSiteName({
          data: {
            siteSn: updatedSite.siteSn,
            date_update: updatedSite.date_update,
          },
          siteId,
        })
      );
      dispatch(notify("Edited the site's SN!", 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitSiteError(getErrorMsg(e)));
    }
  };
};

export const addSiteConcentrateurs = (  //Contacts
  siteId: string,
//  concentrateurs: Concentrateur[],
  concentrateurs: string[],
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setSubmitSiteLoading());
      const updatedConcentrateurs = await concentrateurService.addConcentrateurs(siteId, concentrateurs);
      dispatch(addConcentrateurs({ concentrateurs: updatedConcentrateurs, siteId }));
      dispatch(notify('New ceoncentrateur(s) added to the site!', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitSiteError(getErrorMsg(e)));
    }
  };
};

export const removeSiteContact = (
/*  siteId: string,
  contactId: string*/
  siteId: string, //number
  contactId: string // number
): AppThunk => {
  return async (dispatch) => {
    try {
      await contactService.removeContact(siteId, contactId);
 //     dispatch(removeContact({ contactId, siteId }));
      dispatch(notify('Removed the site contact.', 'success'));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const removeSiteConcentrateur = (
/*  siteId: string,
  contactId: string*/
  siteId: string,
  concentrateurId: string
): AppThunk => {
  return async (dispatch) => {
    try {
      await concentrateurService.deleteConcentrateur(siteId, concentrateurId);
  //     dispatch(removeContact({ contactId, siteId }));
      dispatch(notify('Removed the site concentrateur.', 'success'));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const leaveSiteContactship = (
  siteId: string,
  history: History
): AppThunk => {
  return async (dispatch) => {
    try {
      await contactService.leaveSite(siteId);
      history.push('/');
      dispatch(removeSite(siteId));
      dispatch(notify('Successfully left the site contactship!', 'success'));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const selectSitesState = (state: RootState) => state.sites;

export const selectSitesStateBySn = (state: RootState, siteSn: string) => {
  fetchSitesBySn(siteSn);
  console.log("Asked: sites ["+siteSn+"]");
  return /*state.sites.sites = */state.sites.sites.filter((s) => s.sn_site === siteSn);
//  return state.sites = state.sites.filter((p) => p.id !== action.payload);
};

export const selectSiteById = (state: RootState, siteId: string, contactId: string) => {
  return state.sites.sites.find((p) => p.id === siteId);
};

export const fetchSiteBySiteId = (siteId: string): AppThunk => {
  return async (dispatch) => {
    try {//@ts-ignore
      dispatch(setFetchSitesLoading());
      const site = await siteService.getSiteBySiteId(siteId);
      dispatch(setSites(site));
   //   dispatch(setEquipementsBySitesSn({ equipements: equipements, siteSn }));
    } catch (e) {
      //@ts-ignore
      dispatch(setFetchSitesError(getErrorMsg(e)));
    }
  };
};

export const fetchSitesBySn = (siteSn: string): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setFetchSitesLoading());
      const sitesBySn = await siteService.getSitesSn(siteSn);
      dispatch(setSites(sitesBySn));
      dispatch(fetchContacts());
      dispatch(fetchConcentrateursBySitesSn(siteSn));
      dispatch(fetchEquipementsForSiteSn(siteSn));
      console.log("getSitesBySn");
    } catch (e) {
      //@ts-ignore
      dispatch(setFetchSitesError(getErrorMsg(e)));
    }
  };
};

export const selectSiteBySiteId = (state: RootState, siteId: string/*, siteSn: string*/) => {
//  return state.equipements.equipements?.[siteSn];
// foreach on equipement<array>, return array of equipements ...
  console.log("grrrr before fetchSite...")
  fetchSiteBySiteId(siteId/*, siteSn*/);    // TEST

  console.log("selectSiteBySiteIdSn siteSlice " + siteId/* + " " + siteSn*/);
//  fetchEquipementsBySitesSn(siteSn);
//  return state.equipements.equipements[siteSn];     // apparently GOOD
  return state.sites.sites?.filter((s) =>
    s.id === siteId
  );
//  return state.equipements.equipements?.[siteSn];     // TEST !!!

//  return state.equipements.equipements.setEquipementsBySitesSn(state, siteSn);
  //.gefilterBy((s) => s.sn_site == sn_site);
};

export const selectSitesBySn = (state: RootState, siteSn: string/*, siteSn: string*/) => {
  //  return state.equipements.equipements?.[siteSn];
  // foreach on equipement<array>, return array of equipements ...
    console.log("grrrr before fetchSite...")
    fetchSiteBySiteId(siteSn/*, siteSn*/);    // TEST
  
    console.log("selectSitesBySn siteSlice " + siteSn/* + " " + siteSn*/);
  //  fetchEquipementsBySitesSn(siteSn);
  //  return state.equipements.equipements[siteSn];     // apparently GOOD
    return state.sites.sites?.filter((s) =>
      s.sn_site === siteSn
    );
  //  return state.equipements.equipements?.[siteSn];     // TEST !!!
  
  //  return state.equipements.equipements.setEquipementsBySitesSn(state, siteSn);
    //.gefilterBy((s) => s.sn_site == sn_site);
  };
  
/*export const selectSitesBySn = (siteSn: string,
  ): AppThunk => {
    console.log("selectSitesBySn AppThunk sitesSlice");
    return (dispatch) => {
      dispatch(fetchSitesBySn(siteSn));
      dispatch(fetchEquipementsForSiteSn(siteSn));
      dispatch(fetchContacts());
//      dispatch(fetchConcentrateursBySitesSn('100107'));
    }
// OKAY  return state.sites.sites.filter((s) => s.sn_site == sn_site);
//    return state.sites.sites.find((s) => s.sn_site == sitesIdList);
  };
*/
  export const viewSiteSn = (
    siteSn: string,
  ): AppThunk => {
    console.log("viewSiteSn AppThunk sitesSlice");
    return (dispatch) => {
      dispatch(fetchSitesBySn(siteSn));
      dispatch(fetchEquipementsForSiteSn(siteSn));
      dispatch(fetchContacts());
//      dispatch(fetchConcentrateursBySitesSn('100107'));
    }
  };

  export const updateLegendes = ( siteSn: string, ): AppThunk => {
      const res = siteService.updateLegendes(siteSn)
      console.log("server response: "+res)
      return (dispatch) => { dispatch(notify('Les légendes seront mises à jour dans un instant', 'success')); }
  }
export default sitesSlice.reducer;
