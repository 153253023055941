import { Switch, Route, Redirect } from 'react-router-dom';
import LoginPage from './pages/Auth/LoginPage';
import SignupPage from './pages/Auth/SignupPage';
import SitesPage from './pages/Main/SitesPage';
import SiteSnPage from './pages/Main/SiteSnPage';
import SiteDetailsPage from './pages/Main/SiteDetailsPage';
import EquipDetailsPage from './pages/Main/EquipDetailsPage';
import ConcentrateurDetailsPage from './pages/Main/ConcentrateursDetailsPage';
import NotFoundPage from './pages/Main/NotFoundPage';
import { useSelector } from 'react-redux';
import { selectAuthState } from './redux/slices/authSlice';
import storage from './utils/localStorage';

import { Container, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

const Routes = () => {
  const { user } = useSelector(selectAuthState);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const isLoggedIn = storage.loadUser() || user;

  return (
    <Container disableGutters={isMobile}>
      <Switch>
        <Route exact path="/">
          {isLoggedIn ? <SitesPage /> : <Redirect to="/login" />}
        </Route>
        {/*<Route exact path="/sites/:siteId">
          {isLoggedIn ? <SiteDetailsPage /> : <Redirect to="/login" />}
        </Route>*/}
        <Route exact path="/site/:siteSn/:siteId">
          {isLoggedIn ? <SiteSnPage /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/sites/:siteSn">
          {isLoggedIn ? <SiteDetailsPage /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/site/:siteSn/:siteId/equipements">
          {isLoggedIn ? <EquipDetailsPage /> : <Redirect to="/login" />}
          {/*user ? <EquipDetailsPage /> : <Redirect to="/login" />*/}
        </Route>
        <Route exact path="/site/:siteId/contact">
          {/*user ? <ContactDetailsPage /> : <Redirect to="/" />*/}
        </Route>
        <Route exact path="/site/:siteId/concentrateurs/:concentrateurId">
          {user ? <ConcentrateurDetailsPage /> : <Redirect to="/" />}
        </Route>
        <Route exact path="/login">
          {!isLoggedIn ? <LoginPage /> : <Redirect to="/" />}
        </Route>
        <Route exact path="/signup">
          {!isLoggedIn ? <SignupPage /> : <Redirect to="/" />}
        </Route>
        <Route>
          <NotFoundPage />
        </Route>
      </Switch>
    </Container>
  );
};

export default Routes;
