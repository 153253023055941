import { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { IconButton, List, ListItem, ListItemIcon, ListItemText, Collapse, makeStyles, Menu, MenuItem, FormControl, Paper, Link, Button, Tooltip } from "@material-ui/core";
import { Assignment, AssignmentOutlined, ExpandLess, ExpandMore, ReportProblem, ReportProblemOutlined, StarBorder } from "@material-ui/icons";
import ConfirmDialog from "./ConfirmDialog";
import { useDispatch } from 'react-redux';
import { deleteEquipement, closeReopenEquipement, deleteEquipementDB } from '../redux/slices/equipementsSlice';
import { ConcentrateurState, EquipementPayload, PosteState, QuestionsInstrumState, RoomState, UsageState, Voie2mesureState } from '../redux/types';
import { useBodyStyles } from '../styles/muiStyles';
import EquipementForm from '../pages/Main/EquipementForm';
//import EquipModSuivi from '../pages/Main/EquipModSuivi';
import FormDialog from './FormDialog';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import RedoIcon from '@material-ui/icons/Redo';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import AddIcon from '@material-ui/icons/AddBoxOutlined';
import DevOff from '@material-ui/icons/PowerOffRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForeverOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';
import CommentOutlinedIcon from '@material-ui/icons/CommentOutlined';
//import DatePicker from 'react-rainbow-components/components/DateTimePicker';
import RepAuxQuestions from './RepAuxQuestions';
import { forgotVoie2mesure } from '../redux/slices/voie2mesuresSlice';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    rootNoTopPad: {
      '&': {
        width: "100%",
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        paddingTop: 0,
      },
      'div svg': {
        height: '1em',
        width: '1em',    
      }
    },
    nested: {
        paddingLeft: theme.spacing(4)
    },
    nestedSecondLevel: {
        paddingLeft: theme.spacing(8)
    }
}));

interface EquipModMenuProps {
    /*  id_poste: number;
      id_type_usage: number;
      id_piece: number;
      comments: string;
      a_suivre: boolean;
      suivi: boolean;
      date_start: Date;
      date_end: Date;
      str_piece: string;
      str_equip: string;*/
      linkName: string;
      site_id: string;
      sn_site: string;
      id_voie: number;
      currentData: EquipementPayload;
    //  isResolved: boolean;
      usages: UsageState[];
      postes: PosteState[];
      pieces: RoomState[];
      sn_module: number;
      voies2mesures: Voie2mesureState[];
      questions: QuestionsInstrumState[];
      conc: ConcentrateurState[];
      iconSize?: 'small' | 'default' | 'large';
      voieId: number;
    }
    
    const EquipModMenu: React.FC<EquipModMenuProps> = ({
    /*  id_poste,
      id_type_usage,
      id_piece,
      comments,
      a_suivre,
      suivi,
      date_start,
      date_end,
      sn_site,
      str_piece,
      str_equip,*/
      linkName,
      sn_module,
      site_id,
      sn_site,
      id_voie,
      currentData,
      usages, postes, pieces, voies2mesures, questions,
      conc,
      iconSize,
      voieId,
    }) => {
//      console.log("Site SN ModMenu: "+sn_site);
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useBodyStyles(true);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const classes2 = useStyles();
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [openSecondLevel, setOpenSecondLevel] = useState(true);
    const handleClick1 = () => {
        setOpen1(!open1);
    };
    const handleClick2 = () => {
        setOpen2(!open2);
    };
    const handleClickSecondLevel = () => {
        setOpenSecondLevel(!openSecondLevel);
    };
    const handleOpenMenu = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
    };
      
    const handleCloseMenu = () => {
        setAnchorEl(null);
    };

    /*const handleDeleteEquipementSite = () => {
    //    dispatch(deleteEquipement(site_id, sn_site, history));
    dispatch(deleteEquipementSite(site_id, currentData.sn_site, history));
    };*/
    const handleDeleteEquipementDB = () => {
      //    dispatch(deleteEquipement(site_id, sn_site, history));
      dispatch(deleteEquipementDB(site_id, sn_site, currentData.id, voieId/*currentData.sn_site*/));
    };
    const handleForgotVoie2mesureDB = () => {
      //    dispatch(deleteEquipement(site_id, sn_site, history));
      dispatch(forgotVoie2mesure(site_id, sn_site, currentData.id, String(voieId)/*currentData.sn_site*/));
    };
    const handleCloseEquipement = () => {
      dispatch(closeReopenEquipement(site_id, currentData.sn_site, 'close'));
    };

    const handleReopenEquipement = () => {
    dispatch(closeReopenEquipement(site_id, currentData.sn_site, 'reopen'));
    };

    let suivi: boolean;
    if(voies2mesures.filter((v) => v.id_equipement === Number(currentData.id)).length > 0) { 
      suivi = true
//      console.log("app SUIVI = true") 
    }
    else {
      suivi = false
//      console.log("app SUIVI = false")
    }

    let dbleSuivi: boolean;
    if(voies2mesures.filter((v) => v.id_equipement === Number(currentData.id) && v.voie_date_end !== null).length > 1) { 
      dbleSuivi = true
//      console.log("app SUIVI = true") 
    }
    else {
      dbleSuivi = false
//      console.log("app SUIVI = false")
    }

    return (
        <div>
 {/*}       <IconButton onClick={handleOpenMenu} size="small">
          <MoreHorizIcon color="primary" fontSize={iconSize || 'large'} />
    </IconButton>*/}
        <DevOff style={{top: '0.1rem', position: 'relative', 
        color: currentData.app_date_end !== null ? 'black' : 'transparent' }} /> 
        <span style={{bottom: '0.265rem', position: 'relative'}}>
        {currentData.str_equip?.length > 0 ?
          (<Tooltip title={<><h3>{currentData.str_equip}</h3><h3>{"ID: "+currentData.id}</h3><h3>{currentData.reponses}</h3></>}>
            <Link onClick={handleOpenMenu} color="secondary">
              {linkName}
            </Link>
          </Tooltip>)
          : <Tooltip title={<><h3>{linkName}</h3><h3>{"ID: "+currentData.id}</h3><h3>{currentData.reponses}</h3></>}>
          <Link onClick={handleOpenMenu} color="secondary">
            {linkName}
          </Link>
        </Tooltip>
        }
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseMenu}
          marginThreshold={8}
          elevation={4}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes2.rootNoTopPad}
        >
            <ListItem button onClick={handleClick1}>
            <ListItemIcon>
                <ReportProblemOutlined />
            </ListItemIcon>
            <ListItemText primary="Correction BDD/instrumentation" />
            {open1 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open1} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes2.nested}>
            {/*}    <FormDialog
                  triggerBtn={{
                    type: 'menu',
                    text: 'Modifier l\'appareil',
                    icon: EditOutlinedIcon,
                    iconStyle: { marginRight: '10px' },
                    closeMenu: handleCloseMenu,
                  }}
                  title="Correction des infos sur l'appareil"
                >
                  <EquipementForm
                    isEditMode={true}
		                //voieId={id_voie}
                    voieId={Number(voies2mesures.find((v)=>v.id_equipement === Number(currentData.id))?.id) || id_voie}
                    siteId={site_id}
                    siteSn={sn_site}
                    sn_module={sn_module}
                    conc={conc}
                    currentData={currentData}
                    usages={usages}
                    postes={postes}
                    pieces={pieces}
                    voiesDuSite={voies2mesures}
                    questions={questions}
                    dbleSuivi={dbleSuivi}
                    isSuivi={suivi}
		                newEquip={false}
                    cas={"device"}
                  />
                </FormDialog>*/}
                <FormDialog
                  triggerBtn={{
                    type: 'menu',
                    text: 'Modifier les infos',
                    icon: EditOutlinedIcon,
                    iconStyle: { marginRight: '10px' },
                    closeMenu: handleCloseMenu,
                  }}
                  title="Correction des infos"
                >
                  <EquipementForm
                    isEditMode={true}
		                //voieId={id_voie}
                    ////voieId={Number(voies2mesures.find((v)=>v.id_equipement === Number(currentData.id))?.id) || id_voie}
                    voieId={voieId}
                    siteId={site_id}
                    siteSn={sn_site}
                    sn_module={sn_module}
                    conc={conc}
                    currentData={currentData}
                    usages={usages}
                    postes={postes}
                    pieces={pieces}
                    voiesDuSite={voies2mesures}
                    questions={questions}
                    dbleSuivi={dbleSuivi}
                    isSuivi={suivi}
		                newEquip={false}
                    cas={"suivi"}
                  />
                </FormDialog>
                <ConfirmDialog
                  title="Supprimer sans garder de trace"
                  contentText="Supprimer définitevement cet appareil SANS garder de trace dans la base ? L'appareil sera supprimé AINSI que toutes les voies qui lui sont associées."
                  actionBtnText="Supprimer l'appareil du logement"
                  triggerBtn={{
                    type: 'menu',
                    text: 'Supprimer l\'appareil du logement',
                    icon: DeleteForeverIcon,
                    iconStyle: { marginRight: '10px' },
                    closeMenu: handleCloseMenu,
                  }}
                  actionFunc={handleDeleteEquipementDB}
                />
                {voies2mesures.filter((v) => v.id_equipement === Number(currentData.id)).length > 0 ?
                <ConfirmDialog
                  title="Oublier cette voie de suivi"
                  contentText="Supprimer CE suivi de l'appareil = faire comme si cet appareil n'avait jamais été suivi sur CETTE voie (ne supprime pas les autres suivis éventuels)."
                  actionBtnText="Oublier ce suivi"
                  triggerBtn={{
                    type: 'menu',
                    text: 'Oublier cette voie de suivi',
                    icon: DeleteForeverIcon,
                    iconStyle: { marginRight: '10px' },
                    closeMenu: handleCloseMenu,
                  }}
                  actionFunc={handleForgotVoie2mesureDB}
                />
                : null }
            </List>
            </Collapse>
            <ListItem button onClick={handleClick2}>
            <ListItemIcon>
                <AssignmentOutlined />
            </ListItemIcon>
            <ListItemText primary="Évolution de l'instrumentation" />
            {open2 ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={open2} timeout="auto" unmountOnExit>
            { /*!currentData?.app_date_end*/ true ?
            <List component="div" disablePadding className={classes2.nested}>
                <FormDialog
                    triggerBtn={{
                      type: 'menu',
                      text: 'Mettre à jour les infos',// l\'appareil',
                      icon: EditOutlinedIcon,
                      iconStyle: { marginRight: '10px' },
                      closeMenu: handleCloseMenu,
                    }}
                    title="Évolution appareil"
                  >
                    <EquipementForm
                      isEditMode={false}
                      voieId={voieId}
                      //voieId={Number(voies2mesures.find((v)=>v.id_equipement === Number(currentData.id))?.id) || id_voie}
                      siteId={site_id}
                      siteSn={sn_site}
                      sn_module={sn_module}
                      conc={conc}
                      currentData={currentData}
                      usages={usages}
                      postes={postes}
                      pieces={pieces}
                      voiesDuSite={voies2mesures}
                      questions={questions}
                      dbleSuivi={dbleSuivi}
                      isSuivi={suivi}
		                  newEquip={false}
                      cas={"device"}
                    />
                  </FormDialog>
{/*                  <FormDialog
                    triggerBtn={{
                      type: 'menu',
                      text: 'Mettre à jour le suivi',
                      icon: EditOutlinedIcon,
                      iconStyle: { marginRight: '10px' },
                      closeMenu: handleCloseMenu,
                    }}
                    title="Évolution voie de suivi"
                  >
                    <EquipementForm
                      isEditMode={false}
                      voieId={Number(voies2mesures.find((v)=>v.id_equipement === Number(currentData.id))?.id) || id_voie}
                      siteId={site_id}
                      siteSn={sn_site}
                      sn_module={sn_module}
                      conc={conc}
                      currentData={currentData}
                      usages={usages}
                      postes={postes}
                      pieces={pieces}
                      voiesDuSite={voies2mesures}
                      questions={questions}
                      dbleSuivi={dbleSuivi}
                      isSuivi={suivi}
		                  newEquip={false}
                      cas={"suivi"}
                    />
                  </FormDialog>*/}
            </List>
            :null }
            </Collapse>
        </List>
       {/*} <MenuItem
          onClick={handleCloseMenu}
          component={RouterLink}
          to={sites/currentData.site_id`/equipements/${currentData.id}`}
        >
          <OpenInNewIcon style={{ marginRight: '10px' }} />
          Détails Equipement (INACTIF)
        </MenuItem>*/}
      {/*}  <FormDialog
        triggerBtn={{
          type: 'menu',
          text: 'Réponses aux Questions appareil',
          icon: EditOutlinedIcon,
          iconStyle: { marginRight: '10px' },
          closeMenu: handleCloseMenu,
        }}
        title="Saisie des caractéristiques de l'appareil"
      >
        <RepAuxQuestions /*etatEquip={data}/reponses={currentData.reponses} siteId={site_id} idEquip={Number(currentData.id)} usageId={Number(currentData.id)} 
          usageName={usages.find((u) => Number(u.id) === currentData.id_type_usage)?.name || "" } currentData={currentData}
          allUsages={usages} />
      </FormDialog>*/ }
      </Menu>
      </span>
    </div>
    );
};

export default EquipModMenu;
