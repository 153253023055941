import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Concentrateur, ConcentrateurState, SiteContact, SiteState } from '../../redux/types';
import { ContactState, Contact } from '../../redux/types';
import { selectAuthState } from '../../redux/slices/authSlice';
import { RootState } from '../../redux/store';
import SitesMenu from './SitesMenu';
import moment from 'moment';
import { formatDateTime, truncateString } from '../../utils/helperFuncs';
import { sortConcentrateursBy, fetchConcentrateursBySiteId, selectConcentrateursBySiteId, selectConcentrateurById, selectConcentrateursState } from '../../redux/slices/concentrateursSlice';
import { fetchContacts, fetchContactsBySiteId, selectContactsBySiteId, selectContactById, selectContactsState } from '../../redux/slices/contactsSlice';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
  Paper,
  ListItem,
  List,
  SvgIcon,
} from '@material-ui/core';
import { useTableStyles } from '../../styles/muiStyles';
import WarningIcon from '@material-ui/icons/WarningOutlined';
import WifiIcon from '@material-ui/icons/WifiRounded';
import ConcentrateursActionCard from './ConcentrateursActionCard';

const tableHeaders = ['#Logement', '#Concentrateurs', '#Modules', 'Occupant', 'Last data', 'TS last data', 'Actions'];

const SitesTable: React.FC<{ sites: SiteState[], contacts: ContactState[]/*, concentrateurs: ConcentrateurState[]*/ }> = ({
  sites,
  contacts,
//  concentrateurs,
}) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(selectAuthState);
  const siteId = "10";
//  const concentrateurs = fetchConcentrateursBySiteId("2");
/*  const contacts = useSelector((state: RootState) =>
 //   selectContactsBySiteId(state, 'siteId')
    fetchContacts()
  );*/
/*  const { fetchLoading, fetchError, sortBy, filterBy } = useSelector(
    selectContactsState
  );*/
//  const [filterValue, setFilterValue] = useState('');
  const sortConcByDate = (Concentrateurs: Concentrateur[]) => {
    const dateFormat = 'YYYY-MM-DDTHH:MM:SS.SSSZ'
    console.log(Concentrateurs.length,Concentrateurs.map((c) => (c.date_start)))
    return Concentrateurs.slice().sort((a,b)=>
      moment(a.date_start, dateFormat).unix() - moment(b.date_start, dateFormat).unix())/*.reverse()*/;
  }
/*  console.log(contacts[0].adresse);
  console.log(contacts[0].site_id); */
  /*useEffect(() => {
    if (!contacts) {
      dispatch(fetchContactsBySiteId(siteId));
    //  dispatch(selectContactsBySiteId(siteId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/
// TODO: refaire ce tri pour ne garder que les logements qui n'ont pas de date de fin, 
// dans le cas où un site_sn correspond à plusieurs site_id
const days3: Date = new Date();
days3.setDate(days3.getDate() - 3); // subtracted 3 days from existing date
const seen = new Set();
const uniqueSites = sites.filter(item => {
  const duplicate = seen.has(item.sn_site);
  seen.add(item.sn_site);
  return !duplicate;
});

  return (
    <Paper className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((t) => (
              <TableCell key={t} align="center">
                {t}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {uniqueSites.map((p) => (   // TODO : n'afficher que le CONC ayant une date de fin en 1970, idem contact / modules
            <TableRow key={p.id}>
              <TableCell
                onClick={() => history.push(`/site/${p.sn_site}/${p.id}`)/*</TableRow>history.push(`/sites/${p.id}`)*/}
                className={classes.clickableCell}
                align="center"
              >
                <Link
                  component={RouterLink}
                  to={`/site/${p.sn_site}`/*`/sites/${p.id}`*/}
                  color="secondary"
                >
                  {p.date_end ?
                    <s>{p.sn_site}</s>
                    : p.sn_site
                  }
                </Link>
              </TableCell>
              <TableCell className={classes.littWcell} align="center">
                <List>{sortConcByDate(p.concentrateur).map((c) => (
                  <Link
                    component={RouterLink}
    /*                to={`/site/${siteSn}/contact`}*/
                    to={`/concentrateur/${c.id}`/*`/sites/${p.id}`*/}
                    color="secondary" >
                    <ListItem>
                      {c.date_end ? <s>{c.hardSn}</s> : c.hardSn }
                      {c.techno === "WIFI" ? <WifiIcon style={{ marginLeft: '0.2em', color: 'blue' }}/> : null }
                    </ListItem>
                  </Link>
                  ))
                }</List>
              </TableCell>
              <TableCell className={classes.littWcell} align="center">
              <List>
                  {sortConcByDate(p.concentrateur).map((c) => (
                      <ListItem>
                        {c.date_end ?
                          <s>{c.modules.map((mod) => (
                            mod.toString()
                          )).join(" ")}</s>
                          : c.modules.map((mod) => (
                            mod.toString()
                          )).join(" ")}
                      </ListItem>
                  ))}
              </List>
              </TableCell>
              <TableCell align="center">
              <Link
                  component={RouterLink}
  /*                to={`/site/${siteSn}/contact`}*/
                  to={`/contacts/${p.sn_site}`/*`/sites/${p.id}`*/}
                  color="secondary" >
                  {/*contacts.find(contacts => { return contacts.site_id === Number(p.id); })?.prenom}{" "*/}
                  {/*contacts.find(contacts => { return contacts.site_id === Number(p.id); })?.nom*/}
                  {p.contacts.find((c) => c.site_sn.find((sn) => sn === Number(p.sn_site)))?.prenom}{" "}
                  {p.contacts.find((c) => c.site_sn.find((sn) => sn === Number(p.sn_site)))?.nom}
                  {/*contacts.find((c) => c.site_id.find((d) => d === Number(p.id)))?.nom*/}
                </Link>
              </TableCell>
              <TableCell 
                className={classes.littWcell}
                align="center">
                <List>
                {sortConcByDate(p.concentrateur).map((c) =>
                  <ListItem>
                    {c.date_end ?
                      <s>{new Date(c.date_last_data).toLocaleDateString()}</s>
                      : new Date(c.date_last_data).toLocaleDateString()
                    }
                    { (+new Date(c.date_last_data) - +days3 < 3) && !c.date_end ? 
                        (<WarningIcon style={{ marginLeft: '0.2em', color: 'red' }}/>)
                        : null
                    }
                  </ListItem>
                )}
                </List>
              </TableCell>
              <TableCell
                className={classes.littWcell}
                align="center">
                <List>
                {sortConcByDate(p.concentrateur).map((c) =>
                  <ListItem>
                  {c.date_end ?
                    <s>{new Date(c.ts_last_data).toLocaleDateString()}</s>
                    : new Date(c.ts_last_data).toLocaleDateString()
                  }
                  { (+new Date(c.ts_last_data) - +days3 < 3) && !c.date_end ?
                      (<WarningIcon style={{ marginLeft: '0.2em', color: 'red' }}/>)
                      : null
                  }
                </ListItem>
                )}
                </List>
              </TableCell>
              <TableCell align="center">
                <SitesMenu
                  siteId={p.id}
                  sn_site={p.sn_site}
                  iconSize='default'
                  /*{p.name}*/
                  /*isAdmin={true}*//*{p.createdBy.id === user?.id}*/
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default SitesTable;
