import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import voie2mesureService from '../../services/voie2mesures';
import { 
  EditedEquipementData,
  EquipementPayload,
  Voie2mesure,
//  Voie2mesureFilterValues,
  Voie2mesurePayload,
//  Voie2mesureSortValues,
  Voie2mesureState,
  VoiesSortValues,
  VoiesFilterValues,
} from '../types';
import { notify } from './notificationSlice';
import { getErrorMsg } from '../../utils/helperFuncs';
import equipementsSlice, { addEquipement, oublierLaVoie, setEquipementsBySitesSn, updateEquipement } from './equipementsSlice';
import equipementService from '../../services/equipements';

interface InitialVoie2mesureState {
//  voie2mesures: { [siteId: string]: Voie2mesureState[] };
//  voie2mesures: Voie2mesure[];
  voie2mesures: Voie2mesureState[]; // previously disabled 230316
  fetchLoading: boolean;
  fetchError: string | null;
  submitLoadingVoie2mesure: boolean;
  submitErrorVoie2mesure: string | null;
  sortBy: VoiesSortValues;
  filterBy: VoiesFilterValues;
/*  sortBy: Voie2mesureSortValues,
  filterBy: Voie2mesureFilterValues,*/
//  status: 'idle' | 'loading' | 'succeeded';
//  error: string | null;
}

const initialState: InitialVoie2mesureState = {
  voie2mesures: [],
  fetchLoading: false,
  fetchError: null,
  submitLoadingVoie2mesure: false,
  submitErrorVoie2mesure: null,
  sortBy: 'recapstyle'/*'newest'*/,
  filterBy: /*'open'*/'all',
//  sortBy: 'newest',
//  filterBy: 'all',
/*  voie2mesures: [],
  status: 'idle',
  error: null,*/
};

const voie2mesuresSlice = createSlice({
  name: 'voie2mesures',
  initialState,
  reducers: {
//    setVoie2mesures: (state, action: PayloadAction<{ voie2mesure: Voie2mesureState[], siteId: string }>) => { //230320
    //setVoie2mesures: (state, action: PayloadAction<Voie2mesure[]>) => {
/*    setEquipements: (
      state,
      action: PayloadAction<{ equipements: EquipementState[]; siteId: string }>
      ) => {
      console.log("setEquipements... SLICE");
      state.equipements[action.payload.siteId] = action.payload.equipements;
      state.fetchLoading = false;
      state.fetchError = null;
    },*/
    setVoie2mesures: (state, action: PayloadAction<Voie2mesureState[]>) => {
      state.voie2mesures = action.payload;
//      state.voie2mesures[action.payload.siteId] = action.payload.voie2mesure;   //230320
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;
      console.log("setVoies... SLICE");
    },
//    setSiteVoie2mesures: (state, action: PayloadAction<{ voie2mesure: Voie2mesure, siteId: string }>) => {
    setSiteVoie2mesures: (state, action: PayloadAction<{ voie2mesure: Voie2mesureState, site_id: string }>) => {
      //setVoie2mesures: (state, action: PayloadAction<Voie2mesureState[]>) => {
      //state.voie2mesures[action.payload.siteId] = action.payload.voie2mesure.;
/*      state.voie2mesures[action.payload.voie2mesure.id] = action.payload;
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;*/
      console.log("setSiteVoies... EMPTY !!!!!!!!!!!!!!!!!!!");
    },
    addVoie2mesures: ( state, action: PayloadAction<Voie2mesureState>) => {
    /*  state.voie2mesures[action.payload.data.site_sn] = state.voie2mesures[
        action.payload.data.site_sn
      ]. map((b) =>
        b.id === action.payload.data.id_voie ? { ...b, ...action.payload.data } : b
//        b.id === action.payload.equipementId ? { ...b, ...action.payload.data } : b
      );*/
      state.fetchLoading = true;
      state.voie2mesures.push(action.payload);
      state.submitLoadingVoie2mesure = false;
      state.submitErrorVoie2mesure = null;
    /*addVoie2mesures: (state, action: PayloadAction<Voie2mesureState>) => {
      state.voie2mesures.push(action.payload);
      state.submitLoadingVoie2mesure = false;
      state.submitErrorVoie2mesure = null;*/
    },
    updateVoie2mesuresDB: (
      state,
      action: PayloadAction<{
        data: Voie2mesurePayload;
        voieId: string;
        siteId: string;
      }>
    ) => {
      console.log("updateVoie2mesuresDB... SLICE");
    /*  state.voie2mesures[action.payload.data.site_sn] = state.voie2mesures[
        action.payload.data.site_sn
      ]. map((b) =>
        b.id === action.payload.data.id_voie ? { ...b, ...action.payload.data } : b
//        b.id === action.payload.equipementId ? { ...b, ...action.payload.data } : b
      );*/
      state.voie2mesures.map((p) =>
        p.id === action.payload.voieId ? { ...p, ...action.payload.data } : p
      );
      state.submitLoadingVoie2mesure = false;
      state.submitErrorVoie2mesure = null;
/*      state.voie2mesures = state.voie2mesures.map((p) =>
        p.id === action.payload.voieId ? { ...p, ...action.payload.data } : p
      );
      state.submitLoadingVoie2mesure = false;
      state.submitErrorVoie2mesure = null;*/
    },
    stopVoie2mesures: (
      state,
      action: PayloadAction<{
        data: Voie2mesurePayload;
        voieId: string;
        siteId: string;
      }>
    ) => {
      console.log("stopVoie2mesures... SLICE");
      //@ts-ignore
      state.voie2mesures = state.voie2mesures.map((p) =>
        p.id === action.payload.voieId ? { ...p, ...action.payload.data } : p
      );
      state.submitLoadingVoie2mesure = false;
      state.submitErrorVoie2mesure = null;
    },
    removeVoie2mesures: (
      state,
      action: PayloadAction<{
        equipId: string;
        voieId: string;
        siteId: string;
      }>
    ) => {
      state.voie2mesures = state.voie2mesures.filter((v) => v.id !== action.payload.voieId);
    },
    setVoie2mesuresLoading: (state) => {
      state.fetchLoading = true;
//      state.status = 'loading';
      state.fetchError = null;
    },
    setFetchVoie2mesuresError: (state, action: PayloadAction<string>) => {
      state.fetchLoading = false;
      state.fetchError = action.payload;
    },
    setSubmitVoie2mesuresError: (state, action: PayloadAction<string>) => {
      state.submitLoadingVoie2mesure = false;
      state.submitErrorVoie2mesure = action.payload;
    },
    clearSubmitVoie2mesuresError: (state) => {
      state.submitErrorVoie2mesure = null;
    },
    sortVoiesBy: (state, action: PayloadAction<VoiesSortValues>) => {
      //      console.log("sortEquipementBy "+String(action.payload)+" CALLED");
      state.sortBy = action.payload;
    },
    filterVoiesBy: (state, action: PayloadAction<VoiesFilterValues>) => {
      state.filterBy = action.payload;
    },
  },
});

export const { addVoie2mesures, removeVoie2mesures, setVoie2mesures, setSiteVoie2mesures, setVoie2mesuresLoading, setFetchVoie2mesuresError,/*, sortVoie2mesuresBy*/
updateVoie2mesuresDB, filterVoiesBy, sortVoiesBy, stopVoie2mesures, clearSubmitVoie2mesuresError, setSubmitVoie2mesuresError } = voie2mesuresSlice.actions;

export const forgotVoie2mesure = (
  siteId: string,
  siteSn: string,
  equipId: string,
  voieId: string,
  closeDialog?: () => void
): AppThunk => {
  console.log("forgot 'bout Suivi");
  return async (dispatch) => {
    try {
      dispatch(setVoie2mesuresLoading());
//      const updatedVoie = await voie2mesureService.updateVoie2mesuresDB(voieId, voieData);
      const updatedEquipement = await voie2mesureService.removeVoie2mesure(siteId,voieId);
      // TODO: changer pour retourner la ligne d'équipement entière plutôt que la voie concernée, qui ne se met pas à jour dans la liste
      const {
        //id_equip,
        id,
        id_poste,
        id_type_usage,
        id_piece,
        reference,
        id_ref,
        reponses,
        id_questions,
        comments,
        a_suivre,
        suivi,
        app_date_start,
        app_date_end,
        site_sn,
        sn_site,
        str_piece,
        str_equip,
        site_id,
        date_update,
        nrg_class, 
        normalized_pw,
        water_consumption,
        pw_veille,
        room,
        poste,
        voies2mesures,
        usage,
        questions_instrum,
	id_circuit,
      } = updatedEquipement as EditedEquipementData;

      /*dispatch(
        removeVoie2mesures({equipId,voieId,siteId})
      );
      dispatch(
        oublierLaVoie({voieId})
      );
      dispatch(
        updateEquipement({
          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, site_sn, sn_site, 
            str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille,
            room, poste, voies2mesures, usage, questions_instrum },
          equipId, siteId,
        })
      );*/
      // TODO: bug : la ligne de l'app disaparaît (backoffice ok), mais il n'apparaît pas dans les non suivis !!!??
      dispatch(
        oublierLaVoie({data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, site_sn, sn_site, 
          str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille,
          room, poste, voies2mesures, usage, questions_instrum, id_circuit },
          voieId, siteId,
        })
      );
      const updatedEquips = await equipementService.getEquipementsBySiteSn(String(siteSn));
      dispatch(setEquipementsBySitesSn(updatedEquips));
      const updatedVoies2mesures = await voie2mesureService.getSiteVoie2mesures(String(siteSn));//getSiteVoie2mesures(updatedEquipement.site_sn);
      dispatch(setVoie2mesures(updatedVoies2mesures));
      dispatch(notify("Suivi de l'appareil oublié !", 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitVoie2mesuresError(getErrorMsg(e)));
    }
  };
};

export const stopVoie2mesureDB = (
  voieId: string,
  equipementId: string,
  siteId: string,
  voieData: Voie2mesurePayload,
  closeDialog?: () => void
): AppThunk => {
  console.log("stopSuiviDB");
  return async (dispatch) => {
    try {
      dispatch(setVoie2mesuresLoading());
//      const updatedVoie = await voie2mesureService.updateVoie2mesuresDB(voieId, voieData);
      const updatedEquipement = await voie2mesureService.stopVoie2mesuresDB(voieId, voieData);
      // TODO: changer pour retourner la ligne d'équipement entière plutôt que la voie concernée, qui ne se met pas à jour dans la liste
      const {
        //id_equip,
        id,
        id_poste,
        id_type_usage,
        id_piece,
        reference,
        id_ref,
        reponses,
        id_questions,
        comments,
        a_suivre,
        suivi,
        app_date_start,
        app_date_end,
        site_sn,
        sn_site,
        str_piece,
        str_equip,
        site_id,
        date_update,
        nrg_class, 
        normalized_pw,
        water_consumption,
        pw_veille,
        room,
        poste,
        voies2mesures,
        usage,
        questions_instrum,
	id_circuit,
      } = updatedEquipement as EditedEquipementData;

      dispatch(
        updateEquipement({
          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, site_sn, sn_site, 
            str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille,
            room, poste, voies2mesures, usage, questions_instrum, id_circuit },
          equipementId, siteId,
        })
      );
      const updatedEquips = await equipementService.getEquipementsBySiteSn(String(voieData.site_sn));
      dispatch(setEquipementsBySitesSn(updatedEquips));
      const updatedVoies2mesures = await voie2mesureService.getSiteVoie2mesures(String(voieData.site_sn)/*updatedEquipement.site_sn*/);//getSiteVoie2mesures(updatedEquipement.site_sn);
      dispatch(setVoie2mesures(updatedVoies2mesures));
      dispatch(notify("Suivi de l'appareil arrêté avec succès.", 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitVoie2mesuresError(getErrorMsg(e)));
    }
  };
};
export const stopVoie2mesure = (
  voieId: string,
  equipementId: string,
  siteId: string,
  voieData: Voie2mesurePayload,
  closeDialog?: () => void
): AppThunk => {
  console.log("stopSuivi");
  return async (dispatch) => {
    try {
      dispatch(setVoie2mesuresLoading());
//      const updatedVoie = await voie2mesureService.updateVoie2mesuresDB(voieId, voieData);
      const updatedEquipement = await voie2mesureService.stopVoie2mesures(voieId, voieData);
      // TODO: changer pour retourner la ligne d'équipement entière plutôt que la voie concernée, qui ne se met pas à jour dans la liste
      const {
        //id_equip,
        id,
        id_poste,
        id_type_usage,
        id_piece,
        reference,
        id_ref,
        reponses,
        id_questions,
        comments,
        a_suivre,
        suivi,
        app_date_start,
        app_date_end,
        site_sn,
        sn_site,
        str_piece,
        str_equip,
        site_id,
        date_update,
        nrg_class, 
        normalized_pw,
        water_consumption,
        pw_veille,
        room,
        poste,
        voies2mesures,
        usage,
        questions_instrum,
	id_circuit,
      } = updatedEquipement as EditedEquipementData;

      dispatch(
        updateEquipement({
          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, site_sn, sn_site, 
            str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille,
            room, poste, voies2mesures, usage, questions_instrum, id_circuit },
          equipementId, siteId,
        })
      );
      const updatedEquips = await equipementService.getEquipementsBySiteSn(String(voieData.site_sn));
      dispatch(setEquipementsBySitesSn(updatedEquips));
      const updatedVoies2mesures = await voie2mesureService.getSiteVoie2mesures(String(voieData.site_sn)/*updatedEquipement.site_sn*/);//getSiteVoie2mesures(updatedEquipement.site_sn);
      dispatch(setVoie2mesures(updatedVoies2mesures));
      dispatch(notify("Suivi de l'appareil arrêté avec succès.", 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitVoie2mesuresError(getErrorMsg(e)));
    }
  };
};

export const createVoie2mesureDB = (
  equipementId: string,
  siteId: string,
  voieData: Voie2mesurePayload,
  closeDialog?: () => void
): AppThunk => {
  console.log("createVoie");
  return async (dispatch) => {
    try {
      dispatch(setVoie2mesuresLoading());
      const updatedEquipement = await voie2mesureService.createVoie2mesuresDB(siteId, voieData);
      const {
        //id_equip,
        id,
        id_poste,
        id_type_usage,
        id_piece,
        reference,
        id_ref,
        reponses,
        id_questions,
        comments,
        a_suivre,
        suivi,
        app_date_start,
        app_date_end,
        sn_site,
        str_piece,
        str_equip,
        site_id,
        date_update,
        nrg_class, 
        normalized_pw,
        water_consumption,
        pw_veille,
        room,
        poste,
        voies2mesures,
        usage,
        questions_instrum,
	id_circuit,
      } = updatedEquipement as EditedEquipementData;

      dispatch(
        updateEquipement({
          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
            str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille,
            room, poste, voies2mesures, usage, questions_instrum, id_circuit },
          equipementId, siteId,
        })
      );
      const updatedEquips = await equipementService.getEquipementsBySiteSn(String(voieData.site_sn));
      dispatch(setEquipementsBySitesSn(updatedEquips));
      const updatedVoies2mesures = await voie2mesureService.getSiteVoie2mesures(String(voieData.site_sn)/*updatedEquipement.site_sn*/);//(updatedEquipements[0].site_sn);
      dispatch(setVoie2mesures(updatedVoies2mesures));
//      fetchVoie2mesures();
      dispatch(notify('Suivi ajouté avec succès.', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitEquipementError(getErrorMsg(e)));
    }
  };
};

export const createVoie2mesure = (
  equipementId: string,
  siteId: string,
  voieData: Voie2mesurePayload,
  closeDialog?: () => void
): AppThunk => {
  console.log("createVoieDB");
  return async (dispatch) => {
    try {
      dispatch(setVoie2mesuresLoading());
//      const updatedVoie = await voie2mesureService.updateVoie2mesuresDB(voieId, voieData);
      const updatedEquipements = await voie2mesureService.createVoie2mesures(siteId, voieData);
      let nbEquip: Number;
      updatedEquipements[1] ? nbEquip = 2 : nbEquip = 1; //updateEquipements.length;
      console.log("nb Equipements retournés par l'API: "+nbEquip)
      if(nbEquip > 1) {
        const [{
          //id_equip,
          id,
          id_poste,
          id_type_usage,
          id_piece,
          reference,
          id_ref,
          reponses,
          id_questions,
          comments,
          a_suivre,
          suivi,
          app_date_start,
          app_date_end,
          site_sn,
          sn_site,
          str_piece,
          str_equip,
          site_id,
          date_update,
          nrg_class, 
          normalized_pw,
          water_consumption,
          pw_veille,
          room,
          poste,
          voies2mesures,
          usage,
          questions_instrum,
	  id_circuit,
        }] = updatedEquipements as EditedEquipementData[];

  //      fetchVoie2mesures();
            console.log("updatedEquipement ID: "+updatedEquipements[1].id)
            dispatch(updateEquipement({
              data: updatedEquipements[1],
    /*          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
                str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum } = e.,*/
              equipementId, siteId,
            }));
            console.log("addEquipement ID: "+updatedEquipements[0].id)
            dispatch(addEquipement({
              equipement: updatedEquipements[0],
    /*          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
                str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum } = e.,*/
              siteId,
            }));
      }
      if(nbEquip === 1) {
        const {
          //id_equip,
          id,
          id_poste,
          id_type_usage,
          id_piece,
          reference,
          id_ref,
          reponses,
          id_questions,
          comments,
          a_suivre,
          suivi,
          app_date_start,
          app_date_end,
          site_sn,
          sn_site,
          str_piece,
          str_equip,
          site_id,
          date_update,
          nrg_class, 
          normalized_pw,
          water_consumption,
          pw_veille,
          room,
          poste,
          voies2mesures,
          usage,
          questions_instrum,
	  id_circuit,
        } = updatedEquipements[0] as EditedEquipementData;

        dispatch(updateEquipement({
            data: updatedEquipements[0], /*{ id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, site_sn, sn_site, 
              str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum },*/
            equipementId, siteId,
          }));
      }
      /*dispatch(notify('Voie de suivi mise à jour avec succès.', 'success'));
      closeDialog && closeDialog();*/
      const updatedEquips = await equipementService.getEquipementsBySiteSn(String(voieData.site_sn));
      dispatch(setEquipementsBySitesSn(updatedEquips));
      const updatedVoies2mesures = await voie2mesureService.getSiteVoie2mesures(String(voieData.site_sn)/*updatedEquipement.site_sn*/);//getSiteVoie2mesures(updatedEquipements[0].site_sn);
      dispatch(setVoie2mesures(updatedVoies2mesures));
      dispatch(notify('Suivi ajouté avec succès...', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitVoie2mesuresError(getErrorMsg(e)));
    }
  };
};
    
export const editVoie2mesureDB = (
  voieId: string,
  equipementId: string,
  siteId: string,
  voieData: Voie2mesurePayload,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
      try {
        dispatch(setVoie2mesuresLoading())
        //      const updatedVoie = await voie2mesureService.updateVoie2mesuresDB(voieId, voieData)
        console.log("editVoieDB, voie_date_end: "+voieData.voie_date_end+" / voieData.action: "+voieData.action)
        let updatedEquipements: any
        if (voieData.action === "endvoie") {      // arrêt VOIE
          console.log("## Arrêt voie ...................... ;-)")
          updatedEquipements = await voie2mesureService.arretSuiviDB(voieId, voieData)
        } 
        if (voieData.action === "refollow") {
          console.log("## Reprise du suivi ...................... ;-)")
          updatedEquipements = await voie2mesureService.repriseSuiviDB(voieId, voieData)
        }
        if (voieData.action === "chgmtvoie") {
          console.log("## MàJ voie ...................... ;-)")
          updatedEquipements = await voie2mesureService.updateVoie2mesuresDB(voieId, voieData)
        }
        let nbEquip: number;
        updatedEquipements[1] ? nbEquip = 2 : nbEquip = 1; //updateEquipements.length;
        console.log("nb Equipements retournés par l'API: "+nbEquip)
        if(nbEquip > 1) {
          const [{
            //id_equip,
            id,
            id_poste,
            id_type_usage,
            id_piece,
            reference,
            id_ref,
            reponses,
            id_questions,
            comments,
            a_suivre,
            suivi,
            app_date_start,
            app_date_end,
            sn_site,
            site_sn,
            str_piece,
            str_equip,
            site_id,
            date_update,
            nrg_class, 
            normalized_pw,
            water_consumption,
            pw_veille,
            room,
            poste,
            voies2mesures,
            usage,
            questions_instrum,
	    id_circuit,
          }] = updatedEquipements as EditedEquipementData[];
          console.log("updatedEquipement ID: "+updatedEquipements[1].id)
          dispatch(updateEquipement({
            data: updatedEquipements[1],
  /*          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
              str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum } = e.,*/
            equipementId, siteId,
          }));
          console.log("addEquipement ID: "+updatedEquipements[0].id)
          dispatch(addEquipement({
            equipement: updatedEquipements[0],
  /*          data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
              str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum } = e.,*/
            siteId,
          }));
        }
        if(nbEquip === 1) {
          const {
            //id_equip,
            id,
            id_poste,
            id_type_usage,
            id_piece,
            reference,
            id_ref,
            reponses,
            id_questions,
            comments,
            a_suivre,
            suivi,
            app_date_start,
            app_date_end,
            site_sn,
            sn_site,
            str_piece,
            str_equip,
            site_id,
            date_update,
            nrg_class, 
            normalized_pw,
            water_consumption,
            pw_veille,
            room,
            poste,
            voies2mesures,
            usage,
            questions_instrum,
	    id_circuit,
          } = updatedEquipements[0] as EditedEquipementData
          dispatch(updateEquipement({
              data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
                str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum, id_circuit },
              equipementId, siteId,
            }))
        }
        const updatedEquips = await equipementService.getEquipementsBySiteSn(String(voieData.site_sn))
        dispatch(setEquipementsBySitesSn(updatedEquips))
        const updatedVoies2mesures = await voie2mesureService.getSiteVoie2mesures(String(voieData.site_sn))
        dispatch(setVoie2mesures(updatedVoies2mesures))
        if (voieData.action === "endvoie" ) { dispatch(notify('Voie de suivi arrêtée avec succès.', 'success')) } 
        if (voieData.action === "chgmtvoie") { dispatch(notify('Voie de suivi corrigée avec succès.', 'success')) }
        if (voieData.action === "refollow") { dispatch(notify('Suivi redémarré avec succès.', 'success')) }
        closeDialog && closeDialog();
      } catch (e) {
        //@ts-ignore
        dispatch(setSubmitVoie2mesuresError(getErrorMsg(e)));
      }
    };
};

export const editVoie2mesure = (
  voieId: string,
  equipementId: string,
  siteId: string,
  voieData: Voie2mesurePayload,
  closeDialog?: () => void
): AppThunk => {
  console.log("editVoie");
  return async (dispatch) => {
    try {
      dispatch(setVoie2mesuresLoading());
      let updatedEquipements: any
      if (voieData.dbleSuivi === false) {
        console.log("################### DbleSuivi = FALSE")
      if (voieData.action === "refollow" /*&& voieData.delDevice === false*/) {
          console.log("################### CASE : refollow")
          updatedEquipements = await voie2mesureService.repriseSuivi(voieId, voieData);
        }
        if (voieData.action === "endvoie" && voieData.voie_date_end !== null) {    // delDevice true/false handled in API
          console.log("################### CASE : endvoie")
          updatedEquipements = await voie2mesureService.arretSuivi(voieId, voieData);
        }
        if (voieData.action === "chgmtvoie") {
          console.log("################### CASE : chgmtvoie")
          // MàJ paramètres voie sur une nouvelle voie
          /*if(voieData.delDevice === true) { console.log("################### + delDevice "); updatedEquipements = await voie2mesureService.updateVoie2mesuresDB(voieId, voieData) }
          else {*/ console.log("################### SANS delDevice (flag deleted 24/04/12) "); updatedEquipements = await voie2mesureService.updateVoie2mesures(voieId, voieData) //}
        }
      } else {    // delDevice et dbleSuivi sont exclusifs, pas possible qu'on ait delDevice = true ci-dessous
        console.log("################### DbleSuivi = TRUE / date_chgmt : "+voieData.voie_date_chgmt+" / Flag dbleSuivi: "+voieData.dbleSuivi)
        if (voieData.voie_date_chgmt !== undefined) {
          console.log("################### CASE : addDbleSuivi")
          updatedEquipements = await voie2mesureService.addDbleSuiviDB(voieId, voieData);
        } else { console.log("no action ......................") }
      }
      let nbEquip: number;
      updatedEquipements[1] ? nbEquip = 2 : nbEquip = 1; //updateEquipements.length;
      console.log("nb Equipements retournés par l'API: "+nbEquip)
      if(nbEquip > 1) {
        const [{
          //id_equip,
          id,
          id_poste,
          id_type_usage,
          id_piece,
          reference,
          id_ref,
          reponses,
          id_questions,
          comments,
          a_suivre,
          suivi,
          app_date_start,
          app_date_end,
          sn_site,
          site_sn,
          str_piece,
          str_equip,
          site_id,
          date_update,
          nrg_class, 
          normalized_pw,
          water_consumption,
          pw_veille,
          room,
          poste,
          voies2mesures,
          usage,
          questions_instrum,
	  id_circuit,
        }] = updatedEquipements as EditedEquipementData[];
            console.log("updatedEquipement ID: "+updatedEquipements[1].id)
            dispatch(updateEquipement({
              data: updatedEquipements[1], equipementId, siteId,
            }));
            console.log("addEquipement ID: "+updatedEquipements[0].id)
            dispatch(addEquipement({
              equipement: updatedEquipements[0],siteId,
            }));
      }
      if(nbEquip === 1) {
        const {
          //id_equip,
          id,
          id_poste,
          id_type_usage,
          id_piece,
          reference,
          id_ref,
          reponses,
          id_questions,
          comments,
          a_suivre,
          suivi,
          app_date_start,
          app_date_end,
          site_sn,
          sn_site,
          str_piece,
          str_equip,
          site_id,
          date_update,
          nrg_class, 
          normalized_pw,
          water_consumption,
          pw_veille,
          room,
          poste,
          voies2mesures,
          usage,
          questions_instrum,
	  id_circuit,
        } = updatedEquipements[0] as EditedEquipementData;

        dispatch(updateEquipement({
            data: { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, app_date_start, app_date_end, sn_site, 
              str_piece, str_equip, site_id, date_update, nrg_class, normalized_pw, water_consumption, pw_veille, room, poste, voies2mesures, usage, questions_instrum, id_circuit },
            equipementId, siteId,
          }));
      }
      const updatedEquips = await equipementService.getEquipementsBySiteSn(String(voieData.site_sn));
      dispatch(setEquipementsBySitesSn(updatedEquips));
      const updatedVoies2mesures = await voie2mesureService.getSiteVoie2mesures(String(voieData.site_sn)/*updatedEquipement.site_sn*/);//(updatedEquipements[0].site_sn);
      dispatch(setVoie2mesures(updatedVoies2mesures));
      if (voieData.action === "refollow") { dispatch(notify('Reprise du suivi validé.', 'success')); }
      if (voieData.action === "endvoie") { dispatch(notify('Fin de suivi validé.', 'success')); }
      if (voieData.action === "chgmtvoie") { dispatch(notify('Suivi mis à jour avec succès.', 'success')); }
      if (voieData.dbleSuivi === true) { dispatch(notify('Suivi multiple configuré.', 'success')); }
      //dispatch(notify('Voie de suivi mise à jour avec succès.', 'success'))
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitVoie2mesuresError(getErrorMsg(e)));
    }
  };
};
export const fetchVoie2mesuresBySiteId = (site_id: string): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setVoie2mesuresLoading());
      const siteVoie2mesures = await voie2mesureService.getSiteVoie2mesures(site_id);
//      dispatch(setSiteVoie2mesures({ voie2mesures: voie2mesureState ??, siteId }));
      dispatch(setSiteVoie2mesures({ voie2mesure: siteVoie2mesures, site_id }));
    } catch (e) {
      //@ts-ignore
      dispatch(setFetchVoie2mesuresError(getErrorMsg(e)));
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));    }
  };
};

export const fetchVoie2mesures = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setVoie2mesuresLoading());
      const allVoie2mesures = await voie2mesureService.getVoie2mesures();
      dispatch(setVoie2mesures(allVoie2mesures));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};
export const fetchVoiesBySitesSn = (siteSn: string): AppThunk => {
  return async (dispatch) => {
    try {//@ts-ignore
      dispatch(setVoie2mesuresLoading());
      const allVoie2mesures = await voie2mesureService.getSiteVoie2mesures(siteSn);
      dispatch(setVoie2mesures(allVoie2mesures));
   //   dispatch(setEquipementsBySitesSn({ equipements: equipements, siteSn }));
    } catch (e) {
      //@ts-ignore
      dispatch(setFetchEquipementsError(getErrorMsg(e)));
    }
  };
};
export const selectVoie2mesuresBySiteId = (state: RootState, site_id: string) => {
  return state.voie2mesures.voie2mesures?.[Number(site_id)];
  //return state.voie2mesures.voie2mesures?.[Number(siteId)];
};

export const selectVoie2mesuresState = (state: RootState) => state.voie2mesures;
export const selectVoie2mesureById = (
  state: RootState,
  site_id: string,
  voie2mesureId: string) => {
	  return state.voie2mesures.voie2mesures?.[Number(site_id)]; //.find((p) => p.id === voie2mesureId);
//    return state.voie2mesures.voie2mesures/*?.[Number(siteId)]*/.find((p) => p.id === voie2mesureId);
};

export default voie2mesuresSlice.reducer;
