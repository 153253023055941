import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSiteById,
  selectSitesBySn,
  selectSitesState,
  deleteSite,
  leaveSiteContactship,
  fetchSitesBySn,
} from '../../redux/slices/sitesSlice';
import { autoLogin, selectAuthState } from '../../redux/slices/authSlice';
import { fetchContacts, selectContactsBySiteId, selectContactsState } from '../../redux/slices/contactsSlice';
import { fetchUsers } from '../../redux/slices/usersSlice';
import { RootState } from '../../redux/store';
import SiteForm from './SiteForm';
import ContactsCard from './ContactsCard';
import { styled } from '@material-ui/styles';
import FormDialog from '../../components/FormDialog';
import ConfirmDialog from '../../components/ConfirmDialog';
import { formatDateTime } from '../../utils/helperFuncs';

import {
  fetchConcentrateursBySiteId,
  fetchConcentrateursBySitesSn,
  selectConcentrateursBySiteId,
  selectConcentrateursBySiteSn,
  selectConcentrateurById,
  selectConcentrateursState,
  fetchConcentrateursForSiteSn,
} from '../../redux/slices/concentrateursSlice';

import {
  Paper,
  Typography,
  Button,
  Divider,
  useMediaQuery,
  DialogTitle,
  IconButton,
  Dialog,
  DialogActions,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useMainPageStyles } from '../../styles/muiStyles';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import { Contact, ContactState, SiteState, Equipement, EquipementState, Voie2mesure } from '../../redux/types';
import { selectEquipementsStateSn, fetchEquipementsBySitesSn, selectEquipementsBySiteSn, selectEquipementsState, fetchEquipementsForSiteSn } from '../../redux/slices/equipementsSlice';
import EquipementsCard from './EquipementsCard';
import { fetchPostes, selectPostesBySiteId, selectPostesState } from '../../redux/slices/postesSlice';
import { selectUsagesState } from '../../redux/slices/usagesSlice';
import { selectQuestionsInstrumsState } from '../../redux/slices/questionsInstrumSlice';
import { fetchVoie2mesures, selectVoie2mesuresState } from '../../redux/slices/voie2mesuresSlice';
import { selectRoomsState } from '../../redux/slices/roomsSlice';

interface ParamTypes {
  siteId: string;
  contactId: string; 
  siteSn: string; 
  /*
  sitesWSn: string;    // contient les sites correspondants au SN demandé SiteState[]
  contactsSitesSn: string;*/ //ContactState[];
/*concHardSn: string;
  concTel: string;
  concId: string;
  isMobile: boolean;*/
  /*siteSn: string;
  concentrateurs: string;*/
//  concentrateurs: Array<{ id: string }>;
}

const EquipDetailsPage = () => {
  const classes = useMainPageStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { siteId, contactId, siteSn /*, sitesWSn, contactsSitesSn*//*, concHardSn, concTel, concId*/ } = useParams<ParamTypes>();
  //console.log("DEBUG siteId: "+siteId+" siteSn: "+siteSn)
  const { user } = useSelector(selectAuthState);
  const siteInState = useSelector((state: RootState) =>
    selectSiteById(state, siteId, contactId)
  );
  const { sites /*, fetchStatus*/ } = useSelector(
    selectSitesState
  );
  const { equipements, fetchLoading, fetchError, sortBy } = useSelector(
    selectEquipementsState
  );
  const { contacts } = useSelector(
    selectContactsState
  );
  const { concentrateurs } = useSelector( selectConcentrateursState );
  const siteConcentrateurs /*concInState*/ = useSelector((state: RootState) =>
  //  selectConcentrateursBySiteId(state, siteId)
  //  selectConcentrateursBySiteSn(state, siteSn)
    selectConcentrateursBySiteSn(state, siteSn)
  );
  const equipementsStateSiteSn = useSelector((state: RootState) =>
    selectEquipementsBySiteSn(state, siteSn)
  );
  if (!equipements) {
        dispatch(autoLogin());
        dispatch(fetchEquipementsForSiteSn(siteSn));    // CAUSE jwt malformed error ?
        dispatch(fetchEquipementsBySitesSn(siteSn));
        dispatch(fetchConcentrateursForSiteSn(siteSn));    // CAUSE jwt malformed error ?
  }
  useEffect(() => {
    if (equipementsStateSiteSn.length > 0) {
      dispatch(autoLogin());
      dispatch(fetchEquipementsForSiteSn(siteSn));    // CAUSE jwt malformed error ?
      dispatch(fetchEquipementsBySitesSn(siteSn));
      dispatch(fetchConcentrateursForSiteSn(siteSn));    // CAUSE jwt malformed error ?
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log("Site SN EquipDetailsPage: "+siteSn);
  

  const [filterValue, setFilterValue] = useState('');
  const filteredSortedSites = sites;
  const filteredSortedContacts = contacts;
  const [viewConcentrateurs, setViewConcentrateurs] = useState(false);
  
  console.log("siteInState : " + siteInState+" / Site ID EquipDetailsPage: "+siteId);
  
//  const { postes } = useSelector( selectPostesState );
  const { postes } = useSelector( selectPostesState );/*((state: RootState) => selectPostesBySiteId(state, '100107'));*/
  const { usages } = useSelector(selectUsagesState);
  const { rooms } = useSelector(selectRoomsState);
  const { questions_instrums } = useSelector(selectQuestionsInstrumsState);
  const { voie2mesures } = useSelector(selectVoie2mesuresState);

  console.log("selConcbySiteSn : " + /*concInState.*/concentrateurs.length/*.map((c) => c.hardSn)*/);
  console.log("selEquipState " + equipements + " equipSateSiteSn" + equipementsStateSiteSn/* + " siteEquipements "+siteEquipements.length*/);
//  const {id, id_piece, id_poste, id_questions, id_ref, id_type_usage} = equipements;
  console.log("nb équipements trouvés pour le site["+siteSn+"]: "+ equipementsStateSiteSn.length);
//  equipementsStateSiteSn.map((equi) => console.log(equi.str_equip));

//  const currConcentrateurs = concInState.concentrateurs
/*  if (!siteInState) {
    return (
      <div className={classes.root}>
        <Paper className={classes.notFoundPaper}>
          <Typography
            variant="h6"
            color="secondary"
            className={classes.error404Text}
            style={{ marginTop: '5em' }}
          >
            Cacospatch404: Equipements non trouvés !
          </Typography>
        </Paper>
      </div>
    );
  }*/

/*  if (equipementsStateSiteSn.length === 0) {
    return (
      <div className={classes.root}>
        <Paper className={classes.notFoundPaper}>
          <Typography
            variant="h6"
            color="secondary"
            className={classes.error404Text}
            style={{ marginTop: '5em' }}
          >
            404: Pas d'équipements trouvés... ça craint !
          </Typography>
        </Paper>
      </div>
    );
  }*/

  //const { id, sn_site, /*contact_id*/ /*contacts,*/ /*contactId,*/ date_instru, date_update, concentrateur/*s*/ } = siteInState;
 // const { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, date_start, date_end, sn_site, str_piece, str_equip, site_id } = equipementsStateSiteSn;
//  const { id, tel, date_start, date_end, date_last_data, ts_last_data, pdt, modules, aliasSn, techno, version, hardSn, site_id, siteSn } = concInState;

  //const isAdmin = createdBy.id === user?.id;
  const isAdmin = true;

  const handleDeleteSite = () => {
 //   dispatch(deleteSite(id, history));
  };

  const handleLeaveSite = () => {
 //   dispatch(leaveSiteContactship(id, history));
  };

//  const showContactsBtn = () => {
  const showConcentrateursBtn = () => {
    //if (contacts.length < 2) return null;
    return null;
    if (isMobile) {
      return (
        <Button
          color={viewConcentrateurs ? 'secondary' : 'primary'}
          variant="contained"
          onClick={() => setViewConcentrateurs(!viewConcentrateurs)}
          style={{ marginRight: '1em' }}
          className={classes.roundIconButton}
        >
          {viewConcentrateurs ? <ExpandLessIcon /> : <PeopleAltTwoToneIcon />}
        </Button>
      );
    } else {
      return (
        <Button
          color="secondary"
          variant="outlined"
          startIcon={viewConcentrateurs ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          onClick={() => setViewConcentrateurs(!viewConcentrateurs)}
          style={{ marginRight: '1em' }}
        >
          {viewConcentrateurs ? 'Hide Concentrateurs' : 'View Concentrateurs'}
        </Button>
      );
    }
  };

  const leaveSiteBtn = () => {
    if (isAdmin) return null;

    return (
      <ConfirmDialog
        title="Confirm Leave Site"
        contentText="Êtes-vous certain de vouloir quitter l'édition des propriétés du site (=Logement) ?"
        actionBtnText="Quitter le Site"
        triggerBtn={{
          type: isMobile ? 'round' : 'normal',
          text: 'Leave Site',
          icon: ExitToAppOutlinedIcon,
        }}
        actionFunc={handleLeaveSite}
      />
    );
  };

/*  const adminBtns = () => {
    if (!isAdmin) return null;

    return (
      <>
        <FormDialog
          triggerBtn={{
            type: isMobile ? 'round' : 'normal',
            text: 'Ajouter un équipement',
            icon: GroupAddOutlinedIcon,
            style: { marginRight: '1em' },
          }}
          title="Add equipement to site"
        >
        </FormDialog>
        <ConfirmDialog
          title="Confirm Delete Site"
          contentText="Are you sure you want to permanently delete your site?"
          actionBtnText="Delete Site"
          triggerBtn={{
            type: isMobile ? 'round' : 'normal',
            text: 'Delete Site',
            icon: DeleteOutlineIcon,
          }}
          actionFunc={handleDeleteSite}
        />
      </>
    );
  };*/

  return (
    <div className={classes.root}>
{/*      <Paper className={classes.detailsHeader}>
        <div className={classes.flexHeader}>
          <Typography
            variant={isMobile ? 'h5' : 'h4'}
            color="secondary"
            style={{ marginRight: '0.2em' }}
          >
            <strong>{}</strong>
          </Typography>
          {isAdmin && (
            <FormDialog
              triggerBtn={{ type: 'icon', icon: EditIcon, size: 'small' }}
              title="Edit the site name"
            >
            </FormDialog>
          )}
        </div>
        <Divider style={{ margin: '0.5em 0' }} />
        <Typography variant="subtitle2" color="secondary">
          Admin: <strong></strong>
        </Typography>
        <Typography variant="subtitle2" color="secondary">
          Created At: <em>{}</em>
        </Typography>
        <div className={classes.btnsWrapper}>
          {showConcentrateursBtn()}
          {leaveSiteBtn()}
          {adminBtns()}
        </div>
          </Paper>*/}
      <EquipementsCard allConcentrateurs={concentrateurs[siteId]} postes={postes} usages={usages} 
  questions={questions_instrums} pieces={rooms} voies2mesures={voie2mesures}
  concentrateurs={siteConcentrateurs} equipements={equipementsStateSiteSn} siteId={siteId} siteSn={siteSn}
        /*hardSn={"hardSN"*concentrateurs*} tel={"TEL"/*concentrateurs*} modules={[9876,6775,8906]} concId={"concId"/*concentrateurs*}
        date_last_data={new Date()} /*concId={"concID"}
        ts_last_data={new Date()}*/ isMobile={isMobile} />
      </div>
      );
};

export default EquipDetailsPage;
