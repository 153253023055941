import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { SiteContact, SiteState, Concentrateur } from '../../redux/types';
import { ContactState, Contact } from '../../redux/types';
import { selectAuthState } from '../../redux/slices/authSlice';
import { RootState } from '../../redux/store';
import SitesMenu from './SitesMenu';
import moment from 'moment';
import { formatDateTime, truncateString } from '../../utils/helperFuncs';
import { fetchConcentrateursBySiteId, selectConcentrateursBySiteId, selectConcentrateurById, selectConcentrateursState } from '../../redux/slices/concentrateursSlice';
import { fetchContacts, fetchContactsBySiteId, selectContactsBySiteId, selectContactById, selectContactsState } from '../../redux/slices/contactsSlice';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Link,
  Paper,
} from '@material-ui/core';
import { useTableStyles } from '../../styles/muiStyles';
import ConcentrateursActionCard from './ConcentrateursActionCard';
import ConcentrateursMenu from './ConcentrateursMenu';
import DevOff from '@material-ui/icons/PowerOffRounded';

const tableHeaders = ['#Logement', '#Concentrateurs', '#Modules', 'Date start', 'Date end', 'Last data', 'TS last data', 'PDTemps', 'Tech/Vers', 'Actions'];

const SitesTableDetail: React.FC<{ sites: SiteState[], siteSn: string, siteId: string/*, contacts: ContactState[]*/ }> = ({
  sites,
  siteSn,
  siteId,
//  contacts,
}) => {
  const classes = useTableStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector(selectAuthState);
//  const concentrateurs = fetchConcentrateursBySiteId("2");
/*  const contacts = useSelector((state: RootState) =>
 //   selectContactsBySiteId(state, 'siteId')
    fetchContacts()
  );*/
  const { fetchLoading, fetchError, sortBy, filterBy } = useSelector(
    selectContactsState
  );
  const [filterValue, setFilterValue] = useState('');
  const sortConcByDate = (Concentrateurs: Concentrateur[]) => {
    const dateFormat = 'YYYY-MM-DDTHH:MM:SS.SSSZ'
    console.log(Concentrateurs.length,Concentrateurs.map((c) => (c.date_start)))
    return Concentrateurs.slice().sort((a,b)=>
      moment(a.date_start, dateFormat).unix() - moment(b.date_start, dateFormat).unix())/*.reverse()*/;
  }
  /*useEffect(() => {
    if (!contacts) {
      dispatch(fetchContactsBySiteId(siteId));
    //  dispatch(selectContactsBySiteId(siteId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/

/*  const dispatch = useDispatch();
  const allContacts = useSelector((state)=>state.contactsSlice.selectContactById)
//  const allContacts = fetchContacts() as ContactState[];
  // Find multiple objects that satisfy a condition
    const activeContacts = allContacts.filter(obj => {
       return obj.active === true;
    });
*/
    return (
        <Paper className={classes.table}>
        <Table>
          <TableHead>
            <TableRow>
              {tableHeaders.map((t) => (
                <TableCell key={t} align="center">
                  {t}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
          {sites.map((site) => (
            site.concentrateur.map((p) => (
            /*sites.filter(site => {return site.sn_site === siteSn}).map((p) => (*/
            <TableRow key={p.id}>
              <TableCell
                onClick={() => history.push(`/site/${siteSn}/${siteId}/equipements`)}
                //onClick={() => history.push(`/site/${p.site_sn}`)/*history.push(`/sites/${p.id}`)*/}
                className={classes.clickableCell}
                align="center"
              >
                <Link
                  component={RouterLink}
                  to={`/site/${siteSn}/${siteId}/equipements`/*`/sites/${p.id}`*/}
                  color="secondary"
                >{p.site_sn}
                </Link>
                {/*<Link
                  component={RouterLink}
                  to={`/site/${p.site_sn}/${siteId}`/*`/sites/${p.id}`/}
                  color="secondary"
                >
                  {p.site_sn}
                </Link>*/}
              </TableCell>
              <TableCell align="center">
              <DevOff style={{top: '0.1rem', position: 'relative', 
                color: p.date_end !== null ? 'black' : 'transparent' }} /> 
                <span style={{bottom: '0.265rem', position: 'relative'}}>{p.hardSn/*p.concentrateurs.length .map((c) => (c.hardSn))*/}</span>
              </TableCell>
              <TableCell align="center">{p.modules.toString().replaceAll(",",String.fromCharCode(10)+String.fromCharCode(13))}</TableCell>
              <TableCell align="center">{new Date(p.date_start).toLocaleDateString()}</TableCell>
              <TableCell align="center">{p.date_end ? new Date(p.date_end).toLocaleDateString() : null}</TableCell>
              <TableCell align="center">{new Date(p.date_last_data).toLocaleDateString()}</TableCell>
              <TableCell align="center">{new Date(p.ts_last_data).toLocaleDateString()}</TableCell>
              <TableCell align="center">{p.pdt}</TableCell>
              <TableCell align="center">{p.techno !== null ? p.techno : null}{" / "}{p.version !== null ? p.version : null}</TableCell>
              <TableCell align="center">
                {/*<ConcentrateursMenu
                  concId={p.id.toString()}
                  siteSn={p.site_sn.toString()}
                  hardSn={p.hardSn.toString()}
                  tel={p.tel.toString()}
                  date_last_data={p.date_last_data}
                  ts_last_data={p.ts_last_data}
                  modules={p.modules}
                  siteId={siteId}
                  currentData={{hardSn: p.hardSn.toString(), tel: p.tel.toString(), siteSn: p.site_sn.toString(), siteId: site.id, date_start: p.date_start, date_end: p.date_end, date_last_data: p.date_last_data, ts_last_data: p.ts_last_data, pdt: p.pdt, modules: p.modules, aliasSn: p.alias_sn, techno: p.techno?.toString(), version: p.version !== null ? p.version : 0 }}
                />*/}
              </TableCell>
            </TableRow>
          ))))}
        </TableBody>
      </Table>
    </Paper>
    );
/*  return (
    <Paper className={classes.table}>
      <Table>
        <TableHead>
          <TableRow>
            {tableHeaders.map((t) => (
              <TableCell key={t} align="center">
                {t}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sites.map((p) => (
            <TableRow key={p.id}>
              <TableCell
                onClick={() => history.push(`/site/${p.sn_site}`)/*</TableRow>history.push(`/sites/${p.id}`)*}
                className={classes.clickableCell}
                align="center"
              >
                <Link
                  component={RouterLink}
                  to={`/site/${p.sn_site}`/*`/sites/${p.id}`*}
                  color="secondary"
                >
                  {p.sn_site/*truncateString(p.name, 30)*}
                </Link>
              </TableCell>
              <TableCell align="center">
                {p.concentrateur.map((c) => (c.hardSn))/*p.concentrateurs.length .map((c) => (c.hardSn))*}
              </TableCell>
              <TableCell align="center">{p.concentrateur.map((conc) => (
                conc.modules.toString()
              ))}
              </TableCell>
              <TableCell align="center">
                {contacts.find(contacts => { return contacts.site_id === Number(p.id); })?.prenom} {contacts.find(contacts => { return contacts.site_id === Number(p.id); })?.nom}
              </TableCell>
              <TableCell align="center">{p.concentrateur.map((conc) => (
                new Date(conc.date_last_data).toLocaleDateString()
              ))}
              </TableCell>
              <TableCell align="center">
                {/*new Date(p.date_update).toLocaleDateString()*//*.split('T')[0]*}
                {p.concentrateur.map((conc) => (
                new Date(conc.ts_last_data).toLocaleDateString()
              ))}
              </TableCell>
              <TableCell align="center">
                <SitesMenu
                  siteId={p.id}
                  sn_site={p.sn_site}/*{p.name}*
                  /*isAdmin={true}*//*{p.createdBy.id === user?.id}*
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );*/
};

export default SitesTableDetail;
