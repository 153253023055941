import { useDispatch, useSelector } from 'react-redux';
import FilterBar from '../../components/FilterBar';
import SortBar from '../../components/SortBar';
import FormDialog from '../../components/FormDialog';
import SiteForm from './SiteForm';
import { SiteSortValues } from '../../redux/types';
import {
  sortSitesBy,
  selectSitesState,
} from '../../redux/slices/sitesSlice';

import { useActionCardStyles } from '../../styles/muiStyles';
import AddIcon from '@material-ui/icons/Add';

const menuItems = [
  { value: 'sn-sites-asc', label: 'SN Site ASC' },
  { value: 'sn-sites-desc', label: 'SN Site DESC' },
  { value: 'contact-a-z', label: 'Nom (A - Z)' },
  { value: 'contact-z-a', label: 'Nom (Z - A)' },
  { value: 'sn-concentrateurs-asc', label: 'SN Concentrateur ASC' },
  { value: 'sn-concentrateurs-desc', label: 'SN Concentrateur DESC' },
  { value: 'last-data', label: 'Last Data' },
  { value: 'ts-last-data', label: 'TS Last Data' },
];

const SitesActionCard: React.FC<{
  filterValue: string;
  setFilterValue: (filterValue: string) => void;
  isMobile: boolean;
}> = ({ filterValue, setFilterValue, isMobile }) => {
  const classes = useActionCardStyles();
  const dispatch = useDispatch();
  const { sortBy } = useSelector(selectSitesState);

  const handleSortChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = e.target.value as SiteSortValues;
    dispatch(sortSitesBy(selectedValue));
  };

  return (
    <div>
      <div className={classes.inputs}>
        <div className={classes.searchBarWrapper}>
          <FilterBar
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            label="Sites"
            size={isMobile ? 'small' : 'medium'}
          />
        </div>
        <div className={classes.sortBarWrapper}>
          <SortBar
            sortBy={sortBy}
            handleSortChange={handleSortChange}
            menuItems={menuItems}
            label="Sites"
            size={isMobile ? 'small' : 'medium'}
          />
        </div>
      </div>
      <FormDialog
        triggerBtn={
          isMobile
            ? {
                type: 'fab',
                variant: 'extended',
                text: 'Site',
                icon: AddIcon,
              }
            : {
                type: 'normal',
                text: 'Ajouter un Site',
                icon: AddIcon,
                size: 'large',
              }
        }
        title="Ajouter un site"
      >
      </FormDialog>
    </div>
  );
};

export default SitesActionCard;
