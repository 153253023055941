import axios from 'axios';
import backendUrl from '../backendUrl';
import { setConfig } from './auth';
import { Voie2mesurePayload }from '../redux/types';

//const baseUrl = `${backendUrl}/sites`;
const baseUrl = `${backendUrl}/voie2mesures`;

//const getVoie2mesures = async (siteId: string) => {
const getVoie2mesures = async () => {
//onst response = await axios.get(baseUrl, setConfig());
//  const response = await axios.get(`${backendUrl}/sites/voie2mesures`, setConfig());
  const response = await axios.get(`${backendUrl}/voies2mesures`, setConfig());
  return response.data;
};

const getSiteVoie2mesures = async (site_sn: string) => {
  //onst response = await axios.get(baseUrl, setConfig());
//  const response = await axios.get(`${backendUrl}/sites/${site_sn}/voies2mesures`, setConfig());
  const response = await axios.get(`${backendUrl}/voies2mesures/${site_sn}/voies2mesures`, setConfig());
  return response.data;
};

const createVoie2mesuresDB = async (
  siteId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.post(
    `${backendUrl}/voies2mesures/createdb/${siteId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
  return response.data;
};

const createVoie2mesures = async (
  siteId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.post(
    `${backendUrl}/voies2mesures/create/${siteId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
  console.log("SN SITE RESPONSE: "+response.data[0].sn_site)
  return response.data;
};
const repriseSuiviDB = async (
  voieId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.post(
    `${backendUrl}/voies2mesures/reprisedb/${voieId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
  console.log("SN SITE RESPONSE: "+response.data[0].sn_site)
  return response.data;
};

const arretSuiviDB = async (
  voieId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.post(
    `${backendUrl}/voies2mesures/arretdb/${voieId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
  console.log("SN SITE RESPONSE: "+response.data[0].sn_site)
  return response.data;
};
const repriseSuivi = async (
  voieId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.post(
    `${backendUrl}/voies2mesures/reprise/${voieId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
  console.log("SN SITE RESPONSE: "+response.data[0].sn_site)
  return response.data;
};

const arretSuivi = async (
  voieId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.post(
    `${backendUrl}/voies2mesures/arret/${voieId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
  console.log("SN SITE RESPONSE: "+response.data[0].sn_site)
  return response.data;
};
const addDbleSuiviDB = async (
  voieId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.post(
    `${backendUrl}/voies2mesures/dblesuivi/${voieId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
//  console.log("SN SITE RESPONSE: "+response.data.sn_site)
  return response.data;
};

const updateVoie2mesuresDB = async (
  voieId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.post(
    `${backendUrl}/voies2mesures/updatedb/${voieId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
  return response.data;
};

const stopVoie2mesuresDB = async (
  voieId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.put(
    `${backendUrl}/voies2mesures/stopsuividb/${voieId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
  return response.data;
};
const updateVoie2mesures = async (
  voieId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.post(
    `${backendUrl}/voies2mesures/update/${voieId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
  console.log("SN SITE RESPONSE: "+response.data[0].sn_site)
  return response.data;
};

const stopVoie2mesures = async (
  voieId: string,
  voieData: Voie2mesurePayload
) => {
  const response = await axios.put(
    `${backendUrl}/voies2mesures/stopsuivi/${voieId}`,
//    `${backendUrl}/${siteId}/equipements/${equipementId}`,
//    `${baseUrl}/${siteId}/equipements/${equipementId}`,
    voieData,
    setConfig()
  );
  return response.data;
};
const addVoie2mesures = async (site_id: string, voie2mesures: string[]) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/voie2mesures`,
    { voie2mesures },
    setConfig()
  );
  return response.data;
};

const removeVoie2mesure = async (site_id: string, voie2mesureId: string) => {
//const removeVoie2mesure = async (site_id: string, voie2mesureId: number) => {
  const response = await axios.delete(
    `${backendUrl}/voies2mesures/removedb/${voie2mesureId}`,
    setConfig()
  );
  return response.data;
};

const leaveSite = async (site_id: string) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/voie2mesures/leave`,
    null,
    setConfig()
  );
  return response.data;
};

const voie2mesureService = { 
  addVoie2mesures,
  addDbleSuiviDB,
  updateVoie2mesures,
  repriseSuiviDB,
  arretSuiviDB,
  repriseSuivi,
  arretSuivi,
  stopVoie2mesures,
  updateVoie2mesuresDB,
  createVoie2mesures,
  createVoie2mesuresDB,
  stopVoie2mesuresDB,
  removeVoie2mesure,
  leaveSite,
  getVoie2mesures,
  getSiteVoie2mesures,
};

export default voie2mesureService;
