import axios from 'axios';
import backendUrl from '../backendUrl';
import { setConfig } from './auth';
import { UsagePayload }from '../redux/types';

//const baseUrl = `${backendUrl}/sites`;
const baseUrl = `${backendUrl}/usages`;

//const getUsages = async (siteId: string) => {
const getUsages = async () => {
//onst response = await axios.get(baseUrl, setConfig());
//  const response = await axios.get(`${backendUrl}/sites/usages`, setConfig());
  const response = await axios.get(`${backendUrl}/usages`, setConfig());
  return response.data;
};

const getSiteUsages = async (site_sn: string) => {
  //onst response = await axios.get(baseUrl, setConfig());
  const response = await axios.get(`${backendUrl}/sites/${site_sn}/usages`, setConfig());
  return response.data;
};

const addUsages = async (site_id: string, usages: string[]) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/usages`,
    { usages },
    setConfig()
  );
  return response.data;
};

const removeUsage = async (site_id: string, usageId: string) => {
//const removeUsage = async (site_id: string, usageId: number) => {
  const response = await axios.delete(
    `${baseUrl}/${site_id}/usages/${usageId}`,
    setConfig()
  );
  return response.data;
};

const leaveSite = async (site_id: string) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/usages/leave`,
    null,
    setConfig()
  );
  return response.data;
};

const usageService = { 
  addUsages,
  removeUsage,
  leaveSite,
  getUsages,
  getSiteUsages,
};

export default usageService;
