import { useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  deleteSite,
  leaveSiteContactship,
} from '../../redux/slices/sitesSlice';
import ConfirmDialog from '../../components/ConfirmDialog';
import FormDialog from '../../components/FormDialog';
import SiteForm from './SiteForm';

import { Menu, IconButton, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';

interface SitesMenuProps {
  siteId: string;
//  siteSn: string;
  sn_site: string;
//  contacts: string;
/*  currentName: string;
  currentContacts: string[];
  isAdmin: boolean;*/
  iconSize?: 'small' | 'default' | 'large';
}

const SitesMenu: React.FC<SitesMenuProps> = ({
  siteId,
  sn_site,
//  contacts,
  iconSize,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDeleteSite = () => {
    dispatch(deleteSite(siteId, history));
  };

  const handleLeaveSite = () => {
    dispatch(leaveSiteContactship(siteId, history));
  };

  return (
    <div>
      <IconButton onClick={handleOpenMenu} size="small">
        <MoreHorizIcon color="primary" fontSize={iconSize || 'large'} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        marginThreshold={8}
        elevation={4}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <MenuItem
          onClick={handleCloseMenu}
          component={RouterLink}
          //to={`/sites/${siteId}`}
          to={`/sites/${sn_site}`}
        >
          <OpenInNewIcon style={{ marginRight: '10px' }} />
          Site Details
        </MenuItem>
          <ConfirmDialog
            title="Confirm Leave Site"
            contentText="Are you sure you want to leave the site's contactship?"
            actionBtnText="Leave Site"
            triggerBtn={{
              type: 'menu',
              text: 'Leave Site',
              icon: ExitToAppOutlinedIcon,
              iconStyle: { marginRight: '10px' },
              closeMenu: handleCloseMenu,
            }}
            actionFunc={handleLeaveSite}
          />
          <div>
            <FormDialog
              triggerBtn={{
                type: 'menu',
                icon: EditOutlinedIcon,
                text: 'Edit Name',
                iconStyle: { marginRight: '10px' },
                closeMenu: handleCloseMenu,
              }}
              title="Edit the site name"
            >
              <SiteForm
                editMode="siteSn"
                currentSiteSn={sn_site}
                siteId={siteId}
              />
            </FormDialog>
            <ConfirmDialog
              title="Confirm Delete Site"
              contentText="Are you sure you want to permanently delete your site?"
              actionBtnText="Delete Site"
              triggerBtn={{
                type: 'menu',
                text: 'Delete Site',
                icon: DeleteOutlineIcon,
                iconStyle: { marginRight: '10px' },
                closeMenu: handleCloseMenu,
              }}
              actionFunc={handleDeleteSite}
            />
          </div>
      </Menu>
    </div>
  );
};

export default SitesMenu;
