import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SiteState, ContactState } from '../../redux/types';
import { selectAuthState } from '../../redux/slices/authSlice';
import SitesMenu from './SitesMenu';
import { formatDateTime, truncateString } from '../../utils/helperFuncs';

import { Divider, Typography, Link } from '@material-ui/core';
import { useMainPageStyles } from '../../styles/muiStyles';
import BugReportTwoToneIcon from '@material-ui/icons/BugReportTwoTone';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const SitesListMobile: React.FC<{ sites: SiteState[], contacts: ContactState[] }> = ({
  sites,
}) => {
  const classes = useMainPageStyles();
  const { user } = useSelector(selectAuthState);

  return (
    <div>
      <Divider />
      {sites.map((p, i) => (
        <div
          key={p.id}
          style={{ paddingBottom: i + 1 === sites.length ? '2em' : 0 }}
        >
          <div className={classes.listItemWrapper}>
            <Link
              component={RouterLink}
              to={`/sites/${p.id}`}
              color="secondary"
              variant="h6"
            >
              {p.id/*truncateString(p.name, 30)*/}
              <OpenInNewIcon color="primary" className={classes.gotoIcon} />
            </Link>
            <Typography variant="body2" color="secondary">
              Updated: <strong>{p.date_update}</strong>
            </Typography>
            <Typography variant="body2" color="secondary">
              Created: <strong>{formatDateTime(p.date_instru)}</strong>
            </Typography>
            <div className={classes.flexedWrapper}>
              <div className={classes.textIconsWrapper}>
                <div className={classes.iconText}>
                  <BugReportTwoToneIcon color="secondary" />
                  <Typography variant="subtitle1" color="secondary">
                    : {p.concentrateur.length}
                  </Typography>
                </div>
                <div className={classes.iconText}>
                  <PeopleAltTwoToneIcon color="secondary" />{' '}
                  <Typography variant="subtitle1" color="secondary">
                    : {p.contacts.concat.name/*contact_id  .length*/}
                  </Typography>
                </div>
              </div>
              <SitesMenu
                siteId={p.id}
                sn_site={p.sn_site}
/*                isAdmin={true}*/
                iconSize="default"
              />
            </div>
          </div>
          <Divider />
        </div>
      ))}
    </div>
  );
};

export default SitesListMobile;
