import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  createNewSite,
  editSiteSn,
  clearSubmitSiteError,
  selectSitesState,
} from '../../redux/slices/sitesSlice';
import { format } from 'date-fns';
import { ContactPayload, SitePayload } from '../../redux/types';
import { getPiscineInfos, piscineFields, getSDetailRegister, getClimInfos, getVentilInfos, getEcsInfos, getChauffInfos, chauffFields, ecsFields, climFields, getPlaquesInfos, getFourInfos, plaquesFields, fourFields, ventilFields } from '../../types/siteDetailsInfos';
import ErrorBox from '../../components/ErrorBox';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import IconDashboard from '@material-ui/icons/Dashboard';

import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  InputAdornment,
  FormLabel,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  OutlinedInput,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { useFormStyles, useTableStyles } from '../../styles/muiStyles';
import DateIcon from '@material-ui/icons/DateRangeTwoTone';
import SubjectIcon from '@material-ui/icons/Subject';
import { ChangeEvent, useState } from 'react';
import { MultiSelectTreeViewProps } from '@material-ui/lab';
import { SelectProps } from '@material-ui/core';
import AppMenu from '../../components/AppMenu';
import { MultiSelect } from "react-multi-select-component";
/*import { Dropdown } from 'react-nested-dropdown';
import { ChauffageSelector } from '../../components/ChauffageSelector';*/

const validationSchema = yup.object({
  site_sn: yup
    .string()
    .required('Required')
    .min(3, 'Must be at least 3 characters')
    .max(60, 'Must be at most 60 characters'),

  cpt_type: yup.string().required('Required'),
});

export type occupationProps = {
  siteId: string;
  range: string;
  nombre: number;
  taux: number;
}
type statsOccup = {
  [key:string]: occupationProps[];
}

interface SiteDetailFormxProps {
  closeDialog?: () => void;
  siteId: string;
  isEditMode: boolean;
  isMobile: boolean;
  currentData?: SitePayload;
}

const SiteDetailFormx: React.FC<SiteDetailFormxProps> = ({
  closeDialog,
  isEditMode,
  isMobile,
  siteId,
  currentData,
}) => {
  const classes = useFormStyles();
  const classes2 = useTableStyles();
  const dispatch = useDispatch();
  const { submitError, submitLoading } = useSelector(selectSitesState);
  const contact = currentData?.contacts?.find((c) => c.site_sn[0] === Number(currentData?.sn_site));
  let set: Array<occupationProps> = []

  let currOccupation: occupationProps[]
  if(currentData !== undefined) {
    currentData?.tx_occupation?.map((line) => {
      let tab = line.split("|")
      if(line.length >= 1) {
        set.push({siteId: currentData.id, range: tab[0], nombre: Number(tab[1].replace("nb ","")), taux: Number(tab[2].replace("poids ","")) })
      }
    })
  } else {
    console.log("REGISTER: currentData undefined")
    set.push({ siteId: "0", range: "0-10", nombre: 0, taux: 0 })
    set.push({ siteId: "0", range: "11-17", nombre: 0, taux: 0 })
    set.push({ siteId: "0", range: "18-24", nombre: 0, taux: 0 })
    set.push({ siteId: "0", range: "25-59", nombre: 0, taux: 0 })
    set.push({ siteId: "0", range: "60-74", nombre: 0, taux: 0 })
    set.push({ siteId: "0", range: ">74", nombre: 0, taux: 0 })
  }
  const chauffDetail: string[][] = []
  currentData?.chauff_detail?.map((detail,index) => {
    let sub = detail.split("+")
    chauffDetail[index] = []
    sub.map((s) => chauffDetail[index].push(s))
  //  console.log("chauff_detail: "+chauffDetail[index])
  })
  console.log("currentData.piscine_infos: "+currentData?.piscine_infos)
  const infosPiscine = getPiscineInfos(currentData?.piscine_infos)
  const infosClim = getClimInfos(currentData?.clim_type, currentData?.clim_detail, currentData?.clim_rq)
  const infosVentil = getVentilInfos(currentData?.ventil_type, currentData?.ventil_detail, currentData?.ventil_rq)
  const infosEcs = getEcsInfos(currentData?.ecs_nrj, currentData?.ecs_detail, currentData?.ecs_rq)
  const infosChauff = getChauffInfos(currentData?.chauff_nrj, chauffDetail/*currentData?.chauff_detail*/, currentData?.chauff_rq)
  const infosPlaques = getPlaquesInfos(currentData?.cuissonplq_nrj, currentData?.cuissonplq_detail, currentData?.cuissonplq_rq)
  const infosFour = getFourInfos(currentData?.four_type, currentData?.four_detail, currentData?.four_rq)
//  const infosChauff = getChauffInfos(chauffFields.map((cf) => cf.nrj), chauffFields.map((cf) => cf.detail), chauffFields.map((cf) => cf.rq))
  const { register, control, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: /*getSDetailRegister(currentData, contact),*/
    {
      /*occupy_0_10_nb: set[0].nombre || '',
      occupy_11_17_nb: set[1].nombre || '',
      occupy_18_24_nb: set[2].nombre || '',
      occupy_25_59_nb: set[3].nombre || '',
      occupy_60_74_nb: set[4].nombre || '',
      occupy_74_nb: set[5].nombre || '',*/
      sn_site: currentData?.sn_site || '',
      site_id: currentData?.id || '',
      ctct_prenom: contact?.prenom || '',
      ctct_nom: contact?.nom || '',
      ctct_tel: contact?.tel || '',
      ctct_mail: contact?.mail || '',
      ctct_adresse: contact?.adresse || '',
      ctct_code_postal: contact?.code_postal || '',
      ctct_actif: contact?.actif || '',
      ctct_revenus: contact?.revenus || '',
      ctct_remarques: contact?.remarques || '',
      gmt_date: currentData?.gmt_date || '',
      date_instru: currentData?.date_instru || '',
      date_end: currentData?.date_end || '',
    //  occupation: Array.from(statOccupy[currentData?.id || "0"]) as occupationProps[],
      cpt_type: currentData?.cpt_type || '',
      type_inst_elec: currentData?.type_inst_elec || '',
      index_cpt_elec: currentData?.index_cpt_elec || '',
      index_cpt_hc: currentData?.index_cpt_hc || '',
      tarif_elec: currentData?.tarif_elec || '',
      puiss_sscrite: currentData?.puiss_sscrite || '',
      prm_linky: currentData?.prm_linky || '',
      adresse: currentData?.adresse || '',
      code_postal: currentData?.code_postal || '',
      instrum_by: currentData?.instrum_by || '',
      nature: currentData?.nature || '',
      surface: currentData?.surface || '',
      surf_unused: currentData?.surf_unused || '',
      surf_nonchauff: currentData?.surf_nonchauff || '',
      niveaux: currentData?.niveaux || '',
      date_update: currentData?.date_update || '',
      rq_instru: currentData?.rq_instru || '',
      chauff_nrj0: infosChauff?.[0].nrj,
      chauff_detail0: infosChauff?.[0].detail?.map((i) => i.length > 3 ? i+"," : null),
      chauff_rq0: infosChauff?.[0].rq,
      chauff_nrj1: infosChauff?.[1].nrj,
      chauff_detail1: infosChauff?.[1].detail?.map((i) => i.length > 3 ? i+"," : null),
      chauff_rq1: infosChauff?.[1].rq,
      chauff_nrj2: infosChauff?.[2].nrj,
      chauff_detail2: infosChauff?.[2].detail?.map((i) => i.length > 3 ? i+"," : null),
      chauff_rq2: infosChauff?.[2].rq,
      ecs_nrj0: infosEcs?.[0].nrj,
      ecs_detail0: infosEcs?.[0].detail,
      ecs_rq0: infosEcs?.[0].rq,
      ecs_nrj1: infosEcs?.[1].nrj,
      ecs_detail1: infosEcs?.[1].detail,
      ecs_rq1: infosEcs?.[1].rq,
      cuissonplq_nrj0: infosPlaques?.[0].type,
      cuissonplq_detail0: infosPlaques?.[0].nbFeux,
      cuissonplq_rq0: infosPlaques?.[0].rq,
      cuissonplq_nrj1: infosPlaques?.[1].type,
      cuissonplq_detail1: infosPlaques?.[1].nbFeux,
      cuissonplq_rq1: infosPlaques?.[1].rq,
      four_type0: infosPlaques?.[0].type,
      four_detail0: infosPlaques?.[0].nbFeux,
      four_rq0: infosPlaques?.[0].rq,
      four_type1: infosPlaques?.[1].type,
      four_detail1: infosPlaques?.[1].nbFeux,
      four_rq1: infosPlaques?.[1].rq,
      ventil_type0: infosVentil?.[0].type,
      ventil_detail0: infosVentil?.[0].detail,
      ventil_rq0: infosVentil?.[0].rq,
      ventil_type1: infosVentil?.[1].type,
      ventil_detail1: infosVentil?.[1].detail,
      ventil_rq1: infosVentil?.[1].rq,
      ventil_type2: infosVentil?.[2].type,
      ventil_detail2: infosVentil?.[2].detail,
      ventil_rq2: infosVentil?.[2].rq,
      clim_type0: infosClim?.[0].type,
      clim_detail0: infosClim?.[0].detail,
      clim_rq0: infosClim?.[0].rq,
      clim_type1: infosClim?.[1].type,
      clim_detail1: infosClim?.[1].detail,
      clim_rq1: infosClim?.[1].rq,
      clim_type2: infosClim?.[2].type,
      clim_detail2: infosClim?.[2].detail,
      clim_rq2: infosClim?.[2].rq,
      piscine_infos_present: infosPiscine.present,
      piscine_infos_type: infosPiscine.type,
      piscine_infos_chauffage: infosPiscine.chauffage,
      piscine_infos_lgdia: infosPiscine.longueurOrDiam,
      piscine_infos_larg: infosPiscine.largeur,
      piscine_infos_prof: infosPiscine.prof,
      piscine_infos_consoppe: infosPiscine.consoPompe,
      piscine_infos_note: infosPiscine.note,
    },
  });
  console.log("currData occupy: "+currentData?.tx_occupation?.map((oc) => oc))
  const [state, setState] = useState({
//    theSite: selectedLogement,
    chauff_nrj0: infosChauff?.[0].nrj,
    chauff_detail0: infosChauff?.[0].detail?.map((i) => i.length > 3 ? i+"," : null),
    chauff_rq0: infosChauff?.[0].rq,
    chauff_nrj1: infosChauff?.[1].nrj,
    chauff_detail1: infosChauff?.[1].detail?.map((i) => i.length > 3 ? i+"," : null),
    chauff_rq1: infosChauff?.[1].rq,
    chauff_nrj2: infosChauff?.[2].nrj,
    chauff_detail2: infosChauff?.[2].detail?.map((i) => i.length > 3 ? i+"," : null),
    chauff_rq2: infosChauff?.[2].rq,
    ecs_nrj0: infosEcs?.[0].nrj,
    ecs_detail0: infosEcs?.[0].detail,
    ecs_rq0: infosEcs?.[0].rq,
    ecs_nrj1: infosEcs?.[1].nrj,
    ecs_detail1: infosEcs?.[1].detail,
    ecs_rq1: infosEcs?.[1].rq,
    cuissonplq_type0: infosPlaques?.[0].type,
    cuissonplq_nbFeux0: infosPlaques?.[0].nbFeux,
    cuissonplq_rq0: infosPlaques?.[0].rq,
    cuissonplq_type1: infosPlaques?.[1].type,
    cuissonplq_nbFeux1: infosPlaques?.[1].nbFeux,
    cuissonplq_rq1: infosPlaques?.[1].rq,
    four_type0: infosFour?.[0].type,
    four_detail0: infosFour?.[0].detail,
    four_rq0: infosFour?.[0].rq,
    four_type1: infosFour?.[1].type,
    four_detail1: infosFour?.[1].detail,
    four_rq1: infosFour?.[1].rq,
    ventil_type0: infosVentil?.[0].type,
    ventil_detail0: infosVentil?.[0].detail,
    ventil_rq0: infosVentil?.[0].rq,
    ventil_type1: infosVentil?.[1].type,
    ventil_detail1: infosVentil?.[1].detail,
    ventil_rq1: infosVentil?.[1].rq,
    ventil_type2: infosVentil?.[2].type,
    ventil_detail2: infosVentil?.[2].detail,
    ventil_rq2: infosVentil?.[2].rq,
    clim_type0: infosClim?.[0].type,
    clim_detail0: infosClim?.[0].detail,
    clim_rq0: infosClim?.[0].rq,
    clim_type1: infosClim?.[1].type,
    clim_detail1: infosClim?.[1].detail,
    clim_rq1: infosClim?.[1].rq,
    clim_type2: infosClim?.[2].type,
    clim_detail2: infosClim?.[2].detail,
    clim_rq2: infosClim?.[2].rq,
    piscine_infos_present: infosPiscine.present,
    piscine_infos_type: infosPiscine.type,
    piscine_infos_chauffage: infosPiscine.chauffage,
    piscine_infos_lgdia: infosPiscine.longueurOrDiam,
    piscine_infos_larg: infosPiscine.largeur,
    piscine_infos_prof: infosPiscine.prof,
    piscine_infos_consoppe: infosPiscine.consoPompe,
    piscine_infos_note: infosPiscine.note,
    revenus: contact?.revenus,
  });
  const hdlSetChauff = (event: React.ChangeEvent<{  name?: string | undefined; value: unknown; }> /*event: SelectChangeEvent*/) => {
    console.log(event.target.name+": "+event.target.value);
    setState({ ...state, [event.target.name as string]: event.target.value });
    //setSelectValue(event.target.value as string);
  };
  const [chauff0detail, setChauff0detail] = useState<string[]>([]);
  const hdlChgChauff0detail = (val: any/*event: { name?: string | undefined; value: any}*/) => {
    console.log("################ e.taget.value 4chauffDet: "+val)
    const valeur = typeof val === "string" ? val.split(",") : val
    setChauff0detail(valeur)
    console.log("multiSelectValues: "+chauff0detail)
    //typeof event.target.value // Is now a string[]
   /* const selectedOptions = event.currentTarget.selectedOptions;
    const newChauffDet = [];
    for (let i = 0; i < selectedOptions.length; i++) {
      newChauffDet.push(selectedOptions[i].value);
    }
    setChauff0detail(newChauffDet);*/
  };
  console.log("chauff_detail0: "+state.chauff_detail0)
  console.log("infosChauff[0].nrj: "+infosChauff[0].nrj+" si UNDEFINED : no details")
  /***********************************************************************************/
  const [selected, setSelected] = useState([]); // chauffageSelectorTest
  
  //console.log("Infos piscine: "+infosPiscine.present+" "+infosPiscine.type+" "+infosPiscine.chauffage)
  console.log("infosChauff[0].detail: "+infosChauff.map((ic) => ic.detail+" "))
/*  chauffFields.map((c) =>
    c.detail.map((u) => console.log("chauffFields detail MAP: "+u))
  )*/
  const handleCreateContact = (data: ContactPayload) => {
    //dispatch(createNewContact(/*siteId,*/ data, closeDialog));
  };
  const handleUpdateContact = (data: ContactPayload) => {
    //dispatch(editContact(siteId as string, siteId/* data*/, closeDialog));
  };
  const handleCreateSite = (data: SitePayload) => {
    dispatch(createNewSite(/*siteId,*/ data, closeDialog));
  };
  const handleUpdateSite = (data: SitePayload) => {
    dispatch(editSiteSn(siteId as string, siteId/* data*/, closeDialog));
  };
  
  return (
    <><><div className={classes2.TopDivider}>
      <form onSubmit={handleSubmit(isEditMode ? handleUpdateContact : handleCreateContact)}>
      <Typography
        variant={isMobile ? 'h5' : 'h4'}
        color="secondary"
        style={{ marginRight: '0.2em', marginTop: '1.5rem' }}
      >Contact <SubjectIcon color="primary" />
      </Typography>
      <Table className={classes2.contactDetailsTable}>
        <TableBody>
          <TableRow>
            <TableCell colSpan={1}>
              <TextField margin='dense'
                //className={MoreClasses.tiersWidth}
                inputRef={register}
                name="ctct_prenom"
                type="text"
                label="Prénom"
                variant="outlined"
                fullWidth
                //onChange={handleChange}
                error={'reponses' in errors}
                style={{ marginTop: '1.1em' }} /></TableCell>
            <TableCell colSpan={1}>
              <TextField margin='dense'
                //className={MoreClasses.tiersWidth}
                inputRef={register}
                name="ctct_nom"
                type="text"
                label="Nom"
                variant="outlined"
                fullWidth
                //onChange={handleChange}
                error={'reponses' in errors}
                style={{ marginTop: '1.1em' }} /></TableCell>
            <TableCell colSpan={1}>
              <TextField margin='dense'
                //className={MoreClasses.tiersWidth}
                inputRef={register}
                name="ctct_tel"
                type="text"
                label="Téléphone"
                variant="outlined"
                fullWidth
                //onChange={handleChange}
                error={'reponses' in errors}
                style={{ marginTop: '1.1em' }} /></TableCell>
            <TableCell colSpan={1}>
              <TextField margin='dense'
                //className={MoreClasses.tiersWidth}
                inputRef={register}
                name="ctct_mail"
                type="text"
                label="@mail"
                fullWidth
                variant="outlined"
                //onChange={handleChange}
                error={'reponses' in errors}
                style={{ marginTop: '1.1em' }} /></TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={2}>
              <TextField margin='dense'
                //className={MoreClasses?.tiersWidth}
                inputRef={register}
                name="ctct_adresse"
                fullWidth
                type="text"
                label="Adresse"
                variant="outlined"
                //onChange={handleChange}
                error={'reponses' in errors}
                style={{ marginTop: '1.1em' }} /></TableCell>
            <TableCell colSpan={1}>
              <TextField margin='dense'
                //className={MoreClasses?.tiersWidth}
                inputRef={register}
                name="ctct_code_postal"
                type="text"
                label="Code postal"
                fullWidth
                variant="outlined"
                //onChange={handleChange}
                error={'reponses' in errors}
                style={{ marginTop: '1.1em' }} /></TableCell>
            <TableCell colSpan={1}>
            <Select style={{top: '0.33rem', width: '14.15rem'}} inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                    id="ctct_revenus" margin="dense" variant="outlined" onChange={hdlSetChauff}
                    className={classes2.demiWidth} defaultValue={contact?.revenus} name="ctct_revenus">
                    <MenuItem key={"Primaire"} value={"Primaire"} {...contact?.revenus === "Primaire" ? selected : null}>Primaire</MenuItem> 
                    <MenuItem key={"Secondaire"} value={"Secondaire"} {...contact?.revenus === "Secondaire" ? selected : null}>Secondaire</MenuItem>)
                  </Select>
                  {/*<TextField margin='dense'
                //className={MoreClasses?.tiersWidth}
                inputRef={register}
                name="ctct_revenus"
                type="text"
                label="Revenus"
                variant="outlined"
                //onChange={handleChange}
                error={'reponses' in errors}
  style={{ marginTop: '1.1em' }} />*/}</TableCell>
            {/*<TableCell>
              <TextField margin='dense'
                //className={MoreClasses?.tiersWidth}
                inputRef={register}
                name="contact_actif"
                type="text"
                label="Actif"
                variant="outlined"
                //onChange={handleChange}
                error={'reponses' in errors}
  style={{ marginTop: '1.1em' }} /></TableCell>*/}
          </TableRow>
          <TableRow>
            <TableCell colSpan={4}>
              <TextField margin='dense'
                //className={MoreClasses?.tiersWidth}
                inputRef={register}
                name="ctct_remarques"
                type="text"
                label="Remarques"
                variant="outlined"
                fullWidth
                //onChange={handleChange}
                error={'reponses' in errors}
                style={{ marginTop: '1.1em' }} /></TableCell>
          </TableRow>
        </TableBody>
      </Table></form>
      <Button
          size="large"
          color="primary"
          variant="contained"
          //fullWidth
          className={classes.submitBtn}
          type="submit"
          disabled={submitLoading}
        >
          {isEditMode ? 'Mettre à jour le contact' : 'Créer le contact'}
        </Button>
        {submitError && (
          <ErrorBox
            errorMsg={submitError}
            clearErrorMsg={() => dispatch(clearSubmitSiteError())} />
        )}</div></>
      <><div className={classes2.TopDivider}><form
        onSubmit={handleSubmit(isEditMode ? handleUpdateSite : handleCreateSite)}
      >
        <Typography
            variant={isMobile ? 'h5' : 'h4'}
            color="secondary"
            style={{ marginRight: '0.2em', marginTop: '1.5rem', marginBottom: '1em' }}
          >Logement <SubjectIcon color="primary" />
          </Typography>
          <Table className={classes2.contactDetailsTable}>
            <TableBody>
            <TableRow>
              {/*            <TableCell style={{
              width: '62%',
            }}></TableCell>*/}
              <TableCell>
                <TextField
                  inputRef={register}
                  name="sn_site"
                  //fullWidth
                  type="text"
                  label="N° de logement"
                  variant="outlined"
                  error={'sn_site' in errors}
                  helperText={'sn_site' in errors ? errors.sn_site?.message : ''}
                />
              </TableCell>
              <TableCell><TextField
                //className={classes.fieldMargin}
                type="datetime-local"
                inputRef={register}
                name="date_instru"
                value={format(new Date(currentData?.date_instru || new Date()), "yyyy-MM-dd'T'h':'mm")}
                label="Date instrumentation"
                variant="outlined"
                error={'cpt_type' in errors}
                helperText={'cpt_type' in errors ? errors.cpt_type?.message : ''}
              /></TableCell>
              <TableCell><TextField margin='dense'
                //className={MoreClasses?.tiersWidth}
                inputRef={register}
                name="gmt_date"
                type="text"
                label="GMT (1 ou 2)"
                variant="outlined"
                //onChange={handleChange}
                error={'reponses' in errors}
                style={{ marginTop: '1.1em' }}
                /></TableCell>
              <TableCell><TextField
                //className={classes.fieldMargin}
                type="datetime-local"
                inputRef={register}
                name="date_end"
                value={format(new Date(currentData?.date_end || new Date()), "yyyy-MM-dd'T'h':'mm")}
                label="Date de fin du logement"
                variant="outlined"
                error={'cpt_type' in errors}
                helperText={'cpt_type' in errors ? errors.cpt_type?.message : ''}
              /></TableCell>
            </TableRow>
              <TableRow>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="cpt_type"
                  type="text"
                  label="Type compteur élec."
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="type_inst_elec"
                  type="text"
                  label="Type installation élec."
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                /></TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="index_cpt_elec"
                  type="text"
                  label="Index cpt élec."
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="index_cpt_hc"
                  type="text"
                  label="Index cpt HC"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Select style={{top: '0.4rem', width: '14.15rem'}} inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                    id="tarif_elec" margin="dense" variant="outlined" onChange={hdlSetChauff}
                    className={classes2.demiWidth} defaultValue={currentData?.tarif_elec} name="tarif_elec">
                    <MenuItem key={"Simple_tarif"} value={"Simple tarif"} {...currentData?.tarif_elec === "Simple Tarif" ? selected : null}>Simple tarif</MenuItem> 
                    <MenuItem key={"Double_tarif"} value={"Double tarif"} {...currentData?.tarif_elec === "Double Tarif" ? selected : null}>Double tarif</MenuItem>)
                  </Select>
                  {/*<TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="tarif_elec"
                  type="text"
                  label="Tarif élec."
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
            />*/}</TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="puiss_sscrite"
                  type="text"
                  label="Puissance souscrite"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="prm_linky"
                  type="text"
                  label="PRM Linky"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="adresse"
                  type="text"
                  label="Adresse"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="code_postal"
                  type="text"
                  label="Code postal"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="instrum_by"
                  type="text"
                  label="Instrumenté / Visité par"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="nature"
                  type="text"
                  label="Nature"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="surface"
                  type="text"
                  label="Surface"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="surf_unused"
                  type="text"
                  label="Surf. non utilisée"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="surf_nonchauff"
                  type="text"
                  label="Surf. non chauffée"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
                <TableCell><TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  name="niveaux"
                  type="text"
                  label="Nb niveaux"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em' }}
                  /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={4} >
                <TextField margin='dense'
                  //className={MoreClasses?.tiersWidth}
                  inputRef={register}
                  multiline
                  name="rq_instru"
                  type="text"
                  rows={6}
                  label="Notes d'instrumentation"
                  variant="outlined"
                  error={'reponses' in errors}
                  style={{ marginTop: '1.1em', width: '100%' }}
                  /></TableCell>
              </TableRow>
              </TableBody></Table><>
              <Table style={{ marginRight: '0.2em', marginTop: '1em', width: '100%' }}
                    className={classes2.siteDetailsTable}>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell colSpan={1}>
                      Âges
                    </TableCell>
                    <TableCell colSpan={2}>
                      0-10
                    </TableCell>
                    <TableCell colSpan={2}>
                      11-17
                    </TableCell>
                    <TableCell colSpan={2}>
                      18-24
                    </TableCell>
                    <TableCell colSpan={2}>
                      25-59
                    </TableCell>
                    <TableCell colSpan={2}>
                      60-74
                    </TableCell>
                    <TableCell colSpan={2}>
                      &gt;74
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                <TableCell rowSpan={3} style={{ padding: '0' }}>
                      <Typography
                        color="secondary"
                      >Occupation
                      </Typography>
                    </TableCell>
                  <TableRow>
                  <TableCell colSpan={1}>
                    Nombre
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_0-10_nb"
                      type="text"
                      variant="outlined"
                      defaultValue={set.find((s)=>s.range === "0-10")?.nombre || 0/*currOccupation.find((co) => co.range === "0-10")?.nombre*/}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_11-17_nb"
                      type="text"
                      variant="outlined"
                      defaultValue={set.find((s)=>s.range === "11-17")?.nombre || 0}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_18-24_nb"
                      type="text"
                      variant="outlined"
                      defaultValue={set.find((s)=>s.range === "18-24")?.nombre || 0}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_25-59_nb"
                      type="text"
                      variant="outlined"
                      defaultValue={set.find((s)=>s.range === "25-59")?.nombre || 0}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_60-74_nb"
                      type="text"
                      variant="outlined"
                      defaultValue={set.find((s)=>s.range === "60-74")?.nombre || 0}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_74_nb"
                      type="text"
                      variant="outlined"
                      defaultValue={set.find((s)=>s.range === ">74")?.nombre || 0}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  </TableRow>
                  <TableRow className={classes2.lastTrSiteDetails}>
                  <TableCell colSpan={1}>
                    Temps présence (%)
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_0-10_tx"
                      type="text"
                      variant="outlined"
                      defaultValue={(set.find((s)=>s.range === "0-10")?.taux || 0)*100}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_11-17_tx"
                      type="text"
                      variant="outlined"
                      defaultValue={(set.find((s)=>s.range === "11-17")?.taux ||0)*100}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_18-24_tx"
                      type="text"
                      variant="outlined"
                      defaultValue={(set.find((s)=>s.range === "18-24")?.taux ||0)*100}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_25-59_tx"
                      type="text"
                      variant="outlined"
                      defaultValue={(set.find((s)=>s.range === "25-59")?.taux ||0)*100}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_60-74_tx"
                      type="text"
                      variant="outlined"
                      defaultValue={(set.find((s)=>s.range === "60-74")?.taux ||0)*100}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  <TableCell colSpan={2}>
                    <TextField margin='dense'
                      //className={MoreClasses?.tiersWidth}
                      inputRef={register}
                      name="occupy_74_tx"
                      type="text"
                      variant="outlined"
                      defaultValue={(set.find((s)=>s.range === ">74")?.taux ||0)*100}
                      //onChange={handleChange}
                      error={'reponses' in errors}
                    />
                  </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
                <Table style={{ marginRight: '0.2em', marginTop: '1em', width: '100%' }}
                    className={classes2.siteDetailsTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={1}></TableCell>
                      <TableCell colSpan={1} style={{ backgroundColor: 'transparent' }}>
                      </TableCell>
                      <TableCell colSpan={2}>
                        Principal
                      </TableCell>
                      <TableCell colSpan={2}>
                        Secondaire
                      </TableCell>
                      <TableCell colSpan={2}>
                        Appoint
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableCell rowSpan={4} style={{ padding: '0' }}>
                      <Typography
                        color="secondary"
                      >Chauffage
                      </Typography>
                    </TableCell>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Énergie
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="chauff_nrj0" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosChauff[0].nrj} name="chauff_nrj0">
                        {chauffFields.map((u) => ( infosChauff[0].nrj !== u.nrj ?
                          <MenuItem key={u.nrj+"_0"} value={u.nrj}>{u.nrj}</MenuItem> 
                          : <MenuItem key={u.nrj+"_0"} value={u.nrj} selected><em>{u.nrj}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="chauff_nrj1" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosChauff[1].nrj} name="chauff_nrj1">
                        {chauffFields.map((u) => ( infosChauff[1].nrj !== u.nrj ?
                          <MenuItem key={u.nrj+"_1"} value={u.nrj}>{u.nrj}</MenuItem> 
                          : <MenuItem key={u.nrj+"_1"} value={u.nrj} selected><em>{u.nrj}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="chauff_nrj2" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosChauff[2].nrj} name="chauff_nrj2">
                        {chauffFields.map((u) => ( infosChauff[2].nrj !== u.nrj ?
                          <MenuItem key={u.nrj+"_2"} value={u.nrj}>{u.nrj}</MenuItem> 
                          : <MenuItem key={u.nrj+"_2"} value={u.nrj} selected><em>{u.nrj}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Détails
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="chauff_detail0" margin="dense" variant="outlined" /*multiple*/
                        className={classes2.demiWidth} /*defaultValue={infosChauff[0].detail}*/ /*name="chauff_detail0"*/
                        defaultValue={/*state.chauff_detail0*/infosChauff[0].detail/*infosChauff*//*infosChauff[0].detail[0]*//*chauffDetail[0][0] ? chauffDetail[0][0] : ""*//* state.chauff_nrj0.find((ic) => ic.nrj === infosChauff[0].nrj)?.detail*/}>
                        { chauffFields?.find((cf) => cf.nrj === state.chauff_nrj0)?.detail.map((j) => (
                        //  <MenuItem key={j+"_0"} value={j}>{j}</MenuItem> 
                          infosChauff[0].detail?.map((c) => c !== j ?
                          <MenuItem key={j+"_0"} value={j}>{j}</MenuItem> 
                          : <MenuItem key={j+"_0"} value={j} selected><em>{j}</em></MenuItem> )
                        )) }
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="chauff_detail1" margin="dense" variant="outlined" /*multiple*/
                        className={classes2.demiWidth} /*defaultValue={infosChauff[0].detail}*/ /*name="chauff_detail0"*/
                        defaultValue={/*state.chauff_detail0*/infosChauff[1].detail/*infosChauff*//*infosChauff[0].detail[0]*//*chauffDetail[0][0] ? chauffDetail[0][0] : ""*//* state.chauff_nrj0.find((ic) => ic.nrj === infosChauff[0].nrj)?.detail*/}>
                        { chauffFields?.find((cf) => cf.nrj === state.chauff_nrj1)?.detail.map((j) => (
                        //  <MenuItem key={j+"_0"} value={j}>{j}</MenuItem> 
                          infosChauff[1].detail?.map((c) => c !== j ?
                          <MenuItem key={j+"_1"} value={j}>{j}</MenuItem> 
                          : <MenuItem key={j+"_1"} value={j} selected><em>{j}</em></MenuItem> )
                        )) }
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="chauff_detail2" margin="dense" variant="outlined" /*multiple*/
                        className={classes2.demiWidth} /*defaultValue={infosChauff[0].detail}*/ /*name="chauff_detail0"*/
                        defaultValue={/*state.chauff_detail0*/infosChauff[2].detail/*infosChauff*//*infosChauff[0].detail[0]*//*chauffDetail[0][0] ? chauffDetail[0][0] : ""*//* state.chauff_nrj0.find((ic) => ic.nrj === infosChauff[0].nrj)?.detail*/}>
                        { chauffFields?.find((cf) => cf.nrj === state.chauff_nrj2)?.detail.map((j) => (
                        //  <MenuItem key={j+"_0"} value={j}>{j}</MenuItem> 
                          infosChauff[2].detail?.map((c) => c !== j ?
                          <MenuItem key={j+"_2"} value={j}>{j}</MenuItem> 
                          : <MenuItem key={j+"_2"} value={j} selected><em>{j}</em></MenuItem> )
                        )) }
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow className={classes2.lastTrSiteDetails}>
                    <TableCell colSpan={1}>
                      Notes
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="chauff_rq0"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="chauff_rq1"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="chauff_rq2"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    </TableRow>
                  </TableBody>
                </Table></>
                <Table style={{ marginRight: '0.2em', marginTop: '1em', width: '100%' }}
                    className={classes2.siteDetailsTable}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={1}>
                      </TableCell>
                      <TableCell colSpan={1} style={{ backgroundColor: 'transparent' }}>
                      </TableCell>
                      <TableCell colSpan={2}>
                        Principal
                      </TableCell>
                      <TableCell colSpan={2}>
                        Secondaire
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                  <TableCell rowSpan={4} style={{ padding: '0' }}>
                      <Typography
                        color="secondary"
                      >ECS
                      </Typography>
                    </TableCell>
                  <TableRow>
                    <TableCell colSpan={1}>
                      Énergie
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="ecs_nrj0" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosEcs[0].nrj} name="ecs_nrj0">
                        {ecsFields.nrj[0] !== infosEcs[0].nrj ?
                          <MenuItem key={infosEcs[0].nrj+"_0"} value={infosEcs[0].nrj}>{infosEcs[0].nrj}</MenuItem> 
                          : <MenuItem key={infosEcs[0].nrj+"_0"} value={infosEcs[0].nrj} selected><em>{infosEcs[0].nrj}</em></MenuItem>
                        }
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="ecs_nrj1" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosEcs[1].nrj} name="ecs_nrj1">
                        {ecsFields.nrj[1] !== infosEcs[1].nrj ?
                          <MenuItem key={infosEcs[1].nrj+"_1"} value={infosEcs[1].nrj}>{infosEcs[1].nrj}</MenuItem> 
                          : <MenuItem key={infosEcs[1].nrj+"_1"} value={infosEcs[1].nrj} selected><em>{infosEcs[1].nrj}</em></MenuItem>
                        }
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Détails
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="ecs_detail0" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosEcs[0].detail} name="ecs_detail0">
                        {ecsFields.detail[0] !== infosEcs[0].detail ?
                          <MenuItem key={infosEcs[0].detail+"_0"} value={infosEcs[0].detail}>{infosEcs[0].detail}</MenuItem> 
                          : <MenuItem key={infosEcs[0].detail+"_0"} value={infosEcs[0].detail} selected><em>{infosEcs[0].detail}</em></MenuItem>
                        }
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="ecs_detail1" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosEcs[0].detail} name="ecs_detail1">
                        {ecsFields.detail[1] !== infosEcs[1].detail ?
                          <MenuItem key={infosEcs[1].detail+"_1"} value={infosEcs[1].detail}>{infosEcs[1].detail}</MenuItem> 
                          : <MenuItem key={infosEcs[1].detail+"_1"} value={infosEcs[1].detail} selected><em>{infosEcs[1].detail}</em></MenuItem>
                        }
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow className={classes2.lastTrSiteDetails}>
                    <TableCell colSpan={1}>
                      Notes
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="ecs_rq0"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="ecs_rq1"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    </TableRow>
                    <TableCell rowSpan={4} style={{ padding: '0' }}>
                      <Typography
                        color="secondary"
                      >Cuisson
                      </Typography>
                    </TableCell>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Énergie plaques
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="cuissonplq_type0" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosPlaques[0].type} name="cuissonplq_type0">
                        {plaquesFields.type.map((u) => ( infosPlaques[0].type !== u ?
                          <MenuItem key={u+"_0"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_0"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="cuissonplq_type1" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosPlaques[1].type} name="cuissonplq_type1">
                        {plaquesFields.type.map((u) => ( infosPlaques[1].type !== u ?
                          <MenuItem key={u+"_1"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_1"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Nombre de feux
                    </TableCell>
                    <TableCell colSpan={2}>
                    <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="cuissonplq_nbFeux0" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosPlaques[0].nbFeux} name="cuissonplq_nbFeux0">
                        {plaquesFields.nbFeux.map((u) => ( infosPlaques[0].nbFeux !== u ?
                          <MenuItem key={u+"_0"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_0"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                    <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="cuissonplq_nbFeux1" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosPlaques[1].nbFeux} name="cuissonplq_nbFeux1">
                        {plaquesFields.nbFeux.map((u) => ( infosPlaques[1].nbFeux !== u ?
                          <MenuItem key={u+"_1"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_1"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow className={classes2.lastTrSiteDetails}>
                    <TableCell colSpan={1}>
                      Notes
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="cuissonplq_rq0"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="cuissonplq_rq1"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    </TableRow>
                    <TableCell rowSpan={4} style={{ padding: '0' }}>
                      <Typography
                        color="secondary"
                      >Four
                      </Typography>
                    </TableCell>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Type
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="four_type0" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosFour[0].type} name="four_type0">
                        {fourFields.type.map((u) => ( infosFour[0].type !== u ?
                          <MenuItem key={u+"_0"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_0"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="four_type1" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosFour[1].type} name="four_type1">
                        {fourFields.type.map((u) => ( infosFour[1].type !== u ?
                          <MenuItem key={u+"_1"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_1"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Détails
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="four_detail0"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="four_detail1"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    </TableRow>
                    <TableRow className={classes2.lastTrSiteDetails}>
                    <TableCell colSpan={1}>
                      Remarque
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="four_rq0"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="four_rq1"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    </TableRow>
                    <TableCell rowSpan={4} style={{ padding: '0' }}>
                      <Typography
                        color="secondary"
                      >Ventilation
                      </Typography>
                    </TableCell>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Type
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="ventil_type0" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosVentil[0].type} name="ventil_type0">
                        {ventilFields.type.map((u) => ( infosClim[0].type !== u ?
                          <MenuItem key={u+"_0"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_0"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="ventil_type1" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosVentil[1].type} name="ventil_type1">
                        {ventilFields.type.map((u) => ( infosVentil[1].type !== u ?
                          <MenuItem key={u+"_1"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_1"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Détails
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="ventil_detail0" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosVentil[0].detail} name="ventil_detail0">
                        {ventilFields.detail.map((u) => ( infosClim[0].detail !== u ?
                          <MenuItem key={u+"_0"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_0"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="ventil_detail1" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosVentil[1].detail} name="ventil_detail1">
                        {ventilFields.detail.map((u) => ( infosVentil[1].detail !== u ?
                          <MenuItem key={u+"_1"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_1"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow className={classes2.lastTrSiteDetails}>
                    <TableCell colSpan={1}>
                      Remarque
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="ventil_rq0"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="ventil_rq1"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    </TableRow>
                    <TableCell rowSpan={4} style={{ padding: '0' }}>
                      <Typography
                        color="secondary"
                      >Climatisation
                      </Typography>
                    </TableCell>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Type
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="clim_type0" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosClim[0].type} name="clim_type0">
                        {climFields.type.map((u) => ( infosClim[0].type !== u ?
                          <MenuItem key={u+"_0"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_0"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="clim_type1" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosClim[1].type} name="clim_type1">
                        {climFields.type.map((u) => ( infosClim[1].type !== u ?
                          <MenuItem key={u+"_1"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_1"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow>
                    <TableCell colSpan={1}>
                      Détails
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="clim_detail0" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosClim[0].detail} name="clim_detail0">
                        {climFields.detail.map((u) => ( infosClim[0].detail !== u ?
                          <MenuItem key={u+"_0"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_0"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
                        id="clim_detail1" margin="dense" variant="outlined" onChange={hdlSetChauff}
                        className={classes2.demiWidth} defaultValue={infosClim[1].detail} name="clim_detail1">
                        {climFields.detail.map((u) => ( infosClim[1].detail !== u ?
                          <MenuItem key={u+"_1"} value={u}>{u}</MenuItem> 
                          : <MenuItem key={u+"_1"} value={u} selected><em>{u}</em></MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    </TableRow>
                    <TableRow className={classes2.lastTrSiteDetails}>
                    <TableCell colSpan={1}>
                      Remarque
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="clim_rq0"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    <TableCell colSpan={2}>
                      <TextField margin='dense'
                        //className={MoreClasses?.tiersWidth}
                        inputRef={register}
                        name="clim_rq1"
                        type="text"
                        variant="outlined"
                        //onChange={handleChange}
                        error={'reponses' in errors}
                      />
                    </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>

              <Typography
                  //variant={isMobile ? 'h7' : 'h6'}
                  variant={/*isMobile ? 'h7' : */'h6'}
                  color="secondary"
                  style={{ marginRight: '0.2em', marginTop: '1em' }}
                >Piscine
              </Typography>[{currentData?.piscine_infos}]
              <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <InputLabel shrink>Présence</InputLabel>
                    <Select inputRef={register} margin="dense" variant="outlined" label="Présence" name="piscine_infos_present" 
                      defaultValue={infosPiscine.present} id="piscine_infos_present">
                      {piscineFields.present.map((f) => (
                        infosPiscine.present === f ? <MenuItem key={"presencePiscine_"+f} value={f} selected>{f}</MenuItem>
                        : <MenuItem key={"presencePiscine_"+f} value={f}>{f}</MenuItem> ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <InputLabel shrink>Type</InputLabel>
                    <Select inputRef={register} margin="dense" variant="outlined" /*onChange={handleChangePiscine}*/ name="piscine_infos_type" 
                      defaultValue={infosPiscine.type} id="piscine_infos_type">
                      {piscineFields.type.map((f) => (
                        infosPiscine.type === f ? <MenuItem key={"typePiscine_"+f} value={f} selected>{f}</MenuItem>
                        : <MenuItem key={"typePiscine_"+f} value={f}>{f}</MenuItem> ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <InputLabel shrink>Chauffée</InputLabel>
                    <Select inputRef={register} margin="dense" variant="outlined" label="Présence" /*onChange={handleChangePiscine}*/ name="piscine_infos_chauffage" 
                      defaultValue={infosPiscine.chauffage} id="piscine_infos_chauffage">
                      {piscineFields.chauffage.map((f) => (
                        infosPiscine.chauffage === f ? <MenuItem key={"chauffagePiscine_"+f} value={f} selected>{f}</MenuItem>
                        : <MenuItem key={"chauffagePiscine_"+f} value={f}>{f}</MenuItem> ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    <TextField margin='dense'
                    //className={MoreClasses?.tiersWidth}
                    inputRef={register}
                    name="piscine_infos_lgdia"
                    type="text"
                    label="Long/Diam (m)"
                    variant="outlined"
                    error={'reponses' in errors}
                    style={{ marginTop: '1.1em' }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField margin='dense'
                    //className={MoreClasses?.tiersWidth}
                    inputRef={register}
                    name="piscine_infos_larg"
                    type="text"
                    label="Largeur (m)"
                    variant="outlined"
                    error={'reponses' in errors}
                    style={{ marginTop: '1.1em' }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField margin='dense'
                    //className={MoreClasses?.tiersWidth}
                    inputRef={register}
                    name="piscine_infos_prof"
                    type="text"
                    label="Prof. (m)"
                    variant="outlined"
                    error={'reponses' in errors}
                    style={{ marginTop: '1.1em' }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField margin='dense'
                    //className={MoreClasses?.tiersWidth}
                    inputRef={register}
                    name="piscine_infos_consoppe"
                    type="text"
                    label="Conso Pompe (W)"
                    variant="outlined"
                    error={'reponses' in errors}
                    style={{ marginTop: '1.1em' }}
                    />
                  </TableCell>
                  </TableRow>
                  <TableRow>
                  <TableCell colSpan={7}>
                    <TextField margin='dense'
                    //className={MoreClasses?.tiersWidth}
                    inputRef={register}
                    name="piscine_infos_note"
                    type="text"
                    multiline
                    fullWidth
                    rows={3}
                    label="Notes"
                    variant="outlined"
                    error={'reponses' in errors}
                    style={{ marginTop: '1.1em' }}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
              </Table>
          <Button
          size="large"
          color="primary"
          variant="contained"
          //fullWidth
          className={classes.submitBtn}
          type="submit"
          disabled={submitLoading}
        >
          {isEditMode ? 'Mettre à jour les infos logement' : 'Créer un nouveau logement'}
        </Button>
        {submitError && (
          <ErrorBox
            errorMsg={submitError}
            clearErrorMsg={() => dispatch(clearSubmitSiteError())} />
        )}
        <TextField margin='dense'
          //className={MoreClasses?.tiersWidth}
          inputRef={register}
          name="date_update"
          type="text"
          label="Date d'état infos"
          variant="outlined"
          error={'reponses' in errors}
          style={{ marginLeft: '2rem', top: '0.53rem', marginTop: '1.1em' }}
          />
        <Typography  className={classes2.TopDivider}
                  //variant={isMobile ? 'h7' : 'h6'}
                  variant={/*isMobile ? 'h7' : */'h6'}
                  color="secondary"
                  style={{ marginRight: '0.2em', paddingTop: '1.5rem', marginTop: '1.5rem' }}
                >Informations de veilles appareils
              </Typography>
              <p>Ces informations sont là en tant que traces : elles sont normalement destinées à être reportées dans les détails des équipements du logements.</p>
              <Table>
              <TableBody>
                {currentData?.veilles_app?.map((app,index) => 
                  <TableRow><TableCell>{app}</TableCell><TableCell>{currentData?.veilles_pw?.[index]}</TableCell><TableCell>{currentData?.veilles_rq?.[index]}</TableCell></TableRow>)}
            </TableBody>
          </Table>
        </form></div></>
      </>
  );
};

export default SiteDetailFormx;
