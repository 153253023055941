import axios from 'axios';
import backendUrl from '../backendUrl';
import { setConfig } from './auth';
import { ContactPayload }from '../redux/types';

//const baseUrl = `${backendUrl}/sites`;
const baseUrl = `${backendUrl}/contacts`;

//const getContacts = async (siteId: string) => {
const getContacts = async () => {
//onst response = await axios.get(baseUrl, setConfig());
//  const response = await axios.get(`${backendUrl}/sites/contacts`, setConfig());
  const response = await axios.get(`${backendUrl}/contacts`, setConfig());
  return response.data;
};

const getSiteContacts = async (site_sn: string) => {
  //onst response = await axios.get(baseUrl, setConfig());
  const response = await axios.get(`${backendUrl}/sites/${site_sn}/contacts`, setConfig());
  return response.data;
};

const addContacts = async (site_id: string, contacts: string[]) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/contacts`,
    { contacts },
    setConfig()
  );
  return response.data;
};

const removeContact = async (site_id: string, contactId: string) => {
//const removeContact = async (site_id: string, contactId: number) => {
  const response = await axios.delete(
    `${baseUrl}/${site_id}/contacts/${contactId}`,
    setConfig()
  );
  return response.data;
};

const leaveSite = async (site_id: string) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/contacts/leave`,
    null,
    setConfig()
  );
  return response.data;
};

const contactService = { 
  addContacts,
  removeContact,
  leaveSite,
  getContacts,
  getSiteContacts,
};

export default contactService;
