import React from 'react';

import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import SortIcon from '@material-ui/icons/Sort';

const FilterConcentrateur: React.FC<{
  grepValue: string | null;
  handleGrepChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
  menuItems: { value: string; label: string }[];
  label: string;
  size?: 'small' | 'medium';
}> = ({ grepValue, handleGrepChange, menuItems, label, size }) => {
  return (
    <FormControl variant="outlined" fullWidth size={size || 'medium'}>
     {<InputLabel id="sort-conc">{label}</InputLabel>}
      <Select
        labelId="sort-conc"
        value={grepValue}
        onChange={handleGrepChange}
        defaultValue={{ value: '0', label: 'Tous'}}
        label="Concetrateur(s)"
        variant='outlined'
        startAdornment={
          <InputAdornment position="start">
            <SortIcon
              color="primary"
              fontSize={size === 'small' ? 'default' : 'large'}
            />
          </InputAdornment>
        }
      >
        {menuItems.map((m) => (
          <MenuItem key={m.value} value={m.value}>
            {m.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterConcentrateur;
