import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchEquipementsBySiteId,
  fetchEquipementsBySitesSn,
  fetchEquipementsForSiteSn,
  selectEquipementsBySiteId,
  selectEquipementsState,
  selectEquipementsStateSn,
} from '../../redux/slices/equipementsSlice';
import { RootState } from '../../redux/store';
import { SiteEquipement, Equipement, EquipementState, /* Equipement*/
UsagePayload,
ConcentrateurState,
UsageState,
PosteState,
QuestionsInstrumState,
RoomState,
Voie2mesureState} from '../../redux/types';
import EquipementsTable from './EquipementsTable';
import EquipementsActionCard from './EquipementsActionCard';
import EquipementsListMobile from './EquipementsListMobile';
import sortEquipements from '../../utils/sortEquipements';
import filterEquipements from '../../utils/filterEquipements';
import removeDiacritics from '../../utils/diacriticHandle';
import LoadingSpinner from '../../components/LoadingSpinner';
import InfoText from '../../components/InfoText';

import { Paper, Tooltip, Typography } from '@material-ui/core';
import { useMainPageStyles } from '../../styles/muiStyles';
import { useActionCardStyles } from '../../styles/muiStyles';
import BugReportOutlinedIcon from '@material-ui/icons/DevicesTwoTone';
import InfoIcon from '@material-ui/icons/Info';
import { fetchVoiesBySitesSn, selectVoie2mesuresState } from '../../redux/slices/voie2mesuresSlice';

const EquipementsCard: React.FC<{ 
  siteId: string;
  siteSn: string;
  equipements: EquipementState[];
  concentrateurs: ConcentrateurState[];     // concentrateurs associés au logement
  allConcentrateurs: ConcentrateurState[];
  postes: PosteState[];
  usages: UsageState[]; 
  questions: QuestionsInstrumState[];
  pieces: RoomState[];
  voies2mesures: Voie2mesureState[];
  isMobile: boolean }> = ({ 
    siteId, siteSn,
    concentrateurs,
    postes,
    usages,
    questions,
    pieces,
    voies2mesures,
    isMobile }) => {
  const classes = useMainPageStyles();
  const dispatch = useDispatch();  
  const classes2 = useActionCardStyles();
  //equipements.map((e) => console.log("quip: " + e.reponses));

  const { equipements, fetchLoading, fetchError, sortBy, filterBy } = useSelector(
    selectEquipementsState
  );
  const siteEquipement : EquipementState[] = equipements[siteSn];
  let cptEquipIncDbleSuivi = 0;
  siteEquipement.map((e) => (
      e.voies2mesures?
        (cptEquipIncDbleSuivi += (e.voies2mesures.length),
    //    console.log("Nb_voies_suivi4app: "+e.str_equip+" ["+e.voies2mesures.length+"]"),
        e.voies2mesures.length === 0 ? cptEquipIncDbleSuivi += (1) : null)
      : null
    )
  );
  const [filterValue, setFilterValue] = useState('');
  

  useEffect(() => {
    dispatch(fetchEquipementsForSiteSn(siteSn));
/*    if (!equipements) {
      dispatch(fetchEquipementsBySiteId(siteId)); // TODO : By site SN instead of ID
      //dispatch(fetchEquipementsBySitesSn(siteSn));
    }*/
    dispatch(fetchVoiesBySitesSn(siteSn));
/*    if (!usages) {
      dispatch(fetchUsages());
      //dispatch(fetchEquipementsBySitesSn(siteSn));
    }*/
 /*   if (!concentrateurs) {
      dispatch(fetchConcentrateursForSiteSn(siteSn));
      //dispatch(fetchEquipementsBySitesSn(siteSn));
    }*/
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { voie2mesures } = useSelector(selectVoie2mesuresState);

  const accentsTidy = function(s: string){
    var r=s.toLowerCase();
    r = r.replace(new RegExp(/\s/g),"");
    r = r.replace(new RegExp(/[àáâãäå]/g),"a");
    r = r.replace(new RegExp(/æ/g),"ae");
    r = r.replace(new RegExp(/ç/g),"c");
    r = r.replace(new RegExp(/[èéêë]/g),"e");
    r = r.replace(new RegExp(/[ìíîï]/g),"i");
    r = r.replace(new RegExp(/ñ/g),"n");                
    r = r.replace(new RegExp(/[òóôõö]/g),"o");
    r = r.replace(new RegExp(/œ/g),"oe");
    r = r.replace(new RegExp(/[ùúûü]/g),"u");
    r = r.replace(new RegExp(/[ýÿ]/g),"y");
    r = r.replace(new RegExp(/\W/g),"");
    return r;
};
const filteredSortedEquipements =
  siteEquipement &&
    sortEquipements(
      siteEquipement.filter(
        (b) =>
/*          b.usage.name.toLowerCase().includes(filterValue.toLowerCase()) || 
          b.room?.name.toLowerCase().includes(filterValue.toLowerCase()) || 
          b.poste?.name.toLowerCase().includes(filterValue.toLowerCase()) ||*/
          (b.usage.name.toLowerCase()/*.normalize("NFD").replace(/[\u0300-\u036f]/g, "")*/.includes(filterValue.toLowerCase()/*.normalize("NFD").replace(/[\u0300-\u036f]/g, "")*/) || 
          b.comments.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filterValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || 
          b.reponses.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filterValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || 
          b.room?.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filterValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || 
          b.poste?.name.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filterValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) || 
          b.voies2mesures?.find((v)=> v.id_conc === Number(filterValue)) || 
          b.voies2mesures?.find((v)=> v.id_module === Number(filterValue)) || 
          b.voies2mesures?.find((v)=> v.id_module === Number(filterValue)) || 
/*          b.voies2mesures?.find((v)=> String(v.id_conc?.valueOf).toLowerCase().includes(filterValue.toLowerCase())) || 
          b.voies2mesures?.find((v)=> String(v.id_module?.valueOf).toLowerCase().includes(filterValue.toLowerCase())) || */
          b.voies2mesures?.find((v)=> v.commentaires ? v.commentaires.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filterValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) : null) ||
          b.voies2mesures?.find((v)=> v.id_sensor ? v.id_sensor.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(filterValue.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")) : null)/*||
          containsEquipement(b,filterValue)*/
/*          b.reponses?.toLowerCase().includes(filterValue.toLowerCase()) ||
          b.comments?.toLowerCase().includes(filterValue.toLowerCase()) */
//          b.voies2mesures?.slice().every((v) => String(v.id_conc)).includes(filterValue.toLowerCase())
//          b.voies2mesures?.filter((v) => String(v.id_conc).includes(filterValue.toLowerCase()))
           //id_conc.includes(filterValue.toLowerCase()) 
        //  b.voies2mesures[0]?.id_conc.includes(filterValue.toLowerCase())
          /*?.id_conc === Number(filterValue)*/
          ) && filterEquipements(b, filterBy)
      ),
      sortBy
  );
  const equipementsDataToDisplay = () => {
    if (/*equipements.length === 0*/fetchLoading) {
      return (
        <LoadingSpinner
          marginTop={isMobile ? '3em' : '4em'}
          size={isMobile ? 60 : 80}
        />
      );
    } else if (fetchError) {
      return (
        <InfoText
          text={`Error: ${fetchError}`}
          variant={isMobile ? 'h6' : 'h5'}
        />
      );
    } else if (!siteEquipement || siteEquipement.length === 0) {
      return (
        <InfoText text="No Equipements added yet." variant={isMobile ? 'h6' : 'h5'} />
      );
    } else if (!filteredSortedEquipements || filteredSortedEquipements.length === 0) {
      return (
        <InfoText text="No matches found." variant={isMobile ? 'h6' : 'h5'} />
      );
    } else {
      return (
        <div>
          {isMobile ? (
            <EquipementsListMobile siteSn={siteSn} siteId={siteId} equipements={/*equipements*/filteredSortedEquipements} concentrateurs={concentrateurs} 
            usages={usages} postes={postes} pieces={pieces} voies2mesures={voie2mesures/*voies2mesures*/} questions={questions} />
          ) : (
            <EquipementsTable filterBy={filterBy} siteSn={siteSn} siteId={siteId} equipements={/*equipements*/filteredSortedEquipements} concentrateurs={concentrateurs} 
            usages={usages} postes={postes} pieces={pieces} voies2mesures={voie2mesures/*voies2mesures*/} questions={questions} sortBy={String(sortBy)}/>
          )}
        </div>
      );
    }
  };

  return (
    <>
    <Paper className={classes.equipementsPaper}>
      <div style={{display: 'flex'}}>
        <Typography
          variant={isMobile ? 'h6' : 'h5'}
          color="secondary"
          className={classes.flexHeader}
        >
          <BugReportOutlinedIcon
            fontSize={isMobile ? 'default' : 'large'}
            style={{ marginRight: '0.2em' }} />
            Equipements du logement&nbsp;<b>#{siteSn}</b>
        </Typography>
        <Tooltip title={<><h3>{"Équipements suivis EN COURS: "+filteredSortedEquipements.filter((eq)=>eq.voies2mesures.length > 0 && eq.app_date_end === null).length}</h3>
        <h3>{"Équipements suivis ARRÊTÉS: "+filteredSortedEquipements.filter((eq)=>eq.voies2mesures.length > 0 && eq.app_date_end !== null).length}</h3>
        <h3>{"NON suivis: "+filteredSortedEquipements.filter((eq)=>eq.voies2mesures.length === 0).length+" (dont "+filteredSortedEquipements.filter((eq)=>eq.voies2mesures.length === 0 && eq.app_date_end !== null).length+" arrêtés)"}</h3>
        <h3>{"Voies ACTIVES: "+voies2mesures.filter((vm=>vm.voie_date_end === null)).length}</h3>
        {concentrateurs.map((c) => 
          c.date_end !== null && c.date_end.toString().length > 5 ?
            <h3>{"Concentrateur "+c.hardSn+" / "+c.techno+"(v"+c.version+") +" +c.tel+" start: "+c.date_start.toString().substring(0,10)+" end: "+c.date_end.toString().substring(0,10)}</h3>
          : <h3>{"Concentrateur "+c.hardSn+" / "+c.techno+"(v"+c.version+") +" +c.tel+" start: "+c.date_start.toString().substring(0,10)}</h3>
        )}
        </>}>{/*<h3>{"Voies ARRÊTÉES: "+voies2mesures.filter((vm=>vm.voie_date_end !== null)).length}</h3>*/}
          <InfoIcon style={{top: '0.25rem', marginLeft: '0.5rem', position: 'relative', color: '#007acc'}} /> 
        </Tooltip>
      </div>
        <div className={classes.equipementsActionCard}>
          <EquipementsActionCard
            siteId={siteId}
            siteSn={siteSn}
            conc={concentrateurs}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            isMobile={isMobile}
            usages={usages}
            postes={postes}
            pieces={pieces}
            voies2mesures={voies2mesures}
            questions={questions} 
            equipements={siteEquipement}/>
        </div>
        {siteEquipement.length > 0 ? equipementsDataToDisplay(): null}
    </Paper>
    </>
  );
};

export default EquipementsCard;
