import { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectSiteById,
  selectSitesBySn,
  selectSitesState,
  viewSiteSn,
  deleteSite,
  leaveSiteContactship,
  fetchSitesBySn,
  selectSiteBySiteId,
  selectSitesStateBySn,
  fetchSites,
} from '../../redux/slices/sitesSlice';
import { autoLogin, selectAuthState, setUser } from '../../redux/slices/authSlice';
import { fetchContacts, selectContactsBySiteId, selectContactsState } from '../../redux/slices/contactsSlice';
import { fetchUsers } from '../../redux/slices/usersSlice';
import { RootState } from '../../redux/store';
import SiteForm from './SiteForm';
import ContactsCard from './ContactsCard';
import ConcentrateursCard from './ConcentrateursCard';
import ConfirmDialog from '../../components/ConfirmDialog';
import FormDialog from '../../components/FormDialog';
import { formatDateTime } from '../../utils/helperFuncs';
import SubjectIcon from '@material-ui/icons/Subject';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  fetchConcentrateursBySiteId,
  fetchConcentrateursBySitesSn,
  selectConcentrateursBySiteId,
  selectConcentrateursBySiteSn,
  selectConcentrateurById,
  selectConcentrateursState,
} from '../../redux/slices/concentrateursSlice';

import {
  Paper,
  Typography,
  Button,
  Divider,
  useMediaQuery,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  InputAdornment,
  TableHead,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useMainPageStyles, useTableStyles } from '../../styles/muiStyles';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import GroupAddOutlinedIcon from '@material-ui/icons/GroupAddOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import PeopleAltTwoToneIcon from '@material-ui/icons/PeopleAltTwoTone';
import { Contact, ContactState, SitePayload, SiteState } from '../../redux/types';
import { useForm } from 'react-hook-form';
import { Label, TextFieldsOutlined, Title } from '@material-ui/icons';
import { number, string } from 'yup';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import SitesActionCard from './SitesActionCard';
import SiteDetailForm from './SiteDetailForm';
import SiteDetailFormx from './SiteDetailFormx';
import { useReducer } from 'react';
import authService from '../../services/auth';
import storage from '../../utils/localStorage';

const validationSchema = yup.object({
  title: yup
    .string()
    .required('Required')
    .min(3, 'Must be at least 3 characters')
    .max(60, 'Must be at most 60 characters'),
  description: yup.string().required('Required'),
});

interface ParamTypes {
/*  siteId: string;
  contactId: string; */
  siteSn: string; /*
  sitesWSn: string;    // contient les sites correspondants au SN demandé SiteState[]
  contactsSitesSn: string;*/ //ContactState[];
/*concHardSn: string;
  concTel: string;
  concId: string;
  isMobile: boolean;*/
  /*siteSn: string;
  concentrateurs: string;*/
//  concentrateurs: Array<{ id: string }>;
}
/*
type occupationProps = {
  siteId: string;
  range: string;
  nombre: number;
  taux: number;
}
type statsOccup = {
  [key:string]: occupationProps[];
}*/
/*type statsOccupy = {
  /*[key:string]: /occupationProps;
//  occupationProps{};
}*/
//interface statsOccupy { [index: string]: { nombre: number, taux: number }}

const SiteDetailsPage = () => {
  const classes = useMainPageStyles();
  const classes2 = useTableStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const dispatch = useDispatch();
  const {/* siteId, contactId, */siteSn /*, sitesWSn, contactsSitesSn*//*, concHardSn, concTel, concId*/ } = useParams<ParamTypes>();
//  const { siteId, siteSn, concentrateurs } = useParams<ParamTypes>();
  useEffect(() => {
    const loggedUser = storage.loadUser();
    if (loggedUser) {
      dispatch(setUser(loggedUser));
      authService.setToken(loggedUser.token);
      dispatch(fetchSites());
    }
  }, []);
  const { user } = useSelector(selectAuthState);  
  const { sites /*, fetchStatus, fetchError, sortBy */} = useSelector(
    selectSitesState
  );
  const { contacts } = useSelector(
    selectContactsState
  );
  console.log("Sites array LENGTH: "+sites.length)
/*  useEffect(() => {
      dispatch(fetchSites());
      dispatch(fetchContacts());
  }, []);*/
/*  dispatch(fetchSitesBySn(siteSn));
  dispatch(fetchUsers());
  dispatch(fetchContacts()); */
  
/*  const { selectAuthState, selectSitesState, selectConcentrateursState, "", selectContactsState, "", "" /*sites, fetchStatus, fetchError, sortBy*//* } = useSelector(
    selectSitesBySn
  );
  const { contacts } = useSelector(
    selectContactsBySiteId
  );
*/
/*  const sites = useSelector((state: RootState) =>
    selectSitesBySn(state, siteSn)
  );*/
  /*const sites = useSelector((state: RootState) =>
    selectSitesStateBySn(state, siteSn)
  );*/
  /*const { sites, fetchStatus, fetchError, sortBy } = useSelector(
    selectSitesState      // fetch -> dispatch -> select = tous les sites
  );*/
//  dispatch(fetchSitesBySn(siteSn));
  
  console.log("SiteSn: "+siteSn)
  const sitesBySn: SiteState[] = [];
  sites.map((s) =>
    {
    if(Number(s.sn_site) === Number(siteSn)) {
      console.log("map siteSN: "+s.sn_site)
      sitesBySn.push(s)
    }}
  )
  console.log("sitesBySn_length: "+sitesBySn?.length)
  sitesBySn?.map((s) =>
    console.log("SN_SITEbySn - ID: "+s.sn_site+" - "+s.id)
  );
  const mostRecentSite = sitesBySn.filter((s) => Number(s.sn_site) === Number(siteSn)).sort((a,b) => +new Date(b.date_update) - +new Date(a.date_update))[0]
//  const [theSite, setTheSite] = useState(mostRecentSite);
//  console.log("STATE [theSite] = "+theSite)
  const [selectSite, setSiteValue] = useState(/*""*/mostRecentSite.id);
/*  useEffect(() => {
    showSiteProperties(selectSite);
    setLogementsList();
  }, [theSite, selectSite]);*/

  const [filterValue, setFilterValue] = useState('');

  const filteredSortedSites = sitesBySn;
  const filteredSortedContacts = contacts;
//  const [viewContacts, setViewContacts] = useState(false);
  const concInState = useSelector((state: RootState) =>
  //  selectConcentrateursBySiteId(state, siteId)
    selectConcentrateursBySiteSn(state, siteSn)
  );
  const id = "999"
  //const isAdmin = createdBy.id === user?.id;
  const isAdmin = true;
  //const mostRecentSite = sitesBySn.find/*findLast*/((s) => Number(s.sn_site) === Number(siteSn))
  const contact = contacts.find((c) => c.site_sn[0] === Number(mostRecentSite?.sn_site));
  const mostRecentSiteId = sitesBySn.find/*findLast*/((s) => Number(s.sn_site) === Number(siteSn))?.id
  console.log("Site ID plus récent trouvé: "+mostRecentSite?.id+" / "+mostRecentSite?.sn_site)
  const contactDuSite = contact; /*mostRecentSite?.contacts.find((c) => Number(c.site_id) === Number(mostRecentSite?.id))*/
  console.log("Contact "+contactDuSite?.nom+" "+contactDuSite?.prenom+" "+contactDuSite?.mail+" "+contactDuSite?.tel+" "+contactDuSite?.adresse)
//  console.log("siteInState: "+siteStateSiteId?.map((s) => s.siteSn))

  let currSite_id = mostRecentSite?.id || "0";
/*  let statOccupy: statsOccup = {}
  sitesBySn.map((s) => {
    let set: Array<occupationProps> = []
    //if(s?.tx_occupation?.length > 0){
    s?.tx_occupation?.map((line) => {
      let tab = line.split("|")
//      console.log("line occupy: "+tab[0]+","+tab[1]+","+tab[2])
      set.push({siteId: s.id, range: tab[0], nombre: Number(tab[1].replace("nb ","")), taux: Number(tab[2].replace("poids ","")) })
    })
//    set.map((x) => console.log("SSSSSSSSSSSEEETTTT: "+x.range+" "+x.nombre+" "+x.taux))
    statOccupy[s.id] = set
  });

  let currOccupation: occupationProps[] = Array.from(statOccupy[currSite_id]);
  currOccupation.map((co) => console.log("currOkk: "+co.range+" "+co.nombre+" "+co.taux))*/
  const currSitesIds: string[] = []; 
  sitesBySn.map((s) => currSitesIds.push(s?.id || ''));
//  const currSite_id = mostRecentSite?.id || null;
  const currctct_prenom = contact?.prenom || '';
  const currctct_nom = contact?.nom || '';
  const currctct_tel = contact?.tel || '';
  const currctct_mail = contact?.mail || '';
  const currctct_adresse = contact?.adresse || '';
  const currctct_code_postal = contact?.code_postal || '';
  const currctct_actif = contact?.actif || true;
  const currctct_revenus = contact?.revenus || '';
  const currctct_remarques = contact?.remarques || '';
  const currgmt_date = mostRecentSite?.gmt_date || 2;
  let currcpt_type = '';
  let currtype_inst_elec = '';
  let currindex_cpt_elec = '';
  let currindex_cpt_hc = '';
  let currtarif_elec = '';
  let currpuiss_sscrite = '';
  let currprm_linky = '';
  let curradresse = '';
  let currcode_postal = '';
  let currinstrum_by = '';
  let currnature = mostRecentSite?.nature || '';
  let currsurface = mostRecentSite?.surface || '';
  let currsurf_unused = mostRecentSite?.surf_unused || '';
  let currsurf_nonchauff = mostRecentSite?.surf_nonchauff || '';
  let currniveaux = mostRecentSite?.niveaux || '';
  let currdate_update = mostRecentSite?.date_update || '';
  let currrq_instru = mostRecentSite?.rq_instru || '';
  let currchauff_nrj = mostRecentSite?.chauff_nrj || [''];
  let currchauff_detail = mostRecentSite?.chauff_detail || [''];
  let currchauff_rq = mostRecentSite?.chauff_rq || [''];
  let currecs_nrg = mostRecentSite?.ecs_nrj || [''];
  let currecs_detail = mostRecentSite?.ecs_detail || [''];
  let currecs_rq = mostRecentSite?.ecs_rq || [''];
  let currcuissonplq_nrg = mostRecentSite?.cuissonplq_nrj || [''];
  let currcuissonplq_detail = mostRecentSite?.cuissonplq_detail || [''];
  let currcuissonplq_rq = mostRecentSite?.cuissonplq_rq || [''];
  let currfour_type = mostRecentSite?.four_type || [''];
  let currfour_detail = mostRecentSite?.four_detail || [''];
  let currfour_rq = mostRecentSite?.four_rq || [''];
  let currventil_type = mostRecentSite?.ventil_type || [''];
  let currventil_detail = mostRecentSite?.ventil_detail || [''];
  let currventil_rq = mostRecentSite?.ventil_rq || [''];
  let currclim_type = mostRecentSite?.clim_type || [''];
  let currclim_detail = mostRecentSite?.clim_detail || [''];
  let currclim_rq = mostRecentSite?.clim_rq || [''];
  const { register, control, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      id: currSite_id/*sitesBySn[0]?.id*/ || '',
      site_id: currSite_id || '',
      ctct_prenom: currctct_prenom || '',
      ctct_nom: currctct_nom || '',
      ctct_tel: currctct_tel || '',
      ctct_mail: currctct_mail || '',
      ctct_adresse: currctct_adresse || '',
      ctct_code_postal: currctct_code_postal || '',
      ctct_actif: currctct_actif || '',
      ctct_revenus: currctct_revenus || '',
      ctct_remarques: currctct_remarques || '',
      gmt_date: currgmt_date || '',
  //    occupation: currOccupation || {} as occupationProps[],
      cpt_type: currcpt_type || '',
      instrum_by: currinstrum_by || '',
    },
  });
  const handleDeleteSite = () => {
    dispatch(deleteSite(id, history));
  };

  const handleLeaveSite = () => {
    dispatch(leaveSiteContactship(id, history));
  };

  const submitModz = (data: SitePayload) => {

  };
  function handleChange(evt: { target: { value: any; name: any; }; }) {
    const value = evt.target.value;
    console.log("handleChange"+evt.target.name+" value: "+value);
    setState({
      ...state,
      [evt.target.name]: value
    });
  }
  let selectedLogement = sitesBySn.find((s) => Number(s.id) === Number(currSite_id));
  const [state, setState] = useState({
    theSite: selectedLogement,
    sitesIds: currSitesIds,
    site_id: currSite_id,
    site_sn: siteSn,
    ctct_prenom:  currctct_prenom,
    ctct_nom:  currctct_nom,
    ctct_tel:  currctct_tel,
    ctct_mail:  currctct_mail,
    ctct_adresse:  currctct_adresse,
    ctct_code_postal:  currctct_code_postal,
    ctct_actif:  currctct_actif,
    ctct_revenus:  currctct_revenus,
    ctct_remarques:  currctct_remarques,
    gmt_date:  currgmt_date,
//    occupation: /*currOccupation*/Array.from(statOccupy[currSite_id/*sitesBySn.sort((a,b) => Number(b.id) - Number(a.id) )[0].id/*state.site_id*/]) as occupationProps[],
    cpt_type: currcpt_type,
    type_inst_elec: '',
    index_cpt_elec: '',
    index_cpt_hc: '',
    tarif_elec: '',
    puiss_sscrite: '',
    prm_linky: '',
    adresse: '',
    code_postal: '',
    instrum_by: '',
    nature:  currnature,
    surface:  currsurface,
    surf_unused:  currsurf_unused,
    surf_nonchauff:  currsurf_nonchauff,
    niveaux:  currniveaux,
    date_update:  currdate_update,
    rq_instru:  currrq_instru,
    chauff_nrj:  currchauff_nrj,
    chauff_detail:  currchauff_detail,
    chauff_rq:  currchauff_rq,
    ecs_nrg:  currecs_nrg,
    ecs_detail:  currecs_detail,
    ecs_rq:  currecs_rq,
    cuissonplq_nrg:  currcuissonplq_nrg,
    cuissonplq_detail:  currcuissonplq_detail,
    cuissonplq_rq:  currcuissonplq_rq,
    four_type:  currfour_type,
    four_detail:  currfour_detail,
    four_rq:  currfour_rq,
    ventil_type:  currventil_type,
    ventil_detail:  currventil_detail,
    ventil_rq:  currventil_rq,
    clim_type:  currclim_type,
    clim_detail:  currclim_detail,
    clim_rq:  currclim_rq
  });

  console.log("Site_ID(current): selectSite["+selectSite+"] "+currSite_id+" / CONTACT: "+currctct_nom+" "+currctct_adresse+" "+currctct_tel)
  const handleChangeBack2 = (event: React.ChangeEvent<{  name?: string | undefined; value: unknown; }> /*event: SelectChangeEvent*/) => {
    setState({ ...state, [event.target.name as string]: event.target.value });
    setSiteValue(event.target.value as string);
  };

  const [cpt_type, setCpt_type] = useState("");

  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);

//  currcpt_type.find((cpt) => Number(cpt.ID) === Number(currSite_id))?.cpt /*sitesBySn.find((s) => s.clim_detail === currSite_id)?.cpt_type*/,
  const handleChangeSite = (event: React.ChangeEvent<{  name?: string | undefined; value: unknown; }> /*event: SelectChangeEvent*/) => {
    const value = event.target.value;
    console.log("EVENTsiteChange: "+event.target.name+" => "+event.target.value);
    setState({ ...state, [event.target.name as string]: event.target.value });
//    setState({ ...state.theSite = sitesBySn.find((s) => Number(s.id) === Number(value))})
//    currOccupation = Array.from(statOccupy[value as string]) as occupationProps[];
    /*setState({
      ...state,
      occupation: currOccupation
    });*/
    setSiteValue(value as string)
    currSite_id = value as string
/*    setState({ ...state, ["occupation"]: Array.from(statOccupy[Number(state.site_id)]) as occupationProps[] });
    setState({ ...state, ["instrum_by"]: currinstrum_by.find((inst) => Number(inst.ID) === Number(selectSite))?.instrum });
    setCpt_type(currcpt_type.find((cpt) => Number(cpt.ID) === Number(selectSite))?.cpt || '');*/
    //setState({ ...state, ["cpt_type"]: currcpt_type.find((cpt) => Number(cpt.ID) === Number(selectSite))?.cpt });
  //  console.log("############ CHANGED site_id: "+currSite_id+"("+state.site_id+") / intrum_by "+state.instrum_by+" / cpt_type "+state.cpt_type)
    //currSite_id = value;
/*    setState({
      ...state,
      occupation: Array.from(statOccupy[value as string]) as occupationProps[]
    });
    //currSite_id = value;
    currOccupation = Array.from(statOccupy[value as string]) as occupationProps[];
*/  
//    setTheSite(sitesBySn.find((s) => Number(s.id) === Number(value)) || mostRecentSite)
    setSiteValues(sitesBySn.find((s) => Number(s.id) === Number(currSite_id/*selectSite*/)))
    selectedLogement = sitesBySn.find((s) => Number(s.id) === Number(value))

    let target = "theSite"
    setState({...state, [target]: sitesBySn.find((s) => Number(s.id) === Number(currSite_id)) })
  };
  const handleChangeVue = (event: React.ChangeEvent<{  name?: string | undefined; value: unknown; }> /*event: SelectChangeEvent*/) => {
    const value = event.target.value;
  //  setTheSite(sitesBySn.find((s) => Number(s.id) === Number(value)) || mostRecentSite)
  }
  function setSiteValues(site: SiteState | undefined ) {
    if (site !== undefined) {
      currcpt_type = site.cpt_type; 
      //setState({ ...state, ["cpt_type"]: currcpt_type });
      currtype_inst_elec = site.type_inst_elec; 
      currindex_cpt_elec = String(site.index_cpt_elec); 
      currindex_cpt_hc = String(site.index_cpt_hc); 
      currtarif_elec = site.tarif_elec; 
      currpuiss_sscrite = String(site.puiss_sscrite); 
      currprm_linky = String(site.prm_linky); 
      curradresse = site.adresse; 
      currcode_postal = String(site.code_postal); 
      currinstrum_by = String(site.instrum_by); 
      console.log(" CPT:"+currcpt_type+" / InstrumBy:"+currinstrum_by)
    } else {
      currcpt_type = '';
      currtype_inst_elec = '';
      currindex_cpt_elec = '';
      currindex_cpt_hc = '';
      currtarif_elec = '';
      currpuiss_sscrite = '';
      currprm_linky = '';
      curradresse = '';
      currcode_postal = '';
      currinstrum_by = '';
      console.log("Site state undefinde ... LLLLLLLLLOOOOOOOOOOOSSSSSSEEEEE !!")
    }
 }
/* if(selectSite === "") { setSiteValues(undefined) }
  let s: SiteState | null | undefined
  s = state.site_id ? sitesBySn.find((x) => Number(x.id) === Number(state.site_id)) : null
*/
/*  useEffect(() => {
//    showSiteProperties(selectSite);
    console.log("USE EFFFFFFFFFFFFFFFFFECCCCCTTTTTTTTTTTTTT !!!")
    forceUpdate();
    setLogementsList();
  }, [state.theSite, selectSite]);*/

  if (!sitesBySn) {
    return (
      <div className={classes.root}>
        <Paper className={classes.notFoundPaper}>
          <Typography
            variant="h6"
            color="secondary"
            className={classes.error404Text}
            style={{ marginTop: '5em' }}
          >
            Cacospatc 04: Site Not Found!
          </Typography>
        </Paper>
      </div>
    );
  }
  const leaveSiteBtn = () => {
    if (isAdmin) return null;
    return (
      <ConfirmDialog
        title="Confirm Leave Site"
        contentText="Êtes-vous certain de vouloir quitter l'édition des propriétés du site (=Logement) ?"
        actionBtnText="Quitter le Site"
        triggerBtn={{
          type: isMobile ? 'round' : 'normal',
          text: 'Leave Site',
          icon: ExitToAppOutlinedIcon,
        }}
        actionFunc={handleLeaveSite}
      />
    );
  };

 /* const showSiteProperties = (theSite: string) => {
    if(selectSite !== '') {
      return (
        <div className={classes.btnsWrapper}>
            {selectSite !== undefined  && selectSite !== "0" ? 
            <SiteDetailForm currSite={sitesBySn.find((s) => Number(s.id) === Number(theSite))} currContact={contactDuSite} />
            : null }
          </div>
      )
    } else {
      return (
        <div>Sélectionnez ci-dessus une date de "vue" des propriétés du logement</div>
      )
    }
  };*/
  sitesBySn.map((s) => console.log("SITE: "+s.id+" / SN: "+s.sn_site))
  const setLogementsList = () => {
    if(state.theSite !== undefined) {
      return (
        <>
        <InputLabel shrink /*className={classes.flexInput}*/>Date d'état sélectionnée</InputLabel>
        <Select inputRef={register} /*displayEmpty defaultValue={currSite_id}*/
          label="Date update" onChange={handleChangeSite} id="site_id"
          margin="dense" variant="outlined" className={classes.flexInput} value={selectSite/*state.site_id*/} name="theSite">
          {sitesBySn.map((u) => ( Number(u.sn_site) !== Number(siteSn/*state.site_id*/) ?
            <MenuItem key={u.id+"_update"} value={u.id}>{u.date_update}</MenuItem> 
            : <MenuItem key={u.id+"_update"} value={u.id} selected><em>{u.date_update}</em></MenuItem>
            /*sitesBySn.map((u) => ( <MenuItem key={u.id} value={u.id}>{u.date_update}</MenuItem> */
            /*Number(u) !== Number(currSite_id/*state.site_id/) ? <MenuItem key={u} value={u}>{sitesBySn?.find((s) => Number(s.id) === Number(u))?.date_update}</MenuItem> 
            : <MenuItem key={u} value={u} selected><em>{sitesBySn?.find((s) => Number(s.id) === Number(u))?.date_update}</em></MenuItem>*/
          ))}
        </Select>
        {sitesBySn.map((s) => (
          <div id={"div"+s.id} style={{'display': (Number(s.id) !== Number(selectSite) ? 'none' : 'block' ) }}>
            <div className={classes.btnsWrapper}>
              <SiteDetailFormx currentData={/*s as SitePayload*/
                { sn_site: s.sn_site,
                  id: s.id,
                  contacts: s.contacts,
                  gmt_date: s.gmt_date,
                  date_instru: s.date_instru,
                  //occup_0_10_nb: s.//occup_0_10_nb,
                  cpt_type: s.cpt_type,
                  type_inst_elec: s.type_inst_elec,
                  index_cpt_elec: s.index_cpt_elec,
                  index_cpt_hc: s.index_cpt_hc,
                  tarif_elec: s.tarif_elec,
                  puiss_sscrite: s.puiss_sscrite,
                  prm_linky: s.prm_linky,
                  adresse: s.adresse,
                  code_postal: s.code_postal,
                  instrum_by: s.instrum_by,
                  tx_occupation: s.tx_occupation,
                  nature: s.nature,
                  surface: s.surface,
                  surf_unused: s.surf_unused,
                  surf_nonchauff: s.surf_nonchauff,
                  niveaux: s.niveaux,
                  date_update: s.date_update,
                  rq_instru: s.rq_instru,
                  chauff_nrj: s.chauff_nrj,
                  chauff_detail: s.chauff_detail,
                  chauff_rq: s.chauff_rq,
                  ecs_nrj: s.ecs_nrj,
                  ecs_detail: s.ecs_detail,
                  ecs_rq: s.ecs_rq,
                  cuissonplq_nrj: s.cuissonplq_nrj,
                  cuissonplq_detail: s.cuissonplq_detail,
                  cuissonplq_rq: s.cuissonplq_rq,
                  four_type: s.four_type,
                  four_detail: s.four_detail,
                  four_rq: s.four_rq,
                  ventil_type: s.ventil_type,
                  ventil_detail: s.ventil_detail,
                  ventil_rq: s.ventil_rq,
                  clim_type: s.clim_type,
                  clim_detail: s.clim_detail,
                  clim_rq: s.clim_rq,
                  piscine_infos: s.piscine_infos,
                  veilles_app: s.veilles_app,
                  veilles_pw: s.veilles_pw,
                  veilles_rq: s.veilles_rq,
                  date_end: s.date_end
                }
                /*{
                  id: s.id || "0",
                  sn_site: siteSn,
                  cpt_type: s.cpt_type
                }*/}
                siteId={currSite_id} isEditMode={true} isMobile={false}/>
                {/*<SiteDetailForm currSite={state.theSite} currContact={contactDuSite} sites={sitesBySn} theId={currSite_id} />*/}
                {/*<SiteDetailForm currSite={sitesBySn.find((s)=>Number(s.id) === Number(currSite_id))} sites={sitesBySn} theId={currSite_id} currContact={contactDuSite} />*/}
            </div>
          </div>
        ))}
        </>
      )
      
    } else {
      return (
        <div>Sélectionnez ci-dessus une date de "vue" des propriétés du logement</div>
      )
    }
  };

  return (
    <Paper className={classes.detailsHeader}>
      <div><Typography
        variant={isMobile ? 'h5' : 'h4'}
        color="secondary"
        style={{ marginRight: '0.2em' }}
      >
        <strong>Logement {siteSn}</strong>
      </Typography>
      <p>Sélectionnez ci-dessous une date d'état du logement pour mettre à jour les infos.<br/>
      Vous pouvez valider les changement en créant une nouvelle date "d'état", ou écraser les infos d'une date "d'état" existante.</p>
      </div>
      <Divider style={{ margin: '0.5em 0' }} />
      {setLogementsList()}
    </Paper>
  );
};

export default SiteDetailsPage;
