import { configureStore, Action } from '@reduxjs/toolkit';
//import { customApiService } from './api'
//import api from './api'
import { ThunkAction } from 'redux-thunk';
import authReducer from './slices/authSlice';
import sitesReducer from './slices/sitesSlice';
import concentrateursReducer from './slices/concentrateursSlice';
import equipementsReducer from './slices/equipementsSlice';
import usersReducer from './slices/usersSlice';
import usagesReducer from './slices/usagesSlice';
import postesReducer from './slices/postesSlice';
import roomsReducer from './slices/roomsSlice';
import questionsReducer from './slices/questionsInstrumSlice';
import voie2mesuresReducer from './slices/voie2mesuresSlice';
import contactsReducer from './slices/contactsSlice';
import notificationReducer from './slices/notificationSlice';
import themeReducer from './slices/themeSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    sites: sitesReducer,
    concentrateurs: concentrateursReducer,
    equipements: equipementsReducer,
    usages: usagesReducer,
    postes: postesReducer,
    rooms: roomsReducer,
    voie2mesures: voie2mesuresReducer,
    questions_instrum: questionsReducer,
    users: usersReducer,
    contacts: contactsReducer,
    notification: notificationReducer,
    theme: themeReducer,
  },
/*  middleware: getDefaultMiddleware =>
  getDefaultMiddleware().concat(api.middleware),*/
/*  middleware: (getDefaultMiddleware) =>
	getDefaultMiddleware({
		thunk: {
			extraArgument: customApiService,
		},
		serializableCheck: false,
	}),*/
});

export type AppDispatch = typeof store.dispatch;

export type RootState = ReturnType<typeof store.getState>;

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
