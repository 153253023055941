import { SiteState, SiteSortValues } from '../redux/types';

const sortSites = (sites: SiteState[], sortBy: SiteSortValues) => {
  switch (sortBy) {
/*    case 'newest':
      return sites
        .slice()
        .sort((a, b) => +new Date(b.date_instru) - +new Date(a.date_instru));

    case 'oldest':
      return sites
        .slice()
        .sort((a, b) => +new Date(a.date_instru) - +new Date(b.date_instru));
*/
    case 'contact-a-z':
      return sites
        .slice()
        .sort((a, b) =>
          a.contacts[0]?.nom.localeCompare(b.contacts[0]?.nom, 'en', { sensitivity: 'base' })
        );

    case 'contact-z-a':
      return sites
        .slice()
        .sort((a, b) =>
          b.contacts[0]?.nom.localeCompare(a.contacts[0]?.nom, 'en', { sensitivity: 'base' })
        );

    case 'sn-concentrateurs-asc':
      return sites.slice().sort((a, b) => a.concentrateur[0]?.hardSn - b.concentrateur[0]?.hardSn);
    
    case 'sn-concentrateurs-desc':
      return sites.slice().sort((a, b) => b.concentrateur[0]?.hardSn - a.concentrateur[0]?.hardSn);
      
    case 'sn-sites-asc':
      return sites.slice().sort((a, b) => String(a.sn_site).localeCompare(b.sn_site, 'en', { sensitivity: 'base' }));

    case 'sn-sites-desc':
      return sites.slice().sort((a, b) => String(b.sn_site).localeCompare(a.sn_site, 'en', { sensitivity: 'base' }));

    case 'last-data':
      return sites
        .slice()
        .sort((a, b) => {
          if (new Date(b.concentrateur[0]?.date_last_data) > new Date(a.concentrateur[0]?.date_last_data)) { return 1; }
          if (new Date(b.concentrateur[0]?.date_last_data) < new Date(a.concentrateur[0]?.date_last_data)) { return -1; }
        return +new Date(b.concentrateur[0]?.date_last_data) - +new Date(a.concentrateur[0]?.date_last_data)
      });

    case 'ts-last-data':
      return sites
        .slice()
        .sort((a, b) => {
          if (new Date(b.concentrateur[0]?.ts_last_data) > new Date(a.concentrateur[0]?.ts_last_data)) { return 1; }
          if (new Date(b.concentrateur[0]?.ts_last_data) < new Date(a.concentrateur[0]?.ts_last_data)) { return -1; }
        return +new Date(b.concentrateur[0]?.ts_last_data) - +new Date(a.concentrateur[0]?.ts_last_data)
      });
  }
};

export default sortSites;
