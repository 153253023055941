import { makeStyles } from '@material-ui/core/styles';

export const useBodyStyles = (darkMode: boolean) =>
  makeStyles(
    (theme) => ({
      root: {
        width: '100vW',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100vH',
        backgroundColor: darkMode ? '#333' : '#00968810',
      },
    }),
    { index: 1 }
  );

export const useTableStyles = makeStyles(
  (theme) => ({
    inputFullWidth: {
      marginRight: '0 !important',
      marginLeft: '0',
      minWidth: '98.7%',
    },
    demiWidth: {
      '&': {
        minWidth: '48.7%',
        marginLeft: 0,
        marginRight: '1.29% !important',
      },
    },
    table: {
      '& thead th': {
        fontWeight: '600',
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.light,
      },
    },
    tableQInstrum: {
      '& thead tr th:nth-child(1)': {
        fontSize: '1em',
        fontWeight: '400',
      },
      '& thead th': {
        fontWeight: '600',
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.light,
        lineHeight: '1.2',
        padding: '6px',
      },
      '& tbody tr td': {
        padding: '6px',
        fontSize: '1em',
      },
      '& tbody tr td > div': {
        marginTop: '0px',
        marginBottom: '0px',
      },
    },
    tableEquipBdRight: {
      '& thead>tr>th': {
/*	borderRightStyle: 'dashed',
	borderRightColor: theme.palette.secondary.main,
	borderRightWidth: '1px',*/
        borderRight: '2px solid blue',
      },
    },
    tableEquipBdLeft: {
      '& thead tr th': {
	borderStyle: 'dashed',
	borderColor: 'yellow',
	borderWidth: '1px',
//	borderLeft: '1px dashed blue',
//	borderLeftColor: theme.palette.secondary.main,
      },
    },
    appDbleSuivi: {
      '&': {
        //@ts-ignore
	      color: theme.palette.secondary.apptabledblesuivi,
      },
    },
    tableEquip: {
      '& thead th': {
        fontWeight: '600',
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.light,
        lineHeight: '1rem',
        padding: '12px',
      },
      '& thead th a': {
        color: theme.palette.secondary.main,
        lineHeight: '1.5rem',
        bottom: '-0.04rem',
        position: 'relative',
      },
      '& thead th a:hover': {
        cursor: 'grab',
      },
      '& thead th a svg': {
        fontSize: '1.2rem',
      },
      '& tbody tr': {
        borderTopColor: theme.palette.secondary.headerappmod,
      },
      '& tbody tr:first-of-type': {
        borderTop: '1px solid !important',
        borderTopColor: theme.palette.secondary.headerappmod,
      },
      '& tbody tr td': {
        padding: '8px',
      },
      /*'& tbody tr td:first-of-type': {
        textAlign: 'left',
      },*/
      '& tbody tr td:nth-child(2)': {
        textAlign: 'left',
      },
    },
    tiersWidth: {
      '&': {
        minWidth: '32.04%',
        marginLeft: 0,
        //marginRight: '1.29% !important',
        marginRight: '0.5em !important',
      },
    },
    div4buttons: {
      '&': {
        marginRight: 'auto !important',
        marginLeft: 'auto !important',
        textAlign: 'center',
      },
      '& li': {
        fontWeight: '500',
        fontSize: '0.875rem',
      },
    },
    buttonsSiteSnP: {
      '&': {
        fontWeight: '600',
        margin: '0.3em 0.7em 0 0',
        height: '2.8em',
        padding: '0 2em',
        marginLeft: '0',
        marginRight: '0.8em',
      },
    },
    buttons: {
      '&': {
        margin: '0 0 0.7em 0',
        height: '3.2em',
        padding: '0 2em',
        marginLeft: '0.4em',
        marginRight: '0.4em',
      },
    },
    dateInput: {
      '&': {
        display: 'inline-table',
        maxWidth: 'inherit !important',
        lineHeight: '1.8em',
      },
      '& div': {
        display: 'inline-table',
        maxWidth: 'inherit !important',
      },
      '& div>div.last': {
//        margin: '0 5px',
        padding: '0',
        paddingLeft:'5px', 
        float: 'left',
        marginLeft: '1em',
      },
      '& div>div>input': {
        //        margin: '0 5px',
        padding: '8px 0px 8px 10px',
        width: 'auto',
        margin: '2px 0',
      },
    },
    lastTrSiteDetails: {
        '&': {
          borderBottom: 'groove 3px',
          //@ts-ignore
          borderBottomColor: theme.palette.secondary.appchgetable,
        },
    },
    TopDivider: {
      /*  '&': {
        //  minWidth: '35rem',
          alignItems: 'center',
        },*/
        '&': {
          marginTop: '1.5rem',
          borderTop: 'solid 2px',
          //@ts-ignore
          borderColor: theme.palette.secondary.main,
        },
      },
    contactDetailsTable: {
    /*  '&': {
      //  minWidth: '35rem',
        alignItems: 'center',
      },*/
      '&': {
        border: 'none'/*'double 1px'*/,
        //@ts-ignore
        borderColor: theme.palette.secondary.appchgetable,
      },
      '& > tbody > tr > td': {
        padding: '0px 12px',
        border: 'none',
      },
    },
    siteDetailsTable: {
    /*  '&': {
      //  minWidth: '35rem',
        alignItems: 'center',
      },*/
      '&': {
        borderRight: 'groove 3px',
        //@ts-ignore
        borderRightColor: theme.palette.secondary.appchgetable,
      },
      '& > tbody > tr > td': {
        padding: '12px',
      },
      '& > tbody > td': {
        borderLeft: 'dashed 3px',
        //@ts-ignore
        borderLeftColor: theme.palette.secondary.appchgetable,
        borderTop: 'dashed 3px',
        //@ts-ignore
        borderTopColor: theme.palette.secondary.appchgetable,
        borderBottom: 'dashed 3px',
        //@ts-ignore
        borderBottomColor: theme.palette.secondary.appchgetable,
        width: '2rem',/*8rem*/
      },
      '& > tbody > td > p': {
        fontWeight: 'bold',
        margin: '0',
        transform: 'rotate(270deg)',
        textTransform: 'uppercase',
        marginLeft: '-5.3rem',
        paddingTop: '4.8rem',
        bottom: '10%',
        position: 'relative',
        /*display: 'table-caption',/*inherit/
        lineHeight: '1.2',
        marginLeft: '0.8rem',*/
      },
      '& tr td': {
        textAlign: 'center',
      },
      '& tbody>tr>td>div': {
        width: '90%',
      },
      '& thead tr td input': {
        textAlign: 'center',
      },
      '& thead th': {
        lineHeight: '0.1',
        height: '0.5rem',
        textAlign: 'center',
        //@ts-ignore
        color: theme.palette.secondary.headerappmod,
        //@ts-ignore
        backgroundColor: theme.palette.secondary.appchgetable,
      },
/*      '& thead tr th': {
        fontWeight: '600',
        //@ts-ignore
        backgroundColor: 'none',
        lineHeight: '0.1',
        height: '0.5rem',
      },*/
      '& thead tr th:first-of-type': {
        //@ts-ignore
        backgroundColor: 'transparent',
      },
      '& tbody tr td:first-of-type': {
        fontWeight: '600',
        //@ts-ignore
        color: theme.palette.secondary.headerappmod,
        //@ts-ignore
        backgroundColor: theme.palette.secondary.appchgetable,
      },
     /* '& tbody>tr>td>div': {
        marginRight: '20px',
      },*/
    },
    appChgTableEdit: {
      '& thead th': {
        fontWeight: '600',
        //@ts-ignore
        color: 'white',//theme.palette.secondary.headerappmod,
        //@ts-ignore
        backgroundColor: theme.palette.secondary.appchgetableHeaderEdit,
      },
      '& thead tr th': {
        fontWeight: '600',
        //color: theme.palette.secondary.main,
        //@ts-ignore
        color: 'white',//theme.palette.secondary.headerappmod,
        //@ts-ignore
        backgroundColor: theme.palette.secondary.appchgetableHeaderEdit,
        lineHeight: '0.1',
        height: '0.5rem',
      },
      '& tbody>tr>td>div': {
        marginRight: '20px',
      },
    },
    appChgTableEvol: {
      '& thead th': {
        fontWeight: '600',
        //@ts-ignore
        color: 'white',//theme.palette.secondary.headerappmod,
        //@ts-ignore
        backgroundColor: 'forestgreen',//theme.palette.secondary.appchgetableHeaderEvol,
      },
      '& thead tr th': {
        fontWeight: '600',
        //color: theme.palette.secondary.main,
        //@ts-ignore
        color: 'white',//theme.palette.secondary.headerappmod,
        //@ts-ignore
        backgroundColor: 'forestgreen',//theme.palette.secondary.appchgetableHeaderEvol,
        lineHeight: '0.1',
        height: '0.5rem',
      },
      '& tbody>tr>td>div': {
        marginRight: '20px',
      },
    },
    appChgTableAdd: {
      '& thead th': {
        fontWeight: '600',
        //@ts-ignore
        color: 'white',
        //@ts-ignore
        backgroundColor: theme.palette.secondary.appchgetableHeaderAdd,
      },
      '& thead tr th': {
        fontWeight: '600',
        //color: theme.palette.secondary.main,
        //@ts-ignore
        color: 'white',// theme.palette.secondary.headerappmod,
        //@ts-ignore
        backgroundColor: theme.palette.secondary.appchgetableHeaderAdd,
        lineHeight: '0.1',
        height: '0.5rem',
      },
      '& tbody>tr>td>div': {
        marginRight: '20px',
      },
    },
    headerAppMod: {
	'& thead tr th': {
          //@ts-ignore
          color: theme.palette.secondary.headerappmod,
          //@ts-ignore
          backgroundColor: theme.palette.secondary.headerappmodbckgrd,
	},
    },
    clickableCell: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main + '15',
        cursor: 'pointer',
      },
    },
    littWcell: {
      '&': {
	padding: '6px',
	lineHeight: '0.4',
      },
    },
    scrollableTable: {
      '& thead th': {
        fontWeight: '600',
        color: theme.palette.secondary.main,
        backgroundColor: theme.palette.primary.light,
      },
      overflowY: 'auto',
      maxHeight: '350px',
    },
  }),
  { index: 1 }
);

export const useDialogStyles = makeStyles(
  (theme) => ({
    dialogWrapper: {
      paddingBottom: 20,
      overflow: 'hidden',
      [theme.breakpoints.down('xs')]: {
        padding: 0,
      },
      minWidth: '85vw',
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      zIndex: 1000,
    },
    roundIconButton: {
      minWidth: 0,
      padding: '0.65em',
      borderRadius: '2em',
    },
  }),
  { index: 1 }
);
const drawerWidth = 240;
export const useFormStyles = makeStyles(
  (theme) => ({
    submitBtn: {
      marginTop: '1.5em',
      height: '3.2em',
    },
    equipementMod: {
      '&': {
        width: '80vw',
        //maxWidth: 'unset !important',
        maxWidth: '100vw',
        display: 'inline-block ! important',
      },
    },
    avatar: {
      color: theme.palette.primary.main,
      backgroundColor: '#d3d3d3',
    },
    fieldMargin: {
      marginTop: '1.5em',
    },
    radioGroupForm: {
      marginTop: '0.8em',
      width: '100%',
    },
    radioGroup: {
      display: 'flex',
      alignItems: 'center',
    },
    radioGroupLabel: {
      marginRight: '2em',
    },
    formControlLabels: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '80%',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        width: 'auto',
      },
    },
    mainMenuItem: {
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
      },
    },
    menuItem: {
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
      },
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
    appMenu: {
      width: '100%',
    },
    navList: {
      width: drawerWidth,
    },
  }),
  { index: 1 }
);

export const useMainPageStyles = makeStyles(
  (theme) => ({
    root: {
      padding: '1em 0',
      [theme.breakpoints.down('xs')]: {
        padding: '0.5em 0.5em',
      },
    },
    equipDtPicker: {
      color: '#fefefe',
      maxWidth: '50%',
      display: 'flex',
      paddingTop: '5px',
    },
    tiersWidth: {
      '&': {
        minWidth: '32.04%',
        marginLeft: 0,
        marginRight: '1.29% !important',
      },
    },
    headerPaper: {
      padding: '0.8em 1.5em',
      marginBottom: '1em',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        padding: '0.3em 0.5em',
        marginBottom: '0.5em',
      },
    },
    headerIcon: {
      fontSize: '4.5em',
      marginRight: '0.2em',
      [theme.breakpoints.down('xs')]: {
        fontSize: '3em',
        marginRight: '0.3em',
      },
    },
    sitesPaper: {
      padding: '1.5em',
      minHeight: 'calc(100vH - 264px)',
      [theme.breakpoints.down('xs')]: {
        padding: '0.7em 0.3em',
        minHeight: 'calc(100vH - 160px)',
      },
    },
    sitesListTable: {
      marginTop: '1.5em',
      [theme.breakpoints.down('xs')]: {
        marginTop: 0,
      },
    },
    detailsHeader: {
      padding: '1.2em 1.5em',
      marginBottom: '1em',
      [theme.breakpoints.down('xs')]: {
        padding: '0.5em 0.7em',
        marginBottom: '0.5em',
      },
    },
    flexHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    btnsWrapper: {
      margin: '1em 0',
      [theme.breakpoints.down('xs')]: {
        margin: '0.5em 0',
      },
    },
    contactsWrapper: {
      marginTop: '1em',
    },
    filterContactsInput: {
      [theme.breakpoints.down('xs')]: {
        width: '55%',
      },
    },
    flexInput: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        marginTop: '0.3em',
      },
    },
    equipementsPaper: {
      padding: '1.5em',
      minHeight: 'calc(100vH - 356px)',
      [theme.breakpoints.down('xs')]: {
        padding: '0.5em 0.7em',
        minHeight: 'calc(100vH - 256px)',
      },
    },
    equipementsActionCard: {
      margin: '1.5em 0',
      [theme.breakpoints.down('xs')]: {
        margin: '0.5em 0',
      },
    },
    concentrateursPaper: {
      padding: '1.5em',
      minHeight: 'calc(100vH - 356px)',
      [theme.breakpoints.down('xs')]: {
        padding: '0.5em 0.7em',
        minHeight: 'calc(100vH - 256px)',
      },
    },
    concentrateursActionCard: {
      margin: '1.5em 0',
      [theme.breakpoints.down('xs')]: {
        margin: '0.5em 0',
      },
    },
    notesPaper: {
      padding: '1.5em',
      minHeight: 'calc(100vH - 420px)',
      [theme.breakpoints.down('xs')]: {
        padding: '0.5em 0.7em',
        minHeight: 'calc(100vH - 320px)',
      },
    },
    notesWrapper: {
      margin: '1.5em 0',
    },
    sortNotesInput: {
      width: '22%',
      [theme.breakpoints.down('xs')]: {
        width: '55%',
      },
    },
    singleNote: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '0.3em',
      marginTop: '0.8em',
    },
    noteBody: {
      marginTop: '0.2em',
    },
    avatar: {
      width: theme.spacing(4.8),
      height: theme.spacing(4.8),
      marginRight: '0.4em',
      marginTop: '0.3em',
      color: theme.palette.primary.main,
      backgroundColor: '#d3d3d3',
    },
    notesBtnWrapper: {
      margin: '0.5em 0',
    },
    iconText: {
      verticalAlign: 'middle',
      display: 'inline-flex',
    },
    textIconsWrapper: {
      display: 'flex',
      width: '100px',
      justifyContent: 'space-between',
    },
    listItemWrapper: {
      padding: '0.4em 0.3em',
    },
    flexedWrapper: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: '0.3em',
    },
    roundIconButton: {
      minWidth: 0,
      padding: '0.65em',
      borderRadius: '2em',
    },
    gotoIcon: {
      fontSize: '0.7em',
      marginLeft: '0.4em',
    },
    marginText: {
      marginBottom: '0.35em',
    },
    notFoundPaper: {
      padding: '1.5em',
      minHeight: 'calc(100vH - 130px)',
      [theme.breakpoints.down('xs')]: {
        padding: '0.5em 0.7em',
        minHeight: 'calc(100vH - 80px)',
      },
    },
    error404Image: {
      width: '250px',
      display: 'block',
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        width: '150px',
      },
    },
    error404Text: {
      fontSize: '2.2em',
      textAlign: 'center',
      marginTop: '1em',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1.5em',
      },
    },
    notFoundWrapper: {
      marginTop: '6em',
    },
  }),
  { index: 1 }
);

export const useActionCardStyles = makeStyles(
  (theme) => ({
    inputs: {
      display: 'flex',
      minWidth: '100%',
      justifyContent: 'space-between',
      marginBottom: '1.5em',
      [theme.breakpoints.down('xs')]: {
        marginBottom: '0.7em',
      },
    },
    searchBarWrapper: {
      width: '70%',
      [theme.breakpoints.down('xs')]: {
        width: '55%',
      },
    },
    sortBarWrapper: {
      width: '25%',
      [theme.breakpoints.down('xs')]: {
        width: '42%',
      },
    },
    flexWrapper: {
      display: 'flex',
      minWidth: '100%',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'flex-start',
        marginLeft: '0.2em',
      },
    },
  }),
  { index: 1 }
);

export const useNavStyles = makeStyles(
  (theme) => ({
    container: {
      position: 'sticky',
      top: 0,
      zIndex: 100,
    },
    leftPortion: {
      flexGrow: 1,
      display: 'flex',
      alignItems: 'center',
    },
    logoWrapper: {
      marginRight: '1em',
      display: 'flex',
      alignItems: 'center',
    },
    logoBtn: {
      textTransform: 'none',
      fontSize: '1.2em',
      padding: '0.1em',
      marginRight: '0.3em',
      [theme.breakpoints.down('xs')]: {
        fontSize: '1em',
        marginLeft: '0.6em',
      },
    },
    backBtn: {
      [theme.breakpoints.down('xs')]: {
        marginLeft: '0.6em',
      },
    },
    svgImage: {
      width: '35px',
      marginRight: '5px',
      [theme.breakpoints.down('xs')]: {
        width: '30px',
      },
    },
    rightBtnWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    lastBtn: {
      marginLeft: '1em',
    },
    threeDotsBtn: {
      padding: '0.35em',
    },
    menuIcon: {
      marginRight: '10px',
    },
    userInfo: {
      display: 'flex',
      alignItems: 'center',
    },
    avatar: {
      width: theme.spacing(4.1),
      height: theme.spacing(4.1),
      marginRight: '0.4em',
      color: theme.palette.primary.main,
      backgroundColor: '#d3d3d3',
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(3.5),
        height: theme.spacing(3.5),
      },
    },
    btnsWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    userBtnMob: {
      padding: '0.1em',
    },
  }),
  { index: 1 }
);

export const useAuthPageStyles = makeStyles(
  (theme) => ({
    root: {
      padding: '1.5em 3em',
      width: '330px',
      margin: 'auto',
      marginTop: '16vH',
      [theme.breakpoints.down('xs')]: {
        width: 'auto',
        margin: '0.5em 0.5em',
        padding: '1em',
      },
    },
    form: {
      marginTop: '3em',
    },
    inputField: {
      marginBottom: '1.5em',
    },
    submitButton: {
      marginTop: '0.5em',
      height: '3.1em',
      fontSize: '1em',
      fontWeight: 500,
    },
    titleLogo: {
      display: 'block',
      width: '7em',
      margin: '0 auto',
      [theme.breakpoints.down('xs')]: {
        width: '6em',
      },
    },
    footerText: {
      marginTop: '1em',
      textAlign: 'center',
    },
    link: {
      cursor: 'pointer',
    },
  }),
  { index: 1 }
);
