import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  createNewSite,
  editSiteSn,
  addSiteConcentrateurs,  //Contacts
  selectSitesState,
  clearSubmitSiteError,
} from '../../redux/slices/sitesSlice';
//import { selectUsersState } from '../../redux/slices/usersSlice';
import { selectContactsState } from '../../redux/slices/contactsSlice';
import { Concentrateur, Contact, SiteState } from '../../redux/types';
import ErrorBox from '../../components/ErrorBox';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  TextField,
  Button,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Chip,
  InputAdornment,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useFormStyles } from '../../styles/muiStyles';
import LabelImportantIcon from '@material-ui/icons/LabelImportant';
import GroupIcon from '@material-ui/icons/Group';

const validationSchema = yup.object({
  //name: yup
  siteId: yup
    .string()
    .required('Required')
    .max(60, 'Must be at most 60 characters'),
});

interface BaseType {
  closeDialog?: () => void;
}
interface CreateSite extends BaseType {
  editMode: null;
  currentSiteSn?: string; //currentName?: string;
  currentConcentrateurs?: string[];   // Concentrateurs[] ???
  siteId: string;
//  siteSn: string;
/*  siteId?: number;
  siteSn?: number;*/
}

interface EditSiteSn extends BaseType {
  editMode: 'siteSn';
  /*currentName: string;*/
  currentSiteSn: string;
  siteId: string;
  currentConcentrateurs?: string[];
}

interface AddSiteConcentrateurs extends BaseType {
  editMode: 'concentrateurs';
  currentConcentrateurs: string[];
  siteId: string;
  currentSiteSn?: string;
//  currentName?: string;
}

type SiteFormProps = CreateSite | EditSiteSn | AddSiteConcentrateurs/*AddSiteContacts*/;

const SiteForm: React.FC<SiteFormProps> = ({
  closeDialog,
  editMode,
  currentSiteSn, /*currentName*/
  currentConcentrateurs,
//  currentContacts,
//  siteSn,
  siteId,
}) => {
  const classes = useFormStyles();
  const dispatch = useDispatch();
  const { submitError, submitLoading } = useSelector(selectSitesState);
  const { contacts } = useSelector(selectContactsState);  //previously USERS
  const { register, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: currentSiteSn || '',
    },
  });
  const [concentrateurs, setConcentrateurs] = useState<string[]>([]); //previously MEMBERS

  const selectConcentrateursOnChange = (e: any, selectedOption: Concentrateur[]) => {
    setConcentrateurs(selectedOption.map((s) => String(s.id)));
  };

  //const contacts = "1";
 /* const handleCreateSite = ({ siteId }: { siteId: string }, { siteSn }: { siteSn: string }, { concentrateurs }: { concentrateurs: string[]}) => {
    //dispatch(createNewSite({ siteId, contacts }, closeDialog));
    //dispatch(createNewSite({ siteId, siteSn, concentrateurs }, closeDialog));
    dispatch(createNewSite({ siteSn, concentrateurs }, closeDialog));
  };*/

  const handleEditName = ({ siteId }: { siteId: string }) => {
    dispatch(editSiteSn(siteId as string, siteId, closeDialog));
  };

  const handleAddContacts = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault();
    //dispatch(addSiteContacts(siteId as string, contacts, closeDialog));
    dispatch(addSiteConcentrateurs(siteId as string, concentrateurs, closeDialog)); // Contacts
//    dispatch(addSiteContacts(contacts as string, closeDialog));
  };

  return (
    <form
     /* onSubmit={
        editMode !== 'contacts'
          ? handleSubmit(
              editMode === 'siteSn' ? handleEditName : handleCreateSite
              //editMode === 'name' ? handleEditName : handleCreateSite
            )
          : handleAddContacts
      }*/
    >
      {editMode !== 'concentrateurs' && ( // contacts
        <TextField
          inputRef={register}
          name="name"
          required
          fullWidth
          type="text"
          label="Site Name"
          variant="outlined"
          error={'name' in errors}
          helperText={'name' in errors ? errors.name?.message : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LabelImportantIcon color="primary" />
              </InputAdornment>
            ),
          }}
        />
      )}
      <Button
        size="large"
        color="primary"
        variant="contained"
        fullWidth
        className={classes.submitBtn}
        type="submit"
        disabled={submitLoading}
      >
        {editMode === 'siteSn'
          ? 'Update Site SN'
        /*  : editMode === 'contacts'
          ? 'Add Site Contacts'*/
          : 'Create New Site'}
      </Button>
      {submitError && (
        <ErrorBox
          errorMsg={submitError}
          clearErrorMsg={() => dispatch(clearSubmitSiteError())}
        />
      )}
    </form>
  );
};

export default SiteForm;
