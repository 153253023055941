import { JsonObjectExpression } from "typescript";
import { Voie2mesureState, EquipementFI, Poste, PosteState, Room, RoomState, Usage, UsageState, Voie2mesureFI, lignesRecapStyle, SiteState, Contact, ConcentrateurState } from "../redux/types";
import * as XLSX from 'xlsx';
import { id } from "date-fns/locale";
import { occupationProps } from "../pages/Main/SiteDetailFormx";
import { formatDate } from "../utils/helperFuncs";

declare global{
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     }
  }
  
interface cellule {
  [key: number]: string;
} 

const cellules : cellule = {};// = { 1: "i", 2: "ii", 3: "iii", 4: "iv", 5: "v", 6: "vi", 7: "vii", 8: "viii" };

export default class CsvDataService {
  static downloadExcel = (filename: string, data: object[]) => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, filename);
  }
  static exportToCsv(rows: object[], usages: UsageState[], postes: PosteState[], pieces: RoomState[], conc: ConcentrateurState[],
    voies: Voie2mesureState[], lesAppareils: EquipementFI[], lesVoies: Voie2mesureFI[], appareils: lignesRecapStyle[], concMod: { sn: string; modules: number[]; }[],
    site: SiteState/*, nonSuivi: EquipementFI[]*/, occupationTab: occupationProps[]) {
    if (!rows || !rows.length) {
      return;
    }
    const BOM = "\uFEFF"; 
    const separator = ';';
    const keys = Object.keys(rows[0]);
    const keysAppareils = ["sn_site", "num_ligne", "liste_pièce", "strSuivi", "piece", "pORl", "poste", "usage", "reponses", "comments", "app_date_start",
      "app_date_end", "precision_piece", "pw_veille", "nrg_class", "normalized_pw", "water_consumption", "id", "id_poste",
      "id_type_usage", "id_piece", "reference", "id_ref", "id_questions", "strQuestInstrum", "site_id"];//Object.keys(lesAppareils[0]);
    /*const keysVoies = ["type_sensor", "id_conc", "id_module", "num_voie", "id_sensor", "pièce/usage", "freq_controle", "phase", "commentaires",
      "voie_date_start", "voie_date_end", "no_type",  "site_id", "site_sn", "id_equipement", "id"];//Object.keys(lesVoies[0]);*/
    const keysVoies = ["type_sensor", "id_conc", "id_module", "num_voie", "id_sensor", "pièce/usage", "réponses", "commentaires",
      "début usage ou suivi", "pw veille", "remarque visite"];//Object.keys(lesVoies[0]);
    const ligneRecapStyle = ["type","conc","module","voie","SN sensor","Pièce", "Usage", "commentaires", "remarque mainteneur", "référence(reponses)", "Statut (0/1/2/3)", "nouvelle réf", "thermostat", "puiss. veille"]
    let idPiece = 0;
    let idPoste = 0;
    let idUsage = 0;
    let idEquipement = 0;
    let sensor = "";
    let concentrateurs = "";
    let module = "";
    let voie = "";
    const SNmodules : number[] = voies.map((s)=>s?.id_module);
    const distinctSNmod = SNmodules.filter((n, i) => SNmodules.indexOf(n) === i).sort((a,b) => b - a);
    //distinctSNmod.map((modSN) => console.log("SN mod: "+modSN))

    let appsToCSV = ""
    let nom = ""
    if(site.contacts[site.contacts.length-1].prenom !== null) {
      nom = site.contacts[site.contacts.length-1].prenom+" "+site.contacts[site.contacts.length-1].nom
    } else {
      nom = site.contacts[site.contacts.length-1].nom
    }
    appsToCSV = BOM
    appsToCSV += "N° logement"+separator+site.sn_site+separator+"Occupant"+separator+nom+separator
      +"Adresse"+separator+site.adresse+separator+""+separator+""+separator+""+separator
      +"Téléphone contact"+separator+"'+"+site.contacts[site.contacts.length-1].tel+ '\n'
    appsToCSV += '\n'
    appsToCSV += "Date d'intervention"+separator+"Mainteneur"+separator+"Index compteur (HC/HP)"+separator+"PRM Linky"
      /*+separator+"Occupation du logement"
    occupationTab.map((oc)=> { appsToCSV += "'"+oc.range+"'"+separator})*/
    appsToCSV += '\n'+""+separator+""+separator+""+separator+"'"+site.prm_linky+separator//+"Nombre pers."+separator
/*    occupationTab.map((oc)=> {appsToCSV += oc.nombre+separator})
    appsToCSV += '\n'+""+separator+""+separator+""+separator+""+separator+""+separator
    occupationTab.map((oc)=> {
      appsToCSV += oc.taux+separator
    })*/
    appsToCSV += '\n'+'\n'
    appsToCSV += "Travaux demandés au mainteneur"+'\n'
    appsToCSV += '\n'+'\n'
    appsToCSV += "Tél concentrateur"+separator+"SN conc"+separator+"Techno/version"+separator+""+separator
      +"Modules présents dans le logement"+'\n'
    conc.filter((c)=>c.date_end === null).map((c,index)=> {
      if(index === 0) {
        appsToCSV += "'+"+c.tel+separator+"SN: "+c.hardSn+separator+c.techno+" ("+c.version+")"+separator
        appsToCSV += ""+separator
        distinctSNmod.map((modSN) => appsToCSV += modSN+separator)
        appsToCSV += '\n'
      } else {
        appsToCSV += "'+"+c.tel+separator+"SN: "+c.hardSn+separator+c.techno+" ("+c.version+")"+separator+'\n'
      }
    })
//    appsToCSV += '\n'+"Modules présents dans le logement"+'\n'
//    distinctSNmod.map((modSN) => appsToCSV += modSN+separator)
    appsToCSV += '\n'+'\n'
    appsToCSV += "Remarque générale du mainteneur"+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+"0"+separator+"pas de changement"+'\n'
    appsToCSV += ""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+"1"+separator+"renouvellement équipement"+'\n'
    appsToCSV += ""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+"2"+separator+"nouvel équipement"+'\n'
    appsToCSV += ""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+""+separator+"3"+separator+"suppression d'équipement"+'\n'
    appsToCSV += ligneRecapStyle.join(separator) + '\n'
    const dejaVu: number[] = new Array();
    appareils.map((row)=> {
      if (row.app_date_end !== null) {
        console.log("APPZ_DATE_END: "+row.type+separator+row.conc+separator+row.module+separator+row.voie+separator+row.id_sensor+separator+row.piece+separator+row.usage+separator+row.commentaire+separator+row.remarque+separator+row.reponses+separator+""+separator+row.nvelle_ref+separator+row.thermostat+separator+row.puiss_veille+separator+row.app_date_end)
      }
      if (row.voie_date_end !== null) {
        console.log("VOIE_DATE_END: "+row.type+separator+row.conc+separator+row.module+separator+row.voie+separator+row.id_sensor+separator+row.piece+separator
          +row.usage+separator+row.commentaire+separator+row.remarque+separator
          +row.reponses+separator+""+separator+row.nvelle_ref+separator+row.thermostat+separator+row.puiss_veille+separator+row.voie_date_end)
      }
      if(row.voie_date_end === null || row.app_date_end === null) {
        console.log("APPZZZZZZZ: "+row.type+separator+row.conc+separator+row.module+separator+row.voie+separator+row.id_sensor+separator+row.piece+separator
        +row.usage+separator+row.commentaire+separator+row.remarque+separator
        +row.reponses+separator+""+separator+row.nvelle_ref+separator+row.thermostat+separator+row.puiss_veille)
      }
    })
    concMod.map((cmod) => cmod.modules.map((module) => {
      console.log("module: "+module)
      // suivis, ordered
      appareils.sort((a,b)=>a.voie < b.voie ? -1 : 1).filter((app)=>app.app_date_end === null && app.voie_date_end === null).map((row) => {
        if( row.module === module && !dejaVu.includes(row.id_voie) ) {
          appsToCSV += row.type+separator+row.conc+separator+row.module+separator+row.voie+separator+row.id_sensor+separator+row.piece+separator
          +row.usage+separator+row.commentaire+separator+row.remarque+separator
          +row.reponses+separator+""+separator+row.nvelle_ref+separator+row.thermostat+separator+row.puiss_veille+'\n'
          console.log(row.type+separator+row.conc+separator+row.module+separator+row.voie+separator+row.id_sensor+separator+row.piece+separator
            +row.usage+separator+row.commentaire+separator+row.remarque+separator
            +row.reponses+separator+""+separator+row.nvelle_ref+separator+row.thermostat+separator+row.puiss_veille)
          dejaVu.push(row.id_voie)
        }
      })}))
      // non-suivis
      appareils.filter((app)=>app.app_date_end === null && app.voie_date_end === null && app.module < 1).map((row) => {
      //if(row.module < 1) {
          appsToCSV += ""+separator+""+separator+""+separator+""+separator+""+separator+row.piece+separator
          +row.usage+separator+row.commentaire+separator+row.remarque+separator
          +row.reponses+separator+""+separator+row.nvelle_ref+separator+row.thermostat+separator+row.puiss_veille+'\n'
      //  }
      })
      appareils.filter((app)=>app.app_date_end !== null || app.voie_date_end !== null)
        //.sort((a,b)=>a.module > b.module ? 1 : -1).sort((a,b)=>a.voie < b.voie ? -1 : 1)
        .map((row) => {
        if(row.voie !== null && row.conc > 1) {
          // précédemment suivis terminés (voie ET usage)
          if(row.app_date_end !== null || "" && row.app_date_end !== null || "") {
            console.log("Dans le IF: "+formatDate(row.app_date_end!))
            /*appsToCSV += row.type+separator+row.conc+separator+row.module+separator+row.voie+separator+row.id_sensor+separator+row.piece+separator
            +row.usage+separator+"Fin suivi: "+formatDate(row.voie_date_end!)+" / fin usage: "+formatDate(row.app_date_end!)+" "+row.commentaire+separator+row.remarque+separator
            +row.reponses+separator+""+separator+row.nvelle_ref+separator+row.thermostat+separator+row.puiss_veille+'\n'*/
          } else { // précédemment suivis terminés (voie seulement, pas usage)
            if(row.voie_date_end !== null || "" && row.app_date_end === null || "") {
              console.log("Dans le IF: "+formatDate(row.voie_date_end!))
              appsToCSV += row.type+separator+row.conc+separator+row.module+separator+row.voie+separator+row.id_sensor+separator+row.piece+separator
              +row.usage+separator+"Fin suivi: "+formatDate(row.voie_date_end!)+" "+row.commentaire+separator+row.remarque+separator
              +row.reponses+separator+""+separator+row.nvelle_ref+separator+row.thermostat+separator+row.puiss_veille+'\n'
            }}
        } else {
          // jamais suivis terminés
          console.log("Dans le ELSE: "+formatDate(row.app_date_end!))
          if(row.app_date_end !== null || "") { 
            appsToCSV += ""+separator+""+separator+""+separator+""+separator+""+separator+row.piece+separator
            +row.usage+separator+"Fin usage: "+formatDate(row.app_date_end!)+" "+row.commentaire+separator+row.remarque+separator
            +row.reponses+separator+""+separator+row.nvelle_ref+separator+row.thermostat+separator+row.puiss_veille+'\n'
          }
        }
        //  }
        })
      const appareilsCSV = BOM +
      keysAppareils.join(separator) +
      '\n' +
      lesAppareils.map((row, index) => {
        return row.sn_site+separator+index+separator+"ListePièces"+separator+row.strSuivi+separator+row.piece+separator+row.pORl+separator+row.poste+separator+
          row.usage+separator+row.reponses+separator+row.comments+separator+row.app_date_start+separator+row.app_date_end+separator+row.precision_piece+separator+
          row.pw_veille+separator+row.nrg_class+separator+row.normalized_pw+separator+row.water_consumption+separator+row.id+separator+row.id_type_usage+separator+
          row.id_piece+separator+row.reference+separator+row.id_ref+separator+row.id_questions+separator+row.strQuestInstrum+separator+row.site_id+separator
      }).join('\n');
    const blobEquipFI = new Blob([appareilsCSV], { type: 'text/csv;charset=utf-8;' });
    const blobVoiesRECAP = new Blob([appsToCSV], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blobVoiesRECAP, "RECAP_"+site.sn_site+".csv");
    } else {
      const link = document.createElement('a');
      const linkEquip = document.createElement('a');
      const linkVoies = document.createElement('a');
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute

        const url2 = URL.createObjectURL(blobEquipFI);
        linkEquip.setAttribute('href', url2);
        linkEquip.setAttribute('download', "FI_"+site.sn_site+".csv");
        linkEquip.style.visibility = 'hidden';
        document.body.appendChild(linkEquip);
        linkEquip.click();
        document.body.removeChild(linkEquip);

        const url3 = URL.createObjectURL(blobVoiesRECAP);
        linkVoies.setAttribute('href', url3);
        linkVoies.setAttribute('download', "RECAP_"+site.sn_site+".csv");
        linkVoies.style.visibility = 'hidden';
        document.body.appendChild(linkVoies);
        linkVoies.click();
        document.body.removeChild(linkVoies);
      }
    }
  }
}
