import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectSitesState } from '../../redux/slices/sitesSlice';
import { selectContactsState } from '../../redux/slices/contactsSlice';
import SitesTable from './SitesTable';
import SiteActionCard from './SitesActionCard';
import ContactCard from './ContactsCard';
import ContactsCard from './ContactsCard';
import SitesListMobile from './SitesListMobile';
import sortSites from '../../utils/sortSites';
import LoadingSpinner from '../../components/LoadingSpinner';
import InfoText from '../../components/InfoText';

import { Paper, Typography, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useMainPageStyles } from '../../styles/muiStyles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { fetchAllConcentrateurs, selectConcentrateurById, selectConcentrateursBySiteId, selectConcentrateursBySiteSn, selectConcentrateursState } from '../../redux/slices/concentrateursSlice';
import { AppThunk, RootState } from '../../redux/store';
import concentrateurs from '../../services/concentrateurs';
import { SiteState } from '../../redux/types';

const SitesPage = () => {
  const classes = useMainPageStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { sites, fetchStatus, fetchError, sortBy } = useSelector(
    selectSitesState
  );
  const { contacts } = useSelector(
    selectContactsState
  );
//  console.log("SITES: " + sites)
//  console.log("Contacts: "+ (contacts.map((c) => c.nom)))
/*  const { concentrateurs } = useSelector(
    selectConcentrateursState
  );*/
  //const siteId = "10";
  /*const { [siteId]: concentrateurs } = useSelector(
    selectConcentrateursState
  );*/
/*  useEffect(() => {
    if (!concentrateurs) {
      dispatch(fetchAllConcentrateurs());
    //  dispatch(selectContactsBySiteId(siteId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/
  const [filterValue, setFilterValue] = useState('');
  //  const filteredSortedSites = sites;
  const filteredSortedContacts = contacts;
  const filteredSortedConcentrateurs = concentrateurs;
  const filteredSortedSites = 
    sites &&
    sortSites(
      sites.filter((s) =>
        //p.name.toLowerCase().includes(filterValue.toLowerCase())
        String(s.sn_site).toLowerCase().includes(filterValue.toLowerCase()) ||
        s.contacts[0]?.nom.toLowerCase().includes(filterValue.toLowerCase()) ||
        String(s.concentrateur[0]?.hardSn).includes(filterValue.toLowerCase()) /*||
        s.concentrateur.map((c) => String(c.modules).toLowerCase().includes(filterValue.toLowerCase()))*/
//        s.concentrateur.map((c) => String(c.modules.map((m) => String(m).toLowerCase().includes(filterValue.toLowerCase()))))
      ),
      sortBy
  );

  const filterSitesConc = (sites: SiteState[]) => {
    sites.map((s) =>
      s.concentrateur.length > 1 ? 
        s.concentrateur.map((c) =>
          console.log(c.hardSn)
        ) &&
        s.concentrateur.map((c) =>
        console.log(c.date_start)
        ) &&
//        s.concentrateur.sort((a, b) => b.date_start.toDateString.split('/').join() - a.date_start) &&
        s.concentrateur.map((c) =>
        console.log(c.date_start)
        )
        /*.filter map((c) =>
          console.log(c.hardSn)
        )*/
      : null
    )
    console.log(sites.length)
  }

  filterSitesConc(sites);

  const sitesDataToDisplay = () => {
    if (fetchStatus === 'loading') {
      return (
        <LoadingSpinner
          marginTop={isMobile ? '4em' : '9em'}
          size={isMobile ? 60 : 80}
        />
      );
    } else if (fetchStatus === 'succeeded' && sites.length === 0) {
      return (
        <InfoText
          text="No Sites added yet."
          variant={isMobile ? 'h6' : 'h5'}
        />
      );
    } else if (fetchStatus === 'failed' && fetchError) {
      return (
        <InfoText
          text={`Error: ${fetchError}`}
          variant={isMobile ? 'h6' : 'h5'}
        />
      );
    } else if (
      fetchStatus === 'succeeded' &&
      sites.length !== 0 &&
      filteredSortedSites?.length === 0
    ) {
      return (
        <InfoText text="No matches found." variant={isMobile ? 'h6' : 'h5'} />
      );
    } else {
      return (
        <div className={classes.sitesListTable}>
          {!isMobile ? (
            <SitesTable sites={filteredSortedSites} contacts={filteredSortedContacts} /*concentrateurs={siteId,filteredSortedConcentrateurs}*/ />
          ) : (
            <SitesListMobile sites={filteredSortedSites} contacts={filteredSortedContacts} /*concentrateurs={filteredSortedConcentrateurs}*/ />
          )}
        </div>
      );
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.headerPaper}>
        <AssignmentIcon
          fontSize="large"
          color="primary"
          className={classes.headerIcon}
        />
        <div>
          <Typography variant={isMobile ? 'h6' : 'h5'} color="secondary">
            Sites de mesures Panel Elecdom
          </Typography>
          <Typography
            variant={isMobile ? 'caption' : 'subtitle1'}
            color="secondary"
          >
            Accueil : liste de tous les logements. Pour accéder aux appareils du logement, cliquez sur le numéro du logement, puis "équipements". 
            Utilisez le menu déroulant pour trier l'ordre des sites, et éventuellement la "recherche" pour entrer un numéro (site / concentrateur) ou un nom (d'occupant).
          </Typography>
        </div>
      </Paper>
      <Paper className={classes.sitesPaper}>
        <SiteActionCard
          filterValue={filterValue}
          setFilterValue={setFilterValue}
          isMobile={isMobile}
        />
        {sitesDataToDisplay()}
      </Paper>
    </div>
  );
};

export default SitesPage;
function dispatch(arg0: AppThunk) {
  throw new Error('Function not implemented.');
}

