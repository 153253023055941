import { useState, useEffect } from 'react';
import { useParams, Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, RootStateOrAny, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { selectSitesBySn, selectSitesState } from '../../redux/slices/sitesSlice';
import { selectContactsState } from '../../redux/slices/contactsSlice';
import { fetchConcentrateursForSiteSn, selectConcentrateursBySiteSn } from '../../redux/slices/concentrateursSlice';
import /*equipementsSlice, */{ fetchEquipements, selectEquipementsState, selectEquipementsBySiteSn, fetchEquipementsForSiteSn, selectEquipementsStateSn } from '../../redux/slices/equipementsSlice';
import GrafanaIcon from '../../svg/grafana-icon.png';
import SitesTableDetail from './SitesTableDetail';
import SiteForm from './SiteForm';
import SitesMenu from './SitesMenu';
import SiteActionCard from './SitesActionCard';
import ContactCard from './ContactsCard';
import ContactsCard from './ContactsCard';
import SitesListMobile from './SitesListMobile';
import sortSites from '../../utils/sortSites';
import LoadingSpinner from '../../components/LoadingSpinner';
import InfoText from '../../components/InfoText';
import FormDialog from '../../components/FormDialog';
import AddIcon from '@material-ui/icons/Add';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  useMediaQuery,
  Link,
  Paper,
  Button,
} from '@material-ui/core';
import { useTableStyles } from '../../styles/muiStyles';
import { useTheme } from '@material-ui/core/styles';
import { useMainPageStyles } from '../../styles/muiStyles';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { SiteState } from '../../redux/types';
import { EEXIST } from 'constants';
import { fetchPostes } from '../../redux/slices/postesSlice';
import { autoLogin, selectAuthState } from '../../redux/slices/authSlice';

interface ParamTypes {
    siteSn: string;
    siteId: string;
}

const SiteSnPage = (/*{ sites }*/) => {
  const { siteSn, siteId } = useParams<ParamTypes>();
  //const sites = useDispatch;
  const classes = useMainPageStyles();
  const MoreClasses = useTableStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const history = useHistory();
  const dispatch = useDispatch();
  /*const equipements = useSelector((state: RootState) =>
    selectEquipementsBySiteSn(state, siteSn)
  );*/
  const { user } = useSelector(selectAuthState);  
  const shortSnSite = siteSn.replace("10","")
//  fetchEquipementsForSiteSn(siteSn);
  useEffect(() => {
      //if (!equipements) {
        dispatch(autoLogin());
        dispatch(fetchEquipementsForSiteSn(siteSn));    // CAUSE jwt malformed error ?
        dispatch(fetchConcentrateursForSiteSn(siteSn));    // CAUSE jwt malformed error ?
//        dispatch(fetchPostes);
//        dispatch(fetchEquipements);     // ne fait rien !!
      //}
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { sites /*, fetchStatus, fetchError, sortBy*/ } = useSelector(
    selectSitesState
  );
  const { contacts } = useSelector(
    selectContactsState
  );
  const { equipements, fetchLoading, fetchError, sortBy } = useSelector(
    selectEquipementsState
  );
 /* const { equipements } = useSelector((state: RootState) =>
    selectEquipementsStateSn(state, siteSn)
  //  selectEquipementsBySiteSn(state, siteSn)
  //  selectEquipementsState(state)
  );*/
 // console.log(siteSn + " / equipements: "+ equipements.equipementsSlice.length);
/*  const { id, id_poste, id_type_usage, id_piece, reference, id_ref, reponses, id_questions, comments, a_suivre, suivi, date_start, date_end, sn_site, str_piece, str_equip, site_id } 
  = equipements[1];*/
//  equipements.map((e) => console.log(e.reference))
  //console.log("EQUIP[1] : " + str_equip)

  console.log("siteId: "+siteId);

  const [filterValue, setFilterValue] = useState('');

  const filteredSortedSites = sites;
  const filteredSortedContacts = contacts;
  
  const filteredSites = sites.filter(site => {return site.sn_site == siteSn});
  console.log("FILTERED" + filteredSites.length);
//  console.log("...ID yé tu: "+filteredSites.map((s) => s.id));
  const siteID = filteredSites.map((s) => s.id);
  const sitesDataToDisplay = () => {
    if (fetchLoading === true) {
      return (
        <LoadingSpinner
          marginTop={isMobile ? '4em' : '9em'}
          size={isMobile ? 60 : 80}
        />
      );
    } else {
      return (
        <div className={classes.sitesListTable}>
          {//!isMobile ? (
          /*sites.map((s) => (
            <SitesTableDetail sites={s/*sitesSNArray*} /*contacts={filteredSortedContacts}* />
          //) : (
          //)
          ))*/
          <SitesTableDetail siteId={siteId} sites={filteredSites} siteSn={siteSn}/*sitesSNArray*} /*contacts={filteredSortedContacts}*/ />
          }
        </div>
      );
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.headerPaper}>
        <AssignmentIcon
          fontSize="large"
          color="primary"
          className={classes.headerIcon}
        />
        <div>
          <Typography variant={isMobile ? 'h6' : 'h5'} color="secondary">
            Site #<b>{siteSn}</b>
          </Typography>
          <Typography
            variant={isMobile ? 'caption' : 'subtitle1'}
            color="secondary"
          >
            Liste des concentrateurs associés au site, actifs et passés. Le concentrateur le plus récemment utilisé est en fin de liste.
          </Typography>
          <div>
            {/*<Button variant="outlined" className={MoreClasses.buttonsSiteSnP}/*onClick={}/>
              <Link
                  component={RouterLink}
                  to={`/site/${siteSn}/modify`}
                  color="secondary"
                >Modifier le site
                </Link>
            </Button>*/}
            { fetchLoading === true ?
            (<LoadingSpinner
              marginTop={isMobile ? '4em' : '9em'}
              size={isMobile ? 60 : 80}
            />)
            : (<Button variant="contained" className={MoreClasses.buttonsSiteSnP} 
              onClick={() => history.push(`/site/${siteSn}/${siteId}/equipements`)}>
              <Link style={{color: 'rgba(0, 0, 0, 0.87)'}}
                  component={RouterLink}
                  to={`/site/${siteSn}/${siteId}/equipements`/*`/sites/${p.id}`*/}
                  color="secondary"
                >Voir les équipements
                </Link>
            </Button>)}
            <Button variant="contained" className={MoreClasses.buttonsSiteSnP} 
              //onClick={() => history.push(`/site/${siteSn}/${siteId}/equipements`)}
              >
              <a style={{color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none'}} href={"https://visu.enertech.fr/d/_9Gr38AVz/par-logement?orgId=57&var-log=log_"+shortSnSite} target="_blank"
                ><img style={{top: '0.15rem', position: 'relative'}} src={GrafanaIcon} width="25"/>&nbsp;<span style={{bottom: '0.25rem', position: 'relative'}}>Voir dans Grafana</span>
              </a>
            </Button>
            {/*<Button variant="outlined" className={MoreClasses.buttonsSiteSnP}/*onClick={}/>
              <Link
                  component={RouterLink}
                  to={`/contacts/${siteSn}`/*`/sites/${p.id}`/}
                  color="secondary"
                >Voir les contacts
                </Link>
            </Button>*/}
          </div>
        </div>
      </Paper>
      <Paper className={classes.sitesPaper}>
        {sitesDataToDisplay()}
      </Paper>
    </div>
  );  
  /*    <div className={classes.sitesListTable}>
        <Paper className={classes.sitesListTable}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeaders.map((t) => (
                  <TableCell key={t} align="center">
                    {t}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {sitesSNArray.map((p) => (
                <TableRow key={p.id}>
                  <TableCell
                    onClick={() => history.push(`/site/${p.sn_site}`)/*</TableRow>history.push(`/sites/${p.id}`)*}
                    className={classes.clickableCell}
                    align="center"
                  >
                    <Link
                      component={RouterLink}
                      to={`/site/${p.sn_site}`/*`/sites/${p.id}`*}
                      color="secondary"
                    >
                      {p.sn_site/*truncateString(p.name, 30)*}
                    </Link>
                  </TableCell>
                  <TableCell align="center">
                    {p.concentrateur.map((c) => (c.hard_sn))/*p.concentrateurs.length .map((c) => (c.hard_sn))*}
                  </TableCell>
                  <TableCell align="center">{p.concentrateur.map((conc) => (
                    conc.modules.toString()
                  ))}
                  </TableCell>
                  <TableCell align="center">
                    {contacts.find(contacts => { return contacts.site_id === Number(p.id); })?.prenom} {contacts.find(contacts => { return contacts.site_id === Number(p.id); })?.nom}
                  </TableCell>
                  <TableCell align="center">{p.concentrateur.map((conc) => (
                    new Date(conc.date_last_data).toLocaleDateString()
                  ))}
                  </TableCell>
                  <TableCell align="center">
                    {/*new Date(p.date_update).toLocaleDateString()*//*.split('T')[0]*}
                    {p.concentrateur.map((conc) => (
                    new Date(conc.ts_last_data).toLocaleDateString()
                  ))}
                  </TableCell>
                  <TableCell align="center">
                    <SitesMenu
                      siteId={p.id}
                      sn_site={p.sn_site}/*{p.name}*/
                      /*isAdmin={true}*//*{p.createdBy.id === user?.id}*
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </div>
    </div>*/
};

export default SiteSnPage;
