import axios from 'axios';
import backendUrl from '../backendUrl';
import { setConfig } from './auth';
import { PostePayload }from '../redux/types';

//const baseUrl = `${backendUrl}/sites`;
const baseUrl = `${backendUrl}/postes`;

//const getPostes = async (siteId: string) => {
const getPostes = async () => {
//onst response = await axios.get(baseUrl, setConfig());
//  const response = await axios.get(`${backendUrl}/sites/postes`, setConfig());
  const response = await axios.get(`${backendUrl}/postes`, setConfig());
  return response.data;
};

const getSitePostes = async (site_sn: string) => {
  //onst response = await axios.get(baseUrl, setConfig());
  const response = await axios.get(`${backendUrl}/sites/${site_sn}/postes`, setConfig());
  return response.data;
};

const addPostes = async (site_id: string, postes: string[]) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/postes`,
    { postes },
    setConfig()
  );
  return response.data;
};

const removePoste = async (site_id: string, posteId: string) => {
//const removePoste = async (site_id: string, posteId: number) => {
  const response = await axios.delete(
    `${baseUrl}/${site_id}/postes/${posteId}`,
    setConfig()
  );
  return response.data;
};

const leaveSite = async (site_id: string) => {
  const response = await axios.post(
    `${baseUrl}/${site_id}/postes/leave`,
    null,
    setConfig()
  );
  return response.data;
};

const posteService = { 
  addPostes,
  removePoste,
  leaveSite,
  getPostes,
  getSitePostes,
};

export default posteService;
