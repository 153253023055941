import React from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  selectConcentrateurById,
  deleteConcentrateur,
  closeReopenConcentrateur,
} from '../../redux/slices/concentrateursSlice';
import { RootState } from '../../redux/store';
import FormDialog from '../../components/FormDialog';
import ConcentrateurForm from './ConcentrateurForm';
import ConfirmDialog from '../../components/ConfirmDialog';
import ConcentrateurCard from './ConcentrateursCard';
import { formatDateTime } from '../../utils/helperFuncs';
import { priorityStyles, statusStyles } from '../../styles/customStyles';
import CSS from 'csstype';

import { Paper, Typography, Divider, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useMainPageStyles } from '../../styles/muiStyles';
import RedoIcon from '@material-ui/icons/Redo';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

interface ParamTypes {
  idSite: string;
/*  siteSn: string;
  hardSn: string;
  tel: string;*/
  concId: string;
}

const ConcentrateursDetailsPage = () => {
  const classes = useMainPageStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { idSite, /*siteSn, hardSn, tel,*/ concId } = useParams<ParamTypes>();
  const history = useHistory();
  const dispatch = useDispatch();
  const concentrateur = useSelector((state: RootState) =>
    selectConcentrateurById(state, idSite, concId)
  );

  if (!concentrateur) {
    return (
      <div className={classes.root}>
        <Paper className={classes.notFoundPaper}>
          <Typography
            variant="h6"
            color="secondary"
            className={classes.error404Text}
            style={{ marginTop: '5em' }}
          >
            404: Concentrateur Not Found!
          </Typography>
        </Paper>
      </div>
    );
  }

  const {
    id,
    tel,
    date_start,
    date_end,
    date_last_data,
    ts_last_data,
    pdt,
    modules,
    aliasSn,
    techno,
    version,
    hardSn,
    siteId,
    siteSn,
/*    title,
    description,
    priority,
    isResolved,
    createdBy,*/
//    date_instru,
//    updatedBy,
//    date_update,
/*    closedBy,
    closedAt,
    reopenedBy,
    reopenedAt,
    notes,*/
  } = concentrateur;

  const handleDeleteConcentrateur = () => {
    dispatch(deleteConcentrateur(siteId, concId, history));
  };

  const handleCloseConcentrateur = () => {
    dispatch(closeReopenConcentrateur(siteId, concId, 'close'));
  };

  const handleReopenConcentrateur = () => {
    dispatch(closeReopenConcentrateur(siteId, concId, 'reopen'));
  };

/*  const statusCSS: CSS.Properties = {		=> useful to highlight old "last-data" problems
    ...statusStyles(isResolved),
    display: 'inline',
    padding: '0.20em 0.4em',
  };*/
/*
  const statusInfo = () => {
    if (!isResolved && reopenedAt && reopenedBy) {
      return (
        <span>
          <div style={statusCSS}>Re-opened</div> -{' '}
          <em>{formatDateTime(reopenedAt)}</em> ~{' '}
          <strong>{reopenedBy.username}</strong>
        </span>
      );
    } else if (isResolved && closedAt && closedBy) {
      return (
        <span>
          <div style={statusCSS}>Closed</div> -{' '}
          <em>{formatDateTime(closedAt)}</em> ~{' '}
          <strong>{closedBy.username}</strong>
        </span>
      );
    } else {
      return <div style={statusCSS}>Open</div>;
    }
  };
*/
  const closeReopenBtns = () => {
/*    if (isResolved) {
      return (
        <ConfirmDialog
          title="Re-open the Concentrateur"
          contentText="Are you sure you want to re-open the concentrateur?"
          actionBtnText="Re-open Concentrateur"
          triggerBtn={{
            type: isMobile ? 'round' : 'normal',
            text: 'Re-open Concentrateur',
            icon: RedoIcon,
          }}
          actionFunc={handleReopenConcentrateur}
        />
      );
    } else {*/
      return (
        <ConfirmDialog
          title="Close the Concentrateur"
          contentText="Are you sure you want to close the concentrateur?"
          actionBtnText="Close Concentrateur"
          triggerBtn={{
            type: isMobile ? 'round' : 'normal',
            text: 'Close Concentrateur',
            icon: DoneOutlineIcon,
          }}
          actionFunc={handleCloseConcentrateur}
        />
      );
//    }
  };

  const updateConcentrateurBtn = () => {
    return (
      <FormDialog
        triggerBtn={{
          type: isMobile ? 'round' : 'normal',
          text: 'Update Concentrateur Info',
          icon: EditOutlinedIcon,
          style: { marginLeft: '1em' },
        }}
        title="Edit the concentrateur details"
      >
        <ConcentrateurForm
          isEditMode={true}
          siteId={siteId}
          concId={concId}
  /*        siteSn={siteSn}
	        tel={tel}
          date_last_data={date_last_data}
          ts_last_data={ts_last_data}
          modules={modules}
          concId={concId/*id*}
          hardSn={hardSn}*/
          currentData={{ hardSn, tel, siteId, siteSn, version, date_start, date_end, date_last_data, ts_last_data, pdt, modules, aliasSn, techno }}
        />
      </FormDialog>
    );
  };

  const deleteConcentrateurBtn = () => {
    return (
      <ConfirmDialog
        title="Confirm Delete Concentrateur"
        contentText="Are you sure you want to permanently delete the concentrateur?"
        actionBtnText="Delete Concentrateur"
        triggerBtn={{
          type: isMobile ? 'round' : 'normal',
          text: 'Delete Concentrateur',
          icon: DeleteOutlineIcon,
          style: { marginLeft: '1em' },
        }}
        actionFunc={handleDeleteConcentrateur}
      />
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.detailsHeader}>
        <Typography variant={isMobile ? 'h5' : 'h4'} color="secondary">
          <strong>{hardSn}</strong>
        </Typography>
        <Divider style={{ margin: '0.5em 0' }} />
        <Typography color="secondary" variant="h6">
          {tel}
        </Typography>
        <Typography
          color="secondary"
          variant="subtitle2"
          className={classes.marginText}
        >
          Status: {/*statusInfo()*/}
        </Typography>
        <Typography
          color="secondary"
          variant="subtitle2"
          className={classes.marginText}
        >
        </Typography>
        <Typography color="secondary" variant="subtitle2">
          Created: <em>{/*formatDateTime(date_instru)*/}</em> ~{' '}
          <strong>{/*createdBy.username*/}</strong>
        </Typography>
        {/*updatedBy && date_update && (*/
          <Typography color="secondary" variant="subtitle2">
            Updated: <em>{/*formatDateTime(date_update)*/}</em> ~{' '}
            <strong>{/*updatedBy.username*/}</strong>
          </Typography>
        /*)*/}
        <div className={classes.btnsWrapper}>
          {closeReopenBtns()}
          {updateConcentrateurBtn()}
          {deleteConcentrateurBtn()}
        </div>
      </Paper>
    </div>
  );
};

export default ConcentrateursDetailsPage;
