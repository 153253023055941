import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import questions_instrumService from '../../services/contacts';
import { 
  QuestionsInstrum,
//  QuestionsInstrumFilterValues,
  QuestionsInstrumPayload,
//  QuestionsInstrumSortValues,
  QuestionsInstrumState,
} from '../types';
import { notify } from './notificationSlice';
import { getErrorMsg } from '../../utils/helperFuncs';

interface InitialQuestionsInstrumState {
//  questions_instrums: { [siteId: string]: QuestionsInstrumState[] };
//  questions_instrums: QuestionsInstrum[];
  questions_instrums: QuestionsInstrumState[]; // previously disabled 230316
  fetchLoading: boolean;
  fetchError: string | null;
  submitLoading: boolean;
  submitError: string | null;
//  sortBy: QuestionsInstrumSortValues,
//  filterBy: QuestionsInstrumFilterValues,
//  status: 'idle' | 'loading' | 'succeeded';
//  error: string | null;
}

const initialState: InitialQuestionsInstrumState = {
  questions_instrums: [],
  fetchLoading: false,
  fetchError: null,
  submitLoading: false,
  submitError: null,
/*  sortBy: 'newest',
  filterBy: 'all',*/
/*  questions_instrums: [],
  status: 'idle',
  error: null,*/
};

const questions_instrumsSlice = createSlice({
  name: 'questions_instrums',
  initialState,
  reducers: {
//    setQuestionsInstrums: (state, action: PayloadAction<{ questions_instrum: QuestionsInstrumState[], siteId: string }>) => { //230320
    //setQuestionsInstrums: (state, action: PayloadAction<Contact[]>) => {
      setQuestionsInstrums: (state, action: PayloadAction<QuestionsInstrumState[]>) => {
      state.questions_instrums = action.payload;
//      state.questions_instrums[action.payload.siteId] = action.payload.contact;   //230320
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;
    },
//    setSiteQuestionsInstrums: (state, action: PayloadAction<{ questions_instrum: Contact, siteId: string }>) => {
    setSiteQuestionsInstrums: (state, action: PayloadAction<{ questions_instrum: QuestionsInstrumState, site_id: string }>) => {
      //setQuestionsInstrums: (state, action: PayloadAction<QuestionsInstrumState[]>) => {
      //state.questions_instrums[action.payload.siteId] = action.payload.contact.;
/*      state.questions_instrums[action.payload.contact.id] = action.payload;
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;*/
    },
    setQuestionsInstrumsLoading: (state) => {
      state.fetchLoading = true;
//      state.status = 'loading';
      state.fetchError = null;
    },
    setFetchQuestionsInstrumsError: (state, action: PayloadAction<string>) => {
      state.fetchLoading = false;
      state.fetchError = action.payload;
    },
/*    sortQuestionsInstrumsBy: (state, action: PayloadAction<ContactSortValues>) => {
      state.sortBy = action.payload;
    },*/
  },
});

export const { setQuestionsInstrums, /*setSiteContacts,*/ setQuestionsInstrumsLoading, setFetchQuestionsInstrumsError/*, sortContactsBy*/ } = questions_instrumsSlice.actions;

export const fetchQuestionsInstrums = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setQuestionsInstrumsLoading());
      const allQuestionsInstrums = await questions_instrumService.getContacts();
      dispatch(setQuestionsInstrums(allQuestionsInstrums));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const selectQuestionsInstrumsState = (state: RootState) => state.questions_instrum;
/*export const selectQuestionsInstrumById = (
  state: RootState,
  site_id: string,
  questions_instrumId: string) => {
	  return state.questions_instrums.contacts?.[Number(site_id)]; //.find((p) => p.id === contactId);
//    return state.questions_instrums.contacts.find((p) => p.id === contactId);
};
*/
export default questions_instrumsSlice.reducer;
