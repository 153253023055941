import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import contactService from '../../services/contacts';
import { 
  Contact,
  ContactFilterValues,
  ContactPayload,
  ContactSortValues,
  ContactState,
  SiteContact,
} from '../types';
import { notify } from './notificationSlice';
import { getErrorMsg } from '../../utils/helperFuncs';

interface InitialContactState {
//  contacts: { [siteId: string]: ContactState[] };
//  contacts: Contact[];
  contacts: ContactState[]; // previously disabled 230316
  fetchLoading: boolean;
  fetchError: string | null;
  submitLoading: boolean;
  submitError: string | null;
  sortBy: ContactSortValues,
  filterBy: ContactFilterValues,
//  status: 'idle' | 'loading' | 'succeeded';
//  error: string | null;
}

const initialState: InitialContactState = {
  contacts: [],
  fetchLoading: false,
  fetchError: null,
  submitLoading: false,
  submitError: null,
  sortBy: 'newest',
  filterBy: 'all',
/*  contacts: [],
  status: 'idle',
  error: null,*/
};

const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
//    setContacts: (state, action: PayloadAction<{ contact: ContactState[], siteId: string }>) => { //230320
    //setContacts: (state, action: PayloadAction<Contact[]>) => {
      setContacts: (state, action: PayloadAction<ContactState[]>) => {
      state.contacts = action.payload;
//      state.contacts[action.payload.siteId] = action.payload.contact;   //230320
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;
    },
//    setSiteContacts: (state, action: PayloadAction<{ contact: Contact, siteId: string }>) => {
    setSiteContacts: (state, action: PayloadAction<{ contact: ContactState, site_id: string }>) => {
      //setContacts: (state, action: PayloadAction<ContactState[]>) => {
      //state.contacts[action.payload.siteId] = action.payload.contact.;
/*      state.contacts[action.payload.contact.id] = action.payload;
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;*/
    },
    setContactsLoading: (state) => {
      state.fetchLoading = true;
//      state.status = 'loading';
      state.fetchError = null;
    },
    setFetchContactsError: (state, action: PayloadAction<string>) => {
      state.fetchLoading = false;
      state.fetchError = action.payload;
    },
    sortContactsBy: (state, action: PayloadAction<ContactSortValues>) => {
      state.sortBy = action.payload;
    },
  },
});

export const { setContacts, setSiteContacts, setContactsLoading, setFetchContactsError, sortContactsBy } = contactsSlice.actions;

export const fetchContactsBySiteId = (site_id: string): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setContactsLoading());
      const siteContacts = await contactService.getSiteContacts(site_id);
//      dispatch(setSiteContacts({ contacts: contactState ??, siteId }));
      dispatch(setSiteContacts({ contact: siteContacts, site_id }));
    } catch (e) {
      //@ts-ignore
      dispatch(setFetchContactsError(getErrorMsg(e)));
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));    }
  };
};

export const fetchContacts = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setContactsLoading());
      const allContacts = await contactService.getContacts();
      dispatch(setContacts(allContacts));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const selectContactsBySiteId = (state: RootState, site_id: string) => {
  return state.contacts.contacts?.[Number(site_id)];
  //return state.contacts.contacts?.[Number(siteId)];
};

export const selectContactsState = (state: RootState) => state.contacts;
export const selectContactById = (
  state: RootState,
  site_id: string,
  contactId: string) => {
	  return state.contacts.contacts?.[Number(site_id)]; //.find((p) => p.id === contactId);
//    return state.contacts.contacts/*?.[Number(siteId)]*/.find((p) => p.id === contactId);
};

export default contactsSlice.reducer;
