import { useForm, Controller } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import {
  createNewEquipement,
  editEquipementDB,
  clearSubmitEquipementError,
  selectEquipementsState,
  closeReopenEquipement,
  deleteEquipementDB,
  editEquipement,
  fetchEquipementsBySiteId,
  fetchEquipementsBySitesSn,
  fetchEquipementsForSiteSn,
  majReponsesFI,
  closeReponsesFI,
} from '../../redux/slices/equipementsSlice';
import { ConcentrateurState, EquipementPayload, PosteState, QuestionsInstrumState, ReponsesPayload, RoomState, UsageState, Voie2mesurePayload, Voie2mesureState } from '../../redux/types';
import ErrorBox from '../../components/ErrorBox';
import RepAuxQuestions from '../../components/RepAuxQuestions';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InfoIcon from '@material-ui/icons/Info';
import {
  TextField,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
  InputAdornment,
  FormLabel,
  FormControl,
  InputLabel,
//  Label,
  MenuItem,
  Select,
  Checkbox,
  withStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Input,
  FormGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

//import {DatePicker} from '@adobe/react-spectrum';
import { useFormStyles, useMainPageStyles, useTableStyles } from '../../styles/muiStyles';
import TitleIcon from '@material-ui/icons/Title';
import SubjectIcon from '@material-ui/icons/Subject';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { format } from 'date-fns';
import React, { ChangeEvent, Fragment, SetStateAction, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//import { dataCollectionPhase } from 'typeorm-model-generator/dist/src/Engine';
import { createVoie2mesure, createVoie2mesureDB, editVoie2mesureDB, stopVoie2mesure, stopVoie2mesureDB, editVoie2mesure, /*updateVoie2mesures, updateVoie2mesuresDB,*/ 
clearSubmitVoie2mesuresError,
selectVoie2mesuresState,
fetchVoiesBySitesSn} from '../../redux/slices/voie2mesuresSlice';
import FormDialog from '../../components/FormDialog';
import { selectQuestionsInstrumsState } from '../../redux/slices/questionsInstrumSlice';
import { Label, SettingsInputComponent } from '@material-ui/icons';
import { boolean } from 'yup/lib/locale';
import Group from '@material-ui/icons/Group';
import { Alert, AlertTitle } from '@material-ui/lab';
/*import { Textarea } from 'react-rainbow-components';
import InfoText from '../../components/InfoText';
import FormDialog from '../../components/FormDialog';
import styled from "styled-components";
import COLORS from "./color";*/
//import StyledLabel from "styled-components";

const validationSchema = yup.object({
  title: yup
    .string()
    .required('Required')
    .min(3, 'Must be at least 3 characters')
    .max(60, 'Must be at most 60 characters'),

  description: yup.string().required('Required'),
});

interface EquipementFormProps {
  closeDialog?: () => void;
  siteSn: string;
  siteId: string;
  voieId: number;
  conc: ConcentrateurState[];
  isEditMode: boolean;
  currentData?: EquipementPayload;
//  equipementId?: string; => ipApp
  voiesDuSite: Voie2mesureState[];
  sn_module: number;
  usages: UsageState[];
  postes: PosteState[];
  pieces: RoomState[];
  questions: QuestionsInstrumState[];
  idApp?: string;
  dbleSuivi: boolean;
  isSuivi: boolean;
  newEquip: boolean;    // => ajout d'équipement
  cas: string;         // switch "device" or "suivi" form
}

const EquipementForm: React.FC<EquipementFormProps> = ({
  closeDialog,
  isEditMode,
  siteId,
  voieId,
  siteSn,
  conc,
  currentData,
  voiesDuSite,
  sn_module,
  usages,
  postes,
  pieces,
  questions,
  idApp,
//  dbleSuivi,
  isSuivi,
  newEquip,
  cas,
}) => {
  const history = useHistory;
  const classes = useFormStyles();
  const MoreClasses = useTableStyles();
  const classes2 = useMainPageStyles();
  const dispatch = useDispatch();
  const { submitErrorEquipement, submitLoadingEquipement } = useSelector(selectEquipementsState);
  console.log("currentData.id: "+currentData?.id+ " / voieId: "+voieId);
  const selectedVoie = voiesDuSite.find((v) => Number(v.id) === voieId);
  //const selectedVoie = voiesDuSite.find((v) => v.id_equipement === Number(currentData?.id);
  /* && v.id_module === sn_module && Number(v.id) === voieId*)*/
  // TODO: make a map on voiesDuSite pour vérifier si la voie ajoutée est bien présente... a priori nan..
  //voiesDuSite.map((v)=>console.log("ID: "+v.id+" - mod: "+v.id_module+" - conc: "+v.id_conc+" - num: "+v.number+" | equipId: "+v.id_equipement))
  console.log("selectedVoie: "+selectedVoie?.id+" / "+selectedVoie?.id_module+" / "+selectedVoie?.number+" / "+selectedVoie?.type_sensor+" / "+selectedVoie?.id_sensor+" / "+selectedVoie?.site_id);
//  console.log("Site_ID: "+siteId);
  if(!currentData) {
//    console.log("currentData(EquipPayload) undefined")
    currentData = {id: "",  id_poste: 0,  id_type_usage: 0,  id_piece: 0,  reference: "",  id_ref: 0,  reponses: "",  id_questions: 0,  comments: "",  a_suivre: false,  
      suivi: false,  app_date_start: new Date, app_date_end: null/*new Date*/,  site_sn: "", sn_site: "",  str_piece: "",  str_equip: "",  site_id: "",  nrg_class: "",  normalized_pw: "", 
      water_consumption: "",  pw_veille: "", lightsUsageTime: "", nbLights: "", powerLights: "", technoLights: "", id_circuit: ""}
  }
  
  useEffect(() => {
    /*dispatch(fetchEquipementsForSiteSn(siteSn));
    dispatch(fetchVoiesBySitesSn(siteSn));*/
    //showDialog(true,true);
  }, []);
  const questions_instrum = useSelector(selectQuestionsInstrumsState);
  const { voie2mesures, submitErrorVoie2mesure, submitLoadingVoie2mesure } = useSelector(selectVoie2mesuresState);
  let lesVoieDuSite: Voie2mesureState[];
  if(selectedVoie === undefined) {
    lesVoieDuSite = voie2mesures;
  } else {
    lesVoieDuSite = voiesDuSite;
  }
  const currentIdCircuit = currentData?.id_circuit || "";
  const currentUsageId = currentData?.id_type_usage || 0;
  let currentPosteId = currentData?.id_poste || 1;
  const currentPieceId = currentData?.id_piece || 0;
/*  console.log("currentUsage: " + currentData?.id_type_usage);
  console.log("currentPiece: " + currentData?.id_piece);
  console.log("currentPoste: " + currentData?.id_poste);*/
  const currReference = currentData?.reference || 1;
  const currId_ref = currentData?.id_ref || 1;
  const currReponses = currentData?.reponses || "";
  const currId_questions = currentData?.id_questions || 1;
  const currComments = currentData?.comments || "";
  const currA_suivre = currentData?.a_suivre || false;
  const currSuivi = currentData?.suivi || false;
  //@ts-ignore
  let currDate_start;
//  console.log("currentData-Date_start: "+currentData?.app_date_start);
  var arrVoies = lesVoieDuSite.slice() || null;
  //console.log("######## theVoieDateSTART: "+arrVoies?.sort((a, b) => +new Date(b.voie_date_start) - +new Date(a.voie_date_start)).pop()?.voie_date_start)
  newEquip ? currDate_start = format(new Date(arrVoies.sort((a, b) => +new Date(b.voie_date_start) - +new Date(a.voie_date_start)).pop()?.voie_date_start || new Date()) 
    || new Date(), "yyyy-MM-dd'T'h':'mm") : currDate_start = new Date();
  //currentData?.app_date_start !== null ? currDate_start = format(new Date(currentData?.app_date_start), "yyyy-MM-dd'T'h':'mm") : currDate_start = null;
//  const currDate_end = format(currentData.app_date_end, "yyyy-MM-dd'T'h':'mm") || '';/*currentData?.app_date_end || new Date();*/
//  console.log("currDate_start: "+currDate_start);

//  GOOD ... almost Good.. const currDate_start = format(new Date(currentData?.app_date_start || new Date()), "yyyy-MM-dd'T'h':'mm") || '' /*new Date()*/;/*currentData?.app_date_start || new Date();*/
//  const currDate_end = format((currentData?.app_date_end || new Date()), "yyyy-MM-dd'T'h':'mm");/*currentData?.app_date_end || new Date();*/
  //@ts-ignore
  let currDate_end;
//  console.log("currentData-Date_end: "+currentData?.app_date_end);
  currentData?.app_date_end !== null ? currDate_end = format(new Date(currentData?.app_date_end), "yyyy-MM-dd'T'h':'mm") : currDate_end = null;
//  const currDate_end = format(currentData.app_date_end, "yyyy-MM-dd'T'h':'mm") || '';/*currentData?.app_date_end || new Date();*/
//  console.log("currDate_end: "+currDate_end);
  const currSn_site = currentData?.sn_site || "";
  const currStr_piece = currentData?.str_piece || "";
  const currStr_equip = currentData?.str_equip || "";
  const currSite_id = siteId/*selectedVoie?.site_id*//*currentData?.site_id*/;
  const currPwVeille = currentData?.pw_veille || "";
  const currNrgClass = currentData?.nrg_class || "";
  const currWaterComsumption = currentData?.water_consumption || "";
  const currNormalized_pw = currentData?.normalized_pw || "";
  const currId_module = selectedVoie?.id_module || sn_module || null;
  const currNumber = selectedVoie?.number || null;
  const currType_sensor = selectedVoie?.type_sensor || '';
  const currId_sensor = selectedVoie?.id_sensor || '';
  const currPhase = selectedVoie?.phase || '';
  const currFreq_controle = selectedVoie?.freq_controle || 255;
  const currNo_type = selectedVoie?.no_type || null;
  const currCommentaires = selectedVoie?.commentaires || '';
  //@ts-ignore
  let currVoie_date_start;
  selectedVoie?.voie_date_start !== undefined ? currVoie_date_start = format(new Date(selectedVoie?.voie_date_start), "yyyy-MM-dd'T'h':'mm") : currVoie_date_start = null; /*selectedVoie?.voie_date_start || new Date();*/
//  console.log("currVoieDateStart: "+currVoie_date_start);
  //@ts-ignore
  let currVoie_date_end: string | number | Date;
  selectedVoie?.voie_date_end !== undefined ? currVoie_date_end = format(new Date(selectedVoie?.voie_date_end), "yyyy-MM-dd'T'h':'mm") : currVoie_date_end = "1970-01-01T1:00"/*null*/; /*selectedVoie?.voie_date_end || new Date();*/
//  console.log("currVoieDateEnd: "+currVoie_date_end);
  const currId_equipement = selectedVoie?.id_equipement || null;
  const currSite_idVoie = siteId/*selectedVoie?.site_id*/;
  const currSite_sn = siteSn/*selectedVoie?.site_sn || null*/;
  const currTechnoLights = (currentUsageId === 342 || currentUsageId === 343) ? currReponses?.split("|")[1].split(",")[0] : '';
  const currLightsUsageTime = (currentUsageId === 342 || currentUsageId === 343) ? currReponses?.split("|")[0] : '';
  const currPwLights = (currentUsageId === 342 || currentUsageId === 343) ? currReponses?.split("|")[1].split(",")[2] : '';
  const currNbLights = (currentUsageId === 342 || currentUsageId === 343) ? currReponses?.split("|")[1].split(",")[1] : '';
  const distinctConcSN: Array<string> = [];
  const distinctSensorSN: Array<string> = [];
  const distinctModuleSN: Array<string> = [];
  const dejaVus: Array<String> = [];

  // TODO: pour créer les var 'distincts' suivantes, piocher dans les states des concentrateurs plutôt que dans les voies... ??
/*  voiesDuSite.map((w) =>
    dejaVus.indexOf(String(w.id_conc)) === -1 ?
      distinctConcSN.push(String(w.id_conc)) && dejaVus.push(String(w.id_conc)) : null
  );
  if(distinctConcSN.length === 0) {*/
    conc.map((c) => distinctConcSN.push(String(c.hardSn)))
//  }
  lesVoieDuSite.map((w) =>
    dejaVus.indexOf(String(w.id_sensor)) === -1 ?
      distinctSensorSN.push(String(w.id_sensor)) && dejaVus.push(String(w.id_sensor)) : null
  );
/*  voiesDuSite.map((w) =>
    dejaVus.indexOf(String(w.id_module)) === -1 ?
      distinctModuleSN.push(String(w.id_module)) && dejaVus.push(String(w.id_module)) : null
  );
  if(distinctModuleSN.length === 0) {*/
    conc.map((c) => c.modules.map((m) => distinctModuleSN.push(String(m))))
//  }
  const currId_conc = selectedVoie?.id_conc || (distinctConcSN.length === 1 ? distinctConcSN[0] : null);
  const currConcId: String | null = conc.find((c) => c.hardSn === currId_conc && c.modules.find((m) => m === currId_module))?.id || null;
  const numerosVoies = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28];
  let stopFlag = true;
// TODO:
// Suivi et Actif : des boolean 'oui' 'non'
  const { register, control, handleSubmit, errors } = useForm({
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    defaultValues: {
  //      id_equip: currentData?.id_equip || '',
      id: currentData?.id || '',
      id_piece: currentData?.id_piece || '',
      id_type_usage: currentData?.id_type_usage || '',
      id_poste: currentData?.id_poste || usages.find((u) => Number(u.id) === currentData?.id_type_usage)?.id_poste,
      reference: currentData?.reference || '',
      reponses: currentData?.reponses || '',
      id_questions: currentData?.id_questions || '',
      nrg_class: currentData?.nrg_class || '',
      normalized_pw: currentData?.normalized_pw || '',
      water_consumption: currentData?.water_consumption || '',
      pw_veille: currentData?.pw_veille || '',
      suivi: currentData?.suivi || false,
      a_suivre: currentData?.a_suivre || false,
      str_piece: currentData?.str_piece || "",
      comments: currentData?.comments/*.map((c)=>String(c))*//*.replace(/^"/g,"")).substring(1,(currentData?.comments.length || 1) - 1)*/ || '',
    /*  app_date_start: format(new Date(currentData?.app_date_start), "yyyy-MM-dd'T'h':'mm") || new Date(),
      app_date_end: format(new Date(currentData?.app_date_end), "yyyy-MM-dd'T'h':'mm") || new Date(),*/
      app_date_start: format(new Date(currentData?.app_date_start
        || lesVoieDuSite.sort((a, b) => +new Date(b.voie_date_start) - +new Date(a.voie_date_start)).pop()?.voie_date_start || ''), "yyyy-MM-dd'T'h':'mm"),
  //      app_date_start: format(new Date(currentData?.app_date_start || new Date()), "yyyy-MM-dd'T'h':'mm"),
      app_date_end: currentData?.app_date_end ? format(new Date(currentData?.app_date_end /*|| new Date()*/), "yyyy-MM-dd'T'h':'mm") : '',
      id_conc: selectedVoie?.id_conc || null,
      id_module: selectedVoie?.id_module || sn_module || null,
      number: selectedVoie?.number || null,
      type_sensor: selectedVoie?.type_sensor || '',
      id_sensor: selectedVoie?.id_sensor || '',
      phase: selectedVoie?.phase || null,
      freq_controle: selectedVoie?.freq_controle || null,
      no_type: selectedVoie?.no_type || null,
      commentaires: selectedVoie?.commentaires || '',
      voie_date_start: format(new Date(selectedVoie?.voie_date_start || new Date()), "yyyy-MM-dd'T'h':'mm"),
      voie_date_end: selectedVoie?.voie_date_end ? format(new Date(selectedVoie?.voie_date_end/*|| new Date()*/), "yyyy-MM-dd'T'h':'mm") : '',
      id_equipement: selectedVoie?.id_equipement || null,
      action: "",
      site_id: siteId/*selectedVoie?.site_id*/,
      site_sn: siteSn/*selectedVoie?.site_sn || null*/,
      sn_site: siteSn,
      lightsUsageTime: currentData?.lightsUsageTime || '0',
      nbLights: currentData?.nbLights || '0',
      powerLights: currentData?.powerLights || '',
  //      technoLights: currentData?.technoLights,
      technoLights: currentData?.reponses.split("|")[1]?.split(",")[0] || "",
      concHarsSn: conc.find((c) => c.hardSn === currId_conc && c.modules.find((m) => m === currId_module))?.id || null,
  /*      title: currentData?.title || '',
      description: currentData?.description || '',
      priority: currentData?.priority || 'low',*/
      dbleSuivi: false,
      delDevice: false,
      id_circuit: currentData?.id_circuit || '',
      //reponsesFromDialog: currReponses || '',
    },
  });
//  console.log("############### DEBUG ME:"+currDate_start)
 // Actions BOUTONS appareil : cesser le suivi (date) / supprimer (date)
  //const [usageName, setUsageName] = /*usageName*/useState(usages.find((u)=>Number(u.id) === Number(currentUsageId))?.name || "")
  //setUsageName(usages.find((u)=>Number(u.id) === Number(currentUsageId))?.name || "")
  let usageName: string = usages.find((u) => Number(u.id) === currentUsageId)?.name || ""
  let Questions = questions_instrum.questions_instrums.filter((q) => 
    q.usage_name.toLocaleLowerCase().includes(usageName.toLocaleLowerCase())
//    q.usage_name.toLocaleLowerCase().includes(usages.find((u) => Number(u.id) === currentUsageId).name.toLocaleLowerCase())
  ) || {};
  const [selectValue, setSelectValue] = useState("");
  const [checkedCorrection, setCheckedCorrection] = useState(true);

  const [showDevice, setShowDevice] = useState(true);
  const [showSuivi, setShowSuivi] = useState(true);

  const [state, setState] = useState({
    id_type_usage: currentUsageId,
    id_poste: currentPosteId,
    id_piece: /*""*/currentPieceId,
    id_circuit: currentIdCircuit,
    reponses: currReponses,
    str_piece: currStr_piece,
    comments: currComments/*'['+currComments.map((com)=>com)+']'*/,
    app_date_start: currDate_start /*!== null ? format(new Date(currDate_start), "yyyy-MM-dd'T'h':'mm") : /*null/currDate_start*/,
    app_date_end: currDate_end /*!== null ? format(new Date(currDate_end), "yyyy-MM-dd'T'h':'mm") : /*null/currDate_end*/,
    pw_veille: currPwVeille,
    nrg_class: currNrgClass,
    water_consumption: currWaterComsumption,
    normalized_pw: currNormalized_pw,
    id_voie: selectedVoie?.id,
    id_conc: currId_conc,
    id_module: currId_module,
    number: currNumber,
    id_sensor: currId_sensor,
    type_sensor: currType_sensor,
    phase: currPhase/* | 0*/,
    commentaires: currCommentaires,
    suivi: currSuivi,
    a_suivre: currA_suivre,
    voie_date_start: currVoie_date_start !== null ? format(new Date(currVoie_date_start), "yyyy-MM-dd'T'h':'mm") : currDate_start/*null*/,/*format(new Date(currVoie_date_start || new Date()), "yyyy-MM-dd'T'h':'mm")*/
    voie_date_end: currVoie_date_end !== "1970-01-01T1:00" ? format(new Date(currVoie_date_end), "yyyy-MM-dd'T'h':'mm") : currDate_start/*null*/,/*format(new Date(currVoie_date_end || new Date()), "yyyy-MM-dd'T'h':'mm")*/
    freq_controle: currFreq_controle,
    site_id: siteId/*selectedVoie?.site_id*//*currentData?.site_id*//*currSite_idVoie*/,
    site_sn: siteSn,
    showNewSnInput: currType_sensor === "MV" ? false : true,
    sensorSnNeeded: true,
    id_equipement: currId_equipement,
    action: "",
    lightsUsageTime: currLightsUsageTime,
 //   technoLights: currReponses.split("|")[1].split(",")[0]  || "",
    nbLights: currNbLights,
    powerLights: currPwLights,
    technoLights: currTechnoLights,// || "",
    concId: currConcId,
    id_questions: "" /*Questions?.filter((q) => q.usage_name.includes(usages.find((u) => Number(u.id) === currentUsageId)?.name || ""))[0]?.questions || ""*/,
    //dbleSuivi: false,
  })
//  console.log("AppDateStart: "+state.app_date_start);
//  console.log("AppDateEnd: "+state.app_date_end);
  //    let theQuestions = questions_instrum.questions_instrums.filter((q) => q.usage_name.includes(usageName));
/*  let theQuestions = questions_instrum.questions_instrums.filter((q) => 
      q.usage_name.toLocaleLowerCase().includes(usageName.toLocaleLowerCase())
  );*/
  let theQuestions : QuestionsInstrumState[] = []
  const buildQuestionsList = (idUsage: number) => {
    //setUsageName(usages.find((u) => Number(u.id) === idUsage)?.name || "")
    usageName = usages.find((u) => Number(u.id) === idUsage)?.name || ""
    console.log("idUsage sélectionné: "+idUsage+" / "+usageName+" idPoste(state): "+state.id_poste+" idPoste(payload): "+currentPosteId)
    //Questions = questions_instrum.questions_instrums.filter((q) => q.id_usage === idUsage);
    Questions = questions_instrum.questions_instrums.filter((q) => q.id_usage === idUsage);
    Questions.map((q) => console.log("QUSET! USAGE: "+q.usage_name))
//    questions_instrum.questions_instrums.filter((q) => q.id_poste === state.id_poste).map((q)=> Questions.push(q))
    if(Questions.length < 1) {
        console.log("Using usage-ID as a filter does not return any Questions ! tryin' with device's POSTE-ID");
        console.log("PosteId from DOM: "+state.id_poste)
        Questions = questions_instrum.questions_instrums.filter((q) => q.id_poste === state.id_poste);
    }
    if(Questions.length < 1) {
        console.log("Id_poste name does not return any Questions ! putting whole Questions list...")
        Questions = questions_instrum.questions_instrums;
    }
    theQuestions = Questions.filter((n, i) => Questions.indexOf(n) === i);
  //  theQuestions.map((s) => console.log("###Q: ID:"+s.id+" Poste:"+s.id_poste+" Usage:"+s.id_usage+" SeqQuest:"+s.questions+" SeqRép:"+s.reponses))
  }
  buildQuestionsList(currentUsageId)
  let arrRep = state.reponses.replaceAll('//','/_/').split('/');
  const [selectedScenario, setSelectedScenario] = useState<String>("chgmt");
  const [selectedScenario2, setSelectedScenario2] = useState<String>("chgmtvoie");
  const [selectedAppScenario, setSelectedAppScenario] = useState<String>("chgmtapp");
  const [selectedVoieScenario, setSelectedVoieScenario] = useState<String>("chgmtvoie");
  const radioHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedScenario(event.target.value);
  };
  const radioHandler2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("######## VOIE scenario radio: "+event.target.value)
    setSelectedScenario2(event.target.value);
  };
  const radioHandlerApp = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedAppScenario(event.target.value);
  };
  const [delDevice,setDelDevice] = useState<boolean>(false);
  const [dbleSuivi,setDbleSuivi] = useState<boolean>(false);
  function chkboxHandler(event: React.ChangeEvent<{}>) {
    const evt = event as ChangeEvent<HTMLInputElement>
    setState({
      ...state,
      [evt.target.name]: evt.target.checked
    })
    if( evt.target.name === "dbleSuivi") { 
      if(evt.target.checked === true ) { setSelectedVoieScenario("chgmtvoie"); setDbleSuivi(evt.target.checked); setDelDevice(!evt.target.checked) }
      else { setDbleSuivi(false); setDelDevice(false) }
    }
    if( evt.target.name === "delDevice" /*state.delDevice === true*/) { 
      if(evt.target.checked === true ) { setSelectedVoieScenario("endvoie"); setDelDevice(evt.target.checked); setDbleSuivi(!evt.target.checked) }
      else { setDbleSuivi(false); setDelDevice(false) } 
    }
    console.log("######## new chkBox target/value: "+evt.target.name+" / "+evt.target.checked+" | @@@@@@@@@@@@@ selectedVoieScenario ["+selectedVoieScenario+"]")
    console.log("######## dbleSuivi STATE: "+dbleSuivi+" | ######### delDevice STATE: "+delDevice)
  }
  const radioHandlerApp2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("######## VOIE scenario radio: "+event.target.value)
    setSelectedVoieScenario(event.target.value);
  };
//  console.log("comments:"+currComments/*.map((com) => String(com) + " / ")*/);
  function handleCommentsChg(evt: { target: { value: any; name: any; }; }) {
//    console.log("comments RAW value: "+evt.target.value)
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value
    });
  }

  function handleDateChg(evt: { target: { value: any; name: any; }; }) {
    console.log("handleDateChg: "+evt.target.name+" "+evt.target.value);
    const value = evt.target.value;
/*    console.log("DATE CHANGE VAL: "+value)
    let str = value.replace("T--:--","T12:00")
    console.log("DATE CHANGE VAL: "+str) */
    setState({
      ...state,
      [evt.target.name]: value
    });
  }
  function handleChange(evt: { target: { value: any; name: any; }; }) {
    const value = evt.target.value;
    console.log("handleChange"+evt.target.name+" value: "+value);
    setState({
      ...state,
      [evt.target.name]: value
    });
  }
  const [powerLights, setPowerLights] = useState(currPwLights);

  const handleChangePwLights = (event: { target: { value: SetStateAction<string>; }; }) => {
    setPowerLights(event.target.value);
    console.log(event.target.value);
  };
  const handleChangeBack = (event: { target: { value: any; name: any }; }/*event: SelectChangeEvent*/) => {
  //  console.log(event.target.name+": "+event.target.value);
    setState({ ...state, [event.target.name]: event.target.value });
    setSelectValue(event.target.value as string);
  };
  const handleChangeBack2 = (event: React.ChangeEvent<{  name?: string | undefined; value: unknown; }> /*event: SelectChangeEvent*/) => {
//    console.log(event.target.name+": "+event.target.value);
    setState({ ...state, [event.target.name as string]: event.target.value });
    setSelectValue(event.target.value as string);
    if( event.target.value === "addNewSensor" ) {
      setShowNewSnInput((showNewSnInput) => !showNewSnInput);
      //      showNewSnInput = true && console.log("NewSensor Click")
    } else {
        if( event.target.value === "MV" ){
          setToggleWScase(false/*!toggleWScase*/);
          setSelectValue(event.target.value);
        } if( event.target.value === "WP" || event.target.value === "WS" || event.target.value === "TM" || event.target.value === "HY" ){
            setToggleWScase(true/*!toggleWScase*/);
          }
          else { setSelectValue(event.target.value as string); }
    }
/*    if(event.target.name as string === "id_type_usage") {
      currentPosteId = usages.find((u) => Number(u.id) === event.target.value as number)?.id_poste || 9
      document.querySelector<HTMLInputElement>('input[name=id_poste]')?.setAttribute("id",String(currentPosteId))
      //setState({ ...state, ['id_poste']: usages.find((u) => Number(u.id) === event.target.value as number)?.id_poste || 9});
      //setState({ ...state, [event.target.name as string]: event.target.value });
    }*/
//    if(event.target.value as string === "technoLights") {
/*    if( lightTypes.filter((type) => type.value.match(event.target.value as string)) ) {
      //console.log("IF ligths STR2REP")
      buildStrLights2strRep();
    }*/
  };

  let strRep = "";
  const CustomColorCheckbox = withStyles({
    root: {
      color: "#13c552",
      "&$checked": {
        color: "#c51325"
      }
    },
    checked: {}
  })((props) => <Checkbox color="default" {...props} />);

  const [sensorSnNeeded, setSensorSnNeeded] = useState(false);
  const [showNewSnInput, setShowNewSnInput] = useState(false);

  const [toggleWScase, setToggleWScase] = useState(true);
/*  if( selectedVoie?.type_sensor === "MV" ){
    setToggleWScase((toggleWScase) => !toggleWScase);
  }*/
 // if(voieSnSensor.length === 0 ) { setToggleWScase((toggleWScase) => !toggleWScase) }
  const onChange = (event: any) => {      // for basic HTML selects
    const value = event.target.value;
    if( value === "addNewSensor" ) {
      setShowNewSnInput((showNewSnInput) => !showNewSnInput);
      //      showNewSnInput = true && console.log("NewSensor Click")
    } else {
        if( value === "MV" ){
          setToggleWScase((toggleWScase) => false/*!toggleWScase*/);
          setSelectValue(value);
        } if( value === "WP" || value === "WS" || value === "TM" || value === "HY" ){
            setToggleWScase((toggleWScase) => true/*!toggleWScase*/);
          }
          else { setSelectValue(value); }
      }
  //    console.log("Piece value: "+state.id_piece);
  };
  /********************* Date Picker ***********************/
  const containerStyles = {
    maxWidth: 400,
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleCloseMenu = () => {
    //setAnchorEl(null);
    console.log("closeMenu CALLED")
  };
  const chkDates = () => {
  //  console.log("Chk Date END APP/VOIE: "+state.app_date_end+" / "+state.voie_date_end)
    if (state.app_date_end === "0NaN-NaN-NaNTNaN:NaN:NaN.NaN+NaN:NaN") {
      setState({
        ...state,
        app_date_end: ''
      });
    }
    if (state.voie_date_end === "0NaN-NaN-NaNTNaN:NaN:NaN.NaN+NaN:NaN") {
      setState({
        ...state,
        voie_date_end: ''
      });
    }    
  };
  const handleSelChge = (event: React.ChangeEvent<{  name?: string | undefined; value: unknown; }> /*event: SelectChangeEvent*/) => {
    console.log(event.target.name+": "+event.target.value);
    setState({ ...state, [event.target.name as string]: event.target.value });
    setSelectValue(event.target.value as string);
  };
  const [openDialog, setOpen] = useState(false);
  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };
  const cleaner = (input: string) => {
    return input?.replaceAll("{","").replaceAll("}","").replaceAll("\"","").replaceAll("/","").replaceAll("\"","") || ""
  };
  const getStrRep = () => {
    let thereponses: string = "";
    console.log("getStrRep -> state.id_questions: "+state.id_questions)
    if(state.id_questions !== ""){
      //const elements = document.getElementsByClassName("rep_*");
      const rep_0 = document.querySelector<HTMLInputElement>('input[name="rep_0"]');
      const rep_1 = document.querySelector<HTMLInputElement>('input[name=rep_1]');
      const rep_2 = document.querySelector<HTMLInputElement>('input[name=rep_2]');
      const rep_3 = document.querySelector<HTMLInputElement>('input[name=rep_3]');
      const rep_4 = document.querySelector<HTMLInputElement>('input[name=rep_4]');
      const rep_5 = document.querySelector<HTMLInputElement>('input[name=rep_5]');
      const rep_6 = document.querySelector<HTMLInputElement>('input[name=rep_6]');
      const rep_7 = document.querySelector<HTMLInputElement>('input[name=rep_7]');
      const rep_8 = document.querySelector<HTMLInputElement>('input[name=rep_8]');
      const rep_9 = document.querySelector<HTMLInputElement>('input[name=rep_9]');
      const rep_10 = document.querySelector<HTMLInputElement>('input[name=rep_10]');
      const rep_11 = document.querySelector<HTMLInputElement>('input[name=rep_11]');
      const rep_12 = document.querySelector<HTMLInputElement>('input[name=rep_12]');
      const rep_13 = document.querySelector<HTMLInputElement>('input[name=rep_13]');
      const rep_14 = document.querySelector<HTMLInputElement>('input[name=rep_14]');
      const rep_15 = document.querySelector<HTMLInputElement>('input[name=rep_15]');
      const rep_16 = document.querySelector<HTMLInputElement>('input[name=rep_16]');
      const rep_17 = document.querySelector<HTMLInputElement>('input[name=rep_17]');
      const rep_18 = document.querySelector<HTMLInputElement>('input[name=rep_18]');
      const rep_19 = document.querySelector<HTMLInputElement>('input[name=rep_19]');
      const rep_20 = document.querySelector<HTMLInputElement>('input[name=rep_20]');
      const rep_21 = document.querySelector<HTMLInputElement>('input[name=rep_21]');
      const rep_22 = document.querySelector<HTMLInputElement>('input[name=rep_22]');
      const rep_23 = document.querySelector<HTMLInputElement>('input[name=rep_23]');
      const rep_24 = document.querySelector<HTMLInputElement>('input[name=rep_24]');
      const rep_25 = document.querySelector<HTMLInputElement>('input[name=rep_25]');
    /*console.log(ensemble.item(10).value); */ // => reponses originales
      if(rep_0?.value !== undefined) {thereponses+=rep_0.value+"/"}
      if(rep_1?.value !== undefined) {thereponses+=rep_1.value+"/"}
      if(rep_2?.value !== undefined) {thereponses+=rep_2.value+"/"}
      if(rep_3?.value !== undefined) {thereponses+=rep_3.value+"/"}
      if(rep_4?.value !== undefined) {thereponses+=rep_4.value+"/"}
      if(rep_5?.value !== undefined) {thereponses+=rep_5.value+"/"}
      if(rep_6?.value !== undefined) {thereponses+=rep_6.value+"/"}
      if(rep_7?.value !== undefined) {thereponses+=rep_7.value+"/"}
      if(rep_8?.value !== undefined) {thereponses+=rep_8.value+"/"}
      if(rep_9?.value !== undefined) {thereponses+=rep_9.value+"/"}
      if(rep_10?.value !== undefined) {thereponses+=rep_10.value+"/"}
      if(rep_11?.value !== undefined) {thereponses+=rep_11.value+"/"}
      if(rep_12?.value !== undefined) {thereponses+=rep_12.value+"/"}
      if(rep_13?.value !== undefined) {thereponses+=rep_13.value+"/"}
      if(rep_14?.value !== undefined) {thereponses+=rep_14.value+"/"}
      if(rep_15?.value !== undefined) {thereponses+=rep_15.value+"/"}
      if(rep_16?.value !== undefined) {thereponses+=rep_16.value+"/"}
      if(rep_17?.value !== undefined) {thereponses+=rep_17.value+"/"}
      if(rep_18?.value !== undefined) {thereponses+=rep_18.value+"/"}
      if(rep_19?.value !== undefined) {thereponses+=rep_19.value+"/"}
      if(rep_20?.value !== undefined) {thereponses+=rep_20.value+"/"}
      if(rep_21?.value !== undefined) {thereponses+=rep_21.value+"/"}
      if(rep_22?.value !== undefined) {thereponses+=rep_22.value+"/"}
      if(rep_23?.value !== undefined) {thereponses+=rep_23.value+"/"}
      if(rep_24?.value !== undefined) {thereponses+=rep_24.value+"/"}
      if(rep_25?.value !== undefined) {thereponses+=rep_25.value+"/"}
      if(arrRep[arrRep.length - 1].match("-")) { thereponses += arrRep[arrRep.length - 1] }
      thereponses = thereponses.replaceAll("/_","/")
    } else {
      thereponses = document.querySelector<HTMLInputElement>('input[name=reponsesFromDialog]')?.value+"";
      //console.log("Else... THEREPONSES from reponsesFromDialog: "+thereponses)
      //thereponses = txtField+""
    }
    console.log("Réponses originales :"+state.reponses)
    //console.log("REPONSES !! :"+thereponses)
    setState({
        ...state,
        [thereponses]: thereponses
      });
    if(thereponses.localeCompare(state.reponses) !== 0) {
        console.log("Réponses CHANGÉES... MODIF ! : "+thereponses);
        const data: ReponsesPayload = { thereponses: thereponses };
        setState({ ...state, reponses: thereponses });
  //      state.reponses = thereponses;
      //  currReponses = thereponses;
        //console.log("Payload reponses: "+data.thereponses);
  //           dispatch(editEquipementDB(currentData?.id/*App*/ as string, /*currentData?.site_id*/siteId/* as string*/, currentData, closeDialog));
      //  dispatch(majReponsesFI(String(idEquip), siteId, data/*thereponses*/, closeDialog));
    } else { console.log("Réponses inchangées... pas de modif") }
    //hdleCloseRepFI(false)
    //hdleCloseRepFI(true)
  }
  const showQRepFI = () => {
    console.log("current STATE.ID_QUESTION: "+state.id_questions+"############ if === 0, prendre le champ txt en compte !!!")
    // TODO: function to update selectedIdQuestions if currentData.id === "" || 0 => new Device case
    //setUsageusages.find((u)=>Number(u.id) === Number(currentUsageId))?.name || ""
//    buildQuestionsList(state.id_type_usage)
    console.log("name=id_type_usage VALUE: "+document.querySelector<HTMLInputElement>('input[name=id_type_usage]')?.value)
    buildQuestionsList(Number(document.querySelector<HTMLInputElement>('input[name=id_type_usage]')?.value))
    return(<>
      <DialogTitle>Saisie des caractéristiques de l'appareil</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Deux options :<br/>
          - Coller les infos de la FI dans le champ ci-dessous<br/>
          - Ou choissir un type d'appareil dans le menu déroulant pour afficher et remplir les questions/réponses
        </DialogContentText>
        <Table>
        <TableHead>
            <TableRow>
            <TableCell>
              <InputLabel>Réponses brutes format FI</InputLabel>
              <TextField style={{paddingBottom: '2px'}}
                margin='dense'
                className={MoreClasses.inputFullWidth}
                //inputRef={register}
                id="reponsesFromDialog"
                name="reponsesFromDialog"
          //          required
                type="text"
              //  label="Réponses brutes format FI"
                variant="outlined"
                //value={state.reponses}
           //     error={'reponses' in errors}
                onChange={handleCommentsChg}
                InputProps={{defaultValue: /*state.reponses*/currReponses,}}
              />
            </TableCell>
            <TableCell><InputLabel>Sélection "questions" pour {usageName}</InputLabel>
            <Select /*inputRef={register}*/ title='Usage type' displayEmpty 
                defaultValue={(theQuestions?.slice(0) as unknown as QuestionsInstrumState)?.id} 
                label="Pattern" onChange={handleSelChge} margin="dense" variant="outlined" className={classes2.flexInput} 
                value={state.id_questions} name="id_questions">
                {theQuestions/*.filter((n, i) => Questions.indexOf(n) === i)*/.filter((qu) => qu.id_usage === state.id_type_usage)
                  .map((q, index) => (
                //q.usage_name.toLocaleLowerCase().includes(usageName.toLowerCase()) /*includes('Adoucisseur')*/ ? 
                    (index === 0 ?
                        <MenuItem key={q.id} value={q.id} selected>{usageName+" - NB Questions ["+q.questions.split(",").length+"] - Année "+q.year+" / Q"+q.id_fi}</MenuItem>
                        : <MenuItem key={q.id} value={q.id}>{usageName+" - NB Questions ["+q.questions.split(",").length+"] - Année "+q.year+" / Q"+q.id_fi}</MenuItem>
                    )
                ))}
                {questions_instrum.questions_instrums.filter((qu)=>Number(qu.id) === 137 || Number(qu.id) === 73).map((q) =>
                  <MenuItem key={q.id} value={q.id} selected>{"Non spécifique - NB Questions ["+q.questions.split(",").length+"] - Année "+q.year+" / Q"+q.id_fi}</MenuItem>
                )}
            </Select>
            </TableCell></TableRow>
{/*                <TableRow>
                <TableCell>CHAMP</TableCell>
                <TableCell>INFO</TableCell>
            </TableRow>*/}
        </TableHead>        <TableBody>
            <TextField
            //    inputRef={register}
                name="title"
                type="text"
                label="Equipement Title"
                variant="outlined"
                value={state.reponses}
                error={'title' in errors}
                //helperText={'title' in errors ? errors.title?.message : ''}
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    <TitleIcon color="primary" />
                    </InputAdornment>
                ),
                }}
                style={{display: 'none'}}
            />
            <TextField
            //    inputRef={register}
                name="thereponses"
                id="thereponses"
                type="text"
                label="Equipement Title"
                variant="outlined"
                value={state.reponses}
                error={'title' in errors}
                //helperText={'title' in errors ? errors.title?.message : ''}
                InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                    <TitleIcon color="primary" />
                    </InputAdornment>
                ),
                }}
                style={{display: 'none'}}
            />
            {//{theQuestions.find((q) => q.id === state.id_questions)?.questions.split(",").map((q, index) =>
            questions_instrum.questions_instrums.find((q) => q.id === state.id_questions)?.questions.split(",").map((q, index) =>
            <TableRow>
                <TableCell>{cleaner(q)}</TableCell>
                <TableCell>
                    {/*console.log("Question:"+q)*/}
                    {questions_instrum.questions_instrums.find((r) => r.id === state.id_questions)?.reponses.split(",")[index].includes("Texte_") ? 
                        <TextField
                        className="reponse"
                        type="text"
                        margin="dense"
                        id={"rep_"+index}
                        name={"rep_"+index}
                        onChange={handleChange}
                        variant="outlined"
                        InputProps={{defaultValue: /*theQuestions.find((s) => s.id === state.id_questions)?.reponses.split(",")[index] + "@" +*/cleaner(arrRep[index]),}}
                        />
                        : null}
                    {questions_instrum.questions_instrums.find((r) => r.id === state.id_questions)?.reponses.split(",")[index].includes("Année_") ? 
                        <TextField
                        className="reponse"
                        type="text"
                        margin="dense"
                        id={"rep_"+index}
                        name={"rep_"+index}
                        onChange={handleChange}
                        variant="outlined"
                        InputProps={{defaultValue: /*theQuestions.find((s) => s.id === state.id_questions)?.reponses.split(",")[index] + "@" +*/cleaner(arrRep[index]),}}
                        />
                        : null}
                    {questions_instrum.questions_instrums.find((r) => r.id === state.id_questions)?.reponses.split(",")[index].includes("Liste_") ?
                        (<Select defaultValue={arrRep[index]} displayEmpty id={"rep_"+index} name={"rep_"+index} onChange={handleSelChge}
                        margin="dense" variant="outlined" className={classes2.flexInput} /*value={state.rep[index]}*/ /*className="reponse"*/ /*value={arrRep[index]}*/>
                            {questions_instrum.questions_instrums.find((r) => r.id === state.id_questions)?.reponses.split(",")[index].replace("Liste_","").split("/").map((field) => (
                                cleaner(arrRep[index]) === cleaner(field) ? <MenuItem value={cleaner(field)} selected><em>{cleaner(field)}</em></MenuItem>
                                : <MenuItem value={cleaner(field)}>{cleaner(field)}</MenuItem>
                            ))}
                        </Select>)
                        : null
                    }
                </TableCell>
            </TableRow>
            )}
        </TableBody>
    </Table>
    </DialogContent></>
    );
  }
  const [alertEquipement,setAlertEquipement] = useState("")
  const isDeviceOkay = () => {
// TODO: chkDates too : case !editMode "changement" 
    if(state.id_type_usage === 0) {
      setAlertEquipement("Veuillez sélectionner un Usage")
      return false
    }
    if(state.id_piece === 0) {
      setAlertEquipement("Veuillez sélectionner une Pièce")
      return false
    }
    if(!isEditMode && !newEquip && selectedAppScenario === "chgmtapp") {
      if (document.querySelector<HTMLInputElement>('input[name=app_date_chgmt]')?.value === "") {
        setAlertEquipement("Veuillez indiquer une date de \"changement\" valide")
        return false
      }
    }
    if(!isEditMode && !newEquip && selectedAppScenario === "end") {
      if (document.querySelector<HTMLInputElement>('input[name=app_date_end]')?.value === "") {
        setAlertEquipement("Veuillez indiquer une date de \"fin d'utilisation\" valide")
        return false
      }
    }
    return true
  }
  const handleCreateEquipement = (data: EquipementPayload) => {
    if( isDeviceOkay() ) {
      chkDates();
      if( state.id_type_usage === 342 || state.id_type_usage === 343 ) {
        if(state.lightsUsageTime === "") {
          strRep = "_" + "|" + state.technoLights + "," + state.nbLights + "," + state.powerLights;
        } else {
          strRep = state.lightsUsageTime + "|" + state.technoLights + "," + state.nbLights + "," + state.powerLights;
        }
//        strRep = data.lightsUsageTime + "|" + state.technoLights + "," + data.nbLights + "," + data.powerLights;
        setState({ ...state, ["reponses"]: strRep });
        data.reponses = strRep
      //  console.log("strRep LIGHTS in Data: "+data.reponses)
      }
      dispatch(createNewEquipement(siteId, data, closeDialog));
      dispatch(fetchEquipementsBySitesSn(siteSn));
      dispatch(fetchVoiesBySitesSn(siteSn));
    }
  };
  idApp = currentData?.id/*_equip*/;
  const handleUpdateEquipementDB = (data: EquipementPayload) => {
    chkDates();
    if( state.id_type_usage === 342 || state.id_type_usage === 343 ) {
      strRep = state.lightsUsageTime + "|" + state.technoLights + "," + state.nbLights + "," + state.powerLights;
      setState({ ...state, ["reponses"]: strRep });
      data.reponses = strRep
    //  console.log("strRep LIGHTS in Data: "+data.reponses)
    }
    dispatch(editEquipementDB(currentData?.id/*App*/ as string, /*currentData?.site_id*/currSite_id/* as string*/, data, closeDialog));
    dispatch(fetchEquipementsBySitesSn(siteSn));
    dispatch(fetchVoiesBySitesSn(siteSn));
  //  showDialog(true,true);
  };
  const handleUpdateEquipement = (data: EquipementPayload) => {
    if( isDeviceOkay() ) {
      chkDates();
      if( state.id_type_usage === 342 || state.id_type_usage === 343 ) {
        strRep = state.lightsUsageTime + "|" + state.technoLights + "," + state.nbLights + "," + state.powerLights;
        setState({ ...state, ["reponses"]: strRep });
        data.reponses = strRep
      //  console.log("strRep LIGHTS in Data: "+data.reponses)
      }
      // TODO: PB => ne peut faire ce dispatch que si l'ID de l'équipement est déjà présent dans la liste... faire un FectchEquipBySiteId AVANT ?
      dispatch(editEquipement(currentData?.id/*App*/ as string, /*currentData?.site_id*/currSite_id/* as string*/, data, closeDialog));
  //    dispatch(fetchEquipementsBySitesSn(siteSn));
      dispatch(fetchEquipementsBySitesSn(siteSn));
      dispatch(fetchVoiesBySitesSn(siteSn));
  //  showDialog(true,true);
    }
  };
  const [alertSuivi,setAlertSuivi] = useState("")
  const isSuiviOkay = () => {
    if(!isEditMode && !newEquip && dbleSuivi) {
      if (document.querySelector<HTMLInputElement>('input[name=voie_date_chgmt]')?.value === "") {
        setAlertSuivi("Veuillez indiquer une date valide pour le début du suivi multiple")
        return false
      }
    }
    if(!isEditMode && !newEquip && selectedVoieScenario=== "chgmtvoie") {
      if (document.querySelector<HTMLInputElement>('input[name=voie_date_chgmt]')?.value === "") {
        setAlertSuivi("Veuillez indiquer une date de \"changement\" valide")
        return false
      }
    }
    if(!isEditMode && !newEquip && selectedVoieScenario === "endvoie") {
      if (document.querySelector<HTMLInputElement>('input[name=voie_date_end]')?.value === "") {
        setAlertSuivi("Veuillez indiquer une date de fin de suivi valide")
        return false
      }
    }
    if(!isEditMode && !newEquip && selectedVoieScenario === "refollow") {
      if (document.querySelector<HTMLInputElement>('input[name=voie_refollow]')?.value === "") {
        setAlertSuivi("Veuillez indiquer une date valide pour la reprise du suivi")
        return false
      }
    }
    if(!isEditMode && !newEquip) {
      if (Number(document.querySelector<HTMLInputElement>('input[name=id_module]')?.value) > 7999 && document.querySelector<HTMLInputElement>('input[name=type_sensor]')?.value === "MV") {
        setAlertSuivi("Seuls des WS/WP/TM/HY peuvent être liés à un module radio")
        return false
      }
      if (Number(document.querySelector<HTMLInputElement>('input[name=id_module]')?.value) < 8000 && document.querySelector<HTMLInputElement>('input[name=type_sensor]')?.value !== "MV") {
        setAlertSuivi("Le type de suivi ne correspond pas au module sélectionné")
        return false
      }
      if ( (document.querySelector<HTMLInputElement>('input[name=type_sensor]')?.value === "WS" 
        || document.querySelector<HTMLInputElement>('input[name=type_sensor]')?.value === "WP"
        || document.querySelector<HTMLInputElement>('input[name=type_sensor]')?.value === "HY"
        || document.querySelector<HTMLInputElement>('input[name=type_sensor]')?.value === "TM" )
        && document.querySelector<HTMLInputElement>('input[name=id_sensor]')?.value === "") {
        setAlertSuivi("SVP veuillez indiquer un SN de capteur pour les WS/WP/TM/HY")
        return false
      }
    }
    
/*    dbleSuivi === true  à ajouter => voie_date_chgmt
    Date du changement = chgmtvoie => voie_date_chgmt
    Fin du suivi = endvoie => voie_date_end
    Date de reprise = refollow => voie_refollow*/
    return true
  }
  const handleCreateVoie = (data: Voie2mesurePayload) => {
    if(isSuiviOkay()) {
      chkDates();
      isEditMode ? dispatch(createVoie2mesureDB(idApp as string, siteId as string, data, closeDialog))
      : dispatch(createVoie2mesure(idApp as string, siteId as string, data, closeDialog))
      dispatch(fetchEquipementsBySitesSn(siteSn));
      dispatch(fetchVoiesBySitesSn(siteSn));
// TODO: be careful here is the solution ??
    }
  };
  idApp = currentData?.id/*_equip*/;
  const handleUpdateVoie = (data: Voie2mesurePayload) => {    // TODO: rendre différent de la version "DB"
    if(isSuiviOkay()) {
    //  console.log("the STOP-FLAG: "+stopFlag);
      console.log("handleUpdateVoie CALLED")
      chkDates();
        // TODO: use data.id_voie ?????????????? dispatch(editVoie2mesure(data.id_voie, idApp as string, currentData?.site_id as string, data, closeDialog));
      dispatch(editVoie2mesure(currentData?.id/*App*/ as string, idApp as string, currentData?.site_id as string, data, closeDialog));
      dispatch(fetchEquipementsBySitesSn(siteSn));
      dispatch(fetchVoiesBySitesSn(siteSn));
    }
  };
  const handleUpdateVoieDB = (data: Voie2mesurePayload) => {
  //  console.log("STOP-FLAG: "+stopFlag);
    if(isSuiviOkay()) {
      chkDates();
      // Si la date de fin était vide et maintenant renseignée => endvoie
      console.log("DEBUG routing updateVoieDB: "+currVoie_date_end+" | "+data.voie_date_end+" = "+String(data.voie_date_end))
      if ( currVoie_date_end === "1970-01-01T1:00" && String(data.voie_date_end) !== "" ) { data.action = "endvoie" }
      // Si la date de fin était vide et le reste => chgmtvoie
      if ( currVoie_date_end === "1970-01-01T1:00" && String(data.voie_date_end) === "" ) { data.action = "chgmtvoie" }
      // Si la date de fin était renseignée et maintenant vide => refollow
      if ( currVoie_date_end !== "1970-01-01T1:00" && String(data.voie_date_end) === "" ) { data.action = "refollow" }
      dispatch(editVoie2mesureDB(String(data.id_voie)/*currentData?.id as string*/, idApp as string, currentData?.site_id as string, data, closeDialog));
      dispatch(fetchEquipementsBySitesSn(siteSn));
      dispatch(fetchVoiesBySitesSn(siteSn));
    }
  };
  console.log("Edit mode set to [ "+isEditMode+" ]");    // correction VS évolution de l'instrum

  const lightTypes = [{ value: "LED", index: 0 },{ value: "Halogène", index: 1},{ value: "CFL", index: 2},
    { value: "Fluorescent", index: 3},{ value: "Incandescent", index: 4},{ value: "Halogène BT", index: 5}]
/*  let light = "no";
  if(currentData.id_type_usage === 342) {
    light = "plafonnier"
  } 
  if(currentData.id_type_usage === 343) {
    console.log("that's a LIGHT");
    light = "prise"
  }*/
  const strLights = currReponses.split("|")
  let tempsLight = strLights[0]
  if(strLights.length > 1) {
    let subStrLights = strLights[1].split(",")
    let technoStr = strLights[1].split(",")[0]
  //  console.log("bad PLACE !!")
  }
  else {
    let subStrLights = "";
    let technoStr = "";
  }
  console.log("Lights DATA: "+currTechnoLights+":"+currNbLights+":"+currLightsUsageTime+":"+currPwLights)

  const showDialog = (device: boolean, suivi: boolean) => {
    return (
      <Paper className={classes2.root}>
      <Table className={ newEquip ? MoreClasses.appChgTableAdd :
        isEditMode ? 
        MoreClasses.appChgTableEdit : MoreClasses.appChgTableEvol
        }>
        <TableHead /*style={{
          paddingTop: '0 important',
          backgroundColor: newEquip ? '#005fd2' : isEditMode ? '#ff402c' : '#c51325'
          //</Table>#ff402c #c51325 #005fd2
          }}*/>
        <TableRow>
          <TableCell align='center' style={{ display: cas.includes("device") || true ? 'table-cell' : 'none', maxWidth: !cas.includes("device") ? '0rem ' : 'inherit'/*, background: !cas.includes("device") ? 'grey' : 'white'*/ }} className={MoreClasses.headerAppMod}><h2>Appareil</h2></TableCell>
          <TableCell align='center' style={{ display: newEquip /*|| cas.includes("device") || true|| !showSuivi*/ ? 'none' : 'table-cell', borderLeft: '3px dashed #333' }}
          /*style={{
            borderLeft: '3px dashed white',
          }}*/><h2>Suivi</h2></TableCell>
        </TableRow>
        </TableHead>
        <TableBody>
        <TableRow>
        { cas.includes("device") || true ?
        <TableCell style={{
            width: '62%',
          }}>
      <form
        onSubmit={handleSubmit(newEquip ? handleCreateEquipement : isEditMode ? handleUpdateEquipementDB : handleUpdateEquipement)}
        //onSubmit={handleSubmit(newEquip ? handleCreateEquipement : isEditMode ? handleUpdateEquipementDB : handleUpdateEquipement)}
      >
        <TextField
          inputRef={register}
          name="id"
          value={idApp}
          hidden
          style={{display: 'none'}}
        />
        <TextField
          inputRef={register}
          name="site_id"
          type="text"
          variant="outlined"
          value={selectedVoie?.site_id/*currSite_id*/}
          error={'title' in errors}
          //helperText={'title' in errors ? errors.title?.message : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TitleIcon color="primary" />
              </InputAdornment>
            ),
          }}
          style={{display: 'none'}}
        />
        <TextField
          inputRef={register}
          name="site_sn"
          type="text"
          variant="outlined"
          value={siteSn}
          error={'title' in errors}
          //helperText={'title' in errors ? errors.title?.message : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TitleIcon color="primary" />
              </InputAdornment>
            ),
          }}
          style={{display: 'none'}}
        />
        <TextField
          inputRef={register}
          name="title"
          type="text"
          label="Equipement Title"
          variant="outlined"
          value="toto"
          error={'title' in errors}
          //helperText={'title' in errors ? errors.title?.message : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <TitleIcon color="primary" />
              </InputAdornment>
            ),
          }}
          style={{display: 'none'}}
        />
        <TextField
          className={classes.fieldMargin}
          multiline
          rows={1}
          rowsMax={4}
          inputRef={register}
          name="description"
          fullWidth
          type="text"
          value="descTiti"
          label="Description"
          variant="outlined"
          error={'description' in errors}
          //helperText={'description' in errors ? errors.description?.message : ''}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SubjectIcon color="primary" />
              </InputAdornment>
            ),
          }}
          style={{display: 'none'}}
        />
        <Controller
          control={control}
          name="priority"
          as={
            <FormControl className={classes.radioGroupForm}>
              <RadioGroup row defaultValue="low" className={classes.radioGroup}>
                <FormLabel className={classes.radioGroupLabel}>
                  Priority:
                </FormLabel>
                <div className={classes.formControlLabels}>
                  <FormControlLabel
                    value="low"
                    control={<Radio color="primary" />}
                    label="Low"
                  />
                  <FormControlLabel
                    value="medium"
                    control={<Radio color="primary" />}
                    label="Medium"
                  />
                  <FormControlLabel
                    value="high"
                    control={<Radio color="primary" />}
                    label="High"
                  />
                </div>
              </RadioGroup>
            </FormControl>
          }
          style={{display: 'none'}}
        />
        <table className="suiviinputs" style={{display: 'block'}}>
        <tbody>
          <tr>
            <td>
        <InputLabel shrink /*className={classes.flexInput}*/>Pièce</InputLabel>
        <Select inputRef={register} /*title='Pièce'*/ defaultValue={currentPieceId/*pieces[currentPieceId - 1].id*/} displayEmpty label="Pièce" onChange={/*handleChange*/handleChangeBack2} 
          margin="dense" variant="outlined" className={classes2.flexInput} value={state.id_piece} name="id_piece">
          {pieces.map((u) => (
            Number(u.id) !== (currentPieceId) ? <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem> : <MenuItem key={u.id} value={u.id} selected><em>{u.name}</em></MenuItem>
          ))}
        </Select></td><td>
        <InputLabel shrink /*className={classes.flexInput+classes.btnsWrapper}*/>Usage</InputLabel>
        <Select inputRef={register} title="Usage" /*defaultValue={usages[currentUsageId - 1].id}*/ displayEmpty label="Usage" onChange={handleChangeBack2} 
          margin="dense" variant="outlined" className={classes2.flexInput} value={state.id_type_usage} name="id_type_usage">
          {usages.map((u) => (
            Number(u.id) !== (currentUsageId) ? <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem> : <MenuItem key={u.id} value={u.id} selected><em>{u.name}</em></MenuItem>
          ))}
        </Select></td><td>
        <InputLabel shrink /*className={classes.flexInput+classes.btnsWrapper}*/>Poste</InputLabel>
        <Select inputRef={register} title="Poste" defaultValue={postes[currentPosteId - 1].id} displayEmpty label="Poste" onChange={handleChangeBack2} /*id="id_poste"*/
          margin="dense" variant="outlined" className={classes2.flexInput} value={state.id_poste} name="id_poste">
          {postes.map((u) => (
            Number(u.id) !== (currentPosteId) ? <MenuItem key={u.id} value={u.id}>{u.name}</MenuItem> : <MenuItem key={u.id} value={u.id} selected><em>{u.name}</em></MenuItem>
          ))}
        </Select></td></tr>
        <TextField
          inputRef={register}
          name="id_type_usage"
          value={state.id_type_usage}
          hidden
          style={{display: 'none'}}
        />
        <TextField
          inputRef={register}
          name="suivi"
          value={state.suivi}
          hidden
          style={{display: 'none'}}
        />
        <TextField
          inputRef={register}
          name="a_suivre"
          value={state.a_suivre}
          hidden
          style={{display: 'none'}}
        />
        <TextField
          inputRef={register}
          name="id_poste"
          value={state.id_poste}
          hidden
          style={{display: 'none'}}
        />
        <TextField
          inputRef={register}
          name="id_piece"
          value={state.id_piece}
          hidden
          style={{display: 'none'}}
        />
        <TextField
          name="reference"
          value={currReference}
          hidden
          style={{display: 'none'}}
        />
        <tr style={{paddingTop: '4px'}}><td>
        <InputLabel shrink /*className={classes.flexInput}*/>Précision pièce</InputLabel>
        <TextField margin='dense'
          style={{marginTop: '0'}}
          className={MoreClasses.inputFullWidth}
          inputRef={register}
          name="str_piece"
          type="text"
//          label="Précision pièce"
          variant="outlined"
          error={'commentaires' in errors}
          //helperText="Commentaires"/*{'tel' in errors ? errors.tel?.message : ''}*/
          defaultValue={currStr_piece}
          //value={currComments.map((com)=>String(com)+"\n")}
          onChange={handleCommentsChg}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SubjectIcon color="primary" />
              </InputAdornment>
            ),
          }}
        /></td>
        <td>
          <InputLabel shrink /*className={classes.flexInput}*/>Associer au circuit élec.</InputLabel>
          <Select inputRef={register} /*title='Pièce'*/ defaultValue={currentIdCircuit/*pieces[currentPieceId - 1].id*/} displayEmpty label="Circuit" onChange={/*handleChange*/handleChangeBack2} 
            margin="dense" variant="outlined" className={classes2.flexInput} value={state.id_circuit} name="id_circuit">
              <MenuItem key="circ_0" value="null">-</MenuItem>
              <MenuItem key="circ_1" value="1">1</MenuItem>
              <MenuItem key="circ_2" value="2">2</MenuItem>
              <MenuItem key="circ_3" value="3">3</MenuItem>
              <MenuItem key="circ_4" value="4">4</MenuItem>
              <MenuItem key="circ_5" value="5">5</MenuItem>
          </Select>
        </td></tr>
        {state.id_type_usage > 341 && state.id_type_usage < 345/* && state.id_type_usage < 344*/ ?
        <tr style={{paddingTop: '8px'}}><td><TextField margin='dense'
          className={MoreClasses.inputFullWidth}
          inputRef={register}
          name="reponses"
  //          required
          type="text"
          label="Réponses Q° instrum"
          variant="outlined"
          value={state.reponses}
          error={'reponses' in errors}
          /*helperText="Réponses Q° instrum"*//*{'hardSn' in errors ? errors.hardSn?.message : ''}*/
          InputProps={{defaultValue: state.reponses/*currReponses*/,}}
          //InputProps={{defaultValue: state.reponses,}}
          style={{display: 'none'}}
        /></td></tr> : <tr><td><TextField margin='dense'
        className={MoreClasses.inputFullWidth}
        inputRef={register}
        name="reponses"
  //          required
        type="text"
        label="Réponses Q° instrum"
        variant="outlined"
        value={state.reponses}
        error={'reponses' in errors}
        InputProps={{defaultValue: state.reponses/*currReponses*/,}}
      /></td><td>
      </td></tr>
      }
        <tr>{ /*state.id_type_usage > 341 && state.id_type_usage < 344 &&*/ state.id_type_usage === 343 ?
        <td><><TextField margin='dense'
          className={MoreClasses.tiersWidth}
    //      inputRef={register}
          name="lightsUsageTime"
  //          required
          type="text"
          label="Durée usage /jour (minutes)"
          variant="outlined"
          value={state.lightsUsageTime}
          onChange={handleChange}
          //defaultValue={currLightsUsageTime}
        //  value={state.lightsUsageTime}
          error={'reponses' in errors}
          style={{marginTop: '1.1em'}}
        /></></td> : null }
        { state.id_type_usage === 342 ?
          <><TextField margin='dense'
          className={MoreClasses.tiersWidth}
     //     inputRef={register}
          name="lightsUsageTime"
    //          required
          type="text"
          label="Durée usage /jour (minutes)"
          variant="outlined"
          defaultValue={"_"}
          value={"_"}
          error={'reponses' in errors}
          /*helperText="Réponses Q° instrum"*//*{'hardSn' in errors ? errors.hardSn?.message : ''}*/
          style={{display: 'none'}}
        /></> : null }
        { state.id_type_usage === 342 || state.id_type_usage === 343 ?
          <><td><InputLabel shrink /*className={classes.flexInput+classes.btnsWrapper}*/>Technologie lumière</InputLabel>
          <Select inputRef={register} /*title="Technologie lumière"*/ defaultValue={currTechnoLights} displayEmpty label="Usage" onChange={handleChangeBack2} 
            margin="dense" variant="outlined" className={classes2.flexInput} value={state.technoLights} name="technoLights">
              {currTechnoLights.length ?
                lightTypes.map((l) =>
                  l.value === strLights[1].split(",")[0] ?
                    <MenuItem key={l.index} value={l.value} selected><em>{l.value}</em></MenuItem>
                  : <MenuItem key={l.index} value={l.value}>{l.value}</MenuItem> )
                : lightTypes.map((l) => <MenuItem key={l.index} value={l.value}>{l.value}</MenuItem> )
              }
          </Select></td>
          <td><TextField margin='dense'
            className={MoreClasses.tiersWidth}
      //      inputRef={register}
            name="nbLights"
    //          required
            type="text"
            //value={state.nbLights}
            value={state.nbLights}
            label="Nombre"
            variant="outlined"
            onChange={handleChange}
            error={'reponses' in errors}
            /*helperText="Réponses Q° instrum"*//*{'hardSn' in errors ? errors.hardSn?.message : ''}*/
            style={{marginTop: '1.1em'}}
            //defaultValue={currLightsUsageTime}
        //  value={state.lightsUsageTime}
            InputProps={{
              defaultValue: currNbLights,
              startAdornment: (
                <InputAdornment position="start">
                  <SubjectIcon color="primary" />
                </InputAdornment>
              ),
            }}
          /></td><td><TextField margin='dense'
            className={MoreClasses.tiersWidth}
       //     inputRef={register}
            name="powerLights"
    //          required
            type="text"
            value={state.powerLights/*currPwLights*/}
            //defaultValue={powerLights}
            label="Puissance (W)"
            variant="outlined"
            onChange={handleChange/*PwLights*/}
            error={'reponses' in errors}
            style={{marginTop: '1.1em'}}
          />
          </td></> : null }
          </tr>
        <TextField
          name="id_questions"
          value={currId_questions}
          hidden
          style={{display: 'none'}}
        />
        <tr style={{paddingTop: '8px'}}><td><TextField margin='dense'
          className={MoreClasses.tiersWidth}
          rows={1}
          rowsMax={1}
          inputRef={register}
          name="nrg_class"
          type="text"
          label="Classe Énergétique"
          variant="outlined"
          onChange={handleChange}
          /*helperText="N° téléphone equipement"{'tel' in errors ? errors.tel?.message : ''}*/
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SubjectIcon color="primary" />
              </InputAdornment>
            ),
          }}
          /></td><td>
          <TextField margin='dense'
            className={MoreClasses.tiersWidth}
            rows={1}
            rowsMax={1}
            inputRef={register}
            name="normalized_pw"
            type="text"
            label="Conso Élec normalisée"
            variant="outlined"
            onChange={handleChange}
            /*helperText="N° téléphone equipement"{'tel' in errors ? errors.tel?.message : ''}*/
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SubjectIcon color="primary" />
                </InputAdornment>
              ),
            }}
          /></td><td>
          <TextField margin='dense'
            className={MoreClasses.tiersWidth}
            rows={1}
            rowsMax={1}
            inputRef={register}
            name="water_consumption"
            type="text"
            label="Consommation d'eau"
            variant="outlined"
            onChange={handleChange}
            /*helperText="N° téléphone equipement"{'tel' in errors ? errors.tel?.message : ''}*/
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SubjectIcon color="primary" />
                </InputAdornment>
              ),
            }}
          /></td></tr>
          <tr style={{paddingTop: '8px'}}><TextField margin='dense'
            className={MoreClasses.inputFullWidth}
            multiline
            rows={1}
            rowsMax={4}
            inputRef={register}
            name="comments"
            type="text"
            label="Commentaires"
            variant="outlined"
            error={'commentaires' in errors}
            //helperText="Commentaires"/*{'tel' in errors ? errors.tel?.message : ''}*/
            defaultValue={currComments}
            //value={currComments.map((com)=>String(com)+"\n")}
            onChange={handleCommentsChg}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SubjectIcon color="primary" />
                </InputAdornment>
              ),
            }}
          />
          </tr>
          <tr><td></td></tr></tbody></table>
          { !isEditMode && !newEquip ?
            <FormControl style={{display: 'inline-flex', margin: '0.2em 0'}}>
                <RadioGroup style={{display: 'inline'}}
                  aria-labelledby="radio-buttons-group-label"
                  defaultValue="chgmtapp"
                  name="radio-buttons-group"
                  onChange={radioHandlerApp}
                >
                  <FormControlLabel value="chgmtapp" control={<Radio />} label="Changement&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
                  <FormControlLabel value="end" control={<Radio />} label="Fin d'utilisation" />
                  { selectedAppScenario === "chgmtapp" ?
                    <Tooltip title={<><p style={{display: 'block', fontSize: '1rem', lineHeight: '1.43'}}>Un "changement" entraine la duplication d'appareil en prenant en compte les nouvelles valeurs.<br/>
                    La date de changement indiquée sera la date de début de l'appareil avec les nouvelles caractéristiques.<br/>
                    Si l'appareil était suivi, la voie de suivi continue de suivre l'appareil avec les nouvelles caractéristiques.</p></>}>
                      <InfoIcon style={{top: '0.2rem', position: 'relative'}} /> 
                    </Tooltip>
                    : <Tooltip title={<><p style={{display: 'block', fontSize: '1rem', lineHeight: '1.43'}}>Une "fin d'utilisation" entraine également l'arrêt de la (ou les) voie(s) qui suivent l'appareil.</p></>}>
                      <InfoIcon style={{top: '0.2rem', position: 'relative'}} /> 
                    </Tooltip>
                  }
                </RadioGroup>
              </FormControl> : null }
              <div style={{display: 'block', margin: '0.2em 0', minWidth: '80%', maxWidth: '100%'}}>
                { !isEditMode && !newEquip && selectedAppScenario === "chgmtapp" ?  // propose de dater les changements d'infos pr évolution de l'instrum
                <>
                <div
                    //  className="rainbow-align-content_center rainbow-m-vertical_large rainbow-p-horizontal_small rainbow-m_auto"
                    //className={classes.flexInput}
                    className={MoreClasses.dateInput}
                    /*style={containerStyles}*/ style={{ /*minWidth: '80%',*/ maxWidth: '100%'}}
                  >
                  <TextField variant="outlined" margin='dense' className={MoreClasses.dateInput}
                    inputRef={register}
                    id="app-datetime-start"
                    name="app_date_start"
                    label="Début d'utilisation"
                    type="datetime-local"
                    //{currDate_start !== null ? defaultValue={format(new Date(currDate_start), "yyyy-MM-dd'T'h':'mm") : null} : null}
                    //@ts-ignore
                    defaultValue={currDate_start/* !== null ? format(new Date(currDate_start), "yyyy-MM-dd'T'h':'mm") : ""*/}
                    //defaultValue={}
                    onChange={handleDateChg}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <TextField variant="outlined" margin='dense' className={MoreClasses.dateInput}
                    inputRef={register}
                    id="app-datetime-chgmt"
                    name="app_date_chgmt"
                    label={dbleSuivi === false ? "Date du changement" : "Début suivi à ajouter"}
                    type="datetime-local"
                    //@ts-ignore
                    defaultValue={currDate_end /*!== null ? format(new Date(currDate_end), "yyyy-MM-dd'T'h':'mm") : ''*/}
                    onChange={handleDateChg}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /></div></> :
                  <><div
                      className={MoreClasses.dateInput}
                      style={containerStyles}
                    >
                    { !isEditMode && !newEquip ?
                    <><TextField variant="outlined" margin='dense' className={MoreClasses.dateInput}
                    inputRef={register}
                    id="app-datetime-start"
                    name="app_date_start"
                    label="Début d'utilisation"
                    type="datetime-local"
                    //{currDate_start !== null ? defaultValue={format(new Date(currDate_start), "yyyy-MM-dd'T'h':'mm") : null} : null}
                    //@ts-ignore
                    defaultValue={currDate_start/* !== null ? format(new Date(currDate_start), "yyyy-MM-dd'T'h':'mm") : ""*/}
                    //defaultValue={}
                    onChange={handleDateChg}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>
                  : <><TextField variant="outlined" margin='dense' className={MoreClasses.dateInput}
                    inputRef={register}
                    id="app-datetime-start"
                    name="app_date_start"
                    label="Début d'utilisation"
                    type="datetime-local"
                    //{currDate_start !== null ? defaultValue={format(new Date(currDate_start), "yyyy-MM-dd'T'h':'mm") : null} : null}
                    //@ts-ignore
                    defaultValue={currDate_start/* !== null ? format(new Date(currDate_start), "yyyy-MM-dd'T'h':'mm") : ""*/}
                    //defaultValue={}
                    onChange={handleDateChg}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /></>
                  }
                 {/* <DatePicker label="Event date" />*/}
                  <TextField variant="outlined" margin='dense' className={MoreClasses.dateInput}
                    inputRef={register}
                    id="app-datetime-end"
                    name="app_date_end"
                    label="Fin d'utilisation"
                    type="datetime-local"
                    //defaultValue="Nan"
                    //@ts-ignore
                    defaultValue={currDate_end/*!== null ? format(new Date(currDate_end), "yyyy-MM-dd'T'h':'mm") : ''*/}
                    onChange={handleDateChg}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /></div></> }
              {/*}<TimePicker onChange={onChange} defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')} />*/}
              {/*<TimePicker.RangePicker />*/}
              <TextField margin='dense'
                className={MoreClasses.tiersWidth}
                rows={1}
                rowsMax={1}
                inputRef={register}
                name="pw_veille"
                type="text"
                label="Puiss. veille"
                variant="outlined"
                defaultValue={currPwVeille}
                onChange={handleChange}
                /*helperText="N° téléphone equipement"{'tel' in errors ? errors.tel?.message : ''}*/
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SubjectIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                style={{display: 'inline-flex', marginLeft: '1.5em', marginRight: '0', maxWidth: '35%', minWidth: '35%'}}
              />
            </div>
              {/*<p style={{display: 'block', marginLeft: '1.5em', marginRight: '0px', maxWidth: '100%', minWidth: '75%',}}>
                NB: Si vous n'avez pas les réponses aux questions à copier-coller (venant d'une FI),<br/>vous pourvez les remplir via le menu de l'appareil =&gt; "Réponses aux questions"
              </p>*/}
          {/*<Button
            size="large"
            color="primary"
            variant="contained"
            fullWidth
            className={classes.submitBtn}
            type="submit"
            disabled={submitLoading}
          >
            {isEditMode ? 'Update Equipement' : 'Create New Equipement'}
          </Button>
          {submitError && (
            <ErrorBox
              errorMsg={submitError}
              clearErrorMsg={() => dispatch(clearSubmitEquipementError())}
            />
          )}*/}
          <div className={MoreClasses.div4buttons}>
          <Button
            size="medium"
            color="secondary"
            variant="contained"
            className={MoreClasses.buttons}
            type="submit"
       //     onClick={handleSubmit(handleUpdateEquipement/*submitUpdateEquip*/)}
            disabled={submitLoadingEquipement}
          >
            {newEquip ? 'Ajouter l\'équipement' : isEditMode ? 'Corriger les données "appareil"' : 'Prendre en compte les changements "appareil"'}
            {/*isEditMode ? 'MàJ info equipement' : 'Ajouter l\'équipement'*/}
        </Button></div>
        {submitErrorEquipement && (
          <ErrorBox
            errorMsg={submitErrorEquipement}
            clearErrorMsg={() => dispatch(clearSubmitEquipementError(null))}
          />
        )}
        {alertEquipement !== "" && (
          <ErrorBox style={{background: 'transparent'}}
            errorMsg={alertEquipement}
            clearErrorMsg={() => /*dispatch(clearSubmitEquipementError(null))*/setAlertEquipement("") }
          />
        )}
      </form>
      <div style={{textAlign: 'center', marginRight: '0'}}>
          <Fragment>
            <Button variant="outlined" onClick={handleClickOpenDialog} style={{padding: '0.55rem 1.28rem'}}>
              Réponses aux Questions appareil
            </Button>
            <Dialog
              fullWidth
              maxWidth={'md'}
              open={openDialog}
              onClose={handleCloseDialog}
              PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                  event.preventDefault();
                //  const formData = new FormData(event.currentTarget as unknown as HTMLFormElement);
                /*  const formJson = Object.fromEntries(formData.entries());
                  const email = formJson.email;
                  console.log(email);/*/
                  handleCloseDialog();
                },
              }}
            >
            {showQRepFI()}
            <DialogActions style={{marginLeft: 'auto', marginRight: 'auto'}}>
              <Button style={{marginLeft: '0rem', marginRight: '1rem'}} onClick={handleCloseDialog}>Annuler</Button>
              <Button style={{marginLeft: '1rem', marginRight: '0rem'}} type="submit" onClick={getStrRep}>Valider</Button>
            </DialogActions>
          </Dialog>
        </Fragment>
      </div>
        </TableCell> : null }
            { !cas.includes("device") || true ?
            <TableCell style={{ display: newEquip ? 'none' : 'block', borderLeft: '3px dashed #333' }}>
            <form className={MoreClasses.demiWidth} /*onSubmit={handleSubmit(isEditMode ? handleUpdateVoie : handleCreateVoie)}*/
  //            onSubmit={handleSubmit(selectedVoie === undefined ? handleCreateVoie : isEditMode ? handleUpdateVoie : handleCreateVoie)} // STOP CASE !!!!!
              onSubmit={handleSubmit(
                selectedVoie?.id ?                                      // La VOIE existe ?
                (isEditMode ? handleUpdateVoieDB : handleUpdateVoie)      // MàJ VOIE
                : handleCreateVoie                                      // OU on créé la VOIE
  /*              selectedVoie?.id ? 
                  isEditMode ? handleUpdateVoieDB : handleUpdateVoie
                  : isEditMode ? handleCreateVoieDB : handleCreateVoie*/
                //!selectedVoie?.id ? handleCreateVoie : isEditMode && !selectedVoie?.id ? handleUpdateVoie : handleCreateVoie
                )}
              >
            <TextField
              inputRef={register}
              name="site_id"
              type="text"
              variant="outlined"
              value={currSite_id}
              error={'title' in errors}
              style={{display: 'none'}}
            />
            <TextField
              inputRef={register}
              name="site_sn"
              type="text"
              variant="outlined"
              value={currSite_sn}
              error={'title' in errors}
              style={{display: 'none'}}
            />
            <TextField
              inputRef={register}
              name="id_equipement"
              type="text"
              variant="outlined"
              value={idApp}
              error={'title' in errors}
              style={{display: 'none'}}
            />
            <TextField
              inputRef={register}
              name="id_voie"
              type="text"
              variant="outlined"
              value={state.id_voie}
              error={'title' in errors}
              style={{display: 'none'}}
            />
            <table className="suiviinputs" style={{display: 'block'}}>
              <tbody>
                <tr>
                  <td>
              <InputLabel shrink className={classes2.flexInput}>Concentrateur</InputLabel>
              <Select inputRef={register} margin="dense" variant="outlined" defaultValue={currId_conc} displayEmpty label="Concentrateur" onChange={handleChangeBack2} 
              className={classes2.flexInput} value={state.id_conc/*selectValue.id_conc*/} name="id_conc">
                <MenuItem key={currId_conc} value={String(currId_conc)}><em>{currId_conc}</em></MenuItem>
                {distinctConcSN.sort().map((v) => (
                  Number(v) !== (currId_conc) ? <MenuItem key={v} value={v}>{v}</MenuItem> 
                  : <MenuItem key={v} value={v} selected><em>{v}</em></MenuItem>
                ))}
              </Select></td>
              <td><InputLabel shrink className={classes2.flexInput}>Module</InputLabel>
              <Select inputRef={register} margin="dense" variant="outlined" defaultValue={currId_module} displayEmpty label="Module" onChange={handleChangeBack2} 
              className={classes2.flexInput} value={state.id_module} name="id_module">
                <MenuItem key={currId_module} value={String(currId_module)}><em>{currId_module}</em></MenuItem>
                {distinctModuleSN.sort().filter((mod) => mod !== "1").map((v) => (
                  Number(v) !== (currId_module) ? <MenuItem key={v} value={v}>{v}</MenuItem> 
                  : <MenuItem key={v} value={v} selected><em>{v}</em></MenuItem>
                ))}
              </Select></td>
              <td><InputLabel shrink className={classes2.flexInput}>N° voie</InputLabel>
              <Select inputRef={register} margin="dense" variant="outlined" defaultValue={currNumber} displayEmpty label="Numéro de voie" onChange={handleChangeBack2} 
              className={classes2.flexInput} value={state.number} name="number">
                <MenuItem key={currNumber} value={String(currNumber)}><em>{currNumber}</em></MenuItem>
                {numerosVoies.map((v) => (
                  Number(v) !== (currNumber) ? <MenuItem key={v} value={v}>{v}</MenuItem> 
                  : <MenuItem key={v} value={v} selected><em>{v}</em></MenuItem>
                ))}
              </Select></td></tr>
              <tr style={{paddingTop: '8px'}}><td><InputLabel shrink className={classes2.flexInput}>Type suivi</InputLabel>
              <Select inputRef={register} displayEmpty margin="dense" variant="outlined" defaultValue={currType_sensor} label="Type suivi" onChange={handleChangeBack2} 
              className={classes2.tiersWidth} value={state.type_sensor} name="type_sensor">
                <MenuItem key='0' value={currType_sensor}><em>{currType_sensor}</em></MenuItem>
                <MenuItem key='1' value={"WP"}>WP</MenuItem>
                <MenuItem key='2' value={"WS"}>WS</MenuItem>
                <MenuItem key='3' value={"MV"}>MV</MenuItem>
                <MenuItem key='4' value={"TM"}>TM</MenuItem>
                <MenuItem key='5' value={"HY"}>HY</MenuItem>
              </Select></td>
              { (state.type_sensor === "WS" || state.type_sensor === "WP" || state.type_sensor === "HY" || state.type_sensor === "TM") && !showNewSnInput && toggleWScase ?
              (<>
              <td><InputLabel shrink className={classes2.flexInput}>N° WP/WS/TM/HY</InputLabel>
              <Select inputRef={register} margin="dense" variant="outlined" displayEmpty defaultValue={currId_sensor} label="N° WP/WS/TM/HY" onChange={handleChangeBack2} 
              className={classes2.tiersWidth} value={state.id_sensor} name="id_sensor">
                <MenuItem key={currId_sensor} value={currId_sensor}><em>{currId_sensor}</em></MenuItem>
                {/*state.sensorSn.toString.length > 0 ?*/ distinctSensorSN.sort().map((v) => (
                  <MenuItem key={v} value={v}>{v}</MenuItem>
                ))}
                <MenuItem value={'addNewSensor'}>Ajouter un N° série</MenuItem>
              </Select></td></>) : null }
              <td>
              { (state.type_sensor === "WP" || state.type_sensor === "WS" || state.type_sensor === "HY" || state.type_sensor === "TM") && showNewSnInput && toggleWScase ?
            <TextField margin='dense'
              className={classes2.tiersWidth} 
              inputRef={register}
              onChange={handleChange}
              name="id_sensor"
              type="text"
              label="Nouveau SN capteur"
              variant="outlined"
            /> : null }
            { (state.type_sensor === "WP" || state.type_sensor === "WS") ? <>
              <InputLabel shrink className={classes2.flexInput}>Phase (WP only)</InputLabel>
              <Select inputRef={register} displayEmpty margin="dense" variant="outlined" defaultValue={currPhase} label="Phase (WP/WS)" onChange={handleChangeBack2} 
              className={classes2.tiersWidth} value={state.phase} name="phase">
                <MenuItem key='0' value={String(currPhase)}><em>{currPhase}</em></MenuItem>
                <MenuItem key='1' value={1}>1</MenuItem>
                <MenuItem key='2' value={2}>2</MenuItem>
              </Select></>: null }</td></tr>
              <tr><td style={{width: '100%'}}><Tooltip title="Pour la détection de rupture dans les données"><InputLabel shrink className={classes2.flexInput}>Fréquence de "contrôle"</InputLabel>
              </Tooltip>
              <Select inputRef={register} displayEmpty margin="dense" variant="outlined" defaultValue={currFreq_controle} label="Fréquence de 'contrôle'" onChange={handleChangeBack2} 
              className={MoreClasses.inputFullWidth} value={state.freq_controle} name="freq_controle">
                <MenuItem key={0} value={currFreq_controle}><em>{currFreq_controle}</em></MenuItem>
                <MenuItem key={255} value={255}>Pas de contrôle</MenuItem>
                <MenuItem key={1} value={1}>1</MenuItem>
                <MenuItem key={7} value={7}>7</MenuItem>
                <MenuItem key={14} value={14}>14</MenuItem>
                <MenuItem key={20} value={20}>20</MenuItem>
                <MenuItem key={30} value={30}>30</MenuItem>
                <MenuItem key={60} value={60}>60</MenuItem>
              </Select></td></tr>
              </tbody>
              </table>
              <TextField
                inputRef={register}
                name="id_conc"
                value={state.id_conc}
                hidden
                style={{display: 'none'}}
              />
              <TextField
                inputRef={register}
                name="concId"
                value={/*state.concHardSn*/conc.find((c) => c.hardSn === state.id_conc && c.modules.find((m) => m === state.id_module))?.id}
                hidden
                style={{display: 'none'}}
              />
              <TextField
                inputRef={register}
                name="id_module"
                value={state.id_module}
                hidden
                style={{display: 'none'}}
              />
              <TextField
                inputRef={register}
                name="number"
                value={state.number}
                hidden
                style={{display: 'none'}}
              />
              <TextField
                inputRef={register}
                name="type_sensor"
                value={state.type_sensor}
                hidden
                style={{display: 'none'}}
              />
              <TextField
                inputRef={register}
                name="id_circuit"
                value={state.id_circuit}
                hidden
                style={{display: 'none'}}
              />
              <TextField
                inputRef={register}
                name="id_sensor"
                value={state.id_sensor}
                hidden
                style={{display: 'none'}}
              />
              <TextField
                inputRef={register}
                name="phase"
                value={state.phase}
                hidden
                style={{display: 'none'}}
              />
              <TextField
                inputRef={register}
                name="freq_controle"
                value={state.freq_controle}
                hidden
                style={{display: 'none'}}
              />
              <TextField
                name="no_type"
                value={currNo_type}
                hidden
                style={{display: 'none'}}
            />
            <div style={{paddingTop: '8px'}}>
            <TextField margin='dense'
              className={classes2.flexInput}
              multiline
              rows={1}
              rowsMax={8}
              inputRef={register}
              name="commentaires"
              fullWidth
              type="text"
              label="Commentaires"
              variant="outlined"
              //error={'commentaires' in errors}
              //helperText="Commentaires"/*{'tel' in errors ? errors.tel?.message : ''}*/
              defaultValue={currCommentaires/*selectValue.comments*/}
              onChange={handleCommentsChg}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SubjectIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
            </div>
            { !isEditMode && lesVoieDuSite.filter((v)=>v.id_equipement === Number(currentData?.id)).length > 0 ?
            <FormControl style={{display: 'inline-flex', margin: '0.2em 0'}}>
              <FormGroup>
                {delDevice === true ? /* Si coché, passer le selectedVoieScenario à "chgmtvoie" et cacher les radioButtons */
                <><FormControlLabel inputRef={register} name="dbleSuivi" id="dbleSuivi" onChange={chkboxHandler} value={dbleSuivi} disabled control={<Checkbox disabled/>} label="Suivre sur plusieurs voies" /></>
                : <><FormControlLabel inputRef={register} name="dbleSuivi" id="dbleSuivi" onChange={chkboxHandler} value={dbleSuivi} control={<Checkbox />} label="Suivre sur plusieurs voies" /></> }
                {/*dbleSuivi === true ? // Si coché, passer le selectedVoieScenario à "endvoie" et cacher les radioButtons 
                <FormControlLabel inputRef={register} name="delDevice" id="delDevice" onChange={chkboxHandler} value={delDevice} control={<Checkbox disabled/>} label="Ne pas recréer l'appareil en non suivi" /> 
                : <FormControlLabel inputRef={register} name="delDevice" id="delDevice" onChange={chkboxHandler} value={delDevice} control={<Checkbox />} label="Ne pas recréer l'appareil en non suivi" /> */}
              </FormGroup>
            </FormControl> : null }
            <div>
            { ( delDevice === false || dbleSuivi === false ) && !isEditMode && /*currentData?.suivi*/ voiesDuSite.filter((v) => v.id_equipement === Number(currentData?.id)).length > 0 && !newEquip ?
              <FormControl style={{display: 'inline-flex', margin: '0.2em 0'}}>
                <RadioGroup style={{display: 'inline'}}
                  aria-labelledby="radio-buttons-group-label"
                  defaultValue="chgmtvoie"
                  name="radio-buttons-group"
                  onChange={radioHandlerApp2}
                >
                { delDevice === false && dbleSuivi === false ?
                  <>
                  { selectedVoieScenario === "chgmtvoie" ?
                  <FormControlLabel value="chgmtvoie" control={<Radio checked />} label="Changement&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" />
                  : <FormControlLabel value="chgmtvoie" control={<Radio checked={false}/>} label="Changement&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;" /> }
                  { selectedVoieScenario === "endvoie" ?
                  <FormControlLabel value="endvoie" control={<Radio checked/>} label="Fin du suivi" />
                  : <FormControlLabel value="endvoie" control={<Radio checked={false}/>} label="Fin du suivi" /> }
                  { selectedVoieScenario === "refollow" ?
                  <FormControlLabel value="refollow" control={<Radio checked/>} label="Suivre à nouveau" />
                  : <FormControlLabel value="refollow" control={<Radio checked={false}/>} label="Suivre à nouveau" /> }
                  { selectedVoieScenario === "refollow" ?
                  <Tooltip title={<><p style={{display: 'block', fontSize: '1rem', lineHeight: '1.43'}}>Si le suivi sur cette voie était arrêté, renseignez une date de reprise du suivi, les paramètres seront pris en compte.</p></>}>
                    <InfoIcon style={{top: '0.2rem', position: 'relative'}} /> 
                  </Tooltip>
                  : null }
                  { selectedVoieScenario === "chgmtvoie" ?
                  <Tooltip title={<><p style={{display: 'block', fontSize: '1rem', lineHeight: '1.43'}}>Si le suivi est en cours, renseignez la date de changement ci-dessous pour valider les nouveaux paramètres.</p></>}>
                    <InfoIcon style={{top: '0.2rem', position: 'relative'}} /> 
                  </Tooltip>
                  : null }
                  { selectedVoieScenario === "endvoie" ?
                  <Tooltip title={<><p style={{display: 'block', fontSize: '1rem', lineHeight: '1.43'}}>Une "fin du suivi" entraine également l'arrêt de l'appareil, sauf si l'appareil est suivi sur d'autres voies encore actives.</p></>}>
                    <InfoIcon style={{top: '0.2rem', position: 'relative'}} /> 
                  </Tooltip>
                  : null }
                  </>: null }
                </RadioGroup>
              </FormControl> : null }
              <div style={{display: 'block', margin: '0.2em 0', paddingTop: '8px'}}>
                { !isEditMode && voiesDuSite.filter((v) => v.id_equipement === Number(currentData?.id)).length > 0 && !newEquip && selectedVoieScenario === "chgmtvoie" ?  // propose de dater les changements d'infos pr évolution de l'instrum
                <>
                <div
                    className={MoreClasses.dateInput}
                    style={containerStyles}
                  >
                  <TextField variant="outlined" margin='dense' className={MoreClasses.dateInput}
                    inputRef={register}
                    id="voie-datetime-start"
                    name="voie_date_start"
                    label="Début du suivi"
                    type="datetime-local"
                    //@ts-ignore
                    defaultValue={currVoie_date_start !== null ? format(new Date(currVoie_date_start), "yyyy-MM-dd'T'h':'mm") : format(new Date(), "yyyy-MM-dd'T'h':'mm")}
  //                    defaultValue={currVoie_date_start/*format(new Date(currVoie_date_start), "yyyy-MM-dd'T'h':'mm")*/}
                    onChange={handleDateChg}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled
                  />&nbsp;&nbsp;
                  <TextField variant="outlined" margin='dense' className={MoreClasses.dateInput} style={{marginLeft: '0.5em'}}
                    inputRef={register}
                    id="voie_date_chgmt"
                    name="voie_date_chgmt"
                    label={dbleSuivi === false ? "Date du changement" : "Début suivi à ajouter"}
                    type="datetime-local"
                    defaultValue={format(new Date(), "yyyy-MM-dd'T'h':'mm")}
  //                    defaultValue={currVoie_date_end/*format(new Date(currVoie_date_end), "yyyy-MM-dd'T'h':'mm")*/ }
                    onChange={handleDateChg}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  /></div></> :
                    <><div
                      className={MoreClasses.dateInput}
                      style={containerStyles}
                    > 
                    { voiesDuSite.filter((v) => v.id_equipement === Number(currentData?.id)).length > 0 && !isEditMode ?
                    <><TextField variant="outlined" margin='dense' className={MoreClasses.dateInput}
                      inputRef={register}
                      id="voie-datetime-start"
                      name="voie_date_start"
                      label="Début du suivi"
                      type="datetime-local"
                      //@ts-ignore
                      defaultValue={currVoie_date_start !== null ? format(new Date(currVoie_date_start), "yyyy-MM-dd'T'h':'mm") : format(new Date(), "yyyy-MM-dd'T'h':'mm")}
    //                    defaultValue={currVoie_date_start/*format(new Date(currVoie_date_start), "yyyy-MM-dd'T'h':'mm")*/}
                      onChange={handleDateChg}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled
                    />&nbsp;&nbsp;</>
                    : <><TextField variant="outlined" margin='dense' className={MoreClasses.dateInput}
                      inputRef={register}
                      id="voie-datetime-start"
                      name="voie_date_start"
                      label="Début du suivi"
                      type="datetime-local"
                      //@ts-ignore
                      defaultValue={currVoie_date_start !== null ? format(new Date(currVoie_date_start), "yyyy-MM-dd'T'h':'mm") : format(new Date(), "yyyy-MM-dd'T'h':'mm")}
    //                    defaultValue={currVoie_date_start/*format(new Date(currVoie_date_start), "yyyy-MM-dd'T'h':'mm")*/}
                      onChange={handleDateChg}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />&nbsp;&nbsp;</>
                    }
                    { selectedVoieScenario === "refollow" ?
                    <><TextField variant="outlined" margin='dense' className={MoreClasses.dateInput} style={{marginLeft: '0.5em'}}
                      inputRef={register}
                      id="voie_refollow"
                      name="voie_refollow"
                      label="Date de reprise"
                      type="datetime-local"
                      //@ts-ignore
                      defaultValue={currVoie_date_end !== "1970-01-01T1:00"/*null*/ ? format(new Date(currVoie_date_end), "yyyy-MM-dd'T'h':'mm") : ''}
    //                    defaultValue={currVoie_date_end/*format(new Date(currVoie_date_end), "yyyy-MM-dd'T'h':'mm")*/ }
                      onChange={handleDateChg}
  //                    value={state.voie_date_end === "" ? "" : state.voie_date_end}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    /></>
                    : <><TextField variant="outlined" margin='dense' className={MoreClasses.dateInput} style={{marginLeft: '0.5em'}}
                      inputRef={register}
                      id="voie_date_end"
                      name="voie_date_end"
                      label="Fin du suivi"
                      type="datetime-local"
                      //@ts-ignore
                      defaultValue={currVoie_date_end !== "1970-01-01T1:00"/*null*/ ? format(new Date(currVoie_date_end), "yyyy-MM-dd'T'h':'mm") : ''}
    //                    defaultValue={currVoie_date_end/*format(new Date(currVoie_date_end), "yyyy-MM-dd'T'h':'mm")*/ }
                      onChange={handleDateChg}
  //                    value={state.voie_date_end === "" ? "" : state.voie_date_end}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    /></> }</div></> }
                  <TextField
                    inputRef={register}
                    name="action"
                    value={selectedVoieScenario}
                    hidden
                    style={{display: 'none'}}
                  />
                  <TextField
                    name="id_equipement"
                    value={idApp}
                    hidden
                    style={{display: 'none'}}
                  />
                  <TextField
                    name="site_id"
                    value={currSite_idVoie}
                    hidden
                    style={{display: 'none'}}
                  />
                  <TextField
                    name="site_sn"
                    value={currSn_site}
                    hidden
                    style={{display: 'none'}}
                  />
                  <TextField
                    name="sn_site"
                    value={currSn_site}
                    hidden
                    style={{display: 'none'}}
                  />
              </div>
            </div><div className={MoreClasses.div4buttons}>
            <Button
              size="medium"
              color="secondary"
              variant="contained"
              fullWidth
              className={MoreClasses.buttons}
              type="submit"
              disabled={submitLoadingVoie2mesure}
            >            
              {selectedVoie?.id ?                                      // La VOIE existe ?
                  'Mettre à jour le suivi'      // MàJ VOIE
                  : 'Suivre l\'appareil sur cette voie'}
            </Button>
            {submitErrorVoie2mesure && (
              <ErrorBox
                errorMsg={submitErrorVoie2mesure}
                clearErrorMsg={() => dispatch(clearSubmitVoie2mesuresError(/*null*/))}
              />
            )}
            {alertSuivi !== "" && (
              <ErrorBox style={{background: 'transparent'}}
                errorMsg={alertSuivi}
                clearErrorMsg={() => /*dispatch(clearSubmitEquipementError(null))*/setAlertSuivi("") }
              />
            )}
            </div>
            </form>
          </TableCell> : null }
          </TableRow>
          </TableBody>
        </Table>
        </Paper>
    );
  };
  return showDialog(true, true);
};

export default EquipementForm;
