import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import roomService from '../../services/rooms';
import { 
  Room,
  //RoomFilterValues,
  RoomPayload,
  //RoomSortValues,
  RoomState,
} from '../types';
import { notify } from './notificationSlice';
import { getErrorMsg } from '../../utils/helperFuncs';

interface InitialRoomState {
//  rooms: { [siteId: string]: RoomState[] };
//  rooms: Room[];
  rooms: RoomState[]; // previously disabled 230316
  fetchLoading: boolean;
  fetchError: string | null;
  submitLoading: boolean;
  submitError: string | null;
/*  sortBy: RoomSortValues,
  filterBy: RoomFilterValues,*/
//  status: 'idle' | 'loading' | 'succeeded';
//  error: string | null;
}

const initialState: InitialRoomState = {
  rooms: [],
  fetchLoading: false,
  fetchError: null,
  submitLoading: false,
  submitError: null,
//  sortBy: 'newest',
 // filterBy: 'all',
/*  rooms: [],
  status: 'idle',
  error: null,*/
};

const roomsSlice = createSlice({
  name: 'rooms',
  initialState,
  reducers: {
//    setRooms: (state, action: PayloadAction<{ room: RoomState[], siteId: string }>) => { //230320
    //setRooms: (state, action: PayloadAction<Room[]>) => {
      setRooms: (state, action: PayloadAction<RoomState[]>) => {
      state.rooms = action.payload;
//      state.rooms[action.payload.siteId] = action.payload.room;   //230320
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;
    },
//    setSiteRooms: (state, action: PayloadAction<{ room: Room, siteId: string }>) => {
    setRoomsLoading: (state) => {
      state.fetchLoading = true;
//      state.status = 'loading';
      state.fetchError = null;
    },
    setFetchRoomsError: (state, action: PayloadAction<string>) => {
      state.fetchLoading = false;
      state.fetchError = action.payload;
    },
  },
});

export const { setRooms, setRoomsLoading, setFetchRoomsError } = roomsSlice.actions;

export const fetchRooms = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setRoomsLoading());
      const allRooms = await roomService.getRooms();
      dispatch(setRooms(allRooms));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const selectRoomsBySiteId = (state: RootState, site_id: string) => {
  return state.rooms.rooms?.[Number(site_id)];
  //return state.rooms.rooms?.[Number(siteId)];
};

export const selectRoomsState = (state: RootState) => state.rooms;
export const selectRoomById = (
  state: RootState,
  site_id: string,
  roomId: string) => {
	  return state.rooms.rooms?.[Number(site_id)]; //.find((p) => p.id === roomId);
//    return state.rooms.rooms/*?.[Number(siteId)]*/.find((p) => p.id === roomId);
};

export default roomsSlice.reducer;
