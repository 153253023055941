import { Link as RouterLink, useHistory } from 'react-router-dom';
import { ConcentrateurState, EquipementState, PosteState, QuestionsInstrumState, RoomState, Voie2mesureState } from '../../redux/types';
//import EquipementsMenu from './EquipementsMenu';
import { formatDateTime, truncateString } from '../../utils/helperFuncs';
import { priorityStyles, statusStyles } from '../../styles/customStyles';

import { Divider, Typography, Link, Paper, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@material-ui/core';
import { useMainPageStyles, useTableStyles } from '../../styles/muiStyles';
import QuestionAnswerTwoToneIcon from '@material-ui/icons/QuestionAnswerTwoTone';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { UsageState, Usage } from '../../redux/types';
import EquipModMenu from '../../components/EquipModMenu';
const tableHeaders = [
  /*  'id',
    'id_poste',
    'id_type_usage',
    'id_piece',
    'reference',
    'id_ref',
    'id_questions',
    'reference',*/
    'Appareil',
    'Actif',    /* actif = a_suivre : ne pas confondre le suivi qui indique si l'app est encore d'acutalité, et la "a suivre" qui signifie que l'app est suivi ou non-suivi */
    'Poste',
    'Pièce',    /* au survol : préciser la pièce avec str_piece */
    'Date fin',
    'Suivi',
    'Conc/Module',
    'Voie',
    /*'str_equip',*/
    'Capteur',
   /* 'WS',     peu pertinent d'après BR */
    'Actions',
  ];
const EquipementsListMobile: React.FC<{ 
    siteId: string,
    siteSn: string,
    equipements: EquipementState[],
    concentrateurs: ConcentrateurState[], 
    postes: PosteState[],
    usages: UsageState[],
    questions: QuestionsInstrumState[],
    pieces: RoomState[],
    voies2mesures: Voie2mesureState[],
  }> = ({ siteId, siteSn, equipements, concentrateurs, usages, postes, questions, pieces, voies2mesures }) => {
//  const classes = useMainPageStyles();
const classes = useTableStyles();
const history = useHistory();
let double = 0;   // flag Double Suivi = ligne couleur différente
let rowColor = "";
return (
  <Paper className={classes.table}>
    <Table className={classes.tableEquip}>
      <TableHead>
        <TableRow>
          {tableHeaders.map((t) => (
            <TableCell key={t} align="center">
              {t}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {equipements.map((e) => (
          (e.voies2mesures.length > 1 && e.id_type_usage != 344 && e.id_type_usage != 345 ?
            (double = 1, rowColor="#333"/*, console.log("Dble suivi 4 '"+e.str_equip+"'")*/)
          : rowColor="unset"),
          //e.voies2mesures.forEach((voie, voieNumber) = {
            e.voies2mesures.length > 0 ?
              e.voies2mesures.map((voie, voieNumber) => {
               /* if(voieNumber > 0 && e.id_type_usage != 344 && e.id_type_usage != 345) 
                  { double = 1; rowColor="#555"; console.log("Dble suivi 4 '"+e.str_equip+"'") }
                else { rowColor="unset"; }*/
                return(
                <TableRow key={e.id+voie.id} style={{backgroundColor: rowColor}}>
                  <Tooltip title={e.reponses + " " + e.str_equip}>
                  <TableCell
                    align="center"
                    onClick={() =>
                      history.push(`/sites/${e.site_id}/equipements/${e.id}`)
                    }
                    className={classes.clickableCell}
                  >
                    {e.str_equip.length > 0 ?
                      (<Tooltip title={e.str_equip+" ID:"+e.id}>
                      <Link
                        component={RouterLink}
                        to={`/sites/${e.site_id}/equipements/${e.id}`}
                        color="secondary"
                      >
                        {/*e.str_equip*/}
                        {e.usage.name}
                      </Link>
                    </Tooltip>)
                    : null
                    }
                  </TableCell>
                  </Tooltip>
                  <TableCell align="center">
                  {e.suivi.toString/*e.a_suivre.valueOf.toString*/}
                  </TableCell>
                  <TableCell align="center">
                    <div>{e.poste.name}</div>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={e?.str_piece}>
                      <Link
                        component={RouterLink}
                        to={`/module/${e?.id_piece}`}
                        color="secondary"
                      >
                      {e.room?.name}
                      </Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <div>{e.app_date_end ?
                      new Date(e.app_date_end).toLocaleDateString()
                      : new Date() }</div>
                  </TableCell>
                  <TableCell align="center">
                  {e.a_suivre.toString/*e.a_suivre.valueOf.toString*/}
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip title={"Concentrateur "+voie.id_conc}>
                      <Link
                        component={RouterLink}
                        to={`/module/${voie.id_module}`}
                        color="secondary"
                      >
                        {voie.id_conc+" / "+voie.id_module}
                      </Link>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">{/*e.voies2mesures.map((v) => (v.number))*/voie.number}</TableCell>
                  <TableCell align="center">
                    {voie.type_sensor + " " + voie.id_sensor}
                  </TableCell>
                  <TableCell align="center">
                    <EquipModMenu
    /*                id_poste={e.id_poste}
                    id_type_usage={e.id_type_usage}
                    id_piece={e.id_piece}
                    comments={e.comments}
                    a_suivre={e.a_suivre}
                    suivi={e.suivi}
                    sn_site={e.sn_site}
                    date_start={e.date_start}
                    date_end={e.date_end}
                    str_piece={e.str_piece}
                    str_equip={e.str_equip}*/
                    linkName={e.usage.name}
                    id_voie={voie.id}
                    sn_module={voie.id_module}
                    site_id={siteId/*e.site_id*/}
                    sn_site={siteSn}
                    currentData={{
                      id/*_equip*/: e.id,
                      id_poste: e.poste.id/*.id_poste*/,
                      id_type_usage: e.id_type_usage,
                      id_piece: e.room?.id/*id_piece*/,
                      reference: e.reference,
                      id_ref: e.id_ref,
                      reponses: e.reponses,
                      id_questions: e.id_questions,
                      comments: e.comments,
                      a_suivre: e.a_suivre,
                      suivi: e.suivi,
                      app_date_start: e.app_date_start,
                      app_date_end: e.app_date_end,
                      sn_site: e.sn_site,
                      site_sn: e.sn_site,
                      str_piece: e.str_piece,
                      str_equip: e.str_equip,
                      site_id: e.site_id,
                      normalized_pw: e.normalized_pw,
                      nrg_class: e.nrg_class,
                      water_consumption: e.water_consumption,
                      pw_veille: e.pw_veille,
                      lightsUsageTime: '',
                      nbLights: '',
                      powerLights: '',
                      technoLights: '',
                    }}
                    usages={usages}
                    postes={postes}
                    pieces={pieces}
                    voies2mesures={voies2mesures}
                    questions={questions}
                    conc={concentrateurs}
                    voieId={voie.id}
                    />
                  </TableCell>
              </TableRow>)
              })
            : (<TableRow key={e.id}>
              <Tooltip title={e.reponses}>
              <TableCell
                align="center"
                onClick={() =>
                  history.push(`/sites/${e.site_id}/equipements/${e.id}`)
                }
                className={classes.clickableCell}
              >
                <Tooltip title={e.str_equip+" ID:"+e.id}>
                  <Link
                    component={RouterLink}
                    to={`/sites/${e.site_id}/equipements/${e.id}`}
                    color="secondary"
                  >
                    {/*e.str_equip*/}
                    {e.usage.name}
                  </Link>
                </Tooltip>
              </TableCell>
              </Tooltip>
              <TableCell align="center">
              {e.suivi.toString/*e.a_suivre.valueOf.toString*/}
              </TableCell>
              <TableCell align="center">
                <div>{e.poste.name}</div>
              </TableCell>
              <TableCell align="center">
                <Tooltip title={e?.str_piece}>
                  <Link
                    component={RouterLink}
                    to={`/module/${e?.id_piece}`}
                    color="secondary"
                  >
                  {e.room?.name}
                  </Link>
                </Tooltip>
              </TableCell>
              <TableCell align="center">
                <div>{e.app_date_end ?
                      new Date(e.app_date_end).toLocaleDateString()
                      : new Date()}</div>
              </TableCell>
              <TableCell align="center">
              {e.a_suivre.toString/*e.a_suivre.valueOf.toString*/}
              </TableCell>
              <TableCell align="center">
                {"-"}
              </TableCell>
              <TableCell align="center">{"-"}</TableCell>
              <TableCell align="center">{"-"}</TableCell>
              <TableCell align="center">
                <EquipModMenu
/*                id_poste={e.id_poste}
                id_type_usage={e.id_type_usage}
                id_piece={e.id_piece}
                comments={e.comments}
                a_suivre={e.a_suivre}
                suivi={e.suivi}
                sn_site={e.sn_site}
                date_start={e.date_start}
                date_end={e.date_end}
                str_piece={e.str_piece}
                str_equip={e.str_equip}*/
                linkName={e.usage.name}
                id_voie={1}
                sn_module={1}
                site_id={siteId/*e.site_id*/}
                sn_site={siteSn}
                currentData={{
                  id/*_equip*/: e.id,
                  id_poste: e.poste.id/*.id_poste*/,
                  id_type_usage: e.id_type_usage,
                  id_piece: e.room?.id/*id_piece*/,
                  reference: e.reference,
                  id_ref: e.id_ref,
                  reponses: e.reponses,
                  id_questions: e.id_questions,
                  comments: e.comments,
                  a_suivre: e.a_suivre,
                  suivi: e.suivi,
                  app_date_start: e.app_date_start,
                  app_date_end: e.app_date_end,
                  sn_site: e.sn_site,
                  site_sn: e.sn_site,
                  str_piece: e.str_piece,
                  str_equip: e.str_equip,
                  site_id: e.site_id,
                  nrg_class: e.nrg_class,
                  normalized_pw: e.normalized_pw,
                  water_consumption: e.water_consumption,
                  pw_veille: e.pw_veille,
                  lightsUsageTime: '',
                  nbLights: '',
                  powerLights: '',
                  technoLights: '',
                }}
                usages={usages}
                postes={postes}
                pieces={pieces}
                voies2mesures={voies2mesures}
                questions={questions}
                conc={concentrateurs}
                voieId={0}
                />
              </TableCell>
          </TableRow>) // End if equipement NON SUIVI
        ))}
      </TableBody>
    </Table>
  </Paper>
  );
};

export default EquipementsListMobile;
