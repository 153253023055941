import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import concentrateurService from '../../services/concentrateurs';
import noteService from '../../services/notes';
import {
  ConcentrateurState,
  ConcentrateurSortValues,
  ConcentrateurPayload,
  EditedConcentrateurData,
  ClosedReopenedConcentrateurData,
  Note,
  ConcentrateurFilterValues,
  Concentrateur,
  SiteConcentrateur,
} from '../types';
import { notify } from './notificationSlice';
import { History } from 'history';
import { getErrorMsg } from '../../utils/helperFuncs';

interface InitialConcentrateurState {
//  concentrateurs: { [siteId: string]: ConcentrateurState[] };
  concentrateurs: { [siteId: string]: ConcentrateurState[] };
  fetchLoading: boolean;
  fetchError: string | null;
  submitLoading: boolean;
  submitError: string | null;
  sortBy: ConcentrateurSortValues;
  filterBy: ConcentrateurFilterValues;
}

const initialState: InitialConcentrateurState = {
  concentrateurs: {},
  fetchLoading: false,
  fetchError: null,
  submitLoading: false,
  submitError: null,
  sortBy: 'newest',
  filterBy: 'all',
};

const concentrateursSlice = createSlice({
  name: 'concentrateurs',
  initialState,
  reducers: {
    setConcentrateurs: (
      state,
      action: PayloadAction<{ concentrateurs: ConcentrateurState[]; siteId: string }>
    ) => {
      state.concentrateurs[action.payload.siteId] = action.payload.concentrateurs;
      state.fetchLoading = false;
      state.fetchError = null;
    },
    setConcentrateursBySitesSn: (
      state,
      action: PayloadAction<{ concentrateurs: ConcentrateurState[]; siteSn: string }>
    ) => {
      state.concentrateurs[action.payload.siteSn] = action.payload.concentrateurs;
      state.fetchLoading = false;
      state.fetchError = null;
      console.log("setConcentrateursBySitesSn... SLICE");
    },
    addConcentrateur: (
      state,
      action: PayloadAction<{ concentrateur: ConcentrateurState; siteId: string }>
    ) => {
      if (action.payload.siteId in state.concentrateurs) {
        state.concentrateurs[action.payload.siteId].push(action.payload.concentrateur);
      } else {
        state.concentrateurs[action.payload.siteId] = [action.payload.concentrateur];
      }
      state.submitLoading = false;
      state.submitError = null;
    },
    updateConcentrateur: (
      state,
      action: PayloadAction<{
        data: EditedConcentrateurData;
        concentrateurId: string;
        siteId: string;
      }>
    ) => {
      state.concentrateurs[action.payload.siteId] = state.concentrateurs[
        action.payload.siteId
      ].map((b) =>
        b.id === action.payload.concentrateurId ? { ...b, ...action.payload.data } : b
      );

      state.submitLoading = false;
      state.submitError = null;
    },
    removeConcentrateur: (
      state,
      action: PayloadAction<{ concentrateurId: string; siteId: string }>
    ) => {
      state.concentrateurs[action.payload.siteId] = state.concentrateurs[
        action.payload.siteId
      ].filter((b) => b.id !== action.payload.concentrateurId);
    },
    updateConcentrateurStatus: (
      state,
      action: PayloadAction<{
        data: ClosedReopenedConcentrateurData;
        concentrateurId: string;
        siteId: string;
      }>
    ) => {
      state.concentrateurs[action.payload.siteId] = state.concentrateurs[
        action.payload.siteId
      ].map((b) =>
        b.id === action.payload.concentrateurId ? { ...b, ...action.payload.data } : b
      );
    },
    addNote: (
      state,
      action: PayloadAction<{ note: Note; concentrateurId: string; siteId: string }>
    ) => {
/*      state.concentrateurs[action.payload.siteId] = state.concentrateurs[
        action.payload.siteId
      ].map((b) =>
        b.id === action.payload.concentrateurId
          ? { ...b, notes: [...b.notes, action.payload.note] }
          : b
      );*/
      state.submitLoading = false;
      state.submitError = null;
    },
    updateNote: (
      state,
      action: PayloadAction<{
        data: { body: string; date_update: Date };
        noteId: number;
        concentrateurId: string;
        siteId: string;
      }>
    ) => {
      const concentrateur = state.concentrateurs[action.payload.siteId].find(
        (b) => b.id === action.payload.concentrateurId
      );
/*
      if (concentrateur) {
        const updatedNotes = concentrateur.notes.map((n) =>
          n.id === action.payload.noteId ? { ...n, ...action.payload.data } : n
        );

        state.concentrateurs[action.payload.siteId] = state.concentrateurs[
          action.payload.siteId
        ].map((b) =>
          b.id === action.payload.concentrateurId ? { ...b, notes: updatedNotes } : b
        );

        state.submitLoading = false;
        state.submitError = null;
      }*/
    },
    removeNote: (
      state,
      action: PayloadAction<{
        noteId: number;
        concentrateurId: string;
        siteId: string;
      }>
    ) => {
      const concentrateur = state.concentrateurs[action.payload.siteId].find(
        (b) => b.id === action.payload.concentrateurId
      );
/*
      if (concentrateur) {
        const updatedNotes = concentrateur.notes.filter(
          (n) => n.id !== action.payload.noteId
        );

        state.concentrateurs[action.payload.siteId] = state.concentrateurs[
          action.payload.siteId
        ].map((b) =>
          b.id === action.payload.concentrateurId ? { ...b, notes: updatedNotes } : b
        );
      }*/
    },
    setFetchConcentrateursLoading: (state) => {
      state.fetchLoading = true;
      state.fetchError = null;
    },
    setFetchConcentrateursError: (state, action: PayloadAction<string>) => {
      state.fetchLoading = false;
      state.fetchError = action.payload;
    },
    setSubmitConcentrateurLoading: (state) => {
      state.submitLoading = true;
      state.submitError = null;
    },
    setSubmitConcentrateurError: (state, action: PayloadAction<string>) => {
      state.submitLoading = false;
      state.submitError = action.payload;
    },
    clearSubmitConcentrateurError: (state) => {
      state.submitError = null;
    },
    sortConcentrateursBy: (state, action: PayloadAction<ConcentrateurSortValues>) => {
      state.sortBy = action.payload;
    },
    filterConcentrateursBy: (state, action: PayloadAction<ConcentrateurFilterValues>) => {
      state.filterBy = action.payload;
    },
  },
});

export const {
  setConcentrateurs,
  setConcentrateursBySitesSn,
  addConcentrateur,
  updateConcentrateur,
  removeConcentrateur,
  updateConcentrateurStatus,
  addNote,
  updateNote,
  removeNote,
  setFetchConcentrateursLoading,
  setFetchConcentrateursError,
  setSubmitConcentrateurLoading,
  setSubmitConcentrateurError,
  clearSubmitConcentrateurError,
  sortConcentrateursBy,
  filterConcentrateursBy,
} = concentrateursSlice.actions;

export const fetchConcentrateursBySiteId = (siteId: string): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setFetchConcentrateursLoading());
      const siteConcentrateurs = await concentrateurService.getAllConcentrateurs();
      dispatch(setConcentrateurs({ concentrateurs: siteConcentrateurs, siteId }));
    } catch (e) {
      //@ts-ignore
      dispatch(setFetchConcentrateursError(getErrorMsg(e)));
    }
  };
};

export const fetchConcentrateursBySitesSn = (siteSn: string): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setFetchConcentrateursLoading());
      const siteConcentrateurs = await concentrateurService.getConcentrateursBySiteSn(siteSn);
//      dispatch(setConcentrateursBySitesSn({ concentrateurs: ConcentrateurState, siteSn }));
      dispatch(setConcentrateurs(siteConcentrateurs));
    } catch (e) {
      //@ts-ignore
      dispatch(setFetchConcentrateursError(getErrorMsg(e)));
    }
  };
};

export const createNewConcentrateur = (
  siteId: string,
  concentrateurData: ConcentrateurPayload,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setSubmitConcentrateurLoading());
      const newConcentrateur = await concentrateurService.createConcentrateur(siteId, concentrateurData);
      dispatch(addConcentrateur({ concentrateur: newConcentrateur, siteId }));
      dispatch(notify('New concentrateur added!', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitConcentrateurError(getErrorMsg(e)));
    }
  };
};

export const editConcentrateur = (
  siteId: string,
//  siteSn: string,
  concentrateurId: string,
  concentrateurData: ConcentrateurPayload,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setSubmitConcentrateurLoading());
      const updatedConcentrateur = await concentrateurService.updateConcentrateur(siteId, concentrateurId, concentrateurData);
      const {
/*        title,
        description,
        priority,
        date_update,
        updatedBy,*/
        tel,
        date_start,
        date_end,
        date_last_data,
        ts_last_data,
        date_update,
        pdt,
        modules,
        aliasSn,
        techno,
        version,
        hardSn,
        //siteId,
        siteSn,
      } = updatedConcentrateur as EditedConcentrateurData;

      dispatch(
        updateConcentrateur({
          data: { tel, date_start, date_end, date_last_data, ts_last_data, date_update, pdt, modules, aliasSn, techno, version, hardSn, siteId, siteSn },
          concentrateurId,
          siteId,
        })
      );
      dispatch(notify('Successfully updated the concentrateur!', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitConcentrateurError(getErrorMsg(e)));
    }
  };
};

export const deleteConcentrateur = (
  siteId: string,
  concentrateurId: string,
  history: History
): AppThunk => {
  return async (dispatch) => {
    try {
      await concentrateurService.deleteConcentrateur(siteId, concentrateurId);
      history.push(`/sites/${siteId}`);
      dispatch(removeConcentrateur({ concentrateurId, siteId }));
      dispatch(notify('Deleted the concentrateur.', 'success'));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const closeReopenConcentrateur = (
  siteId: string,
  concentrateurId: string,
  action: 'close' | 'reopen'
): AppThunk => {
  return async (dispatch) => {
    try {
      let returnedData;
      if (action === 'close') {
        returnedData = await concentrateurService.closeConcentrateur(siteId, concentrateurId);
      } else {
        returnedData = await concentrateurService.reopenConcentrateur(siteId, concentrateurId);
      }
      const {
        isResolved,
        closedAt,
        closedBy,
        reopenedAt,
        reopenedBy,
      } = returnedData as ClosedReopenedConcentrateurData;
      dispatch(
        updateConcentrateurStatus({
          data: { isResolved, closedAt, closedBy, reopenedAt, reopenedBy },
          concentrateurId,
          siteId,
        })
      );
      dispatch(
        notify(
          `${action === 'close' ? 'Closed' : 'Re-opened'} the concentrateur!`,
          'success'
        )
      );
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const createConcentrateur = (
  siteId: string,
  concentrateurId: string,
  concentrateurData: ConcentrateurPayload/*string*/,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setSubmitConcentrateurLoading());
      const newConcentrateur = await concentrateurService.createConcentrateur(siteId, /*concentrateurId, */concentrateurData);
      dispatch(addConcentrateur({ concentrateur: newConcentrateur, siteId }));
      dispatch(notify('New concentrateur added!', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitConcentrateurError(getErrorMsg(e)));
    }
  };
};

export const editNote = (
  siteId: string,
  concentrateurId: string,
  noteId: number,
  noteBody: string,
  closeDialog?: () => void
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setSubmitConcentrateurLoading());
      const returnedData = await noteService.editNote(
        siteId,
        noteId,
        noteBody
      );
      const { body, date_update } = returnedData as Note;
      dispatch(
        updateNote({ data: { body, date_update }, noteId, concentrateurId, siteId })
      );
      dispatch(notify('Updated the note!', 'success'));
      closeDialog && closeDialog();
    } catch (e) {
      //@ts-ignore
      dispatch(setSubmitConcentrateurError(getErrorMsg(e)));
    }
  };
};

export const deleteNote = (
  siteId: string,
  concentrateurId: string,
  noteId: number
): AppThunk => {
  return async (dispatch) => {
    try {
      await noteService.deleteNote(siteId, noteId);
      dispatch(removeNote({ noteId, concentrateurId, siteId }));
      dispatch(notify('Deleted the note.', 'success'));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const fetchAllConcentrateurs = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setFetchConcentrateursLoading());
      const allConcentrateurs = await concentrateurService.getAllConcentrateurs();
      dispatch(setConcentrateurs(allConcentrateurs));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const fetchConcentrateursForSiteSn = (
  siteSn: string,
): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setFetchConcentrateursLoading());
      const concentrateurs = await concentrateurService.getConcentrateursBySiteSn(siteSn); /*'86'*/
    //  equipements.equipements?.[siteSn]((e) => console.log("Equipements4siteSN "+e.str_piece));
    //  dispatch(setEquipementsBySitesSn(equipements));
      dispatch(setConcentrateursBySitesSn({ concentrateurs: concentrateurs, siteSn }));    // A TESTER *************
//      dispatch(setEquipements(equipements));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};


export const selectConcentrateursState = (state: RootState) => state.concentrateurs;

export const selectConcentrateursBySiteId = (state: RootState, siteId: string) => {
  return state.concentrateurs.concentrateurs?.[siteId];
};

export const selectConcentrateursBySiteSn = (state: RootState, siteSn: string) => {
//  return state.concentrateurs.concentrateurs?.[siteSn];
// foreach on concentrateur<array>, return array of concentrateurs ...
  fetchConcentrateursBySitesSn(siteSn);
//  return state.concentrateurs.concentrateurs?.[siteSn]/*setConcentrateursBySitesSn;*/
  return state.concentrateurs.concentrateurs?.[siteSn];
//  return state.concentrateurs.concentrateurs.createSlice/*setConcentrateursBySitesSn(state, siteSn)*/;
  //.gefilterBy((s) => s.sn_site == sn_site);
};

export const selectConcentrateurById = (
  state: RootState,
  siteId: string,
  concentrateurId: string
) => {
  return state.concentrateurs.concentrateurs?.[siteId].find((b) => b.id === concentrateurId);
};

export default concentrateursSlice.reducer;