import { Equipement, EquipementState, EquipementFilterValues } from '../redux/types';

const filterEquipements = (equipement: EquipementState, filterBy: EquipementFilterValues) => {
 // console.log("filterClosed"); 
  switch (filterBy) {
    case 'all':
      return true;
    case 'closed':
      if (String(equipement.app_date_end).length > 4) { return equipement; } else { return false };
//      return equipement.suivi === true;
//      if (equipement.app_date_end !== null) { return false; } else { return true; }
//      if (new Date(equipement.app_date_end).getMilliseconds < new Date().getMilliseconds) { return true; } else { return false; };
    case 'open':
      if (String(equipement.app_date_end).length === 4) { return equipement } else { return false };
//      return String(equipement.app_date_end).length === 4 ? true : false;
//      if (equipement.app_date_end === null) { return true; } else { return false; }
    //      if (equipement.app_date_end !== "") { return true; } else { return false; };
//      return equipement.isResolved === false;
    case 'presentssuivis':
      if (String(equipement.app_date_end).length === 4) { 
        //if (equipement.voies2mesures.length === 0 && equipement.app_date_end === null) { return equipement }
        if (equipement.voies2mesures.length > 0) {
          if (equipement.voies2mesures.filter((vm)=>vm.voie_date_end === null).length > 0) { return equipement }
        }
      } else { return false };
  }
};

export default filterEquipements;
