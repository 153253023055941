import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import posteService from '../../services/postes';
import { 
  Poste,
//  PosteFilterValues,
  PostePayload,
//  PosteSortValues,
  PosteState,
} from '../types';
import { notify } from './notificationSlice';
import { getErrorMsg } from '../../utils/helperFuncs';

interface InitialPosteState {
//  postes: { [siteId: string]: PosteState[] };
//  postes: Poste[];
  postes: PosteState[]; // previously disabled 230316
  fetchLoading: boolean;
  fetchError: string | null;
  submitLoading: boolean;
  submitError: string | null;
/*  sortBy: PosteSortValues,
  filterBy: PosteFilterValues,*/
//  status: 'idle' | 'loading' | 'succeeded';
//  error: string | null;
}

const initialState: InitialPosteState = {
  postes: [],
  fetchLoading: false,
  fetchError: null,
  submitLoading: false,
  submitError: null,
/*  sortBy: 'newest',
  filterBy: 'all',*/
/*  postes: [],
  status: 'idle',
  error: null,*/
};

const postesSlice = createSlice({
  name: 'postes',
  initialState,
  reducers: {
//    setPostes: (state, action: PayloadAction<{ poste: PosteState[], siteId: string }>) => { //230320
    //setPostes: (state, action: PayloadAction<Poste[]>) => {
      setPostes: (state, action: PayloadAction<PosteState[]>) => {
      state.postes = action.payload;
//      state.postes[action.payload.siteId] = action.payload.poste;   //230320
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;
    },
//    setSitePostes: (state, action: PayloadAction<{ poste: Poste, siteId: string }>) => {
    setSitePostes: (state, action: PayloadAction<{ poste: PosteState, site_id: string }>) => {
      //setPostes: (state, action: PayloadAction<PosteState[]>) => {
      //state.postes[action.payload.siteId] = action.payload.poste.;
/*      state.postes[action.payload.poste.id] = action.payload;
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;*/
    },
    setPostesLoading: (state) => {
      state.fetchLoading = true;
//      state.status = 'loading';
      state.fetchError = null;
    },
    setFetchPostesError: (state, action: PayloadAction<string>) => {
      state.fetchLoading = false;
      state.fetchError = action.payload;
    },
/*    sortPostesBy: (state, action: PayloadAction<PosteSortValues>) => {
      state.sortBy = action.payload;
    },*/
  },
});

export const { setPostes /*, setSitePostes*/, setPostesLoading, setFetchPostesError/*, sortPostesBy*/ } = postesSlice.actions;

export const fetchPostes = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setPostesLoading());
      const allPostes = await posteService.getPostes();
      dispatch(setPostes(allPostes));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const selectPostesBySiteId = (state: RootState, site_id: string) => {
  return state.postes.postes?.[Number(site_id)];
  //return state.postes.postes?.[Number(siteId)];
};

export const selectPostesState = (state: RootState) => state.postes;

export const selectPosteById = (
  state: RootState,
  site_id: string,
  posteId: string) => {
	  return state.postes.postes?.[Number(site_id)]; //.find((p) => p.id === posteId);
//    return state.postes.postes/*?.[Number(siteId)]*/.find((p) => p.id === posteId);
};

export default postesSlice.reducer;
