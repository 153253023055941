import { useDispatch, useSelector } from 'react-redux';
import FilterBar from '../../components/FilterBar';
import SortBar from '../../components/SortBar';
import FormDialog from '../../components/FormDialog';
import EquipementForm from './EquipementForm';
import { EquipementSortValues, EquipementFilterValues, Usage, Poste, Room, QuestionsInstrum, ConcentrateurState, UsageState, RoomState, PosteState, Voie2mesureState, QuestionsInstrumState, EquipementState, EquipementFI, Voie2mesureFI, lignesRecapStyle, SiteState } from '../../redux/types';
import {
  sortEquipementsBy,
  filterEquipementsBy,
  selectEquipementsState,
  grepEquipementsBy,
} from '../../redux/slices/equipementsSlice';
import {
  sortVoiesBy,
  filterVoiesBy,
  selectVoie2mesuresState,
} from '../../redux/slices/voie2mesuresSlice';
import { selectUsagesState } from '../../redux/slices/usagesSlice';
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
  FormLabel,
  Button,
  Divider,
  Paper,
  Typography,
} from '@material-ui/core';
import { useActionCardStyles, useMainPageStyles } from '../../styles/muiStyles';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import DLIcon from '@material-ui/icons/GetApp';
import EditIcon from '@material-ui/icons/Edit';
import IconQuestion from '@material-ui/icons/HelpOutlineOutlined';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import { useState } from 'react';
import CsvDataService from '../../components/CsvDataService';
import GrafanaIcon from '../../svg/grafana-icon_light.png';
import FilterConcentrateur from '../../components/FilterConcentrateur';
import { selectSitesState, updateLegendes } from '../../redux/slices/sitesSlice';
import { occupationProps } from './SiteDetailFormx';
import React from 'react';
import ExcelExportComponent from '../../components/ExcelExportComponent';
import { formatDate } from '../../utils/helperFuncs';

const menuItems = [
  { value: 'default', label: 'Défaut (conc/module/voie)' },
  { value: 'newest', label: 'App +récent en premier' },
  { value: 'oldest', label: 'App +ancien en premier' },
  { value: 'suivi-start-asc', label: 'Début du suivi <' },
  { value: 'suivi-start-desc', label: 'Début du suivi >' },
  { value: 'suivi-end-asc', label: 'Fin du suivis <' },
  { value: 'suivi-end-desc', label: 'Fin du suivis >' },
  { value: 'usage-a-z', label: 'Usage (A - Z)' },
  { value: 'usage-z-a', label: 'Usage (Z - A)' },
  { value: 'app-end-asc', label: 'Fin d\'usage <' },
  { value: 'app-end-desc', label: 'Fin d\'usage >' },
  { value: 'poste-a-z', label: 'Poste (A - Z)' },
  { value: 'poste-z-a', label: 'Poste (Z - A)' },
  { value: 'room-a-z', label: 'Pièce (A - Z)' },
  { value: 'room-z-a', label: 'Pièce (Z - A)' },
/*  { value: 'recapstyle', label: 'Format Recap/Maintenance' },
  { value: 'suiviordered', label: 'Non-suivis, Suivis, Dble suivi' },
  { value: 'suivi', label: 'Suivis en premier' },*/
  { value: 'nonsuivi', label: 'Non suivis seulement' },
//  { value: 'ended', label: 'Utilisation terminée' },
  { value: 'most-notes', label: 'Plus commentés' },
  { value: 'least-notes', label: 'Moins commentés' },
];

const EquipementsActionCard: React.FC<{
//  equipId: string;
  siteId: string;
  siteSn: string;
/*  hardSn: string;
  tel: string;
  date_last_data: Date;
  ts_last_data: Date;
  modules: number[];*/
//  concId: string;
  conc: ConcentrateurState[];
  usages: UsageState[];
  postes: PosteState[];
  pieces: RoomState[];
  voies2mesures: Voie2mesureState[];
  questions: QuestionsInstrumState[];
  equipements: EquipementState[];
  filterValue: string;
  setFilterValue: (filterValue: string) => void;
  isMobile: boolean;
}> = ({ siteId, siteSn, /*hardSn, tel, date_last_data, ts_last_data, modules, concId*/ conc, filterValue, setFilterValue, isMobile,
  usages, postes, pieces, voies2mesures, equipements, questions
  }) => {
//  console.log("SITE-ID: "+siteId+" / SITE-SN: "+siteSn)
  const classes = useActionCardStyles();
  const classes2 = useMainPageStyles();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { sites } = useSelector(selectSitesState);
  const shortSnSite = siteSn.replace("10","")
  const updateGrafanaLegends = () => {
    dispatch(updateLegendes(siteSn))
  }
  const openDialog = () => {
    setIsOpen(true);
  };
  const closeDialog = () => {
    setIsOpen(false);
  };
  const formatComments = (input: string | null) : string => {
    return input?.replaceAll("}","").replaceAll("{","").replaceAll(";"," ").replaceAll('\n'," ").replaceAll('\r'," ") || ""
  }
  const formatRep = (input: string | null, id_usage: number) : string => {
    if(id_usage === 342) { // plafonnier
      return input?.replace("_|","").replace(",","=").replace(",","x")+"w" || ""
    } else {
      if(id_usage === 343) { // prise
        return input?.replace("|"," min/day|").replace(",","=").replace(",","x")+"w" || ""
      } else {
            return input?.replaceAll(";"," ").replaceAll('\n'," ").replaceAll('\r'," ") || ""
      }
    }
  }
  const getAppareilsFI = () : EquipementFI[] => {
    const equipFI: EquipementFI[] = new Array(/*equipements.length*/);
    equipements.map((e, index) => {
      let nbVoies = voies2mesures.filter((v) => v.id_equipement === Number(e.id)).length
      let strVoie = ""
      if (nbVoies === 1) {
        strVoie = voies2mesures.filter((v) => v.id_equipement === Number(e.id)).map((voie) =>
          (voie.type_sensor+"_"+voie.id_conc+"_"+voie.id_module+"_"+voie.number+"_"+voie.phase+"_").replace("null_","") ).join('\n')
      }
      if (nbVoies > 1) {
        strVoie += voies2mesures.filter((v) => v.id_equipement === Number(e.id)).map((voie) =>
          voie.type_sensor+"_"+voie.id_conc+"_"+voie.id_module+"_"+voie.number+"_"+voie.phase+"_" ).join('\n')
          .replace("null_","").replace(/(?:\r\n|\r|\n)/g,"& ")
      /*  console.log("Nb_voies ["+nbVoies+"]]: "+e.str_equip)
        console.log("strVoie: "+strVoie)
        return strVoie*/
      }
      let pORl = ""
      e.id_type_usage !== 342 && e.id_type_usage !== 343 ? pORl = "P" : pORl = "L";
      let theEquipFI: EquipementFI = {sn_site: e.sn_site, strSuivi: strVoie, 
        piece:(pieces.find((p)=>Number(p.id)===e.id_piece)?.name || "")+(e.str_piece?.replace(new RegExp(/^..*.\(/)," (") || ""),
//        piece:pieces.find((p) => Number(p.id) === e.id_piece)?.name || "",
        pORl: pORl, poste:postes.find((p) => Number(p.id) === e.id_poste)?.name || "", usage:usages.find((p) => Number(p.id) === e.id_type_usage)?.name || "",
        reponses:formatRep(e.reponses,e.id_type_usage), comments:formatComments(e.comments), app_date_start:formatDate(e.app_date_start),
        app_date_end:formatDate(e.app_date_end), precision_piece:e.str_piece, pw_veille:e.pw_veille, nrg_class:e.nrg_class, 
        normalized_pw:e.normalized_pw, water_consumption:e.water_consumption, id: e.id, id_poste:String(e.id_poste), id_type_usage:String(e.id_type_usage),
        id_piece:String(e.id_piece),reference:e.reference, id_ref:String(e.id_ref), id_questions:String(e.id_questions), id_circuit: "",
        strQuestInstrum:questions.find((q) => Number(q.id) === e.id_questions)?.questions || "", site_id:e.site_id
      }
      /*//@ts-ignore
      let theEquipFI: EquipementFI = [e.sn_site, strVoie, pieces.find((p) => Number(p.id) === e.id_piece)?.name || "",
        pORl, postes.find((p) => Number(p.id) === e.id_poste)?.name || "", usages.find((p) => Number(p.id) === e.id_type_usage)?.name || "",
        e.reponses, e.comments.replaceAll("}","").replaceAll("{",""), e.app_date_start, e.app_date_end, e.str_piece, e.pw_veille,
        e.nrg_class, e.normalized_pw, e.water_consumption, e.id, String(e.id_poste), String(e.id_type_usage), String(e.id_piece),
        e.reference, String(e.id_ref), String(e.id_questions), questions.find((q) => Number(q.id) === e.id_questions)?.questions || "",
        e.site_id];*/
      equipFI.push(theEquipFI)
    })
//    console.log(equipFI)
    return equipFI;
  }
  const getAppareilsNonSuivis = () : EquipementFI[] => {
    const equipFI: EquipementFI[] = new Array(/*equipements.length*/);
    equipements.filter((eq)=>eq.voies2mesures.length === 0 && eq.app_date_end === null).map((e, index) => {
      //@ts-ignore
      let theEquipFI: EquipementFI = {
        sn_site: e.sn_site, strSuivi: "", 
//        piece:pieces.find((p) => Number(p.id) === e.id_piece)?.name || "",
        piece:(pieces.find((p)=>Number(p.id)===e.id_piece)?.name || "")+(e.str_piece?.replace(new RegExp(/^..*. \(/)," (") || ""),
        pORl: "", poste:postes.find((p) => Number(p.id) === e.id_poste)?.name || "", usage:usages.find((p) => Number(p.id) === e.id_type_usage)?.name || "",
        reponses:formatRep(e.reponses,e.id_type_usage), comments:formatComments(e.comments),
        app_date_start:formatDate(e.app_date_start), app_date_end:formatDate(e.app_date_end),
        precision_piece:e.str_piece, pw_veille:e.pw_veille, nrg_class:e.nrg_class, normalized_pw:e.normalized_pw, water_consumption:e.water_consumption,
        id: e.id, id_poste:String(e.id_poste), id_type_usage:String(e.id_type_usage), id_piece:String(e.id_piece),reference:e.reference,
        id_ref:String(e.id_ref), id_questions:String(e.id_questions), id_circuit: "", strQuestInstrum:questions.find((q) => Number(q.id) === e.id_questions)?.questions || "",
        site_id:e.site_id
      }
/*      ["","","","",pieces.find((p) => Number(p.id) === e.id_piece)?.name || "",
        /*postes.find((p) => Number(p.id) === e.id_poste)?.name || "",* usages.find((p) => Number(p.id) === e.id_type_usage)?.name || "",
        formatRep(e.reponses,e.id_type_usage), e.comments.replaceAll("}","").replaceAll("{",""), e.app_date_start?.toDateString().replace("T..*.Z","") || "", e.pw_veille];*/
      equipFI.push(theEquipFI)
    })
//    console.log(equipFI)  Offset : +4, puis 5
    return equipFI;
  }
  const getAppareils = () : lignesRecapStyle[] => {
    const voiesRecap: lignesRecapStyle[] = new Array();
    let moduleFilter = "8"
    // module RADIO(s)
    //    equipements.filter((e)=>e.voies2mesures.length > 0 && e.app_date_end === null || undefined).map((eq) => {
    equipements.filter((e)=>e.voies2mesures.length > 0).map((eq) => {
      eq.voies2mesures.filter((v)=>String(v.id_module).match("/^"+moduleFilter) && v.voie_date_end !== null).map((vm) => {
        let line: lignesRecapStyle = { type:vm.type_sensor, conc:vm.id_conc, module:vm.id_module, voie:vm.number, id_sensor:vm.id_sensor,
          piece:(pieces.find((p)=>Number(p.id)===eq.id_piece)?.name || "")+(eq.str_piece?.replace(new RegExp(/^..*.\(/)," (") || ""),
          usage:usages.find((u)=>Number(u.id)===eq.id_type_usage)?.name || "", 
          commentaire:formatComments(eq.comments+" "+vm.commentaires), reponses:formatRep(eq.reponses,eq.id_type_usage), voie_date_end: vm.voie_date_end,
          puiss_veille:eq.pw_veille, remarque:"", chgmnt_etat:"", nvelle_ref:"", thermostat:"", id_voie:vm.id, app_date_end: eq.app_date_end }
/*        //@ts-ignore
        let line: lignesRecapStyle = [vm.type_sensor, String(vm.id_conc), String(vm.id_module), vm.number, String(vm.id_sensor),
          pieces.find((p)=>Number(p.id)===eq.id_piece)?.name, usages.find((u)=>Number(u.id)===eq.id_type_usage)?.name, eq.comments+" "+vm.commentaires, "", eq.reponses, "", "", "", eq.pw_veille, vm.id]*/
        voiesRecap.push(line)
      })
    })
    // modules MVs
//    equipements.filter((e)=>e.voies2mesures.length > 0 && e.app_date_end === null || undefined).map((eq) => {
    equipements.filter((e)=>e.voies2mesures.length > 0).map((eq) => {
      eq.voies2mesures.filter((v)=>!String(v.id_module).match("/^"+moduleFilter) && v.voie_date_end === null || undefined).map((vm) => {
        let line: lignesRecapStyle = { type:vm.type_sensor, conc:vm.id_conc, module:vm.id_module, voie:vm.number, id_sensor:vm.id_sensor,
          piece:(pieces.find((p)=>Number(p.id)===eq.id_piece)?.name || "")+(eq.str_piece?.replace(new RegExp(/^..*.\(/)," (") || ""),
          usage:usages.find((u)=>Number(u.id)===eq.id_type_usage)?.name || "", voie_date_end: vm.voie_date_end, app_date_end: eq.app_date_end,
          commentaire:formatComments(eq.comments+" "+vm.commentaires), reponses:formatRep(eq.reponses,eq.id_type_usage), puiss_veille:eq.pw_veille, remarque:"",
          chgmnt_etat:"", nvelle_ref:"", thermostat:"", id_voie:vm.id }
/*        //@ts-ignore
        let line: lignesRecapStyle = [vm.type_sensor, String(vm.id_conc), String(vm.id_module), vm.number, String(vm.id_sensor),
          pieces.find((p)=>Number(p.id)===eq.id_piece)?.name, usages.find((u)=>Number(u.id)===eq.id_type_usage)?.name, eq.comments+" "+vm.commentaires, "", eq.reponses, "", "", "", eq.pw_veille, vm.id]*/
        voiesRecap.push(line)
      })
    })
    //    equipements.filter((e)=>e.voies2mesures.length === 0 && e.app_date_end === null || undefined).map((eq) => {
    equipements.filter((e)=>e.voies2mesures.length === 0).map((eq) => {
      let line: lignesRecapStyle = { type:"", conc:0, module:0, voie:0, id_sensor:"",
        piece:(pieces.find((p)=>Number(p.id)===eq.id_piece)?.name || "")+(eq.str_piece?.replace(new RegExp(/^..*.\(/)," (") || ""), 
        usage:usages.find((u)=>Number(u.id)===eq.id_type_usage)?.name || "", voie_date_end: null, app_date_end: eq.app_date_end,
        commentaire:formatComments(eq.comments), reponses:formatRep(eq.reponses,eq.id_type_usage), puiss_veille:eq.pw_veille, 
        remarque:"", chgmnt_etat:"", nvelle_ref:"", thermostat:"", id_voie:0 }
/*        //@ts-ignore
        let line: lignesRecapStyle = ["","","","","",
          pieces.find((p)=>Number(p.id)===eq.id_piece)?.name, usages.find((u)=>Number(u.id)===eq.id_type_usage)?.name, eq.comments, "", eq.reponses, "", "", "", eq.pw_veille]*/
        voiesRecap.push(line)
    })
    // APPS, SUIVIS terminés
    equipements.filter((e)=>e.voies2mesures.filter((v)=>v.voie_date_end !== null)).map((eq) => {
      eq.voies2mesures.filter((v)=>v.voie_date_end !== null).map((v)=> {
        let line: lignesRecapStyle = { type:v.type_sensor, conc:v.id_conc, module:v.id_module, voie:v.number, id_sensor:v.id_sensor,
          piece:(pieces.find((p)=>Number(p.id)===eq.id_piece)?.name || "")+(eq.str_piece?.replace(new RegExp(/^..*.\(/)," (") || ""), 
          usage:usages.find((u)=>Number(u.id)===eq.id_type_usage)?.name || "", voie_date_end: v.voie_date_end, app_date_end: eq.app_date_end || null,
          commentaire:formatComments(eq.comments), reponses:formatRep(eq.reponses,eq.id_type_usage), puiss_veille:eq.pw_veille, 
          remarque:"", chgmnt_etat:"", nvelle_ref:"", thermostat:"", id_voie:0 }
  /*        //@ts-ignore
          let line: lignesRecapStyle = ["","","","","",
            pieces.find((p)=>Number(p.id)===eq.id_piece)?.name, usages.find((u)=>Number(u.id)===eq.id_type_usage)?.name, eq.comments, "", eq.reponses, "", "", "", eq.pw_veille]*/
          voiesRecap.push(line)
        })
    })
    // APPS, NON-SUIVIS terminés
    equipements.filter((e)=>e.voies2mesures.length === 0 && e.app_date_end !== null).map((eq) => {
        let line: lignesRecapStyle = { type:"", conc:0, module:0, voie:0, id_sensor:"",
          piece:(pieces.find((p)=>Number(p.id)===eq.id_piece)?.name || "")+(eq.str_piece?.replace(new RegExp(/^..*.\(/)," (") || ""), 
          usage:usages.find((u)=>Number(u.id)===eq.id_type_usage)?.name || "", voie_date_end: null, app_date_end: eq.app_date_end,
          commentaire:formatComments(eq.comments), reponses:formatRep(eq.reponses,eq.id_type_usage), puiss_veille:eq.pw_veille, 
          remarque:"", chgmnt_etat:"", nvelle_ref:"", thermostat:"", id_voie:0 }
  /*        //@ts-ignore
          let line: lignesRecapStyle = ["","","","","",
            pieces.find((p)=>Number(p.id)===eq.id_piece)?.name, usages.find((u)=>Number(u.id)===eq.id_type_usage)?.name, eq.comments, "", eq.reponses, "", "", "", eq.pw_veille]*/
          voiesRecap.push(line)
    })
    return voiesRecap.filter((vr)=>vr.module !== 0).sort((a,b)=>a.module>b.module? 1 : -1).concat(voiesRecap.filter((vr)=>vr.module === 0));
  }
  const getVoiesRECAP = () : Voie2mesureFI[] => {
    const voiesRecap: Voie2mesureFI[] = new Array(/*voies2mesures.length*/);
    voies2mesures.filter((vm) => vm.voie_date_end !== null || undefined).map((v, index) => {
      //console.log("TITI:"+v.id+","+v.id_conc+","+v.id_module+","+v.id_sensor)
      //@ts-ignore
      let theVoieRecap: Voie2mesureFI = [v.type_sensor, String(v.id_conc), String(v.id_module), v.number,
        "Dans "+equipements.filter((e) => Number(e.id) === v.id_equipement)?.map((e) => e.room?.name)+", "+
        equipements.filter((e) => Number(e.id) === v.id_equipement)?.map((e) => e.usage?.name),
        equipements.find((e) => Number(e.id) === v.id_equipement)?.reponses,
        formatComments(v.commentaires), formatDate(v.voie_date_start),
        equipements.find((e) => Number(e.id) === v.id_equipement)?.pw_veille !== undefined ? equipements.find((e) => Number(e.id) === v.id_equipement)?.pw_veille : null];
      voiesRecap.push(theVoieRecap)
    })
    //console.log(voiesRecap) // 10 champs 
    return voiesRecap;
  }
  const getVoiesRECAPback = () : Voie2mesureFI[] => {
    const voiesRecap: Voie2mesureFI[] = new Array(/*voies2mesures.length*/);
    voies2mesures.filter((vm)=>vm.voie_date_end != null).sort((a,b)=>a.number < b.number ? 1 : -1).map((v, index) => {
      let theVoieRecap: Voie2mesureFI = { type_sensor:v.type_sensor, id_conc:v.id_conc, id_module:v.id_module, num_voie:v.number, id_sensor:v.id_sensor,
        piece:(equipements.find((e) => Number(e.id) === v.id_equipement)?.room?.name || "")+(equipements.find((e) => Number(e.id) === v.id_equipement)?.str_piece?.replace(new RegExp(/^..*.\(/)," (") || ""),
        usage:equipements.find((e) => Number(e.id) === v.id_equipement)?.usage?.name || "",
        freq_controle:v.freq_controle, phase:v.phase, commentaires:formatComments(v.commentaires), 
        voie_date_start:formatDate(v.voie_date_start), voie_date_end:formatDate(v.voie_date_end),
        no_type:v.no_type, site_id:v.site_id, site_sn:v.site_sn, id_equipement:v.id_equipement, id:v.id }
      console.log(voiesRecap)
      voiesRecap.push(theVoieRecap)
    })
    //console.log(voiesRecap)
    return voiesRecap;
  }
  const getLogementsHeader4Recap = () => {
/*    const theSite = sites.sort((a,b)=>a.id>b.id?1:-1).find((s)=>s.sn_site===siteSn)
    const data = theSite?.contacts.sort((a,b)=>a.id>b.id?1:-1)[0].prenom+" "+theSite?.contacts.sort((a,b)=>a.id>b.id?1:-1)[0].nom+" ("+theSite?.contacts.sort((a,b)=>a.id>b.id?1:-1)[0].site_sn+
      ")"+theSite?.contacts.sort((a,b)=>a.id>b.id?1:-1)[0].mail+"\n"+theSite?.contacts.sort((a,b)=>a.id>b.id?1:-1)[0].adresse+" "+
      theSite?.contacts.sort((a,b)=>a.id>b.id?1:-1)[0].code_postal+" "+theSite?.contacts.sort((a,b)=>a.id>b.id?1:-1)[0].tel
  
    const statsOccupy = theSite?.tx_occupation*/
    const statsOccupy = sites[Number(siteId)].tx_occupation
    console.log("siteID: "+siteId+" txOccupy: "+statsOccupy)
    let set: Array<occupationProps> = []
    set.push({ siteId: "0", range: "0-10", nombre: 0, taux: 0 })
    set.push({ siteId: "0", range: "11-17", nombre: 0, taux: 0 })
    set.push({ siteId: "0", range: "18-24", nombre: 0, taux: 0 })
    set.push({ siteId: "0", range: "25-59", nombre: 0, taux: 0 })
    set.push({ siteId: "0", range: "60-74", nombre: 0, taux: 0 })
    set.push({ siteId: "0", range: ">74", nombre: 0, taux: 0 })
    if(statsOccupy !== undefined) {
      statsOccupy.map((line) => {
        let tab: string[] = line.split("|")
        if(tab[1].replace("nb ","").length > 0) {
          //set["range"=tab[0]].nombre = 
          set.find((s)=>s.range.match(tab[0]))!.nombre = Number(tab[1].replace("nb ",""))
          set.find((s)=>s.range.match(tab[0]))!.taux = Number(tab[2].replace("poids ",""))
//          set.push({siteId: siteId, range: tab[0], nombre: Number(tab[1].replace("nb ","")), taux: Number(tab[2].replace("poids ","")) })
        }
      })
    }
    console.log("set OccupationProps: "+set)
    return set
  }
  const helpContent = () => {
    return (
      <Paper className={classes2.detailsHeader}>
        <div className={classes2.detailsHeader}>
        <Typography variant="body2" color="secondary">
          Fonctions disponibles :<br/>
          - ajouter un équipement<br/>
          - modifier un équipement (suivre, ne pas suivre, modifier les infos appareil/poste/pièce) =&gt; menu "..." -&gt; "Correction.."<br/>
          - modifier la voie de suivi =&gt; menu "..." -&gt; "Correction.."<br/>
          - supprimer un équipement =&gt; menu "..." -&gt; "Correction.."
          <Divider style={{ margin: '0.5em 0' }} />
        </Typography>
        <Typography variant="body2" color="secondary">
          Toutes ces actions sont des corrections de base de donnée : elle permettent de corriger l'état de l'instrumentation du logement.<br/>
          Pour les actions "évolution de l'instrumentation" (déplacement d'appareil, changement de WS/WP, basculement suivi/non-suivi, changement de référence, etc..), utilisez le sous-menu du menu "..." -&gt; "Évolution.."
          <Divider style={{ margin: '0.5em 0' }} />
        </Typography>
        </div>
      </Paper>
    )
  };
  const { sortBy, filterBy, grep } = useSelector(selectEquipementsState);

  const concItems: { value: string; label: string; }[] = []
  concItems.push({ value: '0', label: 'Tous' })
  let theLabel = ""
  //@ts-ignore
  conc.slice().sort((a, b) => +new Date(b.date_start) - +new Date(a.date_start)).map((c) => {
    c.date_end !== null ?
      theLabel = c.hardSn+" (fin: "+c.date_end+") / "+c.techno$
    : theLabel = c.hardSn+" (début: "+c.date_start+") / "+c.techno
    //theLabel.replace(/T[0-9]\{2\}:[0-9]\{2\}:[0-9]\{2\}.000Z/,"")
    //theLabel.replace(/(\w+)T/,"$1")
    theLabel.replace("Z","")
    concItems.push({ value: c.hardSn, label: theLabel.replace(/(\w+)T..*.\)/,"$1)") })
  })
  let concMod: { sn: string; modules: number[]; }[] = []
  conc/*.sort((a,b) => a.date_end && b.date_end === null ? 1 : -1 /*a.date_end === null ? -1 : b.date_end !== null ? 1 : 0)*/./*filter((conc)=>conc.date_end===null).*/map((cc) => {
    let modz : number[] = Array.from(cc.modules).sort((a,b) => a > b ? a : b)
    concMod.push({'sn': cc.hardSn, 'modules': modz})
  })
  const export2CSV = () => {
    const nonSuivis = getAppareilsNonSuivis();
    CsvDataService.exportToCsv(equipements, usages, postes, pieces, conc, voies2mesures, getAppareilsFI(), getVoiesRECAPback(), getAppareils().sort((a,b) =>
    a.module > b.module ? 1 : -1).sort((a,b)=>a.voie < b.voie ? 1 : -1), concMod, sites.find((s)=>Number(s.id) === Number(siteId))!/*suivis, nonSuivis*/, getLogementsHeader4Recap());
  };
  const sortedConcModz = concMod.sort((a,b) => b.modules.length - a.modules.length);
  
  const handleGrepChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    let selectedConc = e.target.value as string
    console.log("selectedConc changed to: "+selectedConc)
    dispatch(grepEquipementsBy(selectedConc));
  };
  //dispatch(grepEquipementsBy('0'))
  console.log("GREP value : "+grep)
  const handleSortChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    const selectedValue = e.target.value as EquipementSortValues;
    dispatch(sortEquipementsBy(selectedValue));
    dispatch(sortVoiesBy(selectedValue));
  };

  const handleFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = e.target.value as EquipementFilterValues;
//    console.log("HandleFilterChange: "+selectedValue);
    dispatch(filterEquipementsBy(selectedValue));
    dispatch(filterVoiesBy(selectedValue));
  };

/*  const { usages, fetchLoading, fetchError } = useSelector(
    selectUsagesState
  );*/
  conc.map((c) => console.log("Concentrateur "+c.hardSn+" / "+c.techno+" (v"+c.version+") [ +" +c.tel+" ]"))

  return (
    <div>
      <div className={classes.inputs}>
        <div className={classes.searchBarWrapper} style={{marginRight: '0.4rem'}}>
          <FilterBar
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            label="Equipements"
            size={isMobile ? 'small' : 'medium'}
          />
        </div>
        <div className={classes.sortBarWrapper} style={{marginRight: '0.4rem'}}>
          <FilterConcentrateur
            grepValue={grep}
            handleGrepChange={handleGrepChange}
            menuItems={concItems}
            label="Concentrateurs"
            size={isMobile ? 'small' : 'medium' }
          /> {/*concentrateurs.map((c) => c.date_end ? c.hardSn + "/" + c.techno + "(v" + c.version + ") [" + c.tel + "]" : null)*/}
        </div>
        <div className={classes.sortBarWrapper}>
          <SortBar
            sortBy={sortBy}
            handleSortChange={handleSortChange}
            menuItems={menuItems}
            label="Equipements"
            size={isMobile ? 'small' : 'medium'}
          />
        </div>
      </div>
      <div className={classes.flexWrapper} style={{ width: '100%', display: 'inline-block' }}>
        {isOpen && (
          <>
            <div style={{ width: '100%' }}>
              <div style={{ width: '100%', display: 'inline' }}>{helpContent()}</div>
              <Button style={{ margin: 'auto 1em', padding: '9px 35px', float: 'left' }}
              size="large"
              color="primary"
              variant="contained"
              //className={classes.submitBtn}
              onClick={closeDialog}>Fermer l'aide</Button>
            </div>
          </>
        )}
        <div style={{ width: '100%' }}>
        {!isOpen && (
          <Button style={{ margin: 'auto 1em', padding: '9px 35px', float: 'left' }}
            className="open-btn"
            size="large"
            color="primary"
            variant="contained"
            //className={classes.submitBtn}
            onClick={openDialog}>
            <IconQuestion />
          </Button>
        )}
        <FormDialog 
          triggerBtn={
            isMobile
              ? {
                  type: 'fab',
                  variant: 'extended',
                  text: 'équipement',
                  icon: AddIcon,
                }
              : {
                  type: 'normal',
                  text: 'équipement',
                  icon: AddIcon,
                  size: 'large',
                }
          }
          title="Ajouter un nouvel équipement"
        >
        {/*<EquipementForm isSuivi={false} isEditMode={false} siteSn={siteSn} siteId={siteId} conc={conc} 
        usages={usages} postes={postes} pieces={pieces} voiesDuSite={voies2mesures} questions={questions} sn_module={conc.slice(-1)[0].modules.slice(-1)[0]} newEquip={true}
        voieId={1} dbleSuivi={false} cas={"device"} />*/}
        <EquipementForm isSuivi={false} isEditMode={false} siteSn={siteSn} siteId={siteId} conc={conc} 
          usages={usages} postes={postes} pieces={pieces} voiesDuSite={voies2mesures} questions={questions} sn_module={conc.slice(-1)[0].modules.slice(-1)[0]} newEquip={true}
          voieId={1} dbleSuivi={false} cas={"device"} />
        </FormDialog>
        <Button style={{ margin: 'auto 0',/* padding: '9px 35px',*/ float: 'left' }}
              size="large"
              color="primary"
              variant="contained"
//              className={classes.submitBtn}
              onClick={export2CSV}><DLIcon/> &nbsp;CSV
        </Button>
        <Button style={{ margin: 'auto 1em', padding: '8px 20px', float: 'left' }}
          className="open-btn"
          size="large"
          color="primary"
          variant="contained"
          //className={classes.submitBtn}
          onClick={updateGrafanaLegends}>
          <RefreshIcon /> &nbsp;Légendes
        </Button>

        <Button style={{ margin: 'auto 1em',/* padding: '9px 35px',*/ float: 'left' }} size="large" color="primary" variant="contained" >
        <a style={{color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none', lineHeight: '0.85'}} href={"https://visu.enertech.fr/d/_9Gr38AVz/par-logement?orgId=57&var-log=log_"+shortSnSite} target="_blank"
          ><img /*style={{top: '0.15rem', position: 'relative'}}*/ src={GrafanaIcon} width="25"/>
        </a>
        </Button>
        {/*<ExcelExportComponent siteId={siteId} siteSn={siteSn} conc={conc} usages={usages} postes={postes} pieces={pieces} voies2mesures={voies2mesures} questions={questions} equipements={equipements} />*/}
        <FormControl component="fieldset" style={{ float: 'right' }}>
          <FormLabel component="legend" style={{ fontSize: '0.8em' }}>
            Filtrer les équipements par
          </FormLabel>
          <RadioGroup row value={filterBy} onChange={handleFilterChange}>
            <FormControlLabel
              value="all"
              control={<Radio color="primary" />}
              label="Tous"
            />
            <FormControlLabel
              value="closed"
              control={<Radio color="primary" />}
              label="Terminés"
            />
            <FormControlLabel
              value="open"
              control={<Radio color="primary" />}
              label="Présents"
            />
            <FormControlLabel
              value="presentssuivis"
              control={<Radio color="primary" />}
              label="Présents(+suivis)"
            />
          </RadioGroup>
        </FormControl>
        </div> 
      </div>
    </div>
  );
};

export default EquipementsActionCard;
