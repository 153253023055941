import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../store';
import usageService from '../../services/usages';
import { 
  Usage,
//  UsageFilterValues,
  UsagePayload,
//  UsageSortValues,
  UsageState,
//  SiteUsage,
} from '../types';
import { notify } from './notificationSlice';
import { getErrorMsg } from '../../utils/helperFuncs';

interface InitialUsageState {
//  usages: { [siteId: string]: UsageState[] };
//  usages: Usage[];
  usages: UsageState[]; // previously disabled 230316
  fetchLoading: boolean;
  fetchError: string | null;
  submitLoading: boolean;
  submitError: string | null;
/*  sortBy: UsageSortValues,
  filterBy: UsageFilterValues,*/
//  status: 'idle' | 'loading' | 'succeeded';
//  error: string | null;
}

const initialState: InitialUsageState = {
  usages: [],
  fetchLoading: false,
  fetchError: null,
  submitLoading: false,
  submitError: null,
//  sortBy: 'newest',
//  filterBy: 'all',
/*  usages: [],
  status: 'idle',
  error: null,*/
};

const usagesSlice = createSlice({
  name: 'usages',
  initialState,
  reducers: {
//    setUsages: (state, action: PayloadAction<{ usage: UsageState[], siteId: string }>) => { //230320
    //setUsages: (state, action: PayloadAction<Usage[]>) => {
      setUsages: (state, action: PayloadAction<UsageState[]>) => {
      state.usages = action.payload;
//      state.usages[action.payload.siteId] = action.payload.usage;   //230320
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;
    },
//    setSiteUsages: (state, action: PayloadAction<{ usage: Usage, siteId: string }>) => {
    setSiteUsages: (state, action: PayloadAction<{ usage: UsageState, site_id: string }>) => {
      //setUsages: (state, action: PayloadAction<UsageState[]>) => {
      //state.usages[action.payload.siteId] = action.payload.usage.;
/*      state.usages[action.payload.usage.id] = action.payload;
      state.fetchLoading = false;
//      state.status = 'succeeded';
      state.fetchError = null;*/
    },
    setUsagesLoading: (state) => {
      state.fetchLoading = true;
//      state.status = 'loading';
      state.fetchError = null;
    },
    setFetchUsagesError: (state, action: PayloadAction<string>) => {
      state.fetchLoading = false;
      state.fetchError = action.payload;
    },
  },
});

export const { setUsages, setSiteUsages, setUsagesLoading, setFetchUsagesError } = usagesSlice.actions;

export const fetchUsages = (): AppThunk => {
  return async (dispatch) => {
    try {
      dispatch(setUsagesLoading());
      const allUsages = await usageService.getUsages();
      dispatch(setUsages(allUsages));
    } catch (e) {
      //@ts-ignore
      dispatch(notify(getErrorMsg(e), 'error'));
    }
  };
};

export const selectUsagesBySiteId = (state: RootState, site_id: string) => {
  return state.usages.usages?.[Number(site_id)];
  //return state.usages.usages?.[Number(siteId)];
};

export const selectUsagesState = (state: RootState) => state.usages;
export const selectUsageById = (
  state: RootState,
  site_id: string,
  usageId: string) => {
	  return state.usages.usages?.[Number(site_id)]; //.find((p) => p.id === usageId);
//    return state.usages.usages/*?.[Number(siteId)]*/.find((p) => p.id === usageId);
};

export default usagesSlice.reducer;
